// TODO: create common createEditModal
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup } from "reactstrap";

import * as commonDataSelectors from "./../../../common/redux/data/selectors";
import * as formValidatorActions from "./../../../common/services/form-validator/actions";
import * as processesSelectors from "./../../../common/redux/processes/selectors";
import dictionary from "./../../../common/config/dictionary";
import classname from "./../../../common/helpers/classname";
import RegularModal from "./../../../common/components/blocks/RegularModal";
import DropdownMenu, {
  DropdownItem,
} from "./../../../common/components/elements/DropdownMenu";
import InputText from "./../../../common/components/elements/InputText";
import RichTextEditor from "./../../../common/components/elements/RichTextEditor";
import Button from "./../../../common/components/elements/Button";
import Spinner, {
  spinnerSizes,
} from "./../../../common/components/elements/Spinner";
import ErrorBlock from "./../../../common/components/blocks/ErrorBlock";
import * as commonConfig from "../../../common/config";

import "./style.css";
import * as config from "./config";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";
import { processNames } from "./../redux/sagas";

const componentName = "StatusMessageCreateEditorModal";
const cn = classname(componentName);

function CreateEditModal() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const processes = useSelector(processesSelectors.getProcesses);
  const commonData = useSelector(commonDataSelectors.getData);

  const selectForm = (state) => {
    return selectors.getUi(state).createEditModal.form;
  };

  const updateFormActionCreator = (updateOb) => {
    return actions.updateUi({ createEditModal: { form: updateOb } });
  };

  const updateForm = (updateOb) => {
    dispatch(updateFormActionCreator(updateOb));
  };

  const validateAndUpdateProp = ({ event, prop, value }) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        value,
        formSelector: selectForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    const {
      createEditModal: { type },
    } = ui;

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["id", "message"],
        formSelector: selectForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () =>
          dispatch(config.mapModalTypeToConfig[type].onValidActionCreator()),
      })
    );
  };

  const {
    createEditModal: { show, type, form },
  } = ui;

  if (!show) {
    return null;
  }

  let process = processes[processNames.CREATE_EDIT_MODAL_PROCESS] || {};

  return (
    <RegularModal
      className={componentName}
      onCancel={() =>
        dispatch(actions.updateUi({ createEditModal: { show: false } }))
      }
    >
      <Form onSubmit={onSubmit}>
        <h2 className="text-color1 mb-5">
          <b>{config.mapModalTypeToConfig[type].title}</b>
        </h2>
        {/*id*/}
        {/* // Should not be possible to edit Id-field as it is system-genrated
            type === config.createEditModalTypes.CREATE ?
              <React.Fragment>
                <FormGroup>
                  <InputText
                    id={form.id.id}
                    type={form.id.type}
                    label={form.id.label}
                    value={form.id.value}
                    hasError={form.id.hasError}
                    errorMessage={form.id.errorMessage}
                    onChange={(event) => this.validateAndUpdateProp({event, prop: 'id'})}
                  />
                </FormGroup>
              </React.Fragment>
              :
              null
          */}
        {/*message*/}
        <FormGroup>
          <InputText
            id={form.message.id}
            type={form.message.type}
            label={form.message.label}
            value={form.message.value}
            hasError={form.message.hasError}
            errorMessage={form.message.errorMessage}
            onChange={(event) =>
              validateAndUpdateProp({ event, prop: "message" })
            }
          />
        </FormGroup>
        {/*visibility*/}
        <FormGroup>
          <DropdownMenu
            label="Synlighet"
            toggleButtonText={
              form.visibility.payload && form.visibility.payload.title
            }
          >
            {Object.keys(commonConfig.visibilitiesConfig).map((visibility) => {
              if (visibility == commonConfig.visibilities.INHERIT) return null;

              let ob = commonConfig.visibilitiesConfig[visibility];

              return (
                <DropdownItem
                  key={ob.value}
                  onClick={() =>
                    updateForm({
                      visibility: { value: ob.value, payload: ob },
                    })
                  }
                >
                  {ob.title}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </FormGroup>
        {process.hasError ? (
          <FormGroup className="mb-5">
            <ErrorBlock error={process} />
          </FormGroup>
        ) : null}
        <FormGroup className="text-center">
          {process.inProcess ? (
            <Spinner size={spinnerSizes.MEDIUM} />
          ) : (
            <Button color="color1" className="w-50">
              {dictionary.SAVE}
            </Button>
          )}
        </FormGroup>
      </Form>
    </RegularModal>
  );
}

export default CreateEditModal;
