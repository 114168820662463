import React from "react";
import DebounceInput from "react-debounce-input";

import "./style.css";

import SearchIcon from "./../../icons/Search";
import * as cn from "../../../helpers/classname";

function SearchInput({ value, debounceTimeout, onChange }) {
  return (
    <div className="SearchInput">
      <div className={cn.join("custom-search-input", !!value && "not-empty")}>
        <DebounceInput
          id="browse-search"
          value={value}
          debounceTimeout={debounceTimeout || 300}
          className="form-control"
          onChange={(e) => onChange && onChange(e)}
          placeholder="Fritekstsøk"
        />
        <span className="bar" />
      </div>
      <label htmlFor="browse-search">
        <SearchIcon />
      </label>
    </div>
  );
}

export default SearchInput;
