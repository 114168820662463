import { take, call, select, put } from "redux-saga/effects";

import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchResetPassword() {
  while (true) {
    yield take(actions.RESET_PASSWORD);

    let form = yield select(selectors.getFormValues);

    let result = yield call(request.post, {
      path: "Account/resetpassword",
      processName: "resetPassword",
      params: form,
    });
  }
}

export function* watchFetchMinPasswordLength() {
  while (true) {
    let { userId } = yield take(actions.FETCH_MINPASSWORDLENGTH);

    let result = yield call(request.get, {
      path: `Account/minpasswordlength/${userId}`,
    });

    let updateOb = {
      minPasswordLength: 10,
    };

    if (result instanceof Error) continue;

    updateOb.minPasswordLength = result;
    yield put(actions.updateData(updateOb));
  }
}

export default createRootSaga(watchResetPassword, watchFetchMinPasswordLength);
