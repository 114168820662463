import { take, put, call, select } from "redux-saga/effects";

import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchRegister() {
  while (true) {
    yield take(actions.REGISTER);

    let form = yield select(selectors.getFormValues);

    yield call(request.post, {
      path: "Workshop/signup",
      processName: "register",
      params: form,
    });
  }
}

export function* watchFetchWorkshopTypes() {
  while (true) {
    yield take(actions.FETCH_WORKSHOP_TYPES);

    let result = yield call(request.get, {
      path: "WorkshopType",
      processName: "fetchWorkshopTypes",
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ workshopTypes: result }));
  }
}

export default createRootSaga(watchFetchWorkshopTypes, watchRegister);
