import { take, call, all, select } from "redux-saga/effects";

import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchForgotPassword() {
  while (true) {
    yield take(actions.FORGOT_PASSWORD);

    let form = yield select(selectors.getFormValues);

    let result = yield call(request.post, {
      path: "Account/forgotpassword",
      processName: "forgotPassword",
      params: form,
    });
  }
}

export default createRootSaga(watchForgotPassword);
