import superagent from "superagent";
import { put, call, select } from "redux-saga/effects";
import { delay } from "redux-saga";
import * as authorization from "auth-header";

import * as sessionService from "./../services/session";
import * as processesActions from "./../redux/processes/actions";
import * as processesSelectors from "./../redux/processes/selectors";
import * as sessionActions from "./../redux/session/actions";
import * as sessionSelectors from "./../redux/session/selectors";
import { 
  sessionExpirationSafetyMarginInSeconds, 
  SESSION_HAS_EXPIRED_MESSAGE 
} from "./session";
import * as toasterActions from "../../Toaster/redux/actions";

const LOGIN = "login";
const REFRESH_TOKEN = "refreshToken";
const LOGIN_WITH_ONE_TIME_PASSWORD = "loginWithOneTimePassword";

export const requestProcesses = {
  LOGIN: LOGIN,
  REFRESH_TOKEN: REFRESH_TOKEN,
  LOGIN_WITH_ONE_TIME_PASSWORD: LOGIN_WITH_ONE_TIME_PASSWORD,
};

const doRequest = ({
  method,
  path,
  fullPath,
  isFormUrlencoded,
  params,
  session,
}) => {
  let req = superagent[method](fullPath || `${window.config.apiUrl}/${path}`);

  if (session && session.isAuthorized)
    req.set("Authorization", `Bearer ${session.access_token}`);

  if (isFormUrlencoded) req = req.type("form");

  if (params) req = method === "get" ? req.query(params) : req.send(params);

  return new Promise((resolve) => {
    req.end((err, res) => {
      resolve([res, err]);
    });
  });
};

const checkForError = (response, error) => {
  let hasError = false;

  // synthetic error

  let e;

  // * in case of server side generated error (Business Error)

  if (response?.statusCode === 401) {
    e = new Error("Brukeren er ikke autorisert, prøv å logge ut og inn igjen på nytt.");
    e.status = response.statusCode;
    hasError = true;
  } else if (response && response.body && response.body.hasOwnProperty("errorCode")) {
    hasError = true;

    let { errorCode, message, validationErrors } = response.body;

    e = new Error(message);
    e.status = errorCode;
    if (errorCode === 1) e.payload = { validationErrors };

    // * in case of general server side error, e.g. non Business Errors
  } else if (
    response &&
    response.body &&
    response.body.hasOwnProperty("error") &&
    response.body.hasOwnProperty("errorDescription")
  ) {
    hasError = true;

    let { errorDescription } = response.body;

    e = new Error(errorDescription);
    e.status = 400;

    // * in case of open id error
  } else if (
    response &&
    response.body &&
    response.body.hasOwnProperty("error") &&
    response.body.hasOwnProperty("error_description")
  ) {
    hasError = true;

    let { error_description } = response.body;

    e = new Error(error_description);
    e.status = 400;

    // * in case of open id error in header
  } else if (
    response &&
    response.headers &&
    response.headers.hasOwnProperty("www-authenticate")
  ) {
    hasError = true;

    let wwwAuthenticate = response.headers["www-authenticate"];
    let auth = authorization.parse(wwwAuthenticate);
    let error_description = auth.params.error_description;

    e = new Error(error_description);
    e.status = response.statusCode;

    // * in case of normal error
  } else if (error && error instanceof Error) {
    hasError = true;

    e = error;
  } else if (!(response?.ok)) {
    e = new Error("En ukjent feil har skjedd");
    e.status = response.statusCode;
    hasError = true;
  }

  return { hasError, error: e };
}

function* getSession({
  type,
  username,
  password,
  mfaCode,
  mfaRecoveryCode,
  currentSession,
  rememberMe,
}) {
  let processName = "getSession";

  // refresh tokens are one-time use and when refreshing them in parallel only one will "win"
  // so we must prevent new calls to openid when getSession process already is running

  let processes = yield select(processesSelectors.getProcesses, [processName]);

  if (processes[processName].inProcess) {
    while (true) {
      // sleep for some time

      yield delay(100 /*ms*/);

      // check process one more time

      processes = yield select(processesSelectors.getProcesses, [processName]);

      // if process still running repeat

      if (processes[processName].inProcess) continue;

      // return result: error or session

      if (processes[processName].hasError) {
        let error = new Error(processes[processName].errorMessage);
        error.status = processes[processName].status;
        return error;
      }

      return yield select(sessionSelectors.getSession);
    }
  }

  // do request

  yield put(processesActions.addProcess(processName));

  let params;

  if (type === LOGIN) {
    params = {
      grant_type: "password",
      scope: rememberMe ? "openid offline_access" : "openid",
      username,
      password,
    };

    if (!!mfaCode) {
      params.mfaCode = mfaCode;
    }

    if (!!mfaRecoveryCode) {
      params.mfaRecoveryCode = mfaRecoveryCode;
    }

    // see: https://www.oauth.com/oauth2-servers/access-tokens/refreshing-access-tokens/
  } else if (type === REFRESH_TOKEN) {
    params = {
      grant_type: "refresh_token",
      refresh_token: currentSession.refresh_token,
    };
  } else if (type === LOGIN_WITH_ONE_TIME_PASSWORD) {
    params = {
      grant_type: "onetimepassword",
      scope: "openid offline_access",
      username,
      password,
    };
  }

  let [response, error] = yield call(doRequest, {
    method: "post",
    isFormUrlencoded: true,
    fullPath: `${window.config.serverAddress}/connect/token`,
    processName,
    params,
  });

  // check for error and insufficient response

  let errorResult = checkForError(response, error);

  if (errorResult.hasError) {
    let { error: e } = errorResult;

    let networkIsOfflineError = e.message.includes(
      "the network is offline, Origin is not allowed"
    );
    let refreshTokenIsNoLongerValidError =
      e.message.includes("refresh token has already been redeemed") ||
      e.message.includes("refresh token is no longer valid") ||
      e.message.includes("ppfriskingstokenet har allerede blitt brukt") ||
      e.message.includes("ppfriskingstokenet er ikke lenger gyldig");

    if (networkIsOfflineError || refreshTokenIsNoLongerValidError) {
      if (type === REFRESH_TOKEN) yield put(sessionActions.unsetSession());
      if (networkIsOfflineError)
        e.message =
          "Kunne ikke koble til server. Prøv å logge ut og inn på nytt eller prøv igjen senere";
      if (refreshTokenIsNoLongerValidError)
        e.message = SESSION_HAS_EXPIRED_MESSAGE;
    }

    yield put(
      processesActions.setError(processName, e.status, e.message, e.payload)
    );

    return e;
  }

  let result = response.body;

  if (
    !result?.id_token ||
    !result?.access_token ||
    (!result?.refresh_token && rememberMe)
  ) {
    let message = `insufficient response type in ${processName} process`;
    yield put(processesActions.setError(processName, null, message));
    return new Error(message);
  }

  // prepare session

  let session = yield call(sessionService.prepareSession, result);

  if (session instanceof Error) {
    yield put(processesActions.setError(processName, null, session.message));
    return session;
  }

  // save it

  yield put(sessionActions.setSession(session));

  // set success of process

  yield put(processesActions.setSuccess(processName, response.status));

  // dispatch successful login action

  yield put(sessionActions.successfulLogin());

  return session;
}

function* handleRegularRequest(descriptor) {
  let { processName, returnWholeResponse } = descriptor;

  // add process to processes if processName supplied

  if (processName) yield put(processesActions.addProcess(processName));

  // get current session

  let session = yield select(sessionSelectors.getSession);

  // refresh token if necessary

  const currentTime = new Date().getTime();
  const expirationTime =
    session.expirationTime - sessionExpirationSafetyMarginInSeconds * 1000; // Subtract x seconds to be on the safe side
  const hasTokenExpired = session.isAuthorized && expirationTime < currentTime;

  if (hasTokenExpired) {
    if (!session.refresh_token) {
      yield put(sessionActions.unsetSession());
      yield put(
        toasterActions.showSuccessMessage(SESSION_HAS_EXPIRED_MESSAGE)
      );
      return new Error(SESSION_HAS_EXPIRED_MESSAGE);
    }

    let newSession = yield call(getSession, {
      type: REFRESH_TOKEN,
      currentSession: session,
    });

    if (newSession instanceof Error) {
      if (processName)
        yield put(
          processesActions.setError(
            processName,
            newSession.status,
            newSession.message
          )
        );
      return newSession;
    }

    session = newSession;
  }

  // do request

  let [response, error] = yield call(doRequest, { ...descriptor, session });


  // check for error

  let errorResult = yield call(checkForError, response, error);

  if (errorResult.hasError) {
    let { error: e } = errorResult;

    let tokenIsNoLongerValidError =
      e.message.includes("oken is no longer valid") ||
      e.message.includes("okenet er ikke lenger gyldig");

    if (tokenIsNoLongerValidError) {
      yield put(sessionActions.unsetSession());
      e.message = SESSION_HAS_EXPIRED_MESSAGE;
    }

    let bidTokenIsNoLongerValidError = e.message.includes("eil budtoken");

    if (bidTokenIsNoLongerValidError) {
      // clear bid token
      yield put(
        sessionActions.updateSession(sessionService.prepareBidTokenUpdateOb())
      );
    }

    if (processName)
      yield put(
        processesActions.setError(processName, e.status, e.message, e.payload)
      );

    return e;
  }

  // on success

  if (processName)
    yield put(processesActions.setSuccess(processName, response.status));

  if (returnWholeResponse) return response;
  else return response.body;
}

export const requestService = {
  get: function* (descriptor) {
    return yield call(handleRegularRequest, { method: "get", ...descriptor });
  },
  post: function* (descriptor) {
    return yield call(handleRegularRequest, { method: "post", ...descriptor });
  },
  put: function* (descriptor) {
    return yield call(handleRegularRequest, { method: "put", ...descriptor });
  },
  del: function* (descriptor) {
    return yield call(handleRegularRequest, { method: "del", ...descriptor });
  },
  login: function* (credentials) {
    yield put(processesActions.addProcess(LOGIN));

    let result = yield call(getSession, { type: LOGIN, ...credentials });

    if (result instanceof Error) {
      yield put(
        processesActions.setError(LOGIN, result.status, result.message)
      );
    } else {
      yield put(processesActions.setSuccess(LOGIN, 200));
    }

    return result;
  },
  loginWithOneTimePassword: function* (credentials) {
    yield put(processesActions.addProcess(LOGIN_WITH_ONE_TIME_PASSWORD));

    let result = yield call(getSession, {
      type: LOGIN_WITH_ONE_TIME_PASSWORD,
      ...credentials,
    });

    if (result instanceof Error) {
      yield put(
        processesActions.setError(
          LOGIN_WITH_ONE_TIME_PASSWORD,
          result.status,
          result.message
        )
      );
    } else {
      yield put(processesActions.setSuccess(LOGIN_WITH_ONE_TIME_PASSWORD, 200));
    }

    return result;
  },
};

export default requestService;
