import { fork, take, put, call, select } from "redux-saga/effects";

import request from "./../../../../services/request";
import * as browseActions from "./../../../../../Browse/redux/actions";
import * as browseSelectors from "./../../../../../Browse/redux/selectors";
import * as homeActions from "./../../../../../Home/redux/actions";
import * as homeSelectors from "./../../../../../Home/redux/selectors";
import * as vehiclePageActions from "./../../../../../VehiclePage/redux/actions";
import * as vehiclePageSelectors from "./../../../../../VehiclePage/redux/selectors";
import * as immutable from "./../../../../helpers/immutable";
import { createRootSaga } from "../../../../services/redux-saga";
import { showErrorMessage } from "../../../../../Toaster/redux/actions";

import * as actions from "./actions";

function* updateFavoriteFlag({ estimateId, newValue }) {
  // update data

  let browseData = yield select(browseSelectors.getData);
  let homeData = yield select(homeSelectors.getData);

  // * update home & browse

  for (let instruction of [
    { data: browseData, actions: browseActions },
    { data: homeData, actions: homeActions },
  ]) {
    if (instruction.data.vehicles && instruction.data.vehicles.rows.length) {
      yield put(
        instruction.actions.updateData({
          vehicles: {
            rows: instruction.data.vehicles.rows.map((v) =>
              v.id == estimateId
                ? immutable.updateObjectProps(v, { isFavorite: newValue })
                : v
            ),
          },
        })
      );
    }
  }

  // * update vehicle page

  let vehiclePageData = yield select(vehiclePageSelectors.getData);

  if (vehiclePageData.vehicle && vehiclePageData.vehicle.id == estimateId) {
    yield put(
      vehiclePageActions.updateData({
        vehicle: immutable.updateObjectProps(vehiclePageData.vehicle, {
          isFavorite: newValue,
        }),
      })
    );
  }

  // make request

  let result;

  if (newValue) {
    result = yield call(request.post, {
      path: "Estimate/favorite",
      params: { estimateId },
    });
  } else {
    result = yield call(request.del, {
      path: `Estimate/favorite/${estimateId}`,
    });
  }

  if (result instanceof Error) {
    yield put(showErrorMessage(result.message));
    
    // return old data
    for (let instruction of [
      { data: browseData, actions: browseActions },
      { data: homeData, actions: homeActions },
    ]) {
      if (instruction.data.vehicles && instruction.data.vehicles.rows.length) {
        yield put(instruction.actions.updateData(instruction.data));
      }
    }

    if (vehiclePageData.vehicle && vehiclePageData.vehicle.id == estimateId) {
      yield put(vehiclePageActions.updateData(vehiclePageData));
    }

    return;
  }
}

function* watchUpdateFavoriteFlag() {
  while (true) {
    let action = yield take(actions.UPDATE_FAVORITE_FLAG);

    yield fork(updateFavoriteFlag, action);
  }
}

export default createRootSaga(watchUpdateFavoriteFlag);
