import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

const gallery = (state = {images: [], isOpen: false, current: 0, header: ''}, action) => {
  switch(action.type){
    case actions.UPDATE:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  };
};

export default gallery;