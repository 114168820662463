import React from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

import InfoCard from "../../common/components/blocks/InfoCard";
import Button from "../../common/components/elements/Button";
import { routes } from "../../common/config/routes";

function SaveRecoveryCodes({ codes, history }) {
  return (
    <React.Fragment>
      <Container className="MySettings">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Gjenopprettingskoder</b>
            </h1>
            <div className="form1 mt-3">
              <InfoCard
                type={InfoCard.types.WARNING}
                className="mb-3"
                title={
                  <span className="font-weight-bold">
                    Oppbevar disse kodene på et trygt sted.
                  </span>
                }
                message={
                  <React.Fragment>
                    <p className="mb-3">
                      Hvis du mister enheten hvor autentiseringsappen med
                      nøkkelen er installert og ikke har gjenopprettingskodene,
                      mister du tilgang til å logge inn med brukeren din. Vær
                      oppmerksom på at hver gjenopprettingskode kun kan brukes
                      en gang.
                    </p>
                    <ul>
                      {codes.map((code, i) => {
                        return (
                          <li key={i}>
                            <code>{code}</code>
                          </li>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                }
              ></InfoCard>
              <Button
                color="color1"
                className="w-100"
                onClick={() => history.push(routes.MY_SETTINGS.path)}
              >
                Gå til brukerinnstillinger
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default withRouter(SaveRecoveryCodes);
