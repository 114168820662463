import React from "react";
import { Container, Row, Col } from "reactstrap";

import { useScrollToTop, useTitle } from "../common/hooks";

import Form from "./Form";

function ForgotPassword() {
  useScrollToTop();
  useTitle("Glemt passord");

  return (
    <div className="ForgotPassword">
      <Container>
        <Row className="justify-content-center my-5">
          <Col xs="12" sm="10" md="8" lg="5" className="mt-3">
            <Form />
          </Col>
          <div className="w-100"></div>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPassword;
