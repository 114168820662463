import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";

import Filters from "./../common/components/blocks/Filters";
import VehicleCard from "./../common/components/blocks/VehicleCard";
import SearchInput from "./../common/components/elements/SearchInput";
import Spinner from "./../common/components/elements/Spinner";

import { useTitle, useScrollToTop } from "../common/hooks";
import * as processesSelectors from "./../common/redux/processes/selectors";
import * as scroll from "./../common/helpers/scroll";
import * as commonDataSelectors from "./../common/redux/data/selectors";

function Browse() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const filters = useSelector(selectors.getFilters);

  const commonData = useSelector(commonDataSelectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const getFirstPageProcess = processes[processNames.getFirstPage] || {};
  const loadMoreProcess = processes[processNames.loadMore] || {};

  // count how many rows to show
  const show = config.getVehiclesBy * (ui.showUntil + 1);

  useScrollToTop();

  useTitle("Tilbud");

  useEffect(function getFirstPage() {
    dispatch(actions.getFirstPage({ isInitial: true }));
  }, []);

  useEffect(
    function setScrollWatcher() {
      const handleScroll = () => {
        if (ui.blockHandleScrollFluid) {
          return;
        }

        if (scroll.timeToLoadMore() && getFirstPageProcess.isSuccessed) {
          dispatch(actions.loadMore());
        }
      };

      document.addEventListener("scroll", handleScroll);

      return () => document.removeEventListener("scroll", handleScroll);
    },
    [ui.blockHandleScrollFluid, getFirstPageProcess.isSuccessed]
  );

  const setFilter = (filter) => {
    if (filters.sortingField != filter) {
      dispatch(actions.updateFilters({ sortingField: filter }));
    }
  };

  return (
    <Container className="Browse mt-4" id="browse">
      <Row>
        <Col lg="3" md="12">
          <Filters
            filters={filters}
            update={(updateOb) => dispatch(actions.updateFilters(updateOb))}
          />
        </Col>
        <Col lg="9" md="12" className="right-block">
          <Row>
            <Col md="3" className="col-6 d-flex align-items-center">
              <div className="sorting-filters">
                <div
                  className={[
                    "sorting-filter",
                    filters.sortingField === "created" ? "active" : "",
                  ].join(" ")}
                  onClick={() => setFilter("created")}
                >
                  Nyeste
                </div>
                <div className="divider"></div>
                <div
                  className={[
                    "sorting-filter",
                    filters.sortingField === "bidDeadline" ? "active" : "",
                  ].join(" ")}
                  onClick={() => setFilter("bidDeadline")}
                >
                  Utløper
                </div>
              </div>
            </Col>
            <Col md="9" className="col-6">
              <SearchInput
                value={filters.filterValue}
                onChange={(e) =>
                  dispatch(
                    actions.updateFilters({ filterValue: e.target.value })
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mt-3">
            {getFirstPageProcess.inProcess && (
              <Col xs="12">
                <Spinner />
              </Col>
            )}
            {getFirstPageProcess.isSuccessed &&
              data?.vehicles?.rows?.length > 0 &&
              data.vehicles.rows.slice(0, show).map((v) => {
                return (
                  <Col xs="12" key={v.id}>
                    <VehicleCard vehicle={v} commonData={commonData} />
                  </Col>
                );
              })}
            {getFirstPageProcess.isSuccessed &&
              !data?.vehicles?.rows?.length && (
                <Col xs="12 text-center">
                  <h4 className="text-color1 text-uppercase">
                    <b>Ingen treff</b>
                  </h4>
                </Col>
              )}
          </Row>
          <Row className="mt-4">
            <Col xs="12" className="text-center">
              {loadMoreProcess.inProcess ? <Spinner size="medium" /> : null}
              {getFirstPageProcess.hasError || loadMoreProcess.hasError ? (
                <div className="text-center">
                  <b className="text-danger ">Kunne ikke laste tilbud</b>
                  <br />
                  <b>
                    {getFirstPageProcess.errorMessage ||
                      loadMoreProcess.errorMessage}
                  </b>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Browse;
