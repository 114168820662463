import React, { useCallback, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  PagingState,
  SortingState,
  FilteringState,
  CustomPaging,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";

import BasicModal from "./../../common/components/blocks/BasicModal";
import classname from "./../../common/helpers/classname";
import dictionary from "./../../common/config/dictionary";
import Page from "./../../common/components/blocks/Page";
import ErrorBlock from "./../../common/components/blocks/ErrorBlock";
import Spinner from "./../../common/components/elements/Spinner";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as gridUtils from "./../../common/helpers/grid-utils";

import "./style.css";
import CreateEditModal from "./CreateEditModal";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";

const cn = classname(config.componentName);

function AdminCompanies() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateUi = (updateOb, triggerFetchGrid = true) => {
    dispatch(actions.updateUi(updateOb, triggerFetchGrid));
  };

  const onSortingChange = (sorting) => updateUi({ sorting });
  const onCurrentPageChange = (currentPage) => updateUi({ currentPage });
  const onPageSizeChange = (currentPageSize) => updateUi({ currentPageSize });
  const onFiltersChange = (filters) => updateUi({ filters });

  const HeaderCell = useCallback((props) => {
    let { column } = props;

    if (column.name === "firstColumn") {
      return (
        <th onClick={() => dispatch(actions.launchCreateEditModal())}>
          <span className="link-button">{dictionary.NEW}</span>
        </th>
      );
    }

    return <TableHeaderRow.Cell {...props} />;
  }, []);

  const TableCell = useCallback((props) => {
    const { grid } = data;

    let { column, row } = props;

    if (column.name === "firstColumn") {
      return (
        <Table.Cell className={cn("first-column")}>
          <span
            className="link-button mr-3"
            onClick={() =>
              dispatch(actions.launchCreateEditModal(row.companyNumber))
            }
          >
            {dictionary.EDIT}
          </span>
          <span
            className="link-button text-color8"
            onClick={() => dispatch(actions.deleteItem(row.companyNumber))}
          >
            {dictionary.DELETE}
          </span>
        </Table.Cell>
      );
    }

    return <Table.Cell {...props} />;
  }, []);

  const FilterCell = useCallback((props) => {
    let { column } = props;

    if (column.name === "firstColumn") return <TableFilterRow.Cell />;

    return <TableFilterRow.Cell {...props} />;
  }, []);

  const TradeCondFormatterComponent = useCallback(({ value }) => {
    return (
      <span
        className="link-button"
        onClick={() =>
          dispatch(
            actions.updateUi({ tradeCondModal: { show: true, html: value } })
          )
        }
      >
        {dictionary.VIEW}
      </span>
    );
  }, []);

  useEffect(function () {
    dispatch(actions.fetchGrid());
  }, []);

  let fetchGridProcess = processes[processNames.FETCH_GRID] || {};

  let isDataPopulated = data.grid.rows;

  return (
    <Page className={config.componentName} title={config.title} fluid={true}>
      <CreateEditModal />
      <BasicModal
        header="Salgsbetingelser"
        show={ui.tradeCondModal.show}
        html={ui.tradeCondModal.html}
        onClose={() =>
          dispatch(actions.updateUi({ tradeCondModal: { show: false } }))
        }
      />
      <Row>
        <Col>
          <AdminHeader title={config.title} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={cn("content")}>
            {fetchGridProcess.inProcess ? (
              isDataPopulated ? (
                // spinner that is showed over the grid
                <div className={cn("process-status-over-grid")}>
                  <Spinner size="medium" className={cn("spinner")} />
                </div>
              ) : (
                // first spinner that is showed on initial fetch
                <div className={cn("process-status")}>
                  <Spinner size="medium" />
                </div>
              )
            ) : null}
            {fetchGridProcess.hasError && !isDataPopulated ? (
              <div className={cn("process-status")}>
                <ErrorBlock error={fetchGridProcess} />
              </div>
            ) : null}
            {isDataPopulated ? (
              <React.Fragment>
                <div className={cn("grid-container")}>
                  <Grid rows={data.grid.rows} columns={config.columns}>
                    {/*data formatters*/}
                    <gridUtils.BooleanTypeProvider for={["accessDBSM"]} />
                    <gridUtils.DateTypeProvider for={["tradeCondTime"]} />
                    <DataTypeProvider
                      for={["tradeCond"]}
                      formatterComponent={TradeCondFormatterComponent}
                    />
                    {/*sorting*/}
                    <SortingState
                      sorting={ui.sorting}
                      onSortingChange={onSortingChange}
                    />
                    {/*filtering*/}
                    <FilteringState
                      filters={ui.filters}
                      onFiltersChange={onFiltersChange}
                      columnExtensions={config.filteringStateColumnExtensions}
                    />
                    {/*paging*/}
                    <PagingState
                      currentPage={ui.currentPage}
                      pageSize={ui.currentPageSize}
                      onCurrentPageChange={onCurrentPageChange}
                      onPageSizeChange={onPageSizeChange}
                    />
                    <CustomPaging totalCount={data.grid.totalCount} />
                    {/*table ui components*/}
                    <Table
                      messages={gridUtils.tableMessages}
                      /*columnExtensions={config.columnExtensions}*/
                      cellComponent={TableCell}
                    />
                    <TableHeaderRow
                      showSortingControls
                      cellComponent={HeaderCell}
                    />
                    <TableFilterRow
                      messages={gridUtils.filterRowMessages}
                      showFilterSelector
                      cellComponent={FilterCell}
                    />
                    <PagingPanel
                      messages={gridUtils.pagingPanelMessages}
                      pageSizes={config.pageSizes}
                    />
                  </Grid>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Col>
      </Row>
    </Page>
  );
}

export default AdminCompanies;
