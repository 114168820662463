import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Gallery from "./../Gallery";
import StatusMessages from "./../common/components/blocks/StatusMessages";
import ProtectedRoute from "./../common/components/other/ProtectedRoute";
import * as commonConfig from "../common/config";

import Navigation from "./Navigation";
import * as config from "./config";
import * as actions from "./redux/actions";
import { useDispatch } from "react-redux";

function Main() {
  const dispatch = useDispatch();

  useEffect(function () {
    dispatch(actions.mainComponentDidMount());
  }, []);

  return (
    <div className="Main d-flex flex-column flex-grow-1">
      <Navigation
        logout={() => {
          dispatch(actions.fetchLogout());
        }}
      />
      <StatusMessages
        className="mb-6"
        show={window.config.showStatusMessages}
      />
      <Gallery />
      <Switch>
        {Object.keys(config.mapRouteNameToComponent).map((routeName) => {
          let { path, isProtected, role } = commonConfig.routes[routeName];
          let component = config.mapRouteNameToComponent[routeName];

          return isProtected ? (
            <ProtectedRoute
              key={routeName}
              path={path}
              component={component}
              role={role}
            />
          ) : (
            <Route key={routeName} path={path} component={component} />
          );
        })}
        <Redirect to={commonConfig.DEFAULT_ROUTE_PATH} />
      </Switch>
    </div>
  );
}

export default Main;
