const _prefix = 'REGISTER::';

export const UPDATE_FORM = _prefix + 'UPDATE_FORM';
export const UPDATE_UI = _prefix + 'UPDATE_UI';
export const UPDATE_DATA = _prefix + 'UPDATE_DATA';

export const FETCH_WORKSHOP_TYPES = _prefix + 'FETCH_WORKSHOP_TYPES';
export const REGISTER = _prefix + 'REGISTER';
export const VALIDATE_AND_UPDATE_PROP = _prefix + 'VALIDATE_AND_UPDATE_PROP';

export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});
export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});

export const fetchWorkshopTypes = () => ({type: FETCH_WORKSHOP_TYPES});
export const register = () => ({type: REGISTER});
export const validateAndUpdateProp = (event, prop) => ({type: VALIDATE_AND_UPDATE_PROP, event, prop});

