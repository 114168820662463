import React, { useCallback, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  RowDetailState,
  PagingState,
  SortingState,
  FilteringState,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableRowDetail,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import queryStringParser from "query-string";

import DateRangeFilter from "./../common/components/blocks/DateRangeFilter";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as gridUtils from "./../../common/helpers/grid-utils";
import Spinner from "./../../common/components/elements/Spinner";
import AdminBidsGrid from "./../common/components/blocks/AdminBidsGrid";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import * as immutable from "./../../common/helpers/immutable";

import "./style.css";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import * as models from "./redux/models";
import { useScrollToTop, useTitle } from "../../common/hooks";

const DateTypeProvider = gridUtils.DateTypeProvider;
const BooleanTypeProvider = gridUtils.BooleanTypeProvider;
const IntegerTypeProvider = gridUtils.IntegerTypeProvider;

function AdminWorkshops({ location }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  useScrollToTop();
  useTitle("Verksteder");

  const updateUi = (updateOb, triggerFetchWorkshops) => {
    dispatch(actions.updateUi({ updateOb, triggerFetchWorkshops }));
  };

  const TableRowDetailContentComponent = useCallback(({ row }) => {
    return (
      <AdminBidsGrid
        workshopNumber={row.workshopNumber}
        getBy="workshopNumber"
      />
    );
  }, []);

  const TableCellComponent = useCallback((props) => {
    return (
      <Table.Cell
        {...props}
        className="white-space-normal vertical-align-middle text-center"
      />
    );
  }, []);

  const TableRowComponent = useCallback(({ row, ...props }) => {
    const style =
      row.deletedDate || row.accessBlocked
        ? { backgroundColor: "#ffcccc" }
        : null;
    return (
      <Table.Row
        {...props}
        style={{
          ...style,
        }}
      />
    );
  }, []);

  const TableFilterRowCellComponent = useCallback((props) => {
    if (
      ["approvedDate", "created", "deletedDate"].includes(props.column.name)
    ) {
      return (
        <DateRangeFilter
          columnName={props.column.name}
          ui={ui}
          updateUi={updateUi}
        />
      );
    }

    return <TableFilterRow.Cell {...props} />;
  }, []);

  useEffect(function fetchWorkshopsOnMount() {
    let params = queryStringParser.parse(location.search);

    if (params.workshopNumber) {
      updateUi({
        filters: immutable.pushToArray(ui.filters, {
          columnName: "workshopNumber",
          operation: "contains",
          value: params.workshopNumber,
        }),
      });
    }

    dispatch(actions.fetchWorkshops());
  }, []);

  useEffect(function resetOnUnmount() {
    return () => {
      updateUi(models.ui);
    };
  }, []);

  let isDataPopulated = !!data.workshops;
  let fetchingProcess = processes.fetchWorkshops || {};

  return (
    <Container fluid className="AdminWorkshops">
      <Row>
        <Col>
          <AdminHeader title="Verksteder" />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col className="grid-container">
          {isDataPopulated && !fetchingProcess.hasError ? (
            <Grid rows={data.workshops.rows} columns={config.columns}>
              <PagingState
                currentPage={ui.currentPage}
                pageSize={ui.currentPageSize}
                onCurrentPageChange={(currentPage) =>
                  updateUi({ currentPage, expandedRowIds: [] }, true)
                }
                onPageSizeChange={(currentPageSize) =>
                  updateUi({ currentPageSize }, true)
                }
              />
              <RowDetailState
                expandedRowIds={ui.expandedRowIds}
                onExpandedRowIdsChange={(expandedRowIds) =>
                  updateUi({ expandedRowIds })
                }
              />
              <SortingState
                sorting={ui.sorting}
                onSortingChange={(sorting) =>
                  updateUi({ sorting, expandedRowIds: [] }, true)
                }
              />
              <FilteringState
                filters={ui.filters}
                onFiltersChange={(filters) =>
                  updateUi({ filters, expandedRowIds: [] }, true)
                }
                columnExtensions={config.filteringColumnExtensions}
              />
              <CustomPaging totalCount={data.workshops.totalCount} />
              <DateTypeProvider
                for={["approvedDate", "created", "deletedDate"]}
              />
              <BooleanTypeProvider for={["accessBlocked"]} />
              <Table
                messages={gridUtils.tableMessages}
                cellComponent={TableCellComponent}
                rowComponent={TableRowComponent}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow
                messages={gridUtils.filterRowMessages}
                showFilterSelector
                iconComponent={gridUtils.FilterIcon}
                cellComponent={TableFilterRowCellComponent}
              />
              <TableRowDetail
                contentComponent={TableRowDetailContentComponent}
              />
              <PagingPanel
                messages={gridUtils.pagingPanelMessages}
                pageSizes={config.pageSizes}
              />
            </Grid>
          ) : null}
          {fetchingProcess.inProcess ? (
            <div className="loading-block">
              <Spinner size="medium" />
            </div>
          ) : null}
          {fetchingProcess.hasError ? (
            <div className="text-center mt-5">
              <b className="text-danger">Det oppstod en feil</b>
              <br />
              <b>{fetchingProcess.errorMessage}</b>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default AdminWorkshops;
