export const ui = {
  verificationCodeForm: {
    code: {
      id: "code",
      type: "text",
      label: "Kode",
      value: "",
      hasError: false,
      errorMessage: "",
      rules: ["required"],
    },
  },
};

export const data = {};
