export const getForm = state => {
  state.ResetPassword.form.password.rules = ['required', {name: 'length', size: state.ResetPassword.data.minPasswordLength}];
  state.ResetPassword.form.confirmPassword.rules = ['required', {name: 'length', size: state.ResetPassword.data.minPasswordLength}, {name: 'equal', with: 'password'}];
  return state.ResetPassword.form;
}
export const getFormValues = state => {
  return Object.keys(state.ResetPassword.form).reduce((valuesOb, key) => {
    valuesOb[key] = state.ResetPassword.form[key].value;
    return valuesOb;
  }, {});
};
export const getData = state => state.ResetPassword.data;
