import React from "react";
import {
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";

import CaretBottomIcon from "./../../../common/components/icons/CaretBottom";
import * as userService from "./../../../common/services/user";

import * as tradeConditionsSelectors from "./../../../TradeConditions/redux/selectors";

function UserDropdown({ session, logout }) {
  const tradeConditionsData = useSelector(tradeConditionsSelectors.getData);

  let hasNewUpdatedTcs =
    tradeConditionsData.tc.new && tradeConditionsData.tc.new.rows.length;
  const isWorkshopUser = userService.isWorkshopUser(session);

  return (
    <UncontrolledDropdown className="UserDropdown dropdown1">
      <DropdownToggle className="toggle-button">
        <span>{userService.getFullName(session)}</span>
        <CaretBottomIcon />
      </DropdownToggle>
      <DropdownMenu right>
        <LinkContainer to="/mysettings">
          <DropdownItem>Brukerinnstillinger</DropdownItem>
        </LinkContainer>
        <LinkContainer to={hasNewUpdatedTcs ? "/tc/new" : "/tc/all"}>
          <DropdownItem>
            Salgsbetingelser
            {hasNewUpdatedTcs && isWorkshopUser ? (
              <React.Fragment>
                <Badge color="success" className="ml-3">
                  {tradeConditionsData.tc.new.rows.length}
                </Badge>
              </React.Fragment>
            ) : null}
          </DropdownItem>
        </LinkContainer>
        {isWorkshopUser ? (
          <React.Fragment>
            <DropdownItem divider />
            <LinkContainer to="/mybids/active">
              <DropdownItem>Mine aktive bud</DropdownItem>
            </LinkContainer>
            <LinkContainer to="/mybids/expired">
              <DropdownItem>Mine utløpte bud</DropdownItem>
            </LinkContainer>
            <LinkContainer to="/mybids/bought">
              <DropdownItem>Mine kjøp</DropdownItem>
            </LinkContainer>
            <DropdownItem divider />
          </React.Fragment>
        ) : null}
        <DropdownItem onClick={logout}>Logg ut</DropdownItem>
        {userService.isCompanyOrAdmin(session) ? (
          <React.Fragment>
            <DropdownItem divider />
            <LinkContainer to="/admin">
              <DropdownItem>Administrasjon</DropdownItem>
            </LinkContainer>
          </React.Fragment>
        ) : null}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default UserDropdown;
