const dictionary = {
  YES: 'Ja',
  NO: 'Nei',
  OPEN: 'Åpne',
  NEW: 'Ny',
  EDIT: 'Redigere',
  DELETE: 'Slett',
  VIEW: 'Vis',
  UP: 'Opp',
  DOWN: 'Ned',
  SAVE: 'Lagre',
};

export default dictionary;