export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  { name: 'name', title: 'Firmanavn' },
  { name: 'contactTitle', title: 'TIttel' },
  { name: 'contactFirstName', title: 'Fornavn' },
  { name: 'contactSurName', title: 'Etternavn' },
  { name: 'phone', title: 'Telefon' },
  { name: 'calculatedWorkshopNumber', title: 'DBS verksted nr' },
  { name: 'created', title: 'Mottatt' },
  { name: 'inProgressDate', title: 'Oversendt' },
  { name: 'modifiedBy', title: 'Behandles av' }
];
export const defaultSorting =  [
  {columnName: "created", direction: "desc"}
];
export const defaultFilters = [];
export const filteringColumnExtensions = [
  {columnName: 'calculatedWorkshopNumber', filteringEnabled: false}
];