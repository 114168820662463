export const defaultPageSize = 5;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  //{name: 'id', title: 'Bud ID'},
  //{name: 'estimateId', title: 'Lp. nr.'},
  //{name: 'createdBy', title: 'Budgiver'},
  {name: 'objectName', title: 'Merke/modell'},
  {name: 'amount', title: 'Bud'},
  {name: 'created', title: 'Mottatt'},
  {name: 'bidDeadline', title: 'Budfrist'},  
  {name: 'acceptedTime', title: 'Akseptert'},  
  {name: 'acceptedAmount', title: 'Akseptert beløp'},  
  {name: 'deleted', title: 'Slettet'},  
  {name: 'maxAmount', title: 'Maks. bud'}
];
export const filteringColumnExtensions = [
  {columnName: 'created', filteringEnabled: false},
  {columnName: 'bidDeadline', filteringEnabled: false},
  {columnName: 'acceptedTime', filteringEnabled: false},
  {columnName: 'deleted', filteringEnabled: false},
  {columnName: 'maxAmount', filteringEnabled: false}
];