import { take, call, all, select } from "redux-saga/effects";

import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchSetPassword() {
  while (true) {
    yield take(actions.SET_PASSWORD);

    let form = yield select(selectors.getFormValues);

    let result = yield call(request.post, {
      path: "Account/setpassword",
      processName: "setPassword",
      params: form,
    });
  }
}

export default createRootSaga(watchSetPassword);
