import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import DebounceInput from "react-debounce-input";

import "./style.css";

import * as cn from "../../../helpers/classname";

const isTouchDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

function CustomInput({
  id,
  type,
  label,
  value,
  debounceTimeout,
  hasError,
  errorMessage,
  title,
  onChange,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const isPassword = type === "password";

  return (
    <div
      className={cn.join(
        "CustomInput",
        !!value && "not-empty",
        hasError && "has-error"
      )}
    >
      <DebounceInput
        id={id || Date.now()}
        value={value}
        type={showPassword ? "text" : type}
        element={type === "textarea" ? "textarea" : "input"}
        debounceTimeout={
          debounceTimeout || hasError ? 500 : -1 /*indefinitely*/
        }
        className={`form-control ${isPassword ? "reduced-width" : ""}`}
        onChange={(e) => onChange && onChange(e)}
        title={title}
      />
      {isPassword ? (
        <React.Fragment>
          <div
            id="ShowPassword"
            className="show-password"
            onMouseDown={(e) => {
              e.preventDefault(); // Prevent <DebounceInput>-blur which will trigger onChange()
            }}
            onClick={() => {
              setShowPassword((value) => !value);
            }}
          >
            {showPassword ? "Skjul" : "Vis"}
          </div>
          <UncontrolledTooltip
            placement="right"
            target="ShowPassword"
            trigger="hover"
            className={isTouchDevice() ? "d-none" : ""}
            innerClassName={isTouchDevice() ? "d-none" : ""}
            arrowClassName={isTouchDevice() ? "d-none" : ""}
          >
            Trykk for å bytte mellom å vise eller skjule passord
          </UncontrolledTooltip>
        </React.Fragment>
      ) : null}
      <span className={`bar ${isPassword ? " bar-reduced-width" : ""}`} />
      <label htmlFor={id}>{label}</label>
      {hasError ? (
        <span className="font-smaller">
          <b>* {errorMessage}</b>
        </span>
      ) : null}
    </div>
  );
}
export default CustomInput;
