const _prefix = "BROWSE::";

export const UPDATE_DATA = _prefix + "UPDATE_DATA";
export const UPDATE_UI = _prefix + "UPDATE_UI";
export const UPDATE_FILTERS = _prefix + "UPDATE_FILTERS";

export const GET_FIRST_PAGE = _prefix + "GET_FIRST_PAGE";
export const LOAD_MORE = _prefix + "LOAD_MORE";

export const updateData = (updateOb) => ({ type: UPDATE_DATA, updateOb });
export const updateUi = (updateOb) => ({ type: UPDATE_UI, updateOb });
export const updateFilters = (updateOb) => ({ type: UPDATE_FILTERS, updateOb });

export const getFirstPage = (payload) => ({ type: GET_FIRST_PAGE, ...payload });
export const loadMore = () => ({ type: LOAD_MORE });
