import canLoadApp from "./browser-sniffer";
// devexpress library uses Symbol function that not works in ie11 without polyfill
// so we need to use `babel-polyfill` to get rid of this bug
// bug: https://github.com/facebook/create-react-app/issues/3499
import "babel-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import moment from "moment";
import "moment/locale/nb";

import App from "./App";
import store from "./store";

// TODO: consider about adding service worker
// import registerServiceWorker from './registerServiceWorker';

import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

// Set locale language to norwegian
moment.locale("nb");

if (canLoadApp) {
  const container = document.getElementById("root");

  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
}

//registerServiceWorker();
