import React from "react";
import { Col, Row } from "reactstrap";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { routes } from "./../../common/config";
import Page from "./../../common/components/blocks/Page";
import NavButtons, {
  NavButton,
} from "./../../common/components/elements/NavButtons";

import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import FaqCategories from "./../FaqCategories";
import FaqQuestions from "./../FaqQuestions";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import FaqPreviewModal from "./FaqPreviewModal";

function AdminFaq() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);

  const updateUi = (updateOb) => {
    dispatch(actions.updateUi(updateOb));
  };

  return (
    <Page title={routes.ADMIN_FAQ.title} fluid={true}>
      <FaqPreviewModal
        show={ui.previewModal.show}
        mode={ui.previewModal.mode}
        updateUi={updateUi}
      />
      <Row>
        <Col>
          <AdminHeader title={routes.ADMIN_FAQ.title} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <NavButtons>
            <NavButton
              component={NavLink}
              to={routes.ADMIN_FAQ_CATEGORIES.path}
              activeClassName="active"
            >
              {routes.ADMIN_FAQ_CATEGORIES.title}
            </NavButton>
            <NavButton
              component={NavLink}
              to={routes.ADMIN_FAQ_QUESTIONS.path}
              activeClassName="active"
            >
              {routes.ADMIN_FAQ_QUESTIONS.title}
            </NavButton>
          </NavButtons>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <button
            className="link-button"
            onClick={() =>
              dispatch(
                actions.updateUi({ previewModal: { show: true } }, false)
              )
            }
          >
            Vis forhåndsvisning
          </button>
        </Col>
      </Row>
      <Row>
        <Switch>
          <Route
            path={routes.ADMIN_FAQ_CATEGORIES.path}
            component={FaqCategories}
          />
          <Route
            path={routes.ADMIN_FAQ_QUESTIONS.path}
            component={FaqQuestions}
          />
          <Redirect to={routes.ADMIN_FAQ_CATEGORIES.path} />
        </Switch>
      </Row>
    </Page>
  );
}

export default AdminFaq;
