import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as commonDataSelectors from "./../common/redux/data/selectors";
import * as processesSelectors from "./../common/redux/processes/selectors";
import { routes } from "../common/config/routes";
import Page from "./../common/components/blocks/Page";
import SectionHeader from "./../common/components/blocks/SectionHeader";
import VehicleCard from "./../common/components/blocks/VehicleCard";
import Button from "./../common/components/elements/Button";
import Spinner from "./../common/components/elements/Spinner";

import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import headerBg from "./imgs/bg.jpg";

function Home() {
  const dispatch = useDispatch();

  const commonData = useSelector(commonDataSelectors.getData);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function fetchLastVehicles() {
    dispatch(actions.fetchLastVehicles());
  }, []);

  const fetchLastVehiclesProcess = processes.fetchLastVehicles || {};

  return (
    <Page
      className="Home"
      title={routes.HOME.title}
      fluid={true}
      withPadding={false}
    >
      <SectionHeader
        header="Hjem"
        text="Velkommen til Forsikringsbiler"
        bg={headerBg}
      />
      <Container>
        <Row className="justify-content-center mt-5 mb-4">
          <Col lg="10 text-center">
            <h2 className="text-color1">
              <b>Siste tilbud</b>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {fetchLastVehiclesProcess.inProcess ? (
            <Col xs="10">
              <Spinner />
            </Col>
          ) : null}
          {!fetchLastVehiclesProcess.inProcess &&
          !fetchLastVehiclesProcess.hasError ? (
            data.vehicles && data.vehicles.rows.length ? (
              data.vehicles.rows.map((v) => {
                return (
                  <Col xs="12" sm="12" md="10" key={v.id}>
                    <VehicleCard vehicle={v} commonData={commonData} />
                  </Col>
                );
              })
            ) : (
              <Col xs="10 text-center">
                <h4 className="text-color1 text-uppercase">
                  <b>Ingen treff</b>
                </h4>
              </Col>
            )
          ) : null}
          {fetchLastVehiclesProcess.hasError ? (
            <div className="text-center">
              <b className="text-danger ">Kunne ikke laste tilbud</b>
              <br />
              <b>{fetchLastVehiclesProcess.errorMessage}</b>
            </div>
          ) : null}
        </Row>
        <Row className="mt-4 mb-5 justify-content-center">
          <Col lg="10" className="text-center">
            <NavLink to="/browse">
              <Button color="color2" className="text-uppercase px-3">
                Bla i alle tilbud
              </Button>
            </NavLink>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default Home;
