export const getUi = (state) => state.MySettings.ui;
export const getChangePasswordForm = (state) => {
  // Minimum password length hacks

  // Minimum old password length
  state.MySettings.changePasswordForm.oldPassword.rules = ['required', {name: 'length', size: state.MySettings.ui.minOldPasswordLength}];
  
  // Minimum new password length
  state.MySettings.changePasswordForm.newPassword.rules = ['required', {name: 'length', size: state.MySettings.ui.minNewPasswordLength}];
  state.MySettings.changePasswordForm.confirmPassword.rules = ['required', {name: 'length', size: state.MySettings.ui.minNewPasswordLength}, {name: 'equal', with: 'newPassword'}];

  return state.MySettings.changePasswordForm;
}
export const getChangePasswordFormValues = state => {
  return Object.keys(state.MySettings.changePasswordForm).reduce((valuesOb, key) => {
    valuesOb[key] = state.MySettings.changePasswordForm[key].value;
    return valuesOb;
  }, {});
};