import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import ArrowRightIcon from "./../common/components/icons/ArrowRight";

import "./style.css";
import * as selectors from "./redux/selectors";
import * as actions from "./redux/actions";

function Gallery() {
  const dispatch = useDispatch();

  const gallery = useSelector(selectors.getGallery);

  const next = () => {
    let nextIndex =
      gallery.current + 1 === gallery.images.length ? 0 : gallery.current + 1;

    dispatch(actions.update({ current: nextIndex }));
  };

  const previous = () => {
    let nextIndex =
      gallery.current === 0 ? gallery.images.length - 1 : gallery.current - 1;

    dispatch(actions.update({ current: nextIndex }));
  };

  const goTo = (i) => {
    dispatch(actions.update({ current: i }));
  };

  document.body.style.overflowY = gallery.isOpen ? "hidden" : "scroll";

  if (!gallery.isOpen) return null;

  return (
    <div className="Gallery">
      <div className="gallery-curtain"></div>
      <div className="gallery-wrapper">
        <Container className="my-auto">
          <Row className="mt-5">
            <Col
              xs={{ size: 8, offset: 2 }}
              className="d-flex align-items-center"
            >
              <h3 className="text-color3">
                <b>{gallery.header}</b>
              </h3>
            </Col>
            <Col xs="2" className="d-flex align-items-center">
              <div
                className="gallery-close"
                onClick={() => dispatch(actions.update({ isOpen: false }))}
              >
                ✕
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="2" className="d-flex align-items-center">
              <div onClick={previous} className="control control-left">
                <ArrowRightIcon />
              </div>
            </Col>
            <Col xs="8">
              <img
                className="gallery-image"
                src={gallery.images[gallery.current]}
                alt="Gallery image"
              />
            </Col>
            <Col xs="2" className="d-flex align-items-center">
              <div onClick={next} className="control control-right">
                <ArrowRightIcon />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs="12" className="d-flex justify-content-center text-color3">
              {gallery.current + 1} av {gallery.images.length}
            </Col>
          </Row>
          <Row className="mt-3 mb-5">
            <Col xs={{ size: 8, offset: 2 }}>
              <Row className="no-gutters">
                {gallery.images.map((img, i) => {
                  return (
                    <Col xs="4" sm="4" md="3" lg="2" key={img}>
                      <div
                        className={[
                          "thumb-image",
                          i == gallery.current ? "active" : "",
                        ].join(" ")}
                        style={{ backgroundImage: `url(${img})` }}
                        onClick={() => goTo(i)}
                      ></div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Gallery;
