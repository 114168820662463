import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ArrowRightIcon from "./../common/components/icons/ArrowRight";
import Spinner from "./../common/components/elements/Spinner";
import FavoriteButton from "./../common/components/elements/FavoriteButton";
import BidPasswordModal from "./../common/components/blocks/BidPasswordModal";
import * as processesSelectors from "./../common/redux/processes/selectors";
import * as vehicleService from "./../common/services/vehicle";
import * as processesActions from "./../common/redux/processes/actions";
import * as userService from "./../common/services/user";
import * as commonDataSelectors from "./../common/redux/data/selectors";
import * as sessionService from "./../common/services/session";
import * as sessionSelectors from "./../common/redux/session/selectors";
import * as sessionActions from "./../common/redux/session/actions";

import "./style.css";
import BidForm from "./Form";
import Timer from "./Timer";
import Slider from "./Slider";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

const formatNumber = (num) => num.toFixed(2).replace(".", ",");

function VehiclePage({ match }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const commonData = useSelector(commonDataSelectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  const prepareInfoCardContent = (arr) => {
    return arr.map((row, i) => {
      return (
        <React.Fragment key={i}>
          {row.hideIfNull && row.value == null ? null : (
            <React.Fragment>
              {!row.name && row.setInSeperateRow ? null : (
                <React.Fragment>
                  <Col
                    xs={row.setInSeperateRow ? 12 : 5}
                    className="text-color5"
                  >
                    {row.name ? `${row.name}:` : ""}
                  </Col>
                </React.Fragment>
              )}
              <Col xs={row.setInSeperateRow ? 12 : 7}>
                {row.setAsNotHtml ? (
                  <b>{row.value}</b>
                ) : (
                  <b dangerouslySetInnerHTML={{ __html: row.value }}></b>
                )}
              </Col>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  const getSpesifikasjoner = () => {
    let { vehicle: v } = data;

    let motor = "";

    if (v.engineVolume)
      motor += `${vehicleService.prettyNumber(v.engineVolume)}, `;
    if (v.engineRating)
      motor += `${vehicleService.prettyNumber(v.engineRating)} kW `;
    if (v.engineHorsePower)
      motor += `${vehicleService.prettyNumber(v.engineHorsePower)} hk `;

    motor += v.engineType;

    let wreckActions = (commonData && commonData.wreckActions) || [];

    let wreckAction = "";

    if (v.wreckActionId) {
      wreckAction = wreckActions.filter((w) => w.id == v.wreckActionId);
      wreckAction = wreckAction[0] ? wreckAction[0].text : "";
    }

    let countyPostalCode = "";

    if (v.countyName) {
      countyPostalCode += `${v.countyName} `;
    }
    if (v.workshopPostalCode) {
      countyPostalCode += v.workshopPostalCode;
    }

    // prepare generellTilstand

    let generellTilstand;

    if (v.appraisals && v.appraisals.length) {
      generellTilstand = (
        <ul>
          {v.appraisals.map((sr, i) => {
            let typeText;
            if (sr.type) {
              switch (sr.type) {
                case 1:
                  typeText = "Karosseri";
                  break;
                case 2:
                  typeText = "Lakk";
                  break;
                case 3:
                  typeText = "Interiør";
                  break;
              }
            }

            let valueText;
            if (sr.value) {
              switch (sr.value) {
                case 1:
                  valueText = "Bra";
                  break;
                case 2:
                  valueText = "Normal";
                  break;
                case 3:
                  valueText = "Dårlig";
                  break;
              }
            }
            return (
              <li key={i}>
                {typeText}: {valueText}
              </li>
            );
          })}
        </ul>
      );
    }

    let arr = [
      {
        name: "Oppdragsnummer",
        value:
          v.estimateNumber == null
            ? null
            : `${v.estimateNumber} ${v.estimateVersion} ${v.condemnationVersion}`,
        hideIfNull: true,
      },
      { name: "DBS-bruker", value: v.userName, hideIfNull: true },
      { name: "Lp. nr.", value: v.id },
      { name: "Objektbehandling", value: wreckAction },
      { name: "Merke", value: v.makeText },
      { name: "Modell", value: v.modelText },
      { name: "Årsmodell", value: v.modelYear },
      {
        name: "Reg. 1. gang dato",
        value: v.firstRegistrationDate
          ? vehicleService.getDate(v.firstRegistrationDate)
          : "",
      },
      { name: "Motor", value: motor },
      { name: "Gear", value: v.gearType },
      { name: "Km", value: v.milage },
      { name: "Chassisnr", value: v.chassisNumber },
      { name: "Fargekode/farge, kaross.", value: v.colorCode },
      { name: "Sted", value: countyPostalCode },
      { name: "Spesielle bemerkninger", value: v.notes },
      {
        value:
          v.standardTexts && v.standardTexts.length ? (
            <ul>
              {" "}
              {v.standardTexts.map((text, i) => (
                <li key={i}>{text}</li>
              ))}{" "}
            </ul>
          ) : (
            ""
          ),
        setAsNotHtml: true,
        hideIfNull: true,
      },
      { name: "Utstyr", value: v.equipments ? v.equipments.join(", ") : "" },
      {
        name: "Generell tilstand",
        value: generellTilstand,
        setAsNotHtml: true,
        hideIfNull: true,
      },
      {
        name: "Type, dekk",
        value: `${
          v.tyreType !== undefined ? v.tyreType + ", g" : "G"
        }odkjent dybde: ${vehicleService.getYesNo(v.approvedThreadDepth)}`,
      },
      { name: "Skadetype", value: v.damageType },
      { name: "Budfrist", value: vehicleService.getDate(v.bidDeadline) },
    ];

    // prepare sammenstilling

    let sammenstilling;

    if (v.summaryResults && v.summaryResults.length) {
      sammenstilling = (
        <table className="vp-table-1 mt-3">
          <tbody>
            <tr>
              <th>Beskrivelse</th>
              <th>Beløp</th>
            </tr>
            {v.summaryResults.map((sr, i) => {
              return (
                <tr key={i}>
                  <td className={sr.isSum ? "text-color14" : ""}>{sr.text}</td>
                  <td>{formatNumber(sr.amount)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    arr.push({
      name: "Sammenstilling",
      value: sammenstilling,
      setAsNotHtml: true,
      setInSeperateRow: true,
    });

    // return results

    return prepareInfoCardContent(arr);
  };

  const getSpesifikasjonerTilTakstrapport = () => {
    let { vehicle: v } = data;

    let arr = [];

    // prepare Reservedeler

    let Reservedeler;

    if (v.materialResults && v.materialResults.length) {
      Reservedeler = (
        <table className="vp-table-1 my-3">
          <tbody>
            <tr>
              <th>Delnr.</th>
              <th>Beskrivelse</th>
            </tr>
            {v.materialResults.map((mr, i) => {
              return (
                <tr key={i}>
                  <td>{mr.partNumber}</td>
                  <td>{mr.text}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    arr.push({
      name: "Reservedeler",
      value: Reservedeler,
      setAsNotHtml: true,
      setInSeperateRow: true,
    });

    // prepare Arbeidsbeskrivelse

    let Arbeidsbeskrivelse;

    if (v.workResults && v.workResults.length) {
      let mapGroupToItems = {};

      v.workResults.forEach((wr) => {
        if (!mapGroupToItems[wr.groupText]) mapGroupToItems[wr.groupText] = [];
        mapGroupToItems[wr.groupText].push(wr);
      });

      Arbeidsbeskrivelse = (
        <table className="vp-table-1 my-3">
          <tbody>
            {Object.keys(mapGroupToItems).map((key, i) => {
              return (
                <React.Fragment key={i}>
                  <tr>
                    <th className="text-color14">{key}</th>
                  </tr>
                  {mapGroupToItems[key].map((wr, i) => {
                    return (
                      <tr key={i}>
                        <td>{wr.text}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      );
    }

    arr.push({
      name: "Arbeidsbeskrivelse",
      value: Arbeidsbeskrivelse,
      setAsNotHtml: true,
      setInSeperateRow: true,
    });

    // prepare Lakk

    let Lakk;

    if (v.paintResults && v.paintResults.length) {
      let mapGroupToItems = {};

      v.paintResults.forEach((pr) => {
        if (pr.groupText !== "Perioder-struktur-mattsvart") {
          if (!mapGroupToItems[pr.groupText])
            mapGroupToItems[pr.groupText] = [];
          mapGroupToItems[pr.groupText].push(pr);
        }
      });

      Lakk = (
        <table className="vp-table-1 mt-3">
          <tbody>
            <tr>
              <th>Kode</th>
              <th colSpan="2">Flate</th>
              <th>Areal</th>
              <th>Beskrivelse</th>
            </tr>
            {Object.keys(mapGroupToItems)
              .slice(0)
              .reverse()
              .map((key, i) => {
                function paintSurfaceTypeToString(paintSurfaceType) {
                  switch (paintSurfaceType) {
                    case 0:
                    case 1:
                      return "";
                    case 2:
                      return "N";
                    case 3:
                      return "G";
                    case 4:
                      return "T";
                  }
                }
                return (
                  <React.Fragment key={i}>
                    <tr>
                      <th colSpan="5" className="text-color14">
                        {key}
                      </th>
                    </tr>
                    {mapGroupToItems[key].map((pr, i) => {
                      return (
                        <tr key={i}>
                          <td>{pr.paintCode || ""}</td>
                          <td>
                            {paintSurfaceTypeToString(pr.paintSurfaceType) ||
                              ""}
                          </td>
                          <td>{pr.comment || ""}</td>
                          <td>{pr.area ? formatNumber(pr.area) : ""}</td>
                          <td>{pr.text}</td>
                        </tr>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      );
    }

    arr.push({
      name: "Lakk",
      value: Lakk,
      setAsNotHtml: true,
      setInSeperateRow: true,
    });

    // return results

    return prepareInfoCardContent(arr);
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Aksepterer bud";
        break;
      case 2:
        return "Auksjon avsluttet";
        break;
      case 3:
        return "Bud oversendt";
        break;
      case 4:
        return "Salg avsluttet";
        break;
      case 5:
        return "Alle bud forkastet";
        break;
      case 6:
        return "Migrert fra gammel løsning";
        break;
      case 7:
        return "Tilbakekalt";
        break;
      case 8:
        return "Slettet";
        break;
    }
  };

  useEffect(function fetchVehicleOnMount() {
    dispatch(actions.fetchVehicle(match.params.id));
  }, []);

  useEffect(function resetOnUnmount() {
    dispatch(actions.updateData({ vehicle: null }));
    dispatch(
      actions.updateForm({
        bidPassword: { value: "", hasError: false },
        amount: { value: "", hasError: false },
      })
    );
    dispatch(processesActions.update({ placeBid: null }));
  }, []);

  let { vehicle } = data;

  let header = vehicle ? `${vehicle.makeText} ${vehicle.modelText || ""}` : "";

  return (
    <React.Fragment>
      {ui.updateBidToken ? (
        <BidPasswordModal
          onValid={({ token, expires, expireMinutes }) => {
            // save bid password
            dispatch(
              sessionActions.updateSession(
                sessionService.prepareBidTokenUpdateOb(
                  token,
                  expires,
                  expireMinutes
                )
              )
            );
            // dispatch next action object
            if (ui.nextActionObjectOnValidBidToken)
              dispatch(ui.nextActionObjectOnValidBidToken);
            // remove next action object
            dispatch(
              actions.updateUi({
                updateBidToken: false,
                nextActionObjectOnValidBidToken: null,
              })
            );
          }}
          onCancel={() =>
            dispatch(
              actions.updateUi({
                updateBidToken: false,
                nextActionObjectOnValidBidToken: null,
              })
            )
          }
        />
      ) : null}
      <div className="VehiclePage">
        <Container>
          {processes.fetchVehicle?.inProcess ? (
            <Spinner className="my-10" />
          ) : null}
          {processes.fetchVehicle?.hasError ? (
            <Row className="my-10">
              <Col className="text-center">
                <b className="text-danger ">Kunne ikke laste data</b>
                <br />
                <b>{processes.fetchVehicle?.errorMessage}</b>
              </Col>
            </Row>
          ) : null}
          {processes.fetchVehicle?.isSuccessed && !vehicle ? (
            <Row className="my-10">
              <Col xs="12 text-center">
                <h4 className="text-color1 text-uppercase">
                  <b>Ingen data</b>
                </h4>
              </Col>
            </Row>
          ) : null}
          {processes.fetchVehicle?.isSuccessed && vehicle ? (
            <React.Fragment>
              <Row className="breadcrumb">
                <Col>
                  <NavLink to="/browse" className="text-decoration-none">
                    <span className="text-color5 cursor-pointer">Tilbud</span>
                  </NavLink>
                  <ArrowRightIcon />
                  <span className="text-color1">{header}</span>
                </Col>
              </Row>
              <Row className="vehicle-content no-gutters p-3">
                {vehicle.photos && vehicle.photos.length ? (
                  <Col xs="12">
                    <Slider images={vehicle.photos.map((photo) => photo.url)} />
                  </Col>
                ) : null}
                <Col xs="12" className="mt-4 px-2">
                  <h3 className="d-inline">
                    <b>{header}</b>
                  </h3>
                  <FavoriteButton
                    className="ml-5"
                    estimateId={vehicle.id}
                    isFavorite={vehicle.isFavorite}
                  />
                </Col>
                <Col xs="12" className="mt-4">
                  <Row>
                    <Col xs="12" sm="6">
                      <div className="info-card">
                        <h5 className="text-uppercase">
                          <b>Spesifikasjoner</b>
                        </h5>
                        <Row>{getSpesifikasjoner()}</Row>
                      </div>
                      <div className="info-card mt-4">
                        <h5 className="text-uppercase">
                          <b>Spesifikasjoner til takstrapport</b>
                        </h5>
                        <Row>{getSpesifikasjonerTilTakstrapport()}</Row>
                      </div>
                      {vehicle.attachments && vehicle.attachments.length ? (
                        <div className="info-card mt-4">
                          <h5 className="text-uppercase">
                            <b>Dokumenter</b>
                          </h5>
                          <Row>
                            {vehicle.attachments.map((d) => {
                              return (
                                <Col xs="12" key={d.url}>
                                  <a
                                    href={d.url}
                                    className="text-color1"
                                    target="_blank"
                                  >
                                    {d.name}
                                  </a>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      ) : null}
                    </Col>
                    <Col xs="12" sm="6">
                      <div className="mt-4 letter-spacing-1 text-color5">
                        <div>DATO OPPRETTET:</div>
                        <h4>
                          <b>{vehicleService.getDate(vehicle.created)}</b>
                        </h4>
                      </div>
                      <div className="mt-4">
                        {userService.isWorkshopUser(session) ||
                        vehicle.status === 1 ? (
                          <React.Fragment>
                            <Timer bidDeadline={vehicle.bidDeadline} />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <span className="text-uppercase text-color5">
                              Status:
                            </span>
                            <br />
                            <h4 className="d-inline text-color8">
                              <b>{getStatusText(vehicle.status)}</b>
                            </h4>
                            <br />
                            <br />
                            <span className="text-uppercase text-color5">
                              Budfrist:
                            </span>
                            <br />
                            <h4 className="d-inline text-color8">
                              <b>
                                {vehicleService.getDate(vehicle.bidDeadline)}
                              </b>
                            </h4>
                          </React.Fragment>
                        )}
                      </div>
                      {userService.isWorkshopUser(session) &&
                      vehicleService.acceptsBids(vehicle) &&
                      new Date(vehicle.bidDeadline) > new Date() ? (
                        <React.Fragment>
                          <p className="letter-spacing-1 text-color5 mt-4">
                            NB! Avgitt bud er bindende inntil 5 hverdager etter
                            ovenstående budfrist, jfr. for øvrig
                            salgsbetingelser.
                          </p>
                          <Row className="mt-4">
                            <Col sm="12" md="8">
                              <BidForm />
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default VehiclePage;
