import {prefix} from './../config';

export const UPDATE_DATA = prefix + 'UPDATE_DATA';
export const UPDATE_UI = prefix + 'UPDATE_UI';

export const FETCH_GRID = prefix + 'FETCH_GRID';
export const LAUNCH_CREATE_EDIT_MODAL = prefix + 'LAUNCH_CREATE_EDIT_MODAL';
export const CREATE_ITEM = prefix + 'CREATE_ITEM';
export const EDIT_ITEM = prefix + 'EDIT_ITEM';
export const DELETE_ITEM = prefix + 'DELETE_ITEM';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb, triggerFetchGrid) => ({type: UPDATE_UI, updateOb, triggerFetchGrid});

export const fetchGrid = () => ({type: FETCH_GRID});
export const launchCreateEditModal = (id) => ({type: LAUNCH_CREATE_EDIT_MODAL, id});
export const createItem = () => ({type: CREATE_ITEM});
export const editItem = () => ({type: EDIT_ITEM});
export const deleteItem = (id) => ({type: DELETE_ITEM, id});