import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./../../redux/actions";
import * as selectors from "./../../redux/selectors";
import * as processesSelectors from "./../../../../common/redux/processes/selectors";

function WorkshopSignupTable({ id, workshopNumber }) {
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const form = useSelector(selectors.getForm);

  if (
    form &&
    form.workshopNumber &&
    form.workshopNumber.value &&
    form.workshopNumber.value.length &&
    data.workshopRegisters &&
    data.workshopRegisters[form.workshopNumber.value]
  ) {
    workshopNumber = form.workshopNumber.value;
  }

  let fetchWorkshopRegister =
    processes[`fetchWorkshopRegister:${id}:${workshopNumber}`] || {};

  let signup = data.signups && data.signups[id] ? data.signups[id] : {};
  let wr =
    data.workshopRegisters && data.workshopRegisters[workshopNumber]
      ? data.workshopRegisters[workshopNumber]
      : {};
  let isWRNotFound =
    fetchWorkshopRegister.hasError && fetchWorkshopRegister.status == 5;

  return (
    <table>
      <thead>
        <tr>
          <td scope="col">Felt</td>
          <th scope="col">Data iflg. innmelding</th>
          <th scope="col">
            {isWRNotFound ? (
              <span className="text-color8">
                Verkstedet ble ikke funnet i verkstedregisteret
              </span>
            ) : (
              "Data fra verkstedregisteret"
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {[
          { break: true, hr: false },
          {
            th: "Verksted nr.",
            td1: signup.workshopNumber,
            td2: wr.workshopNumber,
          },
          { th: "Firmanavn", td1: signup.name, td2: wr.name },
          { th: "Tittel", td1: signup.contactTitle, td2: "" },
          {
            th: "Navn",
            td1: signup.contactFirstName + " " + signup.contactSurName,
            td2: "",
          },
          { th: "E-post DBS-M", td1: signup.email, td2: wr.email },
          //{th: 'E-post DBS', td1: '', td2: ''},
          { break: true, hr: true },
          {
            th: "Besøksadresse",
            td1: signup.visitingAddress,
            td2: wr.visitingAddress,
          },
          {
            th: "Besøkspostnr.",
            td1: signup.visitingPostalCode,
            td2: wr.visitingPostalCode,
          },
          //{th: 'Besøksby', td1: '', td2: ''},
          { break: true, hr: true },
          {
            th: "Postadresse",
            td1: signup.postalAddress,
            td2: wr.postalAddress,
          },
          { th: "Postnr.", td1: signup.postalCode, td2: wr.postalCode },
          //{th: 'By', td1: '', td2: ''},
          { break: true, hr: true },
          //{th: 'Fylke', td1: '', td2: ''},
          //{th: 'Telefon', td1: '', td2: ''},
          //{th: 'Telefaks', td1: '', td2: ''},
          {
            th: "Verksted Type",
            td1:
              signup.workshopTypes && signup.workshopTypes.length
                ? signup.workshopTypes.join(", ")
                : null,
            td2:
              wr.workshopTypes && wr.workshopTypes.length
                ? wr.workshopTypes.join(", ")
                : null,
          },
          { th: "Orgnr", td1: signup.orgNo, td2: "" },
          { th: "Godkjnr", td1: signup.approvalNumber, td2: "" },
          { th: "Miljøgodkjent", td1: signup.environmentallyApproved, td2: "" },
          { th: "Bemerkning", td1: signup.notes, td2: "" },
          //{th: 'Saksbehandler', td1: '', td2: ''}
        ].map((ob, i) => {
          if (ob.break) {
            return (
              <tr key={i}>
                <td colSpan="4">{ob.hr ? <hr /> : null}</td>
              </tr>
            );
          }

          return (
            <tr key={i}>
              <th scope="row">{ob.th}</th>
              <td>{ob.td1 || "-"}</td>
              <td>{ob.td2 || "-"}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default WorkshopSignupTable;
