import { fork, take, put, call, all, select, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";
import moment from "moment";

import request from "./../../../common/services/request";
import { createRootSaga } from "../../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

function* _prepareParamsForGrid() {
  let params = {};

  let ui = yield select(selectors.getUi);

  // page size

  params.pageSize = ui.currentPageSize;

  // current page

  params.currentPage = ui.currentPage;

  // sorting

  if (ui.sorting && ui.sorting.length) params.sorting = ui.sorting;

  // filters

  if (ui.filters && ui.filters.length) params.filters = ui.filters;
  if (ui.customFilters && ui.customFilters.length)
    params.filters = (params.filters || []).concat(ui.customFilters);

  // acceptedText filter

  for (let f of ["acceptedAfterDeadline", "notAcceptedAfterDeadline"]) {
    if (ui.acceptedTextFilter == f) params[f] = true;
  }

  // wreckActions

  if (ui.wreckActionsFilters && ui.wreckActionsFilters.length)
    params.wreckActions = ui.wreckActionsFilters;

  // statuses

  params.statuses = ui.statuses;

  return params;
}

export function* fetchEstimates(action) {
  if (
    action.type === actions.UPDATE_UI &&
    (action.updateOb.filters ||
      action.updateOb.wreckActions ||
      action.updateOb.acceptedTextFilter)
  ) {
    yield delay(1000);
  }

  if (
    action.type === actions.UPDATE_UI &&
    action.updateOb.hasOwnProperty("section")
  ) {
    yield put(
      actions.updateUi({
        updateOb: { hideGrid: true },
      })
    );
  }

  let params = yield call(_prepareParamsForGrid);

  let result = yield call(request.post, {
    path: "Estimate/grid/admin",
    processName: "fetchEstimates",
    params,
  });

  if (result instanceof Error) return;

  // save results

  result.rows = result.rows.map((row) => {
    let acceptedDate = row.acceptedTime
      ? moment(row.acceptedTime).format("lll")
      : "Ikke akseptert";
    row.acceptedText = row.hasBids ? acceptedDate : "Ingen bud";
    return row;
  });

  yield put(actions.updateData({ estimates: result }));

  if (
    action.type === actions.UPDATE_UI &&
    action.updateOb.hasOwnProperty("section")
  ) {
    yield put(
      actions.updateUi({
        updateOb: { hideGrid: false },
      })
    );
  }
}

export function* triggerFetchEstimates() {
  let lastTask;

  while (true) {
    const action = yield take([actions.UPDATE_UI, actions.FETCH_ESTIMATES]);

    if (action.type === actions.UPDATE_UI && !action.triggerFetchEstimates) {
      continue;
    }

    if (lastTask) {
      yield cancel(lastTask);
    }

    lastTask = yield fork(fetchEstimates, action);
  }
}

export default createRootSaga(triggerFetchEstimates);
