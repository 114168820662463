import React from "react";

import "./style.css";

import classname from "./../../../helpers/classname";
import Modal from "./../Modal";

const componentName = "RegularModal";
const cn = classname(componentName);

export const modalSizes = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  XLARGE: "xlg",
};

export const modalHeaderTypes = {
  DEFAULT: "DEFAULT",
  CENTERED: "CENTERED",
};

function RegularModal({
  show = true,
  className,
  contentClassName,
  onCancel: onClose,
  size = modalSizes.MEDIUM,
  children,
}) {
  if (!show) return null;

  return (
    <Modal
      className={cn.join(componentName, className)}
      contentClassName={cn.join(
        cn("content"),
        contentClassName,
        cn(`${size}-content`)
      )}
      transparentBackdrop={true}
      onClose={onClose}
    >
      <div className={cn("modal-close")} onClick={onClose}>
        ✕
      </div>
      <div className="form1">{children}</div>
    </Modal>
  );
}

export default RegularModal;
