import React, { useEffect } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import RegularModal from "./../../common/components/blocks/RegularModal";
import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import ErrorBlock from "./../../common/components/blocks/ErrorBlock";
import Checkbox from "./../../common/components/elements/Checkbox";
import InfoCard from "./../../common/components/blocks/InfoCard";
import * as formValidatorActions from "./../../common/services/form-validator/actions";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as processesActions from "./../../common/redux/processes/actions";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import * as userService from "./../../common/services/user";

import "./style.css";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

const resetModel = {
  oldPassword: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  newPassword: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  confirmPassword: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  agreement: {
    value: false,
    hasError: false,
    errorMessage: "",
  },
};

function ChangePasswordModal() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getChangePasswordForm);
  const ui = useSelector(selectors.getUi);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getChangePasswordForm,
        updateFormActionCreator: actions.updateChangePasswordForm,
      })
    );
  };

  const onSubmit = (e) => {
    let props = ["newPassword", "confirmPassword"];

    if (
      ui.changePasswordType === "bid" &&
      ui.changePasswordAction === "reset"
    ) {
      props.push("agreement");
    } else {
      props.unshift("oldPassword");
    }

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props,
        formSelector: selectors.getChangePasswordForm,
        updateFormActionCreator: actions.updateChangePasswordForm,
        onValid: () => dispatch(actions.changePassword()),
        disableScrolling: true,
      })
    );
  };

  const onCancel = () => {
    dispatch(actions.updateUi({ showChangePasswordModal: false }));
  };

  const calculateMinPasswordLength = (session) => {
    return userService.isAdmin(session) ? 16 : 10;
  };

  useEffect(function resetOnUnmount() {
    return () => {
      dispatch(actions.updateChangePasswordForm(resetModel));
      dispatch(processesActions.update({ changePassword: null }));
    };
  }, []);

  const changePasswordProcess = processes.changePassword || {};

  if (changePasswordProcess.isSuccessed) {
    return (
      <RegularModal onCancel={onCancel} className="ChangePasswordModal">
        <h5 className="text-color1 text-center">
          <b>
            Ditt{" "}
            <span className="text-color8">
              {ui.changePasswordType === "bid" ? "budpassord" : "passord"}
            </span>{" "}
            er endret
          </b>
        </h5>
        <div className="text-center mt-4">
          <Button color="color1" className="px-5" onClick={onCancel}>
            OK
          </Button>
        </div>
      </RegularModal>
    );
  }

  return (
    <RegularModal onCancel={onCancel} className="ChangePasswordModal">
      <h5 className="letter-spacing-1 text-color1 mb-5">
        {ui.changePasswordType === "password" ? (
          <span>
            For å endre <span className="text-color8">passord</span> skriv inn
            både det gamle passordet det nye passordet og gjenta det nye
            passordet
          </span>
        ) : null}
        {ui.changePasswordType === "bid" &&
        ui.changePasswordAction === "change" ? (
          <div>
            For å endre <span className="text-color8">budpassord</span> skriv
            inn både det gamle budpassordet det nye budpassordet og gjenta det
            nye budpassordet
          </div>
        ) : null}
        {ui.changePasswordType === "bid" &&
        ui.changePasswordAction === "reset" ? (
          <div>
            <div>
              For å tilbakestille{" "}
              <span className="text-color8">budpassord</span> skriv inn det nye
              budpassordet, gjenta det nye budpassordet og bekreft forbeholdet
              om varsling til verkstedets administrator
            </div>
          </div>
        ) : null}
      </h5>

      <InfoCard
        type={InfoCard.types.WARNING}
        message={`${
          ui.changePasswordType === "bid" ? "Budpassordet" : "Passordet"
        } må være minst ${
          ui.changePasswordType === "bid"
            ? 10
            : calculateMinPasswordLength(session)
        } tegn og bestå av små og store bokstaver, tall og spesialtegn. De siste ${
          ui.changePasswordType === "bid" ? "budpassordene" : "passordene"
        } kan ikke benyttes.`}
      />

      <Form className="mt-5" onSubmit={onSubmit}>
        {ui.changePasswordAction !== "reset" ? (
          <FormGroup>
            <InputText
              id="oldPassword"
              label={`Gammelt ${
                ui.changePasswordType === "bid" ? "budpassord" : "passord"
              }`}
              type="password"
              value={form.oldPassword.value}
              onChange={(e) => validateAndUpdateProp(e, "oldPassword")}
              hasError={form.oldPassword.hasError}
              errorMessage={form.oldPassword.errorMessage}
            />
          </FormGroup>
        ) : null}
        <FormGroup>
          <InputText
            id="newPassword"
            label={`Nytt ${
              ui.changePasswordType === "bid" ? "budpassord" : "passord"
            }`}
            type="password"
            value={form.newPassword.value}
            onChange={(e) => validateAndUpdateProp(e, "newPassword")}
            hasError={form.newPassword.hasError}
            errorMessage={form.newPassword.errorMessage}
          />
        </FormGroup>
        <FormGroup>
          <InputText
            id="confirmPassword"
            label={`Gjenta ${
              ui.changePasswordType === "bid" ? "budpassord" : "passord"
            }`}
            type="password"
            value={form.confirmPassword.value}
            onChange={(e) => validateAndUpdateProp(e, "confirmPassword")}
            hasError={form.confirmPassword.hasError}
            errorMessage={form.confirmPassword.errorMessage}
          />
        </FormGroup>
        {ui.changePasswordType === "bid" &&
        ui.changePasswordAction === "reset" ? (
          <FormGroup>
            <Checkbox
              className={[
                "font-smaller text-color5",
                form.agreement.hasError ? "agreement-is-required" : "",
              ].join(" ")}
              label="Jeg er klar over at kun verkstedets administrator kan tilbakestille budpassord. Jeg forstår at hvis jeg endrer budpassord så vil verkstedets administrator få tilsendt varsel om dette på e-post med bl.a. denne maskinens IP-adresse"
              checked={form.agreement.value}
              onChange={(e) => validateAndUpdateProp(e, "agreement")}
            />
          </FormGroup>
        ) : null}
        {changePasswordProcess.hasError ? (
          <ErrorBlock error={changePasswordProcess} />
        ) : null}
        <FormGroup className="mt-5">
          <Row className="d-flex justify-content-center">
            <Col xs="5">
              {changePasswordProcess.inProcess ? (
                <Spinner size="medium" />
              ) : (
                <Button type="submit" color="color2" className="w-100">
                  Send inn
                </Button>
              )}
            </Col>
            <Col xs="5">
              <Button
                type="button"
                color="color7"
                className="w-100"
                onClick={onCancel}
              >
                Avbryt
              </Button>
            </Col>
          </Row>
          {ui.changePasswordType === "bid" &&
          ui.changePasswordAction === "reset" ? (
            <div className="EditPasswordInsteadOfReset letter-spacing-1 text-color1">
              Trykk{" "}
              <span
                className="link-button"
                onClick={() => {
                  dispatch(actions.updateChangePasswordForm(resetModel));
                  dispatch(
                    actions.updateUi({
                      showChangePasswordModal: true,
                      changePasswordType: "bid",
                      changePasswordAction: "change",
                    })
                  );
                }}
              >
                her
              </span>{" "}
              dersom du heller ønsker endre budpassord uten at det sendes ut
              varsel
            </div>
          ) : null}
        </FormGroup>
      </Form>
    </RegularModal>
  );
}

export default ChangePasswordModal;
