import * as reduxService from "./../../../common/services/redux";

import * as actions from "./actions";
import * as models from "./models";

export default reduxService.createReducer(
  {
    name: "profile",
    state: models.profile,
    action: actions.UPDATE_PROFILE,
  },
  true
);
