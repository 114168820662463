import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

let initState = {
  usernameOrEmail: {
    value: '',
    rules: ['required']
  }
};

const form = (state = initState, action) => {
  switch (action.type) {
    case actions.UPDATE_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  form
});

