import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import "./style.css";
import * as helpers from "./../../../helpers";
import classname from "./../../../helpers/classname";

const componentName = "CustomModal";
const cn = classname(componentName);

function CustomModal({
  className,
  backdropClassName,
  contentClassName,
  translucentBackdrop = true,
  transparentBackdrop = false,
  children,
  closeOnBackdropClick,
  onClose,
}) {
  const rootElement = useRef(document.getElementById("root"));

  const onWrapperClick = (e) => {
    if (closeOnBackdropClick && e.target.id === cn("wrapper") && onClose)
      onClose();
  };

  useEffect(function hideScrollBarOfBody() {
    document.body.style.overflowY = "hidden";
    document.body.style.marginRight = `${helpers.getScrollbarWidth()}px`;

    return () => {
      document.body.style.overflowY = "scroll";
      document.body.style.marginRight = 0;
    };
  }, []);

  return ReactDOM.createPortal(
    <div className={cn.join(componentName, className)}>
      <div
        className={cn.join(
          cn("backdrop"),
          backdropClassName,
          translucentBackdrop && cn("backdrop-translucent"),
          transparentBackdrop && cn("backdrop-transparent")
        )}
      />
      <div
        id={cn("wrapper")}
        className={cn("wrapper")}
        onClick={onWrapperClick}
      >
        <div
          className={cn.join(
            cn("content"),
            contentClassName,
            "fade-in-with-scale"
          )}
        >
          {children}
        </div>
      </div>
    </div>,
    rootElement.current
  );
}

export default CustomModal;
