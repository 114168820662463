import React from "react";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/fontawesome-free-solid";
import moment from "moment";

import "./style.css";

function DateRangeFilter({ columnName, ui, updateUi }) {
  const getUiProp = () => {
    return `${columnName}:DateRangeFilter`;
  };

  const getDateRangeFilterUiObject = () => {
    return ui[getUiProp()] || {};
  };

  const onChange = ({ startDate, endDate }) => {
    // prepare start & end date

    let { startDate: previousStartDate, endDate: previousEndDate } =
      getDateRangeFilterUiObject();

    if (startDate) {
      if (moment.isMoment(startDate)) startDate = startDate.toDate();
    } else if (startDate === undefined) {
      startDate = previousStartDate;
    }

    if (endDate) {
      if (moment.isMoment(endDate)) endDate = endDate.toDate();
    } else if (endDate === undefined) {
      endDate = previousEndDate;
    }

    // normalize values

    if (startDate && endDate) {
      if (startDate > endDate) endDate = startDate;
      else if (endDate < startDate) startDate = endDate;
    }

    // prepare custom filters

    let customFilters = ui.customFilters || [];

    customFilters = customFilters.filter((f) => f.columnName != columnName);

    if (startDate) {
      customFilters.push({ columnName, value: startDate, operation: ">=" });
    }

    if (endDate) {
      customFilters.push({ columnName, value: endDate, operation: "<=" });
    }

    updateUi(
      {
        customFilters,
        [getUiProp()]: {
          startDate,
          endDate,
          isActive: startDate || endDate,
        },
      },
      true
    );
  };

  const setCurrentMonth = () => {
    let startDate = moment().startOf("month");
    let endDate = moment().endOf("month");

    onChange({
      startDate,
      endDate,
    });
  };

  const setCurrentYear = () => {
    let startDate = moment().startOf("year");
    let endDate = moment().endOf("year");

    onChange({
      startDate,
      endDate,
    });
  };

  let { startDate, endDate, isActive } = getDateRangeFilterUiObject();

  return (
    <th className="DateRangeFilter" style={{ fontWeight: "normal" }}>
      <UncontrolledDropdown className="dropdown-filter dropdown1">
        <DropdownToggle className="filter-toggle-button" caret color="primary">
          <FontAwesomeIcon
            icon={faCircle}
            className={["filter-indicator", isActive ? "is-active" : ""].join(
              " "
            )}
          />
          <span className="ml-2 mr-3">Filter</span>
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
            setToBottomAndLeft: {
              enabled: true,
              order: 825,
              fn: (data) => {
                data.offsets.popper.top =
                  data.offsets.reference.height +
                  10 /*top padding of custom input*/;
                data.offsets.popper.left = 0;

                return data;
              },
            },
          }}
        >
          <div className="dropdown-item text-center">
            <span
              className="link-button"
              onClick={() => onChange({ startDate: null, endDate: null })}
            >
              Tøm
            </span>
          </div>
          <div className="dropdown-item">
            <Button
              color="primary"
              size="sm"
              className="mb-1 w-100"
              onClick={setCurrentMonth}
            >
              Denne måneden
            </Button>
            <br />
            <Button
              color="primary"
              size="sm"
              className="w-100"
              onClick={setCurrentYear}
            >
              Dette året
            </Button>
          </div>
          <div className="dropdown-item">
            <div className="d-flex mb-1">
              <ReactDatetime
                strictParsing={true}
                dateFormat={true}
                closeOnSelect={true}
                closeOnTab={true}
                input={true}
                timeFormat={false}
                inputProps={{ placeholder: "Fra dato", readOnly: true }}
                value={startDate}
                onChange={(v) => onChange({ startDate: v })}
              />
              <Button
                color="primary"
                size="sm"
                className="ml-1"
                onClick={() => onChange({ startDate: null })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
            <div className="d-flex mb-1">
              <ReactDatetime
                strictParsing={true}
                dateFormat={true}
                closeOnSelect={true}
                closeOnTab={true}
                input={true}
                timeFormat={false}
                inputProps={{ placeholder: "Til dato", readOnly: true }}
                value={endDate}
                onChange={(v) => onChange({ endDate: v })}
              />
              <Button
                color="primary"
                size="sm"
                className="ml-1"
                onClick={() => onChange({ endDate: null })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </th>
  );
}

export default DateRangeFilter;
