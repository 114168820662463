import { take, call, all, put } from "redux-saga/effects";

import request from "./../../common/services/request";
import * as processesActions from "./../../common/redux/processes/actions";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";

export function* watchHanldeSingleSignon() {
  while (true) {
    let {
      userName,
      oneTimePassword,
      estimateNumber,
      estimateVersion,
      condemnationVersion,
    } = yield take(actions.HANDLE_SINGLE_SIGNON);

    // add process

    let processName = "handleSingleSignon";

    yield put(processesActions.addProcess(processName));

    // login

    let result = yield call(request.loginWithOneTimePassword, {
      username: userName,
      password: oneTimePassword,
    });

    if (result instanceof Error) {
      yield put(
        processesActions.setError(processName, result.status, result.message)
      );
      continue;
    }

    // get estimate id if necessary

    if (estimateNumber && estimateVersion && condemnationVersion) {
      let result = yield call(request.get, {
        path: `Estimate/id/${estimateNumber}/${estimateVersion}/${condemnationVersion}`,
        returnWholeResponse: true,
      });

      if (result instanceof Error) {
        yield put(
          processesActions.setError(processName, result.status, result.message)
        );
        continue;
      }

      // save estimate id

      yield put(
        actions.updateUi({
          estimateId: result.text,
        })
      );
    }

    // set success of process

    yield put(processesActions.setSuccess(processName));
  }
}

export default createRootSaga(watchHanldeSingleSignon);
