import React, { useEffect } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import RegularModal from "./../../common/components/blocks/RegularModal";
import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import * as formValidatorActions from "./../../common/services/form-validator/actions";
import * as processesSelectors from "./../../common/redux/processes/selectors";

import "./style.css";
import * as models from "./../redux/models";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

function UpdateBidModal() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const form = useSelector(selectors.getUpdateBidForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getUpdateBidForm,
        updateFormActionCreator: actions.updateBidForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["amount"],
        formSelector: selectors.getUpdateBidForm,
        updateFormActionCreator: actions.updateBidForm,
        onValid: () => dispatch(actions.updateBid()),
        disableScrolling: true,
      })
    );
  };

  const onCancel = () => {
    dispatch(actions.updateUi({ showUpdateBidModal: false }));
  };

  useEffect(function resetFormOnUnmount() {
    return () => {
      if (!ui.showUpdateBidModal) {
        dispatch(actions.updateBidForm(models.updateBidForm));
      }
    };
  }, []);

  const updateBidProcess = processes["bidProcess"] || {};

  return (
    <RegularModal onCancel={onCancel} className="UpdateBidModal">
      <React.Fragment>
        <h2 className="text-color1">
          <b>Oppdatere bud</b>
        </h2>
        <p className="letter-spacing-1 mt-4">Angi budbeløpet</p>
        <Form className="mt-5" onSubmit={onSubmit}>
          <FormGroup>
            <InputText
              id="amount"
              label="Bud (hele kr)"
              value={form.amount.value}
              onChange={(e) => validateAndUpdateProp(e, "amount")}
              hasError={form.amount.hasError}
              errorMessage={form.amount.errorMessage}
            />
          </FormGroup>
          {updateBidProcess.hasError ? (
            <FormGroup className="mt-5">
              <div className="text-center">
                <b className="text-danger">Det oppstod en feil</b>
                <br />
                <b>{updateBidProcess.errorMessage}</b>
              </div>
            </FormGroup>
          ) : null}
          <FormGroup className="mt-5">
            <Row className="d-flex justify-content-center">
              <Col xs="5">
                <Button
                  type="button"
                  color="color7"
                  className="w-100"
                  onClick={onCancel}
                >
                  Avbryt
                </Button>
              </Col>
              <Col xs="5">
                {updateBidProcess.inProcess ? (
                  <Spinner size="medium" />
                ) : (
                  <Button type="submit" color="color2" className="w-100">
                    Send inn
                  </Button>
                )}
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </React.Fragment>
    </RegularModal>
  );
}

export default UpdateBidModal;
