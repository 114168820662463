import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "./../../../common/components/elements/Spinner";
import Button from "./../../../common/components/elements/Button";
import * as processesSelectors from "./../../../common/redux/processes/selectors";

import "./style.css";
import WorkshopSignupConfirmationModal from "./ConfirmationModal";
import WorkshopSignupForm from "./Form";
import WorkshopSignupTable from "./Table";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";
import * as models from "./../redux/models";

function WorkshopSignup({ id, workshopNumber }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateUi = (updateOb) => {
    dispatch(actions.updateUi({ updateOb }));
  };

  useEffect(function fetchSignupOnMount() {
    dispatch(actions.fetchSignup(id));
  }, []);

  useEffect(function fetchWorkshopRegisterOnMount() {
    if (!data.workshopRegisters || !data.workshopRegisters[workshopNumber]) {
      dispatch(actions.fetchWorkshopRegister({ id, workshopNumber }));
    }
  }, []);

  useEffect(function resetOnUnmount() {
    return () => {
      dispatch(actions.updateForm(models.form));
    };
  }, []);

  let fetchingSignup = processes[`fetchSignup:${id}`] || {};
  let fetchingWorkshopRegister =
    processes[`fetchWorkshopRegister:${id}:${workshopNumber}`] || {};
  let updatingSignupStatus = processes[`updateSignupStatus:${id}`] || {};

  let signup = data.signups && data.signups[id];
  let wr = data.workshopRegisters && data.workshopRegisters[workshopNumber];
  let isWRNotFound =
    fetchingWorkshopRegister.hasError && fetchingWorkshopRegister.status == 5;

  let rowTwoColSpan = 3;
  if (signup && signup.status === 1) rowTwoColSpan = 2;

  return (
    <React.Fragment>
      <WorkshopSignupConfirmationModal />
      <div className="WorkshopSignup">
        {fetchingSignup.inProcess || fetchingWorkshopRegister.inProcess ? (
          <div className="loading-block">
            <Spinner size="medium" />
          </div>
        ) : null}
        {fetchingSignup.hasError ||
        (fetchingWorkshopRegister.hasError && !isWRNotFound) ? (
          <div className="text-center">
            <b className="text-danger">Det oppstod en feil</b>
            <br />
            <b>
              {fetchingSignup.errorMessage ||
                fetchingWorkshopRegister.errorMessage}
            </b>
          </div>
        ) : null}
        {signup && (wr || isWRNotFound) ? (
          <React.Fragment>
            <div className="table-form-container">
              <div className="table-container">
                <WorkshopSignupTable id={id} workshopNumber={workshopNumber} />
              </div>
              <div className="form-container">
                <WorkshopSignupForm id={id} />
              </div>
            </div>
            {updatingSignupStatus.inProcess ? (
              <div className="mt-4">
                <Spinner size="medium" />
              </div>
            ) : null}
            {updatingSignupStatus.hasError ? (
              <div className="mt-4 text-center">
                <b className="text-danger">Det oppstod en feil</b>
                <br />
                <b>{updatingSignupStatus.errorMessage}</b>
              </div>
            ) : null}
            <table className="mt-4">
              <tbody>
                {signup.status == 1 || signup.status == 5 ? (
                  <tr>
                    <td colSpan="6">
                      <Button
                        color="color2"
                        className="w-100"
                        disabled={
                          !form.isFormValid ||
                          ui.disableAcceptButton ||
                          updatingSignupStatus.inProcess
                        }
                        onClick={() =>
                          dispatch(
                            actions.updateSignupStatus({ id, status: 6 })
                          )
                        }
                      >
                        Opprett bruker og send e-post for aktivering
                      </Button>
                    </td>
                  </tr>
                ) : null}
                <tr>
                  {/*
                        signup.status == 1 ?
                          <td colSpan="2">
                            <Button
                              color="color1"
                              className="w-100"
                              disabled={!form.isFormValid || ui.disableAcceptButton || updatingSignupStatus.inProcess}
                              onClick={() => dispatch(actions.updateSignupStatus({id, status: 5}))}
                            >
                              Send e-post for vurdering i selskaper
                            </Button>
                          </td>
                          :
                          null
                        */}

                  {signup.status == 1 ? (
                    <td colSpan="2">
                      <Button
                        color="color1"
                        className="w-100"
                        disabled={
                          ui.disableAcceptButton ||
                          updatingSignupStatus.inProcess
                        }
                        onClick={() =>
                          dispatch(
                            actions.updateSignupStatus({ id, status: -5 })
                          )
                        }
                      >
                        Sett til under behandling i selskaper
                      </Button>
                    </td>
                  ) : null}

                  <td colSpan={rowTwoColSpan}>
                    <Button
                      color="color1"
                      className="w-100"
                      disabled={updatingSignupStatus.inProcess}
                      onClick={() => dispatch(actions.setMeAsAssigned({ id }))}
                    >
                      Sett deg selv som behandler
                    </Button>
                  </td>
                  <td colSpan={rowTwoColSpan}>
                    <Button
                      color="color1"
                      className="w-100"
                      disabled={updatingSignupStatus.inProcess}
                      onClick={() => dispatch(actions.sendMeEmail({ id }))}
                    >
                      Send e-post til deg selv
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <Button
                      color="color8"
                      className="w-100"
                      disabled={updatingSignupStatus.inProcess}
                      onClick={() =>
                        updateUi({
                          confirmationModal: { show: true, id, status: 2 },
                        })
                      }
                    >
                      Avvis med e-post til verksted
                    </Button>
                  </td>
                  <td colSpan="3">
                    <Button
                      color="color8"
                      className="w-100"
                      disabled={updatingSignupStatus.inProcess}
                      onClick={() =>
                        updateUi({
                          confirmationModal: { show: true, id, status: 4 },
                        })
                      }
                    >
                      Avvis uten e-post
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  );
}

export default WorkshopSignup;
