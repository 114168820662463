import * as config from './../config';

export const data = {};

export const form = {
  isFormValid: true,
  workshopNumber: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required', 'number', {name: 'length', size: 7, type: 'eq'}, 'workshopNumber'],
  },
  contactTitle: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required']
  },
  contactName: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required']
  },
  email: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required', 'email']
  }
};

export const ui = {
  // other stuff
  section: 1,
  showBlinkingMessage: false,
  disableAcceptButton: false,
  hideGridSpinner: false,
  // confirmation modal params
  confirmationModal: {
    show: false,
    status: null,
    id: null
  },
  // grid params
  currentPage: 0,
  expandedRowIds: [],
  sorting: config.defaultSorting,
  filters: config.defaultFilters,
  customFilters: [],
  currentPageSize: config.defaultPageSize
};