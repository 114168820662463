import { call, put } from "redux-saga/effects";

import request from "../request";
import * as immutable from "../../helpers/immutable";
import * as helpers from "../../helpers";
import { showErrorMessage } from "../../../Toaster/redux/actions";

export const clientValidators = {
  email: { pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ },
  postalCode: { pattern: /^\d{4}$/ },
  number: { pattern: /^\d{1,}((\.|\,)\d{1,})?$/ },
  alphanumeric: { pattern: /^[a-zA-Z0-9]+$/ },
  phoneNumber: { pattern: /^\+?\d{7,18}$/ },
  required: {
    func: ({ value }) => {
      if (helpers.isArray(value)) {
        return !!value.length;
      } else if (helpers.isBoolean(value)) {
        return value;
      } else {
        return /^\s*\S+/.test(value);
      }
    },
  },
  numberMinMax: {
    func: ({ value, rule: { min, max } }) => value >= min && value <= max,
  },
  length: {
    func: ({ value, rule: { size, type } }) => {
      value = String(value);

      if (type === "gte" || !type) return value && value.length >= size;
      else if (type === "lte") return value && value.length <= size;
      else if (type === "eq") return value && value.length === size;
    },
  },
  equal: {
    func: ({ value, rule, form }) => {
      return value === form[rule.with].value;
    },
  },
  notequal: {
    func: ({ value, rule, form }) => {
      return value !== form[rule.with].value;
    },
  },
  selectFromList: {
    func: ({ form, prop }) => {
      return form[prop].isValueFromList;
    },
  },
};

export const backendValidators = {
  postalCode: function* ({ value, prop }) {
    let processName = `getPostalArea::${prop}`;

    let result = yield call(request.get, {
      path: `Postal/${value}`,
      processName,
    });

    if (result instanceof Error) {
      return {
        isOk: false,
        errorMessage: result.message,
      };
    }

    return {
      isOk: true,
      errorMessage: "",
      payload: { postalArea: result.postalArea },
    };
  },
  workshopNumber: function* ({ value }) {
    let processName = `verifyWorkshopNumber`;

    let result = yield call(request.get, {
      path: `Workshop/verifyUserNotExists/${value}`,
      processName,
    });

    if (result instanceof Error) {
      return {
        isOk: false,
        errorMessage: result.message,
      };
    }

    return {
      isOk: true,
      errorMessage: "",
    };
  },
};

export const errorMessages = {
  required: () => "Feltet er påkrevd",
  email: () => "E-postadressen er ugyldig",
  postalCode: () => "Postnummer må bestå av 4 siffer",
  number: () => "Verdien må være et tall",
  alphanumeric: () => "Verdien kan kun bestå av tall og bokstaver",
  phoneNumber: () => "Verdien må være et telefonnummer",
  length: ({ rule: { size, type }, form, prop }) => {
    let title = form[prop].title;

    if (title) title = `'${title}'`;
    else title = "Verdien";
    if (type == "gte" || !type) return `${title} må ha minst ${size} tegn`;
    else if (type == "lte")
      return `${title} kan ikke bestå av mer enn ${size} tegn`;
    else if (type == "eq") return `${title} må bestå av ${size} tegn`;
  },
  numberMinMax: ({ value, rule: { min, max } }) => {
    if (min && value < min) return `Minimumverdien er ${min}`;
    if (max && value > max) return `Maksimumverdien er ${max}`;
  },
  equal: ({ form, prop, rule }) => {
    return `'${form[prop].title}' er ikke lik '${form[rule.with].title}'`;
  },
  notequal: ({ form, prop, rule }) => {
    return `'${form[prop].title}' er lik '${form[rule.with].title}'`;
  },
  selectFromList: () => "Velg verdi i listen",
};

export function* validateValue({ value, prop, rules, form }) {
  let isOk = true;
  let errorMessage = "";
  let payload = {};

  for (const rule of rules) {
    const ruleName = rule.name || rule;

    // if such ruleName does not exist, warn user about this

    if (!clientValidators[ruleName] && !backendValidators[ruleName]) {
      yield put(showErrorMessage(`Unknown rule '${ruleName}'`));
    }

    // make client side validation

    if (!!clientValidators[ruleName]) {
      let clientValidator = clientValidators[ruleName];

      if (clientValidator.pattern) {
        isOk = clientValidator.pattern.test(value);
      } else if (clientValidator.func) {
        isOk = clientValidator.func({ value, rule, form, prop });
      }

      if (!isOk) {
        errorMessage = errorMessages[ruleName]({ value, prop, rule, form });

        return { isOk, errorMessage };
      }
    }

    // make backend validation

    if (backendValidators[ruleName]) {
      const result = yield call(backendValidators[ruleName], { value, prop });

      if (!isOk) {
        return { isOk: result.isOk, errorMessage: result.errorMessage };
      }

      payload = immutable.updateObjectProps(payload, result.payload);
    }
  }

  return { isOk, errorMessage, payload };
}
