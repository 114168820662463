import routes from "../common/config/routes";

import Home from "./../Home";
import Login from "./../Login";
import SingleSignOn from "./../SingleSignOn";
import ForgotPassword from "./../ForgotPassword";
import ResetPassword from "./../ResetPassword";
import SetPassword from "./../SetPassword";
import Register from "./../Register";
import About from "./../About";
import Forms from "./../Forms";
import Contact from "./../Forms/Contact";
import ChangeContactInformation from "./../Forms/ChangeContactInformation";
import CompanyFeedback from "./../Forms/CompanyFeedback";
import TechnicalError from "./../Forms/TechnicalError";
import Browse from "./../Browse";
import VehiclePage from "./../VehiclePage";
import MyBids from "./../MyBids";
import MySettings from "./../MySettings";
import MFASetup from "../MFASettings/SetupForm";
import MFADisable from "../MFASettings/DisableForm";
import MFAEnable from "../MFASettings/EnableForm";
import MFAResetAuthenticator from "../MFASettings/ResetAuthenticatorForm";
import MFAResetRecoveryCodes from "../MFASettings/ResetRecoveryCodesForm";
import TradeConditions from "./../TradeConditions";
import AdminPage from "./../Admin";
import AdminCompanies from "../Admin/Companies";
import AdminWorkshopSignups from "../Admin/WorkshopSignups";
import AdminAccessMatrix from "./../Admin/AccessMatrix";
import AdminEstimates from "./../Admin/Estimates";
import AdminWorkshops from "./../Admin/Workshops";
import AdminFaq from "./../Admin/Faq";
import AdminStatusMessages from "./../Admin/StatusMessages";

export const mapRouteNameToComponent = {
  [routes.HOME.name]: Home,
  [routes.LOGIN.name]: Login,
  [routes.SINGLE_SIGN_ON_1.name]: SingleSignOn,
  [routes.SINGLE_SIGN_ON_2.name]: SingleSignOn,
  [routes.SINGLE_SIGN_ON_3.name]: SingleSignOn,
  [routes.FORGOT_PASSWORD.name]: ForgotPassword,
  [routes.RESET_PASSWORD.name]: ResetPassword,
  [routes.SET_PASSWORD.name]: SetPassword,
  [routes.REGISTER.name]: Register,
  [routes.ABOUT.name]: About,
  [routes.CONTACT.name]: Contact,
  [routes.CHANGE_CONTACT_FORMATION.name]: ChangeContactInformation,
  [routes.COMPANY_FEEDBACK.name]: CompanyFeedback,
  [routes.TECHNICAL_ERROR.name]: TechnicalError,
  [routes.FORMS.name]: Forms,
  [routes.VEHICLE_PAGE.name]: VehiclePage,
  [routes.BROWSE.name]: Browse,
  [routes.MY_BIDS.name]: MyBids,
  [routes.MY_SETTINGS.name]: MySettings,
  [routes.MFA_SETUP.name]: MFASetup,
  [routes.MFA_DISABLE.name]: MFADisable,
  [routes.MFA_ENABLE.name]: MFAEnable,
  [routes.MFA_RESET_AUTHENTICATOR_APP.name]: MFAResetAuthenticator,
  [routes.MFA_RESET_RECOVERY_CODES.name]: MFAResetRecoveryCodes,
  [routes.TRADE_CONDITIONS.name]: TradeConditions,
  [routes.ADMIN_ACCESS_MATRIX.name]: AdminAccessMatrix,
  [routes.ADMIN_WORKSHOPS.name]: AdminWorkshops,
  [routes.ADMIN_WORKSHOP_SIGNUPS.name]: AdminWorkshopSignups,
  [routes.ADMIN_COMPANIES.name]: AdminCompanies,
  [routes.ADMIN_ESTIMATES.name]: AdminEstimates,
  [routes.ADMIN_FAQ.name]: AdminFaq,
  [routes.ADMIN_STATUSMESSAGES.name]: AdminStatusMessages,
  [routes.ADMIN.name]: AdminPage,
};
