import React from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as formValidatorActions from "./../../common/services/form-validator/actions";

function ForgotPasswordForm() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["usernameOrEmail"],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        onValid: () => dispatch(actions.forgotPassword()),
      })
    );
  };

  const forgotPasswordProcess = processes["forgotPassword"] || {};

  if (forgotPasswordProcess.isSuccessed) {
    return (
      <div className="form1">
        <p className="text-color1 text-center">
          <h5>
            <b>Bekreftelse på sendt e-post</b>
          </h5>
        </p>
        <p className="text-color5 text-center mt-5">
          <h5>
            <b>
              Du skal nå ha mottatt en e-post om hvordan du kan tilbakestille
              passordet ditt
            </b>
          </h5>
        </p>
      </div>
    );
  }

  return (
    <Form className="ForgotPasswordForm form1" onSubmit={onSubmit}>
      <h1 className="text-color1">
        <b>Glemt passord?</b>
      </h1>
      <p className="letter-spacing-1 mt-3">
        Skriv inn ditt brukernavn eller e-postadresse
      </p>
      <FormGroup className="mt-5">
        <InputText
          id="usernameOrEmail"
          label="E-post eller brukernavn"
          value={form.usernameOrEmail.value}
          onChange={(e) => validateAndUpdateProp(e, "usernameOrEmail")}
          hasError={form.usernameOrEmail.hasError}
          errorMessage={form.usernameOrEmail.errorMessage}
        />
      </FormGroup>
      {forgotPasswordProcess.hasError ? (
        <FormGroup>
          <div className="text-center">
            <b className="text-danger">Det oppstod en feil</b>
            <br />
            <b>{forgotPasswordProcess.errorMessage}</b>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup className="mt-5">
        <Row>
          <Col lg="6">
            {forgotPasswordProcess.inProcess ||
            forgotPasswordProcess.isSuccessed ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color1"
                className="text-uppercase w-100"
              >
                Send inn
              </Button>
            )}
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default ForgotPasswordForm;
