import { combineReducers } from "redux";

import * as sessionActions from "./common/redux/session/actions";
import Register from "./Register/redux/reducer";
import Login from "./Login/redux/reducer";
import SingleSignOn from "./SingleSignOn/redux/reducer";
import ForgotPassword from "./ForgotPassword/redux/reducer";
import ResetPassword from "./ResetPassword/redux/reducer";
import SetPassword from "./SetPassword/redux/reducer";
import Browse from "./Browse/redux/reducer";
import Home from "./Home/redux/reducer";
import VehiclePage from "./VehiclePage/redux/reducer";
import Gallery from "./Gallery/redux/reducer";
import MyBids from "./MyBids/redux/reducer";
import TradeConditions from "./TradeConditions/redux/reducer";
import MySettings from "./MySettings/redux/reducer";
import MFASettings from "./MFASettings/redux/reducer";
import AccessMatrix from "./Admin/AccessMatrix/redux/reducer";
import ChangeContactInformation from "./Forms/ChangeContactInformation/redux/reducer";
import CompanyFeedback from "./Forms/CompanyFeedback/redux/reducer";
import TechnicalError from "./Forms/TechnicalError/redux/reducer";
import ContactBlock from "./common/components/blocks/Contact/redux/reducer";
import BidPasswordModal from "./common/components/blocks/BidPasswordModal/redux/reducer";
import AdminCompanies from "./Admin/Companies/redux/reducer";
import AdminEstimates from "./Admin/Estimates/redux/reducer";
import AdminWorkshops from "./Admin/Workshops/redux/reducer";
import AdminBidsGrid from "./Admin/common/components/blocks/AdminBidsGrid/redux/reducer";
import AdminWorkshopSignups from "./Admin/WorkshopSignups/redux/reducer";
import FaqBlock from "./common/components/blocks/Faq/redux/reducer";
import AdminFaq from "./Admin/Faq/redux/reducer";
import AdminFaqQuestions from "./Admin/FaqQuestions/redux/reducer";
import AdminFaqCategories from "./Admin/FaqCategories/redux/reducer";
import StatusMessagesBlock from "./common/components/blocks/StatusMessages/redux/reducer";
import AdminStatusMessages from "./Admin/StatusMessages/redux/reducer";
import processes from "./common/redux/processes/reducer";
import session from "./common/redux/session/reducer";
import data from "./common/redux/data/reducer";
import query from "./common/redux/query/reducer";
import profile from "./common/redux/profile/reducer";

const appReducer = combineReducers({
  Register,
  Login,
  SingleSignOn,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  Browse,
  Home,
  ContactBlock,
  BidPasswordModal,
  VehiclePage,
  Gallery,
  MyBids,
  TradeConditions,
  MySettings,
  MFASettings,
  ChangeContactInformation,
  CompanyFeedback,
  TechnicalError,
  AccessMatrix,
  AdminCompanies,
  AdminEstimates,
  AdminWorkshops,
  AdminBidsGrid,
  AdminWorkshopSignups,
  FaqBlock,
  AdminFaq,
  AdminFaqQuestions,
  AdminFaqCategories,
  StatusMessagesBlock,
  AdminStatusMessages,
  data,
  processes,
  session,
  query,
  profile,
});

// see: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

const rootReducer = (state, action) => {
  if (action.type === sessionActions.UNSET_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;
