import * as reduxService from './../../common/services/redux';

import * as actions from './actions';
import * as models from './models';

export default reduxService.createReducer(
  [
    {name: 'ui', state: models.ui, action: actions.UPDATE_UI},
    {name: 'data', state: models.data, action: actions.UPDATE_DATA},
    {name: 'form', state: models.form, action: actions.UPDATE_FORM}
  ],
  false
);

