import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";

import { useScrollToTop, useTitle } from "../common/hooks";

import Form from "./Form";
import "./style.css";

function Register() {
  useScrollToTop();
  useTitle("Innmelding");

  return (
    <div className="Innmelding">
      <Container>
        <Row className="mt-5">
          <Col lg="6" md="12">
            <h1 className="text-color1">
              <b>Innmelding</b>
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col lg="6" md="12">
            <div className="lead text-color5">
              <p>
                Det er kun bedrifter som er godkjent av myndighetene innenfor de
                aktuelle virksomhetsområdene som kan få tilgang til
                forsikringsbiler.no på internett.
              </p>
              Det skyldes at:
              <ul>
                <li>
                  bare godkjente skadereparasjonsverksteder får drive oppbygging
                  av innløste kjøretøy
                </li>
                <li>
                  det er spesielle miljøkrav for bedrifter som driver
                  demontering
                </li>
                <li>det ikke er mulig å inspisere objektene før bud avgis</li>
              </ul>
              For å kunne få generell tilgang til forsikringsbiler.no kreves det
              å:
              <ul>
                <li>
                  være registrert med organisasjonsnummer i Foretaksregisteret
                </li>
                <li>være registrert i DBS hos Bilskadekontoret</li>
                <li>bruke e-post</li>
              </ul>
              <p className="text-center text-color8 font-weight-bold">
                Innmeldinger som ikke oppfyller ovenstående krav vil derfor ikke
                kunne behandles.
              </p>
              <p>
                Tilgang til forsikringsbiler.no gjelder bedriften som er
                godkjent og denne rettigheten kan ikke overdras til andre.
                Overtredelse vil medføre utestengelse.
              </p>
              <p>
                For å kunne få tilgang, må nedenstående skjema fylles ut og
                sendes. Dersom bedriften ikke er registrert i DBS hos
                Bilskadekontoret vil bedriften få beskjed om dette. Bedriften må
                da henvende seg til sin forretningsforbindelse i forsikring.
                Forsikringsselskapet vil da normalt sørge for registrering i DBS
                i samarbeid med Bilskadekontoret.
              </p>
              <p>
                Når skjema for innmelding er ferdig behandlet vil bedriften
                motta e-post om dette. Bedriften vil motta e-post for aktivering
                før forsikringsbiler.no kan tas i bruk.
              </p>
            </div>
          </Col>
          <Col lg="6" md="12" className="mt-3">
            <Form />
            <p className="text-center mt-3 mb-10">
              <NavLink to="/login" className="text-color1">
                Jeg har allerede en bruker
              </NavLink>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
