export const prefix = 'ADMIN_STATUSMESSAGES::';
export const domain = 'AdminStatusMessages';
export const title = 'Statusmeldinger';
export const componentName = 'AdminStatusMessages';

// grid config

export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'firstColumn'},
  //{name: 'id', title: 'Id'}, // Is system-generated so should not be visible in grid
  {name: 'message', title: 'Melding'},
  {name: 'visibility', title: 'Synlighet'}
];
export const columnExtensions = [];
export const filteringStateColumnExtensions = [];