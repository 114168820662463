import { take, put, call, all } from "redux-saga/effects";

import request from "./../../../../services/request";
import { createRootSaga } from "../../../../services/redux-saga";

import * as config from "./../config";
import * as actions from "./actions";

export const processNames = {
  FETCH_FAQ: `${config.prefix}FETCH_FAQ`,
};

export function* watchFetchFaq() {
  while (true) {
    let { isPreviewMode, previewMode } = yield take(actions.FETCH_FAQ);

    let path;

    if (isPreviewMode) {
      path =
        previewMode === config.previewModes.FOR_AUTHORIZED
          ? "faq/preview/loggedin"
          : "faq/preview/notloggedin";
    } else {
      path = "faq/all";
    }

    let result = yield call(request.get, {
      path,
      processName: processNames.FETCH_FAQ,
    });

    if (result instanceof Error) continue;

    let updateOb = {};

    if (isPreviewMode) {
      updateOb.preview = {
        [previewMode]: result,
      };
    } else {
      updateOb.faq = result;
    }

    yield put(actions.updateData(updateOb));
  }
}

export default createRootSaga(watchFetchFaq);
