import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { NavLink, Redirect } from "react-router-dom";
import QRCode from "react-qr-code";

import Spinner from "../../common/components/elements/Spinner";
import InputText from "./../../common/components/elements/InputText";
import Button from "../../common/components/elements/Button";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";
import { routes } from "../../common/config";
import * as processesSelectors from "../../common/redux/processes/selectors";
import * as formValidatorActions from "../../common/services/form-validator/actions";

import * as models from "../redux/models";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import SaveRecoveryCodes from "../SaveRecoveryCodes";

function MFASetup() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) =>
    actions.updateUi({ verificationCodeForm: updateOb });

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getVerificationCodeForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    const props = ["code"];

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props,
        formSelector: selectors.getVerificationCodeForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.verifyAuthenticatorCode()),
      })
    );
  };

  useEffect(function resetFormOnMount() {
    dispatch(
      actions.updateUi({ verificationCodeForm: models.ui.verificationCodeForm })
    );
  }, []);

  useEffect(function resetProcessOnMount() {
    dispatch(processesActions.resetProcess(actions.VERIFY_AUTHENTICATOR_CODE));
  }, []);

  useEffect(function initAuthenticatorOnMount() {
    dispatch(actions.initAuthenticator());
  }, []);

  const { verificationCodeForm } = ui;
  const initAuthenticatorProcess = processes[actions.INIT_AUTHENTICATOR] || {};
  const verifyCodeProcess = processes[actions.VERIFY_AUTHENTICATOR_CODE] || {};

  if (verifyCodeProcess.isSuccessed) {
    if (verifyCodeProcess.status === 204) {
      return <Redirect to={routes.MY_SETTINGS.path} />;
    } else if (data.recoveryCodes) {
      return <SaveRecoveryCodes codes={data.recoveryCodes} />;
    }
  }

  return (
    <React.Fragment>
      <Container className="MySettings">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Tofaktorautentisering</b>
            </h1>
            {initAuthenticatorProcess?.inProcess && (
              <div className="form1 mt-3">
                <Spinner />
              </div>
            )}
            {initAuthenticatorProcess?.hasError && (
              <div className="form1 mt-3">
                <ErrorBlock error={initAuthenticatorProcess} />
              </div>
            )}
            {initAuthenticatorProcess?.isSuccessed &&
              data?.authenticatorUri && (
                <div className="form1 mt-3">
                  <h3 className="mb-3">
                    <b>Konfigurer autentiseringsapplikasjon</b>
                  </h3>
                  <h6 className="mb-3">
                    <b>
                      Gå gjennom følgende steg for å bruke en
                      autentiseringsapplikasjon:
                    </b>
                  </h6>
                  <ul>
                    <li className="mb-3">
                      Last ned en tofaktor autentiseringsapplikasjon som
                      Microsoft Authenticator for{" "}
                      <a
                        href={"https://go.microsoft.com/fwlink/?Linkid=825072"}
                        target={"_blank"}
                      >
                        Android
                      </a>
                      ,{" "}
                      <a
                        href={"https://go.microsoft.com/fwlink/?Linkid=825073"}
                        target={"_blank"}
                      >
                        iOS
                      </a>{" "}
                      eller{" "}
                      <a
                        href={"https://go.microsoft.com/fwlink/?Linkid=825071"}
                        target={"_blank"}
                      >
                        Windows Phone
                      </a>
                      . Google Authenticator for{" "}
                      <a
                        href={
                          "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                        }
                        target={"_blank"}
                      >
                        Android
                      </a>{" "}
                      eller{" "}
                      <a
                        href={
                          "https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                        }
                        target={"_blank"}
                      >
                        iOS
                      </a>
                      .
                    </li>
                    <li className="mb-3">
                      <p className="mb-3">
                        {" "}
                        Skann QR-koden eller skriv inn denne koden{" "}
                        <code>{data?.sharedKey}</code> i din
                        tofaktor-autentiseringsapp. Mellomrom og store/små
                        bokstaver er valgfritt.
                      </p>
                      <div>
                        <QRCode value={data?.authenticatorUri}></QRCode>
                      </div>
                    </li>
                    <li className="mb-3">
                      <p>
                        Når du har skannet QR-koden eller tastet inn nøkkelen
                        over, vil tofaktorautentiseringsapplikasjonen din gi deg
                        en autentiseringskode.
                      </p>
                      <p className="mb-4">
                        Skriv inn koden i feltet nedenfor for å bekrefte at
                        autentiseringsappen er korrekt satt opp og aktivere
                        tofaktorautentisering.
                      </p>
                      <Form onSubmit={onSubmit}>
                        <FormGroup>
                          <InputText
                            id={verificationCodeForm.code.id}
                            type={verificationCodeForm.code.type}
                            label={verificationCodeForm.code.label}
                            value={verificationCodeForm.code.value}
                            hasError={verificationCodeForm.code.hasError}
                            errorMessage={
                              verificationCodeForm.code.errorMessage
                            }
                            onChange={(e) => validateAndUpdateProp(e, "code")}
                          />
                        </FormGroup>
                        <Row>
                          {verifyCodeProcess.hasError ? (
                            <Col xs="12" className="mb-3">
                              <ErrorBlock error={verifyCodeProcess} />
                            </Col>
                          ) : null}
                          <Col lg="6" className="mx-auto">
                            {verifyCodeProcess.inProcess ? (
                              <Spinner size="medium" />
                            ) : (
                              <Button
                                type="submit"
                                color="color2"
                                className="text-uppercase w-100"
                              >
                                Aktiver 2FA
                              </Button>
                            )}
                          </Col>
                          <Col lg="6">
                            <NavLink to={routes.MY_SETTINGS.path}>
                              <Button
                                color="color1"
                                className="text-uppercase w-100"
                              >
                                Gå tilbake
                              </Button>
                            </NavLink>
                          </Col>
                        </Row>
                      </Form>
                    </li>
                  </ul>
                </div>
              )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MFASetup;
