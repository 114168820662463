import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../../common/helpers/immutable';
import * as models from './models';

const data = (state = models.data, action) => {
  switch (action.type) {
    case actions.UPDATE_DATA:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

const ui = (state = models.ui, action) => {
  switch (action.type) {
    case actions.UPDATE_UI:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  data,
  ui
});

