import React from "react";

import { routes } from "../common/config/routes";
import Page from "./../common/components/blocks/Page";
import SectionHeader from "./../common/components/blocks/SectionHeader";
import AboutBlock from "./../common/components/blocks/About";
import FaqBlock from "./../common/components/blocks/Faq";

import "./style.css";
import headerBg from "./imgs/bg.jpg";

function About() {
  return (
    <Page
      className="About"
      title={routes.ABOUT.title}
      fluid={true}
      withPadding={false}
    >
      <SectionHeader
        className="mb-5"
        header="Spørsmål og svar"
        text="Her får du svar på vanlige spørsmål"
        bg={headerBg}
      />
      <AboutBlock className="mb-5" />
      <FaqBlock className="mb-6" show={window.config.showFaq} />
    </Page>
  );
}

export default About;
