const _prefix = 'ACCESSMATRIX::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH_ACCESS_MATRIX_DATA = _prefix + 'FETCH_ACCESS_MATRIX_DATA';
export const UPDATE_ACCESS = _prefix + 'UPDATE_ACCESS';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const fetchAccessMatrixData = () => ({type: FETCH_ACCESS_MATRIX_DATA});
export const updateAccess = (ids) => ({type: UPDATE_ACCESS, ids});