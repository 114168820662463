import React from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import * as formValidatorActions from "./../../common/services/form-validator/actions";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";

import "./style.css";
import * as selectors from "./../redux/selectors";
import * as actions from "./../redux/actions";
import Checkbox from "../../common/components/elements/Checkbox";

function LoginForm() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getLoginPasswordForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) => {
    return actions.updateUi({ loginPasswordForm: updateOb });
  };

  const validateAndUpdateProp = (event, prop, value) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        value,
        prop,
        formSelector: selectors.getLoginPasswordForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    // set username & password values to store in case if they were autofilled by browser,
    // see: https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
    validateAndUpdateProp(
      null,
      "username",
      document.forms[0].elements.username.value
    );
    validateAndUpdateProp(
      null,
      "password",
      document.forms[0].elements.password.value
    );

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["username", "password", "rememberMe"],
        formSelector: selectors.getLoginPasswordForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.login()),
      })
    );
  };

  return (
    <Form className="LoginPasswordForm form1" onSubmit={onSubmit}>
      <h1 className="text-color1 mb-4">
        <b>Innlogging</b>
      </h1>
      <FormGroup>
        <InputText
          id="username"
          label="E-post eller brukernavn"
          value={form.username.value}
          onChange={(e) => validateAndUpdateProp(e, "username")}
          hasError={form.username.hasError}
          errorMessage={form.username.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="password"
          type="password"
          label="Passord"
          value={form.password.value}
          onChange={(e) => validateAndUpdateProp(e, "password")}
          hasError={form.password.hasError}
          errorMessage={form.password.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <Row className="mt-2">
          <Col>
            <Checkbox
              label="Husk meg"
              checked={form.rememberMe.value}
              onChange={(e) => validateAndUpdateProp(e, "rememberMe")}
            />
          </Col>
          <Col className="text-right">
            <NavLink to="/forgot-password" className="text-color1">
              Glemt passord?
            </NavLink>
          </Col>
        </Row>
      </FormGroup>
      {processes.login?.hasError ? (
        <FormGroup>
          <div className="text-center">
            <b className="text-danger ">Kunne ikke logge inn</b>
            <br />
            <b>{processes.login?.errorMessage}</b>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Row>
          <Col lg="6">
            {processes.login?.inProcess || processes.login?.isSuccessed ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color1"
                className="text-uppercase w-100"
              >
                {"Login"}
              </Button>
            )}
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default LoginForm;
