import React from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import * as sessionSelectors from "./../../common/redux/session/selectors";
import * as sessionService from "./../../common/services/session";
import Button from "./../../common/components/elements/Button";
import CheckCircle from "./../../common/components/icons/CheckCircle";
import TimesCircle from "./../../common/components/icons/TimesCircle";

import "./style.css";
import * as actions from "./../redux/actions";

const _prepareLink = (link) => (/^http/.test(link) ? link : `http://${link}`);

function TradeConditionCard({ tc, isWorkshopUser }) {
  const dispatch = useDispatch();

  const session = useSelector(sessionSelectors.getSession);

  const changeAcceptedStatus = (isAccepted) => {
    if (sessionService.isBidTokenExpired(session)) {
      dispatch(
        actions.updateUi({
          showBidPasswordModal: true,
          nextActionObjectOnValidBidToken: actions.changeStatus(
            tc.companyNumber,
            isAccepted
          ),
        })
      );
    } else {
      dispatch(actions.changeStatus(tc.companyNumber, isAccepted));
    }
  };

  const loadTCText = () => {
    dispatch(actions.loadTCText(tc.companyNumber));
  };

  let lgClassName = isWorkshopUser ? "3" : "6";
  let lgClass = isWorkshopUser ? "2" : "3";

  return (
    <Row className="TradeConditionCard no-gutters">
      <Col lg={lgClassName} md="12" className="company-name">
        {tc.companyHomePage ? (
          <a
            href={_prepareLink(tc.companyHomePage)}
            className="text-color1"
            target="_blank"
          >
            <b>{tc.companyName}</b>
          </a>
        ) : (
          <span className="text-color5">
            <b>{tc.companyName}</b>
          </span>
        )}
      </Col>
      <Col lg={lgClass} md="12" className="tc-view">
        <span className="link-button" onClick={() => loadTCText()}>
          Vis salgsbetingelser
        </span>
      </Col>
      <Col lg={lgClass} md="12" className="tc-status">
        {isWorkshopUser && !tc.updated ? (
          tc.accepted ? (
            <span className="text-color1">
              <b>Akseptert</b>
            </span>
          ) : (
            <span className="text-color8">
              <b>IKKE akseptert</b>
            </span>
          )
        ) : (
          ""
        )}
      </Col>
      {isWorkshopUser ? (
        <Col lg="5" md="12" className="accept-buttons">
          <div className="accept-button">
            <Button
              color="color1"
              onClick={() => changeAcceptedStatus(true)}
              disabled={!tc.updated && tc.accepted}
            >
              <CheckCircle className="accept-button-icon" /> Aksepter
            </Button>
          </div>
          <div className="accept-button">
            <Button
              color="color8"
              onClick={() => changeAcceptedStatus(false)}
              disabled={!tc.updated && !tc.accepted}
            >
              <TimesCircle className="accept-button-icon" /> IKKE aksepter
            </Button>
          </div>
        </Col>
      ) : null}
    </Row>
  );
}

export default TradeConditionCard;
