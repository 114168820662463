import React, {useState} from 'react';

import './style.css';
import classname from './../../../../helpers/classname';

const componentName = 'FaqBlockCategoryCard';
const cn = classname(componentName);

const Card = ({category, description, ...rest}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div
      className={componentName}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      {...rest}
    >
      <h5 className={cn('header')}>
        {
          isFocused && !!description ?
            description :
            category
        }
      </h5>
    </div>
  )
};

export default Card;