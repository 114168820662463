import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as sessionActions from "./../common/redux/session/actions";
import * as processesActions from "./../common/redux/processes/actions";
import * as processesSelectors from "./../common/redux/processes/selectors";

import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

function SingleSignOn({ match }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function unsetPreviousSessionOnMount() {
    let {
      userName,
      oneTimePassword,
      estimateNumber,
      estimateVersion,
      condemnationVersion,
    } = match.params;

    // unset previous session
    // ! with unsetting of session, state of reducer is also being reset

    dispatch(sessionActions.unsetSession());

    // handle single sign on process

    dispatch(
      actions.handleSingleSignon({
        userName,
        oneTimePassword,
        estimateNumber,
        estimateVersion,
        condemnationVersion,
      })
    );
  }, []);

  useEffect(function resetProcessOnUnmount() {
    return () => {
      dispatch(processesActions.update({ handleSingleSignon: null }));
    };
  }, []);

  useEffect(function resetUiOnUnmount() {
    return () => {
      dispatch(actions.updateUi(null));
    };
  }, []);

  let handleSingleSignonProcess = processes.handleSingleSignon || {};

  if (handleSingleSignonProcess.isSuccessed) {
    let estimateId = ui.estimateId || match.params.estimateId;

    if (estimateId) {
      return <Redirect to={`/browse/${estimateId}`} />;
    } else {
      return <Redirect to="/home" />;
    }
  }

  return (
    <Container>
      <Row className="my-5">
        <Col className="text-center">
          {handleSingleSignonProcess.inProcess ? (
            <h4 className="text-color1">
              <b>Vennligst vent mens vi logger deg inn med engangspassord...</b>
            </h4>
          ) : null}
          {handleSingleSignonProcess.hasError ? (
            <div className="text-center">
              <b className="text-danger ">Kunne ikke logge inn</b>
              <br />
              <b>{handleSingleSignonProcess.errorMessage}</b>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default SingleSignOn;
