import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  PagingState,
  CustomPaging,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";

import dictionary from "./../../common/config/dictionary";
import classname from "./../../common/helpers/classname";
import ErrorBlock from "./../../common/components/blocks/ErrorBlock";
import Spinner from "./../../common/components/elements/Spinner";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as gridUtils from "./../../common/helpers/grid-utils";
import * as commonDataSelectors from "./../../common/redux/data/selectors";
import * as visibilityService from "./../../common/services/visibility";
import * as commonDataActions from "./../../common/redux/data/actions";

import "./style.css";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";
import CreateEditModal from "./CreateEditModal";
import { useDispatch, useSelector } from "react-redux";

const cn = classname(config.componentName);

function AdminFaqCategories() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const commonData = useSelector(commonDataSelectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateUi = (updateOb, triggerFetchGrid = true) => {
    dispatch(actions.updateUi(updateOb, triggerFetchGrid));
  };

  const onCurrentPageChange = (currentPage) => {
    updateUi({ currentPage });
  };

  const onPageSizeChange = (currentPageSize) => {
    updateUi({ currentPageSize });
  };

  const HeaderCell = (props) => {
    let { column } = props;

    if (column.name === "firstColumn") {
      return (
        <th onClick={() => dispatch(actions.launchCreateEditModal())}>
          <span className="link-button">{dictionary.NEW}</span>
        </th>
      );
    }

    return <TableHeaderRow.Cell {...props} />;
  };

  const TableCell = (props) => {
    const { grid } = data;

    let { column, row } = props;

    if (column.name === "firstColumn") {
      return (
        <Table.Cell className={cn("first-column")}>
          {grid.rows.indexOf(row) + grid.offset > 0 ? (
            <span
              className="link-button mr-3"
              onClick={() => dispatch(actions.moveUp(row.id))}
            >
              {dictionary.UP}
            </span>
          ) : null}
          {grid.rows.indexOf(row) + grid.offset < grid.totalCount - 1 ? (
            <span
              className="link-button mr-3"
              onClick={() => dispatch(actions.moveDown(row.id))}
            >
              {dictionary.DOWN}
            </span>
          ) : null}
          <span
            className="link-button mr-3"
            onClick={() => dispatch(actions.launchCreateEditModal(row.id))}
          >
            {dictionary.EDIT}
          </span>
          <span
            className="link-button text-color8"
            onClick={() => dispatch(actions.deleteItem(row.id))}
          >
            {dictionary.DELETE}
          </span>
        </Table.Cell>
      );
    }

    return <Table.Cell {...props} />;
  };

  const VisibilityFormatter = ({ value }) => {
    return (
      <span>{visibilityService.getVisibilityConfigByValue(value).title}</span>
    );
  };

  useEffect(function fetchCategories() {
    if (!commonData.faqCategories) {
      dispatch(commonDataActions.fetchFaqCategories());
    }
  }, []);

  useEffect(
    function fetchGrid() {
      const fetchGridProcess = processes[processNames.FETCH_GRID] || {};

      if (
        commonData.faqCategories &&
        !data.grid &&
        !fetchGridProcess.inProcess
      ) {
        dispatch(actions.fetchGrid());
      }
    },
    [commonData.faqCategories]
  );

  let fetchGridProcess = processes[processNames.FETCH_GRID] || {};

  let isDataPopulated = data.grid && commonData.faqCategories;

  return (
    <Col className={config.componentName}>
      <CreateEditModal />
      <Row>
        <Col>
          <div className={cn("content")}>
            {fetchGridProcess.inProcess ? (
              isDataPopulated ? (
                // spinner that is showed over the grid
                <div className={cn("process-status-over-grid")}>
                  <Spinner size="medium" className={cn("spinner")} />
                </div>
              ) : (
                // first spinner that is showed on initial fetch
                <div className={cn("process-status")}>
                  <Spinner size="medium" />
                </div>
              )
            ) : null}
            {fetchGridProcess.hasError && !isDataPopulated ? (
              <div className={cn("process-status")}>
                <ErrorBlock error={fetchGridProcess} />
              </div>
            ) : null}
            {isDataPopulated ? (
              <React.Fragment>
                <div className={cn("grid-container")}>
                  <Grid rows={data.grid.rows} columns={config.columns}>
                    {/*data formatters*/}
                    <DataTypeProvider
                      for={["visibility"]}
                      formatterComponent={VisibilityFormatter}
                    />
                    {/*paging*/}
                    <PagingState
                      currentPage={ui.currentPage}
                      pageSize={ui.currentPageSize}
                      onCurrentPageChange={onCurrentPageChange}
                      onPageSizeChange={onPageSizeChange}
                    />
                    <CustomPaging totalCount={data.grid.totalCount} />
                    {/*table ui components*/}
                    <Table
                      messages={gridUtils.tableMessages}
                      /*columnExtensions={config.columnExtensions}*/
                      cellComponent={TableCell}
                    />
                    <TableHeaderRow cellComponent={HeaderCell} />
                    <PagingPanel
                      messages={gridUtils.pagingPanelMessages}
                      pageSizes={config.pageSizes}
                    />
                  </Grid>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

export default AdminFaqCategories;
