import * as config from './../config';

export const data = {};

export const ui = {
  currentPage: 0,
  expandedRowIds: [],
  sorting: config.defaultSorting,
  filters: config.defaultFilters,
  customFilters: [],
  currentPageSize: config.defaultPageSize
};