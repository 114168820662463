import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import About from "./../common/components/blocks/About";
import Faq from "./../common/components/blocks/Faq";
import StatusMessages from "./../common/components/blocks/StatusMessages";
import * as sessionSelectors from "./../common/redux/session/selectors";

import "./style.css";
import LandingTop from "./Top";
import { useScrollToTop, useTitle } from "../common/hooks";

function Landing() {
  const session = useSelector(sessionSelectors.getSession);

  useTitle();

  useScrollToTop();

  return session.isAuthorized ? (
    <Redirect to="/home" />
  ) : (
    <div className="Landing d-flex flex-column flex-grow-1">
      <LandingTop />
      <StatusMessages
        className="mb-6"
        show={window.config.showStatusMessages}
      />
      <About className="mt-5 mb-5" onLandingPage={true} />
      <Faq className="mb-6" show={window.config.showFaq} />
    </div>
  );
}

export default Landing;
