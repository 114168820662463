import React from "react";

import "./style.css";
import * as processesService from "./../../../services/processes";
import * as cn from "../../../helpers/classname";

export const spinnerSizes = {
  BIG: "big",
  MEDIUM: "medium",
};

const Spinner = ({ size, className, processes, height100 = true }) => {
  if (processes && !processesService.hasInProcess(processes)) return null;

  return (
    <div
      className={cn.join(
        "Spinner",
        className,
        size || "big",
        height100 && "h-100"
      )}
    >
      <div className="img"></div>
    </div>
  );
};

export default Spinner;
