import { fork, take, put, call, select, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";

import request from "./../../common/services/request";
import * as immutable from "./../../common/helpers/immutable";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";
import * as config from "./../config";

const FIRST_PAGE = "FIRST_PAGE";
const LOAD_MORE = "LOAD_MORE";

export const processNames = {
  getFirstPage: "getFirstPage",
  loadMore: "loadMore",
};

function* _prepareParamsForGrid(options) {
  let params = {};

  // page size

  if (options.type === FIRST_PAGE) {
    params.pageSize = config.getVehiclesBy * 2;
  } else {
    params.pageSize = config.getVehiclesBy;
  }

  // current page

  let ui = yield select(selectors.getUi);

  if (options.type === LOAD_MORE) {
    params.currentPage = ++ui.currentPage;
  } else if (options.type === FIRST_PAGE) {
    params.currentPage = 0;
  }

  // prepare filters

  let filters = yield select(selectors.getFilters);

  // * vehicle types

  if (filters.vehicleTypes && filters.vehicleTypes.length) {
    params.vehicleTypes = filters.vehicleTypes;
  }

  // * makes

  if (filters.makes && filters.makes.length) {
    params.makes = filters.makes;
  }

  // * counties

  if (filters.counties && filters.counties.length) {
    params.counties = filters.counties;
  }

  // * wreck actions

  if (filters.wreckActions && filters.wreckActions.length) {
    params.wreckActions = filters.wreckActions;
  }

  // * years

  if (filters.yearFrom) params.yearFrom = filters.yearFrom;
  if (filters.yearTo) params.yearTo = filters.yearTo;

  // * search term

  if (filters.filterValue) params.filterValue = filters.filterValue;

  // * sorting

  params.sorting = [
    {
      columnName: filters.sortingField,
      direction: filters.sortingField === "bidDeadline" ? "" : "desc",
    },
  ];

  // * favorites

  params.favorites = filters.favorites;

  // * bids

  params.bids = filters.bids;

  return params;
}

export function* loadMore() {
  let ui = yield select(selectors.getUi);
  let data = yield select(selectors.getData);

  // block handle scroll fluid

  yield put(actions.updateUi({ blockHandleScrollFluid: true }));

  // load reserve data if possible

  if (ui.currentPage > ui.showUntil) {
    yield put(
      actions.updateUi({
        showUntil: ui.showUntil + 1,
      })
    );
  }

  // load more data if possible

  if (data.vehicles.rows.length >= data.vehicles.totalCount) {
    return;
  }

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: LOAD_MORE });

  // do request

  let result = yield call(request.post, {
    path: "Estimate/grid",
    processName: processNames.loadMore,
    params,
  });

  if (result instanceof Error) return;

  // save current page

  yield put(actions.updateUi({ currentPage: params.currentPage }));

  // save results

  result = immutable.updateObjectProps(result, {
    rows: data.vehicles.rows.concat(result.rows),
  });

  yield put(
    actions.updateData({
      vehicles: result,
    })
  );

  // unblock handle scroll fluid if reserve data left or more data can be fetched

  ui = yield select(selectors.getUi);

  if (
    params.currentPage > ui.showUntil ||
    result.rows.length < result.totalCount
  ) {
    yield put(actions.updateUi({ blockHandleScrollFluid: false }));
  }
}

export function* getFirstPage(action) {
  // block handle scroll fluid

  yield put(actions.updateUi({ blockHandleScrollFluid: true }));

  // wait for some time, don`t do request immediately
  // may be user still interacts with interface

  if (!action.isInitial) yield delay(1000);

  let params = yield call(_prepareParamsForGrid, { type: FIRST_PAGE });

  let result = yield call(request.post, {
    path: "Estimate/grid",
    processName: processNames.getFirstPage,
    params,
  });

  if (result instanceof Error) return;

  // save `currentPage` & `showUntil`

  yield put(
    actions.updateUi({
      currentPage: 1,
      showUntil: 0,
    })
  );

  // save results

  yield put(actions.updateData({ vehicles: result }));

  // unblock handle scroll fluid

  if (result.totalCount > config.getVehiclesBy) {
    yield put(actions.updateUi({ blockHandleScrollFluid: false }));
  }
}

export function* triggerFetchVehicles() {
  let lastTask;

  while (true) {
    const action = yield take([
      actions.GET_FIRST_PAGE,
      actions.LOAD_MORE,
      actions.UPDATE_FILTERS,
    ]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    if (action.type === actions.LOAD_MORE) {
      lastTask = yield fork(loadMore);
    } else {
      lastTask = yield fork(getFirstPage, action);
    }
  }
}

export default createRootSaga(triggerFetchVehicles);
