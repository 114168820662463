import React from "react";
import CheckCircleIcon from "../../icons/CheckCircle";
import WarningIcon from "../../icons/Warning";

import "./style.css";
import classname from "./../../../helpers/classname";

const componentName = "InfoCard";
const cn = classname(componentName);

const types = {
  SUCCESS: "success",
  WARNING: "warning",
};

const mapTypeToIcon = {
  [types.SUCCESS]: CheckCircleIcon,
  [types.WARNING]: WarningIcon,
};

const InfoCard = ({ className, type = "success", title, message }) => {
  const Icon = mapTypeToIcon[type];

  return (
    <div className={`p-3 ${componentName} ${cn(type)} ${className}`}>
      <div className="d-flex">
        <div className="d-flex justify-content-start mr-3 InfoCardIconWrapper">
          <Icon />
        </div>
        <div>{title && message ? title : message}</div>
      </div>
      {
        title && message ? <div className="mt-3">{message}</div> : null
      }      
    </div>
  );
};

InfoCard.types = types;

export default InfoCard;
