import { take, put, call, select } from "redux-saga/effects";

import request from "../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchDisableMFA() {
  while (true) {
    yield take(actions.DISABLE_MFA);

    yield call(request.post, {
      path: "Profile/mfa/disable",
      processName: actions.DISABLE_MFA,
    });
  }
}

export function* watchResetRecoveryCodes() {
  while (true) {
    yield take(actions.RESET_RECOVERY_CODES);

    const result = yield call(request.get, {
      path: "Profile/mfa/generateCodes",
      processName: actions.RESET_RECOVERY_CODES,
    });

    if (result instanceof Error) {
      continue;
    }

    console.log("RECOVERY CODES >");
    console.log(result);

    yield put(
      actions.updateData({
        recoveryCodes: result,
      })
    );
  }
}

export function* watchResetAuthenticator() {
  while (true) {
    yield take(actions.RESET_AUTHENTICATOR);

    yield call(request.post, {
      path: "Profile/mfa/resetAuthenticator",
      processName: actions.RESET_AUTHENTICATOR,
    });
  }
}

export function* watchInitAuthenticator() {
  while (true) {
    yield take(actions.INIT_AUTHENTICATOR);

    let result = yield call(request.get, {
      path: "Profile/mfa/initAuthenticator",
      processName: actions.INIT_AUTHENTICATOR,
    });

    if (result instanceof Error) {
      continue;
    }

    yield put(
      actions.updateData({
        ...result,
      })
    );
  }
}

export function* watchVerifyAuthenticatorCode() {
  while (true) {
    yield take(actions.VERIFY_AUTHENTICATOR_CODE);

    const form = yield select(selectors.getVerificationCodeForm);

    let result = yield call(request.post, {
      path: "Profile/mfa/enableAuthenticator",
      processName: actions.VERIFY_AUTHENTICATOR_CODE,
      params: {
        code: form.code.value,
      },
    });

    if (result instanceof Error) {
      continue;
    }

    yield put(
      actions.updateData({
        recoveryCodes: result,
      })
    );
  }
}

export default createRootSaga(
  watchInitAuthenticator,
  watchVerifyAuthenticatorCode,
  watchDisableMFA,
  watchResetAuthenticator,
  watchResetRecoveryCodes
);
