const _prefix = "LOGIN::";

export const UPDATE_DATA = _prefix + "UPDATE_DATA";
export const UPDATE_UI = _prefix + "UPDATE_UI";

export const LOGIN = _prefix + "LOGIN";
export const SET_STEP = _prefix + "SET_STEP";

export const updateData = (updateOb) => ({ type: UPDATE_DATA, updateOb });
export const updateUi = (updateOb) => ({ type: UPDATE_UI, updateOb });

export const login = () => ({ type: LOGIN });
export const setStep = (nextStep) => ({ type: SET_STEP, nextStep });
