import React from "react";
import { Container } from "reactstrap";
import classname from "../../../../helpers/classname";

const componentName = "StatusMessagesBlock";
const cn = classname(componentName);

const Wrapper = ({ count, className, children }) => {
  className = cn.join(componentName, className);

  if (count <= 0) {
    return null;
  }

  return (
    <Container id={componentName} className={className}>
      <h2 className="text-color1">
        <b>{count > 1 ? "Statusmeldinger" : "Statusmelding"}</b>
      </h2>
      {children}
    </Container>
  );
};

export default Wrapper;
