import { fork, take, put, call, all, select, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";
import request from "./../../../common/services/request";
import { createRootSaga } from "../../../common/services/redux-saga";

function* _prepareParamsForGrid() {
  let params = {};

  let ui = yield select(selectors.getUi);

  // page size

  params.pageSize = ui.currentPageSize;

  // current page

  params.currentPage = ui.currentPage;

  // sorting

  if (ui.sorting && ui.sorting.length) params.sorting = ui.sorting;

  // filters

  params.filters = ui.filters || [];
  if (ui.customFilters && ui.customFilters.length) {
    params.filters = params.filters.concat(ui.customFilters);
  }

  return params;
}

export function* fetchWorkshops(action) {
  if (action.type === actions.UPDATE_UI && action.updateOb.filters) {
    yield delay(1000);
  }

  let params = yield call(_prepareParamsForGrid);

  let result = yield call(request.post, {
    path: "Workshop/grid",
    processName: "fetchWorkshops",
    params,
  });

  if (result instanceof Error) return;

  // save results

  yield put(actions.updateData({ workshops: result }));
}

export function* triggerFetchWorkshops() {
  let lastTask;
  while (true) {
    const action = yield take([actions.UPDATE_UI, actions.FETCH_WORKSHOPS]);

    if (action.type === actions.UPDATE_UI && !action.triggerFetchWorkshops) {
      continue;
    }

    if (lastTask) {
      yield cancel(lastTask);
    }

    lastTask = yield fork(fetchWorkshops, action);
  }
}

export default createRootSaga(triggerFetchWorkshops);
