import { call, select, put } from "redux-saga/effects";

import * as scroll from "../../helpers/scroll";

import { validateValue } from "./validateValue";

export function* submitForm({
  event,
  props,
  formSelector,
  updateFormActionCreator,
  onValid,
  onInvalid,
  disableScrolling,
}) {
  event.preventDefault();

  let form = yield select(formSelector);

  let isFormValid = true;
  let formUpdateOb = { isFormValid };
  let firstElemWithError;

  for (const prop of props) {
    const rules = form[prop].rules;

    if (rules?.length) {
      const result = yield call(validateValue, {
        prop: prop,
        value: form[prop].value,
        rules: rules,
        form: form,
      });

      formUpdateOb = {
        ...formUpdateOb,
        [prop]: {
          hasError: !result.isOk,
          errorMessage: result.errorMessage,
          ...(result.payload || {}),
        },
      };

      if (!result.isOk) {
        isFormValid = false;
        formUpdateOb.isFormValid = false;

        if (!firstElemWithError) {
          firstElemWithError = prop;
        }
      }
    }
  }

  yield put(updateFormActionCreator(formUpdateOb));

  if (isFormValid) {
    if (onValid) {
      yield call(onValid);
    }
  } else {
    if (!disableScrolling) scroll.to(firstElemWithError);
    if (onInvalid) yield call(onInvalid);
  }
}
