import React from 'react';

const name = 'search-icon';

const Search = () => {
  return (
    <svg className={`${name}-container`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g className={name} transform="translate(-1249.000000, -100.000000)">
        <g transform="translate(1097.000000, 100.000000)">
          <g transform="translate(152.000000, 0.000000)">
            <path d="M19.7,18.2l-4.9-5c1.3-1.5,2-3.3,2-5.2C16.7,3.6,13,0,8.4,0S0,3.6,0,8.1s3.8,8.1,8.4,8.1
              c1.7,0,3.4-0.5,4.8-1.5l5,5c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3C20.1,19.3,20.1,18.6,19.7,18.2z M8.4,2.1
              c3.4,0,6.2,2.7,6.2,6s-2.8,6-6.2,6s-6.2-2.7-6.2-6S5,2.1,8.4,2.1z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Search;