import React from "react";

const name = "warning-icon";

const CheckCircle = ({ className }) => {
  return (
    <svg
      className={[`${name}-container`, `${className}-container`].join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      // className="icon icon-tabler icon-tabler-alert-circle"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
        className={[name, className].join(" ")}
      ></path>
      <circle cx="12" cy="12" r="9"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12.01" y2="16"></line>
    </svg>
  );
};

// <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
//     </svg>

export default CheckCircle;
