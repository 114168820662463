import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Spinner from "./../common/components/elements/Spinner";
import * as processesSelectors from "./../common/redux/processes/selectors";
import * as sessionSelectors from "./../common/redux/session/selectors";
import * as scroll from "./../common/helpers/scroll";
import * as userService from "./../common/services/user";
import BidPasswordModal from "./../common/components/blocks/BidPasswordModal";
import * as sessionActions from "../common/redux/session/actions";
import * as sessionService from "../common/services/session";
import { useScrollToTop, useTitle } from "../common/hooks";

import "./style.css";
import TradeConditionCard from "./TradeConditionCard";
import TextModal from "./TextModal";
import * as config from "./config";
import * as selectors from "./redux/selectors";
import * as actions from "./redux/actions";

function TradeConditions({ match }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const session = useSelector(sessionSelectors.getSession);
  const processes = useSelector(processesSelectors.getProcesses);

  useScrollToTop();

  useTitle("Salgsbetingelser");

  useEffect(
    function fetchData() {
      const type = match.params.type;

      dispatch(actions.updateUi({ section: type }));

      if (!ui.firstRequestAccomplished[type]) {
        dispatch(actions.getFirstPage());
      }
    },
    [match.params.type]
  );

  useEffect(
    function setHandleScroll() {
      const handleScroll = () => {
        if (ui.blockHandleScrollFluid[ui.section]) return;

        if (scroll.timeToLoadMore()) dispatch(actions.loadMore());
      };

      document.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    },
    [ui.section, ui.blockHandleScrollFluid[ui.section]]
  );

  // if wrong section provided redirect user to another page

  if (!["new", "all"].includes(ui.section)) {
    return <Redirect to="/" />;
  }

  // prepare status vars

  let getFirstPageInProcess =
    processes[`getFirstPage:${ui.section}tc`]?.inProcess;
  let loadMoreInProcess = processes[`loadMore:${ui.section}tc`]?.inProcess;
  let getFirstPageHasError =
    processes[`getFirstPage:${ui.section}tc`]?.hasError;
  let loadMoreHasError = processes[`loadMore:${ui.section}tc`]?.hasError;
  let errorMessage =
    processes[`getFirstPage:${ui.section}tc`]?.errorMessage ||
    processes[`loadMore:${ui.section}tc`]?.errorMessage;

  let nothingFound =
    !getFirstPageInProcess &&
    !getFirstPageHasError &&
    (!data.tc[ui.section] || !data.tc[ui.section].rows.length);

  // count how many rows to show

  let show = config.loadBy * (ui.showUntil[ui.section] + 1);

  const isWorkshopUser = userService.isWorkshopUser(session);
  let i = 0;

  return (
    <React.Fragment>
      {ui.showBidPasswordModal ? (
        <BidPasswordModal
          bodyText="Skriv inn budpassord for å kunne endre akseptanse på salgsbetingelse"
          onValid={({ token, expires, expireMinutes }) => {
            // save bid token
            dispatch(
              sessionActions.updateSession(
                sessionService.prepareBidTokenUpdateOb(
                  token,
                  expires,
                  expireMinutes
                )
              )
            );
            // dispatch next action object
            if (ui.nextActionObjectOnValidBidToken)
              dispatch(ui.nextActionObjectOnValidBidToken);
            // remove next action object
            dispatch(
              actions.updateUi({
                nextActionObjectOnValidBidToken: null,
                showBidPasswordModal: false,
              })
            );
          }}
          onCancel={() =>
            dispatch(
              actions.updateUi({
                showBidPasswordModal: false,
                nextActionObjectOnValidBidToken: null,
              })
            )
          }
        />
      ) : null}
      <TextModal />
      <Container className="TradeConditions my-4">
        <Row>
          <Col md="10" sm="12" className="mx-auto">
            {isWorkshopUser ? (
              <Row>
                <Col xs="12" className="nav-links">
                  <LinkContainer to="/tc/all">
                    <div className="nav-link">Alle salgsbetingelser</div>
                  </LinkContainer>
                  <LinkContainer to="/tc/new">
                    <div className="nav-link ml-3">
                      Ubesvarte salgsbetingelser
                    </div>
                  </LinkContainer>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center mt-5 mb-4">
                <Col lg="10 text-center">
                  <h1 className="text-color1">
                    <b>Salgsbetingelser</b>
                  </h1>
                </Col>
              </Row>
            )}

            <Row className="mt-4">
              <Col xs="12">
                {ui.section === "all" ? (
                  <div>
                    <p>
                      I listen nedenfor er det for hvert forsikringsselskap
                      angitt en link som viser gjeldende salgsbetingelser.
                    </p>
                    {isWorkshopUser ? (
                      <p>
                        Har du valgt IKKE å akseptere selskapets
                        salgsbetingelser, vil du heller IKKE få tilgang til å se
                        og gi bud på objektene fra dette selskapet. For å endre
                        allerede besvart akseptanse kreves det at man oppgir
                        budpassord.
                      </p>
                    ) : null}
                  </div>
                ) : null}
                {ui.section === "new" ? (
                  <div>
                    <p>
                      I listen nedenfor vises kun ubesvarte (nye eller
                      oppdaterte) salgsbetingelser.
                    </p>
                    {isWorkshopUser ? (
                      <p>
                        Har du valgt IKKE å akseptere selskapets
                        salgsbetingelser, vil du heller IKKE få tilgang til å se
                        og gi bud på objektene fra dette selskapet.
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </Col>
            </Row>

            <Row className="mt-4">
              {getFirstPageInProcess ? (
                <Col xs="12">
                  <Spinner />
                </Col>
              ) : null}
              {getFirstPageHasError ? (
                <Col xs="12" className="text-center">
                  <b className="text-danger">Det oppstod en feil</b>
                  <br />
                  <b>{errorMessage}</b>
                </Col>
              ) : null}
              {nothingFound ? (
                <Col xs="12" className="text-center">
                  <h4 className="text-color1 text-uppercase">
                    {ui.section === "new" ? (
                      <b>Ingen ubesvarte salgsbetingelser</b>
                    ) : (
                      <b>Ingen tilgjengelige salgsbetingelser</b>
                    )}
                  </h4>
                </Col>
              ) : null}
              {!getFirstPageInProcess && !getFirstPageHasError && !nothingFound
                ? data.tc[ui.section].rows.slice(0, show).map((tc) => {
                    return (
                      <Col xs="12" key={i++}>
                        <TradeConditionCard
                          tc={tc}
                          isWorkshopUser={isWorkshopUser}
                        />
                      </Col>
                    );
                  })
                : null}
              <Col xs="12" className="text-center mt-4">
                {loadMoreInProcess ? <Spinner size="medium" /> : null}
                {loadMoreHasError ? (
                  <div className="text-center">
                    <b className="text-danger">Det oppstod en feil</b>
                    <br />
                    <b>{errorMessage}</b>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default TradeConditions;
