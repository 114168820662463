import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

const initUiState = {
  section: 'new',
  blockHandleScrollFluid: {
    all: false,
    new: false
  },
  firstRequestAccomplished: {
    all: false,
    new: false
  },
  currentPage: {
    all: 0,
    new: 0
  },
  showUntil: {
    all: 0,
    new: 0
  },
  showBidPasswordModal: false,
  nextActionObjectOnValidBidToken: null,
  showTextModal: false,
  // company number & isAccepted that will be used in different actions
  companyNumber: null,
  isAccepted: null
};

const ui = (state = initUiState, action) => {
  switch (action.type) {
    case actions.UPDATE_UI:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  }
};

const initDataState = {
  tc: {
    all: null,
    new: null
  }
};

const data = (state = initDataState, action) => {
  switch (action.type) {
    case actions.UPDATE_DATA:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  }
};

export default combineReducers({
  ui,
  data
});

