// Vehicle Types
// id:1 (car.svg) => Personbil/stasjonsvogn || car || легковой автомобиль
// id:2 => Varebil || minibus || минибас
// id:3 (truck.svg) => Lastebil || truck || грузовик
// id:4 => Buss || bus || автобус
// id:5 => Arbeidsmaskin/traktor || working machine/traktor || механизм/трактор
// id:6 (camping_car) => Campingvogn/tilhenger || camping car/trailer || дом на колесах/прицеп
// id:7 (scooter.svg) => Mc/moped/snøscooter || scooter/snowmobile || скутер/снегогод
// id:8 (yacht.svg) => Båt || boat || лодка
// id:9 => Bobil || motorhome || автодом
// id:10 => Annet || other || другое

import carIcon from './imgs/car.svg';
import campingCarIcon from './imgs/camping_car.svg';
import scooterIcon from './imgs/scooter.svg';
import truckIcon from './imgs/truck.svg';
import yachtIcon from './imgs/yacht.svg';

// consts

const mapVehicleTypeIdToIcon = {
  1: carIcon,
  3: truckIcon,
  6: campingCarIcon,
  7: scooterIcon,
  8: yachtIcon
};

const oneSecond = 1000;
const oneMin = 60*oneSecond;
const oneHour =  60*oneMin;
const oneDay = 24*oneHour;
const oneMonth = 30*oneDay;

// private methods

const _reverseString = (str) => str.split('').reverse().join('');

const _getTime = (diff, majorUnit, minorUnit) => {
  let major = Math.floor(diff/majorUnit);
  let minor = Math.floor((diff%majorUnit)/(minorUnit));
  return [major, minor];
};

// public methods

export const getIcon = (vehicleTypeId) => mapVehicleTypeIdToIcon[vehicleTypeId];

export const acceptsBids = (vehicle) => vehicle.status == 1;

export const prettyNumber = (number) => {
  let str = String(number);
  str = _reverseString(str);
  str = str.replace(/(\d{3})/g, '$1 ');
  str = _reverseString(str);
  return str;
};

export const getDate = (date) => {
  date = new Date(date);

  let day = date.getDate();
  day = day < 10 ? '0' + day : day;
  let month = date.getMonth() + 1;
  month = month < 10 ? '0' + month : month;
  let year = date.getFullYear();

  return `${day}.${month}.${year}`;
};

export const getYesNo = (check) => {
  return check ? 'J' : 'N';
};


export const getFullExpirationMessage = (diff) => {
  if(diff < 0) return `Utløpt`;

  let expirationMessage = ``;

  let [months, days] = _getTime(diff, oneMonth, oneDay);

  if(months >= 1){
    expirationMessage += `${months} mnd.`;
    diff = diff - (months * oneMonth);
  };

  if(days >= 1 || months >= 1){
    expirationMessage += ` ${days} d`;
    diff = diff - (days * oneDay);
  };

  // last part hh:mm:ss

  let [hours, mins] = _getTime(diff, oneHour, oneMin);

  diff = diff - (hours * oneHour) - (mins * oneMin);

  let seconds = Math.floor(diff/oneSecond);

  hours = hours < 10 ? '0' + hours : hours;
  mins = mins < 10 ? '0' + mins : mins;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  expirationMessage += ` ${hours}:${mins}:${seconds} s`;

  return expirationMessage;
};

export const getExpirationMessage = (diff) => {
  let expirationMessage;
  // if more than 1 month left (this is approximately, because one month is taken as 30 days)
  if(diff > oneMonth){
    let [months, days] = _getTime(diff, oneMonth, oneDay);
    expirationMessage = `${months} mnd. ${days} d`;
    // if more than 1 day left
  } else if(diff >= oneDay){
    let [days, hours] = _getTime(diff, oneDay, oneHour);
    expirationMessage = `${days} d ${hours} t`; // t = hour
    // if more than 1 hour left
  } else if(diff >= oneHour) {
    let [hours, mins] = _getTime(diff, oneHour, oneMin);
    expirationMessage = `${hours} t ${mins} m`;
    // if less that 1 hour left
  } else if(diff < oneHour && diff > 0){
    let [mins, seconds] = _getTime(diff, oneMin, oneSecond);
    expirationMessage = `${mins} m ${seconds} s`;
  } else {
    expirationMessage = `Utløpt`;
  }
  return expirationMessage;
};

export const getExpirationColorClass = (diff) => {
  let expirationColorClass;
  if(diff > oneDay){
    expirationColorClass = 'text-color13';
  } else if(diff > oneHour){
    expirationColorClass = 'text-color9';
  } else {
    expirationColorClass = 'text-color8';
  }
  return expirationColorClass;
};