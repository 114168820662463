import { take, call, all, select } from "redux-saga/effects";

import * as sessionSelectors from "../../../common/redux/session/selectors";
import * as userService from "../../../common/services/user";
import request from "../../../common/services/request";
import { createRootSaga } from "../../../common/services/redux-saga";

import * as config from "../config";
import * as actions from "./actions";
import * as selectors from "./selectors";

function* watchSend() {
  while (true) {
    yield take(actions.SEND);

    let session = yield select(sessionSelectors.getSession);
    let form = yield select(selectors.getForm);

    let params = {
      workshopNumber: form.workshopNumber.value,
      workshopName: form.workshopName.value,
      contactPersonName: form.contactPersonName.value,
      contactPersonEmail: form.contactPersonEmail.value,
      senderFullName: form.senderFullName.value,
      senderEmail: form.senderEmail.value,
    };

    if (session.isAuthorized) {
      params.senderFullName = yield call(userService.getFullName, session);
      params.senderEmail = yield call(userService.getEmail, session);
    }

    yield call(request.post, {
      path: "form/changecontactinfo",
      processName: config.processNames.SEND,
      params,
    });
  }
}

export default createRootSaga(watchSend);
