const _prefix = 'ADMIN_BIDS_GRID::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH_BIDS = _prefix + 'FETCH_BIDS';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = ({updateOb, triggerFetchBids, getBy, getById}) => ({
  type: UPDATE_UI,
  updateOb,
  triggerFetchBids,
  getBy,
  getById
});

export const fetchBids = ({estimateId, getBy, getById}) => ({type: FETCH_BIDS, getBy, getById});