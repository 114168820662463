const _prefix = 'MYBIDS::';

export const UPDATE_UI = _prefix + 'UPDATE_UI';
export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_FILTERS = _prefix + 'UPDATE_FILTERS';
export const UPDATE_BID_FORM = _prefix + 'UPDATE_BID_FORM';

export const GET_FIRST_PAGE = _prefix + 'GET_FIRST_PAGE';
export const LOAD_MORE = _prefix + 'LOAD_MORE';
export const CANCEL_BID = _prefix + 'CANCEL_BID';
export const DELETE_BID = _prefix + 'DELETE_BID';
export const UPDATE_BID = _prefix + 'UPDATE_BID';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateFilters = (updateOb) => ({type: UPDATE_FILTERS, updateOb});
export const updateBidForm = (updateOb) => ({type: UPDATE_BID_FORM, updateOb});

export const getFirstPage = () => ({type: GET_FIRST_PAGE});
export const loadMore = () => ({type: LOAD_MORE});
export const cancelBid = (bidId) => ({type: CANCEL_BID, bidId});
export const deleteBid = (bidId) => ({type: DELETE_BID, bidId});
export const updateBid = () => ({type: UPDATE_BID});