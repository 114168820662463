import jwtDecoder from "jwt-decode";

import * as sessionModels from "./../redux/session/models";
import * as immutable from "./../helpers/immutable";

export const hasBidToken = (session) => {
  return session.bidToken && session.bidTokenExpirationTime;
};

export const getBidTokenLeftLifeTime = (session) => {
  return isBidTokenExpired(session)
    ? 0
    : session.bidTokenExpirationTime - new Date().getTime();
};

export const isBidTokenExpired = (session) => {
  if (
    !hasBidToken(session) ||
    session.bidTokenExpirationTime < new Date().getTime()
  )
    return true;
};

export const sessionExpirationSafetyMarginInSeconds = 10;
export const SESSION_HAS_EXPIRED_MESSAGE = 
  "Du har blitt logget ut, vennligst logg inn på nytt.";
  
export const prepareBidTokenUpdateOb = (
  bidToken,
  expirationTime,
  expireMinutes
) => {
  // Use expireMinutes instead of expirationTime because of possible time-drift
  // Subtract x seconds to be on the safe side

  const updateOb = {
    bidToken: bidToken || "",
    bidTokenExpirationTime: bidToken
      ? expireMinutes
        ? new Date(
            new Date().getTime() + expireMinutes * 60000 - sessionExpirationSafetyMarginInSeconds * 1000
          ).getTime()
        : new Date(expirationTime).getTime()
      : 0,
  };

  return updateOb;
};

export const prepareSession = (openidResponse) => {
  // try to decode jwt

  let decodedIdToken;

  try {
    decodedIdToken = jwtDecoder(openidResponse.id_token);
  } catch (e) {
    return e;
  }

  let expirationTime = new Date().getTime() + openidResponse.expires_in * 1000;

  // save results

  let session = immutable.updateObjectProps(sessionModels.session, {
    isAuthorized: true,
    ...openidResponse,
    expirationTime,
    decodedIdToken,
  });

  return session;
};
