import React from "react";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import * as config from "./config";

import * as sessionSelectors from "./../common/redux/session/selectors";
import * as userService from "./../common/services/user";
import { routes } from "../common/config/routes";

function Forms() {
  const session = useSelector(sessionSelectors.getSession);

  return (
    <div>
      <Container>
        <Row className="mt-5 text-center">
          <Col xs="12">
            <h1 className="text-color1">{routes.FORMS.title}</h1>
          </Col>
          <Col xs="12" className="mt-3 mb-5">
            {config.menuItems.map((item) => {
              let hasAccess = true;

              if (item.role)
                hasAccess = userService.mapRolesToMethods[item.role](session);

              if (!hasAccess) return null;

              return (
                <div key={item.path}>
                  <NavLink to={item.path} className="text-color1">
                    {item.title}
                  </NavLink>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Forms;
