import React from 'react';

const name = 'arrow-down-icon';

const ArrowDown = () => {
  return (
    <svg className={`${name}-container`} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 8"
    >
      <g className={name} transform="translate(-535.000000, -109.000000)">
        <g transform="translate(455.000000, 101.000000)">
          <g transform="translate(86.500000, 12.000000) rotate(-90.000000) translate(-86.500000, -12.000000) translate(83.000000, 6.000000)">
            <path d="M0.3,5.4l5.3-5.2c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L2.1,6l4.7,4.6c0.3,0.3,0.3,0.9,0,1.2
              c-0.3,0.3-0.9,0.3-1.2,0L0.3,6.6C0.1,6.4,0,6.2,0,6C0,5.8,0.1,5.6,0.3,5.4z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;