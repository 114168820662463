import { take, call, all, select } from "redux-saga/effects";

import * as sessionSelectors from "./../../../../redux/session/selectors";
import * as userService from "./../../../../services/user";
import request from "./../../../../services/request";
import { createRootSaga } from "../../../../services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

function* watchSendMessage() {
  while (true) {
    yield take(actions.SEND_MESSAGE);

    let session = yield select(sessionSelectors.getSession);
    let form = yield select(selectors.getFormValues);

    if (session.isAuthorized) {
      form.senderFullName = yield call(userService.getFullName, session);
      form.senderEmail = yield call(userService.getEmail, session);
    }

    yield call(request.post, {
      path: "form/contactus",
      params: form,
      processName: "sendMessage",
    });
  }
}

export default createRootSaga(watchSendMessage);
