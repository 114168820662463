import { take, call, all, select } from "redux-saga/effects";

import { createRootSaga } from "../../services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchSetSession() {
  while (true) {
    let action = yield take(actions.SET_SESSION);

    localStorage.setItem("session", JSON.stringify(action.session));
  }
}

export function* watchUnsetSession() {
  while (true) {
    yield take(actions.UNSET_SESSION);

    localStorage.removeItem("session");
  }
}

export function* watchUpdateSession() {
  while (true) {
    yield take(actions.UPDATE_SESSION);

    // select updated state of session

    let session = yield select(selectors.getSession);

    localStorage.setItem("session", JSON.stringify(session));
  }
}

export default createRootSaga(
  watchSetSession,
  watchUnsetSession,
  watchUpdateSession
);
