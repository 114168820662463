import React from "react";
import "./style.css";

import * as cn from "../../../helpers/classname";

function CustomRadio({ label, className, onChange, name, checked }) {
  return (
    <div className={cn.join("CustomRadio", className)}>
      <label>
        <input
          type="radio"
          checked={!!checked}
          name={name}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
        <div className="custom-radio"></div>
        {label}
      </label>
    </div>
  );
}

export default CustomRadio;
