export const prefix = 'ADMIN_FAQ_CATEGORIES::';
export const domain = 'AdminFaqCategories';
export const componentName = 'AdminFaqCategories';

// grid config

export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'firstColumn'},
  //{name: 'id', title: 'Id'}, // Is system-generated so should not be visible in grid
  {name: 'name', title: 'Navn'},
  {name: 'description', title: 'Beskrivelse'},
  {name: 'visibility', title: 'Synlighet'}/*,
  {name: 'icon', title: 'Symbol (icon)'},*/ // Commented out temporarily, please uncomment if FontAwesome icon support is added in editor
];
export const columnExtensions = [];
export const filteringStateColumnExtensions = [];