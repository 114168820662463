const _prefix = "FORM_VALIDATOR::";

export const VALIDATE_AND_UPDATE_PROP = _prefix + "VALIDATE_AND_UPDATE_PROP";
export const SUBMIT_FORM = _prefix + "SUBMIT_FORM";

export const validateAndUpdateProp = ({
  prop,
  event,
  value,
  formSelector,
  updateFormActionCreator,
  onValid,
}) => ({
  type: VALIDATE_AND_UPDATE_PROP,
  prop,
  event,
  value,
  formSelector,
  updateFormActionCreator,
  onValid,
});

export const submitForm = ({
  props,
  event,
  formSelector,
  updateFormActionCreator,
  onValid,
  onInvalid,
  disableScrolling,
}) => ({
  type: SUBMIT_FORM,
  props,
  event,
  formSelector,
  updateFormActionCreator,
  onValid,
  onInvalid,
  disableScrolling,
});
