import React from "react";
import { Container, Row, Col } from "reactstrap";

import "./style.css";

function SectionHeader({ header, text, bg, className }) {
  return (
    <div
      className={`SectionHeader ${className || ""}`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="color-layer"></div>
      <Container className="content">
        <Row className="justify-content-center text-center">
          <Col>
            <h1>
              <b>{header}</b>
            </h1>
          </Col>
          <div className="w-100"></div>
          <Col lg="9">
            <p className="lead">{text}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SectionHeader;
