export const ui = {
  step: 1, // 1 = login password form, 2 = authenticator code form, 3 = recovery code form
  loginPasswordForm: {
    username: {
      value: "",
      rules: ["required"],
    },
    password: {
      value: "",
      rules: ["required"],
    },
    rememberMe: {
      value: true,
    },
  },
  authenticatorCodeForm: {
    mfaCode: {
      value: "",
      rules: ["required"],
    },
  },
  recoveryCodeForm: {
    recoveryCode: {
      value: "",
      rules: ["required"],
    },
  },
};

export const data = {};
