import { call, all, takeLatest } from "redux-saga/effects";

import { createRootSaga } from "../redux-saga";

import * as actions from "./actions";
import { validateAndUpdateProp } from "./validateAndUpdateProp";
import { submitForm } from "./submitForm";

export function* watchSubmitForm() {
  yield takeLatest(actions.SUBMIT_FORM, submitForm);
}

export default createRootSaga(validateAndUpdateProp, watchSubmitForm);
