// TODO: create common createEditModal
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup } from "reactstrap";

import classname from "./../../../common/helpers/classname";
import RegularModal from "./../../../common/components/blocks/RegularModal";
import InputText from "./../../../common/components/elements/InputText";
import Button from "./../../../common/components/elements/Button";
import Spinner, {
  spinnerSizes,
} from "./../../../common/components/elements/Spinner";
import ErrorBlock from "./../../../common/components/blocks/ErrorBlock";
import RichTextEditor from "./../../../common/components/elements/RichTextEditor";
import DropdownMenu, {
  DropdownItem,
} from "./../../../common/components/elements/DropdownMenu";
import * as formValidatorActions from "./../../../common/services/form-validator/actions";
import * as processesSelectors from "./../../../common/redux/processes/selectors";
import dictionary from "./../../../common/config/dictionary";

import "./style.css";
import * as config from "./config";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

import { processNames } from "../redux/sagas";

const componentName = "FaqCreateEditorModal";
const cn = classname(componentName);

function CreateEditModal() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const processes = useSelector(processesSelectors.getProcesses);

  const selectForm = (state) => {
    return selectors.getUi(state).createEditModal.form;
  };

  const updateFormActionCreator = (updateOb) => {
    return actions.updateUi({ createEditModal: { form: updateOb } });
  };

  const updateForm = (updateOb) => {
    dispatch(updateFormActionCreator(updateOb));
  };

  const validateAndUpdateProp = ({ event, prop, value }) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        value,
        formSelector: selectForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    const {
      createEditModal: { type },
    } = ui;

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["companyNumber", "companyName", "homePage"],
        formSelector: selectForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () =>
          dispatch(config.mapModalTypeToConfig[type].onValidActionCreator()),
      })
    );
  };

  const {
    createEditModal: { show, type, form },
  } = ui;

  if (!show) {
    return null;
  }

  let process = processes[processNames.CREATE_EDIT_MODAL_PROCESS] || {};

  return (
    <RegularModal
      className={componentName}
      size="lg"
      onCancel={() =>
        dispatch(actions.updateUi({ createEditModal: { show: false } }))
      }
    >
      <Form onSubmit={onSubmit}>
        <h2 className="text-color1 mb-5">
          <b>{config.mapModalTypeToConfig[type].title}</b>
        </h2>
        <FormGroup>
          <InputText
            id={form.companyNumber.id}
            type={form.companyNumber.type}
            label={form.companyNumber.label}
            value={form.companyNumber.value}
            hasError={form.companyNumber.hasError}
            errorMessage={form.companyNumber.errorMessage}
            onChange={(event) =>
              validateAndUpdateProp({ event, prop: "companyNumber" })
            }
          />
        </FormGroup>
        <FormGroup>
          <InputText
            id={form.companyName.id}
            type={form.companyName.type}
            label={form.companyName.label}
            value={form.companyName.value}
            hasError={form.companyName.hasError}
            errorMessage={form.companyName.errorMessage}
            onChange={(event) =>
              validateAndUpdateProp({ event, prop: "companyName" })
            }
          />
        </FormGroup>
        <FormGroup>
          <InputText
            id={form.homePage.id}
            type={form.homePage.type}
            label={form.homePage.label}
            value={form.homePage.value}
            hasError={form.homePage.hasError}
            errorMessage={form.homePage.errorMessage}
            onChange={(event) =>
              validateAndUpdateProp({ event, prop: "homePage" })
            }
          />
        </FormGroup>
        <FormGroup>
          <DropdownMenu
            label={form.accessDBSM.label}
            toggleButtonText={form.accessDBSM.payload}
          >
            <DropdownItem
              onClick={() =>
                updateForm({
                  accessDBSM: { value: true, payload: dictionary.YES },
                })
              }
            >
              {dictionary.YES}
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                updateForm({
                  accessDBSM: { value: false, payload: dictionary.NO },
                })
              }
            >
              {dictionary.NO}
            </DropdownItem>
          </DropdownMenu>
        </FormGroup>
        <FormGroup>
          <RichTextEditor
            id={form.tradeCond.id}
            type={form.tradeCond.type}
            label={form.tradeCond.label}
            initialHtml={form.tradeCond.value}
            hasError={form.tradeCond.hasError}
            errorMessage={form.tradeCond.errorMessage}
            onChange={(html) =>
              validateAndUpdateProp({ value: html, prop: "tradeCond" })
            }
          />
        </FormGroup>
        {process.hasError ? (
          <FormGroup className="mb-5">
            <ErrorBlock error={process} />
          </FormGroup>
        ) : null}
        <FormGroup className="text-center">
          {process.inProcess ? (
            <Spinner size={spinnerSizes.MEDIUM} />
          ) : (
            <Button color="color1" className="w-50">
              {dictionary.SAVE}
            </Button>
          )}
        </FormGroup>
      </Form>
    </RegularModal>
  );
}

export default CreateEditModal;
