import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import Wrapper from "./Wrapper";
import CategoryCard from "./CategoryCard";
import FaqItem from "./FaqItem";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";

import ErrorBlock from "./../ErrorBlock";
import Spinner, { spinnerSizes } from "./../../elements/Spinner";
import * as processesSelectors from "./../../../redux/processes/selectors";
import * as sessionSelectors from "./../../../redux/session/selectors";
import classname from "./../../../helpers/classname";

const componentName = "FaqBlock";
const cn = classname(componentName);

export { previewModes } from "./config";

function FaqBlock({ className, show = true, isPreviewMode, previewMode }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  const setCurrentCategory = (currentCategory) => {
    dispatch(actions.updateUi({ currentCategory }));
  };

  useEffect(
    function fetchFaq() {
      if (!show) {
        return;
      }

      dispatch(actions.fetchFaq({ isPreviewMode, previewMode }));
    },
    [show, isPreviewMode, previewMode]
  );

  useEffect(function resetCurrentCategoryOnUnmount() {
    return () => {
      if (!show) {
        return;
      }

      setCurrentCategory(null);
    };
  }, []);

  if (!show) {
    return null;
  }

  const { currentCategory } = ui;

  const fetchFaqData = processes[processNames.FETCH_FAQ] || {};

  const faqData = isPreviewMode ? data.preview[previewMode] : data.faq;

  if (fetchFaqData.hasError) {
    return (
      <Wrapper
        className={className}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      >
        <Row>
          <Col>
            <ErrorBlock className="mb-3" error={fetchFaqData} />
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (fetchFaqData.inProcess || !faqData) {
    return (
      <Wrapper
        className={className}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      >
        <Row>
          <Col>
            <Spinner size={spinnerSizes.MEDIUM} />
          </Col>
        </Row>
      </Wrapper>
    );
  }

  if (currentCategory) {
    return (
      <Wrapper
        className={className}
        showBackButton={true}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      >
        <Row>
          {currentCategory.description ? (
            <Col xs={12} className="mb-3">
              <p className="lead mb-0">{currentCategory.description}</p>
            </Col>
          ) : null}
          {currentCategory.faqs.map((item) => {
            return (
              <Col key={item.id} xs={12}>
                <FaqItem ob={item} />
              </Col>
            );
          })}
        </Row>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className={className}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
    >
      <Row className={cn("category-cards")} noGutters={true}>
        {faqData.map((ob) => {
          if (!ob.faqs || !ob.faqs.length) return null;

          return (
            <Col
              key={ob.id}
              className={cn("category-card-wrapper")}
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <CategoryCard
                category={ob.name}
                description={ob.description}
                onClick={() => setCurrentCategory(ob)}
              />
            </Col>
          );
        })}
      </Row>
    </Wrapper>
  );
}

export default FaqBlock;
