import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";

import Spinner from "../../common/components/elements/Spinner";

import * as processesSelectors from "../../common/redux/processes/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import Button from "../../common/components/elements/Button";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";
import InfoCard from "../../common/components/blocks/InfoCard";
import { routes } from "../../common/config/routes";

import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import SaveRecoveryCodes from "../SaveRecoveryCodes";

function ResetRecoveryCodesForm() {
  const dispatch = useDispatch();
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function resetProcessOnUnmount() {
    return () => {
      dispatch(processesActions.resetProcess(actions.RESET_RECOVERY_CODES));
    };
  }, []);

  const resetRecoveryCodesProcess =
    processes[actions.RESET_RECOVERY_CODES] || {};

  if (resetRecoveryCodesProcess.isSuccessed && data.recoveryCodes) {
    return <SaveRecoveryCodes codes={data.recoveryCodes} />;
  }

  return (
    <React.Fragment>
      <Container className="ResetRecoveryCodesForm">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Tilbakestill gjenopprettingskoder</b>
            </h1>
            <div className="form1 mt-3">
              <p>
                Her kan du tilbakestille gjenopprettingskodene som kan brukes
                til å logge inn dersom du mister tilgangen til
                autentiseringsappen.
              </p>
              <InfoCard
                type={InfoCard.types.WARNING}
                className="mb-3"
                title={
                  <span className="font-weight-bold">
                    Generering av nye gjenopprettingskoder endrer ikke nøkkelen
                    i autentiseringsappen.
                  </span>
                }
                message={
                  <React.Fragment>
                    Hvis du ønsker å endre nøkkelen som brukes i
                    autentiseringsappen, bør du{" "}
                    <NavLink
                      to={routes.MFA_RESET_AUTHENTICATOR_APP.path}
                      className="text-color1"
                    >
                      tilbakestille autentiseringsappen
                    </NavLink>{" "}
                    istedenfor.
                  </React.Fragment>
                }
              ></InfoCard>
              <Row>
                {resetRecoveryCodesProcess.hasError ? (
                  <Col xs="12" className="mb-3">
                    <ErrorBlock error={resetRecoveryCodesProcess} />
                  </Col>
                ) : null}
                <Col lg="6" className="mx-auto">
                  {resetRecoveryCodesProcess.inProcess ? (
                    <Spinner size="medium" />
                  ) : (
                    <Button
                      color="color8"
                      className="text-uppercase w-100"
                      onClick={() => dispatch(actions.resetRecoveryCodes())}
                    >
                      Generer nye koder
                    </Button>
                  )}
                </Col>
                <Col lg="6">
                  <NavLink to={routes.MY_SETTINGS.path} className="text-color1">
                    <Button color="color1" className="w-100 text-uppercase">
                      Gå tilbake
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ResetRecoveryCodesForm;
