const _prefix = "MFA_SETUP::";

export const UPDATE_DATA = _prefix + "UPDATE_DATA";
export const UPDATE_UI = _prefix + "UPDATE_UI";

export const INIT_AUTHENTICATOR = _prefix + "INIT_AUTHENTICATOR";
export const VERIFY_AUTHENTICATOR_CODE = _prefix + "VERIFY_AUTHENTICATOR_CODE";
export const DISABLE_MFA = _prefix + "DISABLE_MFA";
export const RESET_AUTHENTICATOR = _prefix + "RESET_AUTHENTICATOR";
export const RESET_RECOVERY_CODES = _prefix + "RESET_RECOVERY_CODES";

export const updateData = (updateOb) => ({ type: UPDATE_DATA, updateOb });
export const updateUi = (updateOb) => ({ type: UPDATE_UI, updateOb });

export const initAuthenticator = () => ({
  type: INIT_AUTHENTICATOR,
});
export const verifyAuthenticatorCode = () => ({
  type: VERIFY_AUTHENTICATOR_CODE,
});
export const disableMFA = () => ({ type: DISABLE_MFA });
export const resetAuthenticator = () => ({ type: RESET_AUTHENTICATOR });
export const resetRecoveryCodes = () => ({ type: RESET_RECOVERY_CODES });
