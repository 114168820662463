import { fork, take, put, call, select } from "redux-saga/effects";

import request from "./../../../common/services/request";
import * as sessionSelectors from "./../../../common/redux/session/selectors";
import * as userService from "./../../../common/services/user";
import * as immutable from "./../../../common/helpers/immutable";
import { createRootSaga } from "../../../common/services/redux-saga";
import { showErrorMessage } from "../../../Toaster/redux/actions";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchFetchAccessMatrixData() {
  while (true) {
    let action = yield take(actions.FETCH_ACCESS_MATRIX_DATA);

    let result = yield call(request.get, {
      path: `Access/matrix`,
      processName: `fetchAccessMatrixData`,
    });

    if (result instanceof Error) continue;

    // prepare data

    let preparedAccesses = {};

    for (let accessOb of result.accesses) {
      if (!preparedAccesses[accessOb.workshopTypeId]) {
        preparedAccesses[accessOb.workshopTypeId] = {};
      }

      if (!preparedAccesses[accessOb.workshopTypeId][accessOb.wreckActionId]) {
        preparedAccesses[accessOb.workshopTypeId][accessOb.wreckActionId] = {};
      }

      preparedAccesses[accessOb.workshopTypeId][accessOb.wreckActionId][
        accessOb.vehicleTypeId
      ] = accessOb.access;
    }

    yield put(
      actions.updateData({
        accessMatrix: result,
        preparedAccesses,
      })
    );
  }
}

function* updateAccess(action) {
  let { ids } = action;

  let session = yield select(sessionSelectors.getSession);

  if (!userService.isBskAdmin(session)) return;

  let { workshopTypeId, wreckActionId, vehicleTypeId } = ids;
  let data = yield select(selectors.getData);

  let newAccessValue =
    !data.preparedAccesses[workshopTypeId][wreckActionId][
      vehicleTypeId || "null"
    ];

  yield put(
    actions.updateData({
      preparedAccesses: {
        [workshopTypeId]: {
          [wreckActionId]: {
            [vehicleTypeId || "null"]: newAccessValue,
          },
        },
      },
    })
  );

  let result = yield call(request.post, {
    path: "Access/matrix",
    processName: "updateAccess",
    params: immutable.updateObjectProps(ids, { access: newAccessValue }),
  });

  if (result instanceof Error) {
    yield put(showErrorMessage(result.message));
    yield put(actions.updateData(data));
  }
}

function* watchUpdateAccess() {
  while (true) {
    let action = yield take(actions.UPDATE_ACCESS);

    yield fork(updateAccess, action);
  }
}

export default createRootSaga(watchFetchAccessMatrixData, watchUpdateAccess);
