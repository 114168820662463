import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";
import autoprefixer from "autoprefixer";
import postcssJs from "postcss-js";
import bowser from "bowser";

import Spinner from "./../../common/components/elements/Spinner";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import * as userService from "./../../common/services/user";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import { useScrollToTop, useTitle } from "../../common/hooks";

import "./style.css";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

let prefix = postcssJs.sync([autoprefixer({ grid: true })]);

let prefixer = (styles) => {
  if (!bowser.msie) return styles;

  let prefixedStyles = prefix(styles);

  if (prefixedStyles.display && Array.isArray(prefixedStyles.display)) {
    for (let propValue of prefixedStyles.display) {
      if (/-ms-/.test(prefixedStyles.display)) {
        prefixedStyles.display = propValue;
        break;
      }
    }
  }

  return prefixedStyles;
};

const cellWidth = `2rem`;
const topAreaRowCount = 12;
const leftAreaColumnCount = 8;

const getMetrics = ({ workshopTypes, wreckActions, vehicleTypes }) => {
  let leftAreaRowCount = workshopTypes.length;

  let topAreaColumnCount = wreckActions.reduce((accumulator, ob) => {
    return (accumulator += ob.useVehicleTypes ? vehicleTypes.length : 1);
  }, 0);

  let totalRowCount = topAreaRowCount + leftAreaRowCount;
  let totalColumnCount = leftAreaColumnCount + topAreaColumnCount;

  return {
    leftAreaRowCount,
    topAreaColumnCount,
    totalRowCount,
    totalColumnCount,
  };
};

function AccessMatrix() {
  const dispatch = useDispatch();

  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  useScrollToTop();
  useTitle("Tilgangsmatrise");

  const prepareCell = ({ counter, accessOb, topAreaColumnCount }) => {
    let rowStart = Math.floor(counter / topAreaColumnCount) + 1;
    let columnStart = (counter % topAreaColumnCount) + 1;

    return (
      <div
        key={`${accessOb.workshopTypeId}_${accessOb.wreckActionId}_${accessOb.vehicleTypeId}`}
        className="d-flex justify-content-center align-items-center"
        style={prefixer({
          // position
          gridRow: `${rowStart} / ${rowStart + 1}`,
          gridColumn: `${columnStart} / ${columnStart + 1}`,
        })}
      >
        <div
          className={`grid-cell cell-column${columnStart} cell-row${rowStart}`}
          data-cell-column={columnStart}
          data-cell-row={rowStart}
          data-access-workshop-type-id={accessOb.workshopTypeId}
          data-access-wreck-action-id={accessOb.wreckActionId}
          data-access-vehicle-type-id={accessOb.vehicleTypeId}
        >
          {accessOb.access ? (
            <span className="text-color1">På</span>
          ) : (
            <span className="text-color8">Av</span>
          )}
          {userService.isBskAdmin(session) ? (
            <div className="change-button link-button">
              Skru {accessOb.access ? "av" : "på"}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const getCells = () => {
    if (!data.preparedAccesses) {
      return null;
    }

    let { workshopTypes, wreckActions, vehicleTypes } = data.accessMatrix;

    let metrics = getMetrics({ workshopTypes, wreckActions, vehicleTypes });
    let {
      leftAreaRowCount,
      topAreaColumnCount,
      totalColumnCount,
      totalRowCount,
    } = metrics;

    let arr = [];
    let counter = 0;

    for (let workshopType of workshopTypes) {
      for (let wreckAction of wreckActions) {
        if (wreckAction.useVehicleTypes) {
          for (let vehicleType of vehicleTypes) {
            arr.push(
              prepareCell({
                counter,
                accessOb: {
                  workshopTypeId: workshopType.id,
                  wreckActionId: wreckAction.id,
                  vehicleTypeId: vehicleType.id,
                  access:
                    data.preparedAccesses[workshopType.id][wreckAction.id][
                      vehicleType.id
                    ],
                },
                topAreaColumnCount,
              })
            );
            counter++;
          }
        } else {
          arr.push(
            prepareCell({
              counter,
              topAreaColumnCount,
              accessOb: {
                workshopTypeId: workshopType.id,
                wreckActionId: wreckAction.id,
                vehicleTypeId: null,
                access:
                  data.preparedAccesses[workshopType.id][wreckAction.id][
                    "null"
                  ],
              },
            })
          );
          counter++;
        }
      }
    }

    return arr;
  };

  useEffect(function fetchAccessMatrixDataOnMount() {
    if (!data.accessMatrix) {
      dispatch(actions.fetchAccessMatrixData());
    }
  }, []);

  let workshopTypes,
    wreckActions,
    vehicleTypes,
    totalRowCount,
    totalColumnCount,
    topAreaColumnCount,
    leftAreaRowCount;

  if (data.accessMatrix) {
    ({ workshopTypes, wreckActions, vehicleTypes } = data.accessMatrix);

    let metrics = getMetrics({ workshopTypes, wreckActions, vehicleTypes });

    ({ leftAreaRowCount, topAreaColumnCount, totalColumnCount, totalRowCount } =
      metrics);
  }

  const fetchAccessMatrixDataProcess = processes["fetchAccessMatrixData"] || {};

  return (
    <div className="AccessMatrix px-5">
      <Row className="mb-5">
        <Col xs="12">
          <AdminHeader title="Standard tilgangsmatrise" />
        </Col>
        <Col xs="12">
          <Row>
            {fetchAccessMatrixDataProcess.inProcess ? (
              <Col xs="12">
                <Spinner />
              </Col>
            ) : null}
            {fetchAccessMatrixDataProcess.hasError ? (
              <Col xs="12" className="text-center">
                <b className="text-danger">Det oppstod en feil</b>
                <br />
                <b>{fetchAccessMatrixDataProcess.errorMessage}</b>
              </Col>
            ) : null}
            {fetchAccessMatrixDataProcess.isSuccessed ? (
              <Col xs="12" className="grid-container">
                {/*wrapper of matrix*/}
                <div
                  style={prefixer({
                    display: "inline-grid",
                    // template
                    gridTemplateColumns: `repeat(${totalColumnCount}, ${cellWidth})`,
                    gridTemplateRows: `repeat(${totalRowCount}, ${cellWidth})`,
                  })}
                >
                  {/*top header*/}
                  <div
                    style={prefixer({
                      display: `grid`,
                      // position
                      gridColumn: `${leftAreaColumnCount + 1} / ${
                        totalColumnCount + 1
                      }`,
                      gridRow: `1 / ${topAreaRowCount + 1}`,
                      // template
                      gridTemplateColumns: `repeat(${topAreaColumnCount}, ${cellWidth})`,
                      gridTemplateRows: `repeat(${topAreaRowCount}, ${cellWidth})`,
                    })}
                  >
                    {wreckActions &&
                      wreckActions.map((w, i) => {
                        let startLine = 1;

                        for (let j = 1; j <= i; j++) {
                          startLine += wreckActions[j - 1].useVehicleTypes
                            ? vehicleTypes.length
                            : 1;
                        }

                        let span = w.useVehicleTypes ? vehicleTypes.length : 1;

                        // if use vehicle types

                        if (w.useVehicleTypes) {
                          return (
                            <div
                              key={w.id}
                              style={prefixer({
                                display: "grid",
                                // position
                                gridColumn: `${startLine} / span ${span}`,
                                gridRow: `1 / ${topAreaRowCount + 1}`,
                                // template
                                gridTemplateColumns: `repeat(${span}, ${cellWidth})`,
                                gridTemplateRows: `repeat(${topAreaRowCount}, ${cellWidth})`,
                              })}
                            >
                              <div
                                className="wreck-action-header"
                                style={prefixer({
                                  // position
                                  gridColumn: `1 / span ${span}`,
                                  gridRow: `1 / span 3`,
                                })}
                              >
                                {w.text}
                              </div>
                              {vehicleTypes.map((v, i) => {
                                return (
                                  <div
                                    className="vehicle-type-header"
                                    key={v.id}
                                    style={prefixer({
                                      // position
                                      gridColumn: `${i + 1} / span 1`,
                                      gridRow: `4 / span ${
                                        topAreaRowCount - 3
                                      }`,
                                    })}
                                  >
                                    {v.text}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }

                        // else

                        return (
                          <div
                            key={w.id}
                            className="wreck-action-header vertical-text"
                            style={prefixer({
                              // position
                              gridColumn: `${startLine} / span ${span}`,
                              gridRow: `1 / ${topAreaRowCount + 1}`,
                            })}
                          >
                            {w.text}
                          </div>
                        );
                      })}
                  </div>
                  {/*left header*/}
                  <div
                    style={prefixer({
                      display: `grid`,
                      // position
                      gridColumn: `1 / ${leftAreaColumnCount + 1}`,
                      gridRow: `${
                        topAreaRowCount + 1
                      } / span ${leftAreaRowCount}`,
                      // template
                      gridTemplateColumns: `repeat(${leftAreaColumnCount}, ${cellWidth})`,
                      gridTemplateRows: `repeat(${leftAreaRowCount}, ${cellWidth})`,
                    })}
                  >
                    {workshopTypes &&
                      workshopTypes.map((w, i) => {
                        return (
                          <div
                            key={w.id}
                            className="workshop-type-header"
                            style={prefixer({
                              // position
                              gridRow: `${i + 1} / ${i + 2}`,
                              gridColumn: `1 / ${leftAreaColumnCount + 1}`,
                            })}
                          >
                            {w.text}
                          </div>
                        );
                      })}
                  </div>
                  {/*cells*/}
                  <div
                    className="bg-white"
                    style={prefixer({
                      display: `grid`,
                      // template
                      gridTemplateColumns: `repeat(${topAreaColumnCount}, ${cellWidth})`,
                      gridTemplateRows: `repeat(${leftAreaRowCount}, ${cellWidth})`,
                      // position
                      gridColumn: `${leftAreaColumnCount + 1} / ${
                        totalColumnCount + 1
                      }`,
                      gridRow: `${topAreaRowCount + 1} / ${totalRowCount + 1}`,
                    })}
                    onMouseOver={(e) => {
                      let gridCellElem;

                      if (e.target.classList.contains("grid-cell")) {
                        gridCellElem = e.target;
                      } else if (
                        e.target.parentElement.classList.contains("grid-cell")
                      ) {
                        gridCellElem = e.target.parentElement;
                      }

                      if (!gridCellElem) return;

                      if (!gridCellElem.classList.contains("hovered-cell")) {
                        [].forEach.call(
                          document.querySelectorAll(
                            `.cell-column${gridCellElem.getAttribute(
                              "data-cell-column"
                            )}`
                          ),
                          (elem) => elem.classList.add("hovered-cell")
                        );
                        [].forEach.call(
                          document.querySelectorAll(
                            `.cell-row${gridCellElem.getAttribute(
                              "data-cell-row"
                            )}`
                          ),
                          (elem) => elem.classList.add("hovered-cell")
                        );
                      }
                    }}
                    onMouseOut={(e) => {
                      let gridCellElem;

                      if (e.target.classList.contains("grid-cell")) {
                        gridCellElem = e.target;
                      } else if (
                        e.target.parentElement.classList.contains("grid-cell")
                      ) {
                        gridCellElem = e.target.parentElement;
                      }

                      if (!gridCellElem) return;

                      [].forEach.call(
                        document.querySelectorAll(
                          `.cell-column${gridCellElem.getAttribute(
                            "data-cell-column"
                          )}`
                        ),
                        (elem) => elem.classList.remove("hovered-cell")
                      );
                      [].forEach.call(
                        document.querySelectorAll(
                          `.cell-row${gridCellElem.getAttribute(
                            "data-cell-row"
                          )}`
                        ),
                        (elem) => elem.classList.remove("hovered-cell")
                      );
                    }}
                    onClick={(e) => {
                      if (!e.target.classList.contains("change-button")) {
                        return;
                      }

                      let gridCellElem = e.target.parentElement;

                      dispatch(
                        actions.updateAccess({
                          workshopTypeId: gridCellElem.getAttribute(
                            "data-access-workshop-type-id"
                          ),
                          wreckActionId: gridCellElem.getAttribute(
                            "data-access-wreck-action-id"
                          ),
                          vehicleTypeId: gridCellElem.getAttribute(
                            "data-access-vehicle-type-id"
                          ),
                        })
                      );
                    }}
                  >
                    {getCells()}
                  </div>
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AccessMatrix;
