export const ui = {
  updateBidToken: false,
  nextActionObjectOnValidBidToken: null
};
export const data = {};
export const form = {
  amount: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required', 'number']
  }
}
