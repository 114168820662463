import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../../common/helpers/immutable';
import * as config from './../config';

const data = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE_DATA:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

const initUiState = {
  currentPage: 0,
  expandedRowIds: [],
  sorting: config.defaultSorting,
  filters: config.defaultFilters,
  customFilters: [],
  currentPageSize: config.defaultPageSize,
  wreckActionsFilters: [],
  acceptedTextFilter: '',
  section: 1,
  statuses: [1]
};

const ui = (state = initUiState, action) => {
  switch (action.type) {
    case actions.UPDATE_UI:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  data,
  ui
});

