import React, { useEffect } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import * as selectors from "./../redux/selectors";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as actions from "./../redux/actions";
import * as formValidatorActions from "./../../common/services/form-validator/actions";
import * as models from "../redux/models";

function AuthenticatorCodeForm() {
  const dispatch = useDispatch();
  const form = useSelector(selectors.getAuthenticatorCodeForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) =>
    actions.updateUi({ authenticatorCodeForm: updateOb });

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getAuthenticatorCodeForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["mfaCode"],
        formSelector: selectors.getAuthenticatorCodeForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.login()),
      })
    );
  };

  useEffect(function resetFormOnUnmount() {
    return () => {
      dispatch(
        actions.updateUi({
          authenticatorCodeForm: models.ui.authenticatorCodeForm,
        })
      );
    };
  }, []);

  return (
    <Form className="LoginPasswordForm form1" onSubmit={onSubmit}>
      <h1 className="text-color1 mb-4">
        <b>Tofaktor- autentisering</b>
      </h1>
      <p className="mb-4">
        Innloggingen er beskyttet med nøkkel fra en autentiseringsapp. Skriv inn
        autentiseringskoden fra appen nedenfor.
      </p>
      <FormGroup>
        <InputText
          id="mfaCode"
          label="Kode"
          value={form.mfaCode.value}
          onChange={(e) => validateAndUpdateProp(e, "mfaCode")}
          hasError={form.mfaCode.hasError}
          errorMessage={form.mfaCode.errorMessage}
        />
      </FormGroup>
      {processes.login.hasError ? (
        <FormGroup>
          <div className="text-center">
            <b className="text-danger ">Kunne ikke logge inn</b>
            <br />
            <b>{processes.login.errorMessage}</b>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Row>
          <Col lg="6">
            {processes.login.inProcess || processes.login.isSuccessed ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color1"
                className="text-uppercase w-100"
              >
                Logg inn
              </Button>
            )}
          </Col>
          <Col lg="6">
            <button
              className="link-button text-uppercase w-100 h-100"
              onClick={() => dispatch(actions.setStep(1))}
            >
              Gå tilbake
            </button>
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            Mistet tilgang til din autentiseringsapp? Du kan{" "}
            <span
              className="link-button"
              onClick={() => dispatch(actions.setStep(3))}
            >
              logge inn med en gjenopprettingskode
            </span>.
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default AuthenticatorCodeForm;
