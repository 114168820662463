import React from "react";

import RegularModal, {
  modalSizes,
} from "./../../../components/blocks/RegularModal";
export { modalSizes } from "./../../../components/blocks/RegularModal";

function BasicModal({
  show,
  header,
  html,
  htmlClassName = "",
  text,
  onClose,
  children,
  size = modalSizes.MEDIUM,
}) {
  if (!show) return null;

  return (
    <RegularModal className="BasicModal" size={size} onCancel={onClose}>
      {header ? (
        <h2 className="mb-5">
          <b>{header}</b>
        </h2>
      ) : null}
      {html && (
        <div
          className={htmlClassName}
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      )}
      {text && <div>{text}</div>}
      {children && <div>{children}</div>}
    </RegularModal>
  );
}

export default BasicModal;
