import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  PagingState,
  CustomPaging,
  DataTypeProvider,
  SortingState,
  FilteringState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import * as gridUtils from "./../../../../../common/helpers/grid-utils";
import * as processesSelectors from "./../../../../../common/redux/processes/selectors";

import "./style.css";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import * as config from "./config";
import DateRangeFilter from "./../DateRangeFilter";

const DateTypeProvider = gridUtils.DateTypeProvider;
const IntegerTypeProvider = gridUtils.IntegerTypeProvider;

function AdminBidsGrid(props) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const { getBy } = props;
  const getById = props[getBy];

  const getCurrentInstanceUiObject = () => {
    return (ui[getBy] && ui[getBy][getById]) || {};
  };

  const updateUi = (updateOb, triggerFetchBids) => {
    dispatch(
      actions.updateUi({
        updateOb: {
          [getBy]: {
            [getById]: updateOb,
          },
        },
        triggerFetchBids,
        getById,
        getBy,
      })
    );
  };

  const TableCellComponent = (props) => {
    let style = props.row.deleted ? { backgroundColor: "#ffcccc" } : null;

    return (
      <Table.Cell
        {...props}
        className="white-space-normal vertical-align-middle text-center"
        style={style}
      />
    );
  };

  const TableFilterRowCellComponent = (props) => {
    if (
      ["bidDeadline", "created", "acceptedTime", "deleted"].includes(
        props.column.name
      )
    ) {
      return (
        <DateRangeFilter
          columnName={props.column.name}
          ui={getCurrentInstanceUiObject()}
          updateUi={updateUi}
        />
      );
    }

    return <TableFilterRow.Cell {...props} />;
  };

  useEffect(function setDefaultUiOnMount() {
    updateUi({
      currentPage: 0,
      currentPageSize: config.defaultPageSize,
    });
  }, []);

  useEffect(function resetBidsOnMount() {
    dispatch(
      actions.updateData({
        bids: {
          [getBy]: {
            [getById]: null,
          },
        },
      })
    );
  }, []);

  useEffect(function fetchBidsOnMount() {
    dispatch(actions.fetchBids({ getById, getBy }));
  }, []);

  let isDataPopulated =
    data.bids && data.bids[getBy] && data.bids[getBy][getById];
  let fetchBidsProcess = processes[`fetchBids:${getBy}:${getById}`] || {};

  let columns = [...config.columns];

  if (getBy === "workshopNumber") {
    columns.unshift({
      name: "estimateId",
      title: "Lp. nr.",
    });
  }

  if (getBy === "estimateId") {
    columns.unshift({
      name: "createdBy",
      title: "Budgiver",
    });
  }

  return (
    <Container fluid className="AdminBidsGrid">
      <Row>
        <Col className="grid-container">
          {isDataPopulated /*&& fetchBidsProcess.isSuccessed*/ ? (
            <Grid rows={data.bids[getBy][getById].rows} columns={columns}>
              <DataTypeProvider
                for={["estimateId"]}
                formatterComponent={({ value }) => (
                  <Link to={`/browse/${value}`}>{value}</Link>
                )}
              />
              <DataTypeProvider
                for={["createdBy"]}
                formatterComponent={({ value }) => (
                  <Link to={`/admin/workshops/?workshopNumber=${value}`}>
                    {value}
                  </Link>
                )}
              />
              <DateTypeProvider
                for={["created", "bidDeadline", "acceptedTime", "deleted"]}
              />
              <IntegerTypeProvider for={["amount", "acceptedAmount"]} />
              <DataTypeProvider
                for={["maxAmount"]}
                availableFilterOperations={["equal"]}
              />
              <PagingState
                currentPage={ui[getBy][getById].currentPage}
                pageSize={ui[getBy][getById].currentPageSize}
                onCurrentPageChange={(currentPage) =>
                  updateUi({ currentPage }, true)
                }
                onPageSizeChange={(currentPageSize) =>
                  updateUi({ currentPageSize }, true)
                }
              />
              <SortingState
                sorting={ui[getBy][getById].sorting}
                onSortingChange={(sorting) =>
                  updateUi({ sorting, expandedRowIds: [] }, true)
                }
              />
              <FilteringState
                onFiltersChange={(filters) =>
                  updateUi({ filters, expandedRowIds: [] }, true)
                }
                columnExtensions={config.filteringColumnExtensions}
              />
              <CustomPaging totalCount={data.bids[getBy][getById].totalCount} />
              <Table
                messages={gridUtils.tableMessages}
                cellComponent={TableCellComponent}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow
                messages={gridUtils.filterRowMessages}
                showFilterSelector
                cellComponent={TableFilterRowCellComponent}
              />
              <PagingPanel
                messages={gridUtils.pagingPanelMessages}
                pageSizes={config.pageSizes}
              />
            </Grid>
          ) : null}
          {/*fetchBidsProcess.inProcess ?
                <div className="loading-block">
                  <Spinner size="medium"/>
                </div>
                :
                null*/}
          {fetchBidsProcess.hasError ? (
            <div className="text-center">
              <b className="text-danger">Det oppstod en feil</b>
              <br />
              <b>{fetchBidsProcess.errorMessage}</b>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default AdminBidsGrid;
