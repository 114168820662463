import {prefix} from './../config';

export const UPDATE_DATA = prefix + 'UPDATE_DATA';
export const UPDATE_UI = prefix + 'UPDATE_UI';

export const FETCH_STATUSMESSAGES = prefix + 'FETCH_STATUSMESSAGES';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = (updateOb, triggerFetchGrid) => ({type: UPDATE_UI, updateOb, triggerFetchGrid});

export const fetchStatusMessages = ({isPreviewMode, previewMode}) => ({type: FETCH_STATUSMESSAGES, isPreviewMode, previewMode});