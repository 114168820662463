import React from "react";

import { routes } from "../../common/config/routes";
import Page from "../../common/components/blocks/Page";
import SectionHeader from "../../common/components/blocks/SectionHeader";
import ContactBlock from "../../common/components/blocks/Contact";

import headerBg from "./../../common/imgs/road-arrows.jpg";

function Contact() {
  return (
    <Page
      className="Contact"
      title={routes.CONTACT.title}
      withPadding={false}
      fluid={true}
    >
      <SectionHeader
        header="Kontakt"
        text="Har du spørsmål eller andre henvendelser?"
        bg={headerBg}
      />
      <ContactBlock className="my-5" />
    </Page>
  );
}

export default Contact;
