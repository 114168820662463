import React, { useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "./style.css";

import classname from "./../../../../common/helpers/classname";

const componentName = "RichTextEditor";
const cn = classname(componentName);

function RichTextEditor({
  id,
  className,
  label,
  hasError,
  errorMessage,
  initialHtml = "",
  onChange,
}) {
  const editorReference = useRef(null);

  const [hasText, setHasText] = useState(!!initialHtml);

  const [lastHtml, setLastHtml] = useState(!!initialHtml);

  const [isFocused, setIsFocused] = useState(false);

  const [defaultEditorState] = useState(() => {
    const blocksFromHtml = htmlToDraft(initialHtml);

    const contentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    );

    return EditorState.createWithContent(contentState);
  });

  const focusEditor = () => {
    editorReference.current.focus();
  };

  const onEditorStateChange = (editorState) => {
    let currentContent = editorState.getCurrentContent();

    let hasText = !!currentContent.getPlainText();

    setHasText({ hasText });

    let currentHtml = "";

    if (hasText) currentHtml = draftToHtml(convertToRaw(currentContent));

    if (lastHtml === currentHtml) return;

    setLastHtml({ lastHtml: currentHtml });

    if (onChange) onChange(currentHtml);
  };

  return (
    <div
      id={id || `RichTextEditor::${label}`}
      className={cn.join(
        componentName,
        className,
        hasText && cn("has-text"),
        isFocused && cn("is-focused"),
        hasError && cn("has-error")
      )}
    >
      {label ? (
        <label className="mb-3" onClick={focusEditor}>
          {label}:
        </label>
      ) : null}
      <Editor
        editorRef={(ref) => {
          editorReference.current = ref;
        }}
        defaultEditorState={defaultEditorState}
        editorClassName={cn("editor")}
        onEditorStateChange={onEditorStateChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {label && hasError ? (
        <span className={cn("error-message")}> * {errorMessage}</span>
      ) : null}
    </div>
  );
}

export default RichTextEditor;
