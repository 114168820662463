export const hasError = (processes) => {
  for(let process of processes){
    if(process.hasError)
      return true;
  }

  return false;
};

export const hasInProcess = (processes) => {
  for(let process of processes){
    if(process.inProcess)
      return true;
  }

  return false;
};

export const findError = (processes) => {
  for(let process of processes){
    if(process.hasError){
      return process;
    }
  }
};