import {
  fork,
  take,
  put,
  call,
  select,
  cancel,
  takeEvery,
} from "redux-saga/effects";
import { delay } from "redux-saga";

import request from "./../../common/services/request";
import * as immutable from "./../../common/helpers/immutable";
import * as sessionService from "./../../common/services/session";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import { createRootSaga } from "../../common/services/redux-saga";
import { showErrorMessage } from "../../Toaster/redux/actions";

import * as config from "./../config";
import * as actions from "./actions";
import * as selectors from "./selectors";

const FIRST_PAGE = "FIRST_PAGE";
const LOAD_MORE = "LOAD_MORE";
const LAST_PAGE = "LAST_PAGE";

function* _prepareParamsForGrid(options) {
  let params = {
    sorting: [
      {
        columnName: "created",
        direction: "desc",
      },
    ],
  };

  if (options.type === FIRST_PAGE) {
    params.pageSize = config.getBidsBy * 2;
  } else {
    params.pageSize = config.getBidsBy;
  }

  let ui = yield select(selectors.getUi);

  // add bid token

  let session = yield select(sessionSelectors.getSession);

  params.bidToken = session.bidToken;

  // add page

  if (options.type === FIRST_PAGE) {
    params.currentPage = 0;
  } else if (options.type === LOAD_MORE) {
    params.currentPage = ++ui.currentPage[ui.section];
  } else if (options.type === LAST_PAGE) {
    params.currentPage = ui.currentPage[ui.section];
  }

  let filters = yield select(selectors.getFilters);

  filters = filters[ui.section];

  // * vehicle types

  if (filters.vehicleTypes && filters.vehicleTypes.length) {
    params.vehicleTypes = filters.vehicleTypes;
  }

  // * makes

  if (filters.makes && filters.makes.length) {
    params.makes = filters.makes;
  }

  // * counties

  if (filters.counties && filters.counties.length) {
    params.counties = filters.counties;
  }

  // * wreck actions

  if (filters.wreckActions && filters.wreckActions.length) {
    params.wreckActions = filters.wreckActions;
  }

  // * years

  if (filters.yearFrom) params.yearFrom = filters.yearFrom;
  if (filters.yearTo) params.yearTo = filters.yearTo;

  // * search term

  if (filters.filterValue) params.filterValue = filters.filterValue;

  return params;
}

export function* loadMore() {
  let session = yield select(sessionSelectors.getSession);

  if (sessionService.isBidTokenExpired(session)) {
    yield put(
      actions.updateUi({
        nextActionObjectOnValidBidToken: actions.loadMore(),
      })
    );

    return;
  }

  let ui = yield select(selectors.getUi);
  let data = yield select(selectors.getData);

  // to prevent error on ios

  if (!data.bids[ui.section]) return;

  // block handle scroll fluid

  yield put(
    actions.updateUi({
      blockHandleScrollFluid: {
        [ui.section]: true,
      },
    })
  );

  // load reserve data if possible

  if (ui.currentPage[ui.section] > ui.showUntil[ui.section]) {
    yield put(
      actions.updateUi({
        showUntil: {
          [ui.section]: ui.showUntil[ui.section] + 1,
        },
      })
    );
  }

  // load more data if possible

  if (data.bids[ui.section].rows.length >= data.bids[ui.section].totalCount) {
    return;
  }

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: LOAD_MORE });

  // do request

  let path, processName;

  if (ui.section === "active") {
    path = "Bid/workshop/active";
    processName = "loadMore:active";
  } else if (ui.section === "expired") {
    path = "Bid/workshop/expired";
    processName = "loadMore:expired";
  } else if (ui.section === "bought") {
    path = "Bid/workshop/accepted";
    processName = "loadMore:bought";
  }

  let result = yield call(request.post, {
    path,
    processName,
    params,
  });

  if (result instanceof Error) {
    return;
  }

  // save current page

  yield put(
    actions.updateUi({
      currentPage: {
        [ui.section]: params.currentPage,
      },
    })
  );

  // save results

  result = immutable.updateObjectProps(result, {
    rows: data.bids[ui.section].rows.concat(result.rows),
  });

  yield put(
    actions.updateData({
      bids: {
        [ui.section]: result,
      },
    })
  );

  // unblock handle scroll fluid if reserve data left or more data can be fetched

  ui = yield select(selectors.getUi);

  if (
    params.currentPage > ui.showUntil[ui.section] ||
    result.rows.length < result.totalCount
  ) {
    yield put(
      actions.updateUi({
        blockHandleScrollFluid: {
          [ui.section]: false,
        },
      })
    );
  }
}

export function* getFirstPage() {
  let session = yield select(sessionSelectors.getSession);

  if (sessionService.isBidTokenExpired(session)) {
    yield put(
      actions.updateUi({
        nextActionObjectOnValidBidToken: actions.getFirstPage(),
      })
    );

    return;
  }

  let ui = yield select(selectors.getUi);

  // block handle scroll fluid

  yield put(
    actions.updateUi({
      blockHandleScrollFluid: {
        [ui.section]: true,
      },
    })
  );

  // wait for some time, don`t do request immediately
  // may be user still interacts with interface

  if (ui.firstRequestAccomplished[ui.section]) yield delay(1000);

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: FIRST_PAGE });

  // do request

  let path, processName;

  if (ui.section === "active") {
    path = "Bid/workshop/active";
    processName = "getFirstPage:active";
  } else if (ui.section === "expired") {
    path = "Bid/workshop/expired";
    processName = "getFirstPage:expired";
  } else if (ui.section === "bought") {
    path = "Bid/workshop/accepted";
    processName = "getFirstPage:bought";
  }

  let result = yield call(request.post, {
    path,
    processName,
    params,
  });

  if (result instanceof Error) {
    return;
  }

  // save `currentPage` & `showUntil` & `firstRequestAccomplished`

  let updatedUi = {
    currentPage: {
      [ui.section]: 1,
    },
    showUntil: {
      [ui.section]: 0,
    },
  };

  if (!ui.firstRequestAccomplished[ui.section]) {
    updatedUi.firstRequestAccomplished = { [ui.section]: true };
  }

  yield put(actions.updateUi(updatedUi));

  // save results

  yield put(
    actions.updateData({
      bids: {
        [ui.section]: result,
      },
    })
  );

  // unblock handle scroll fluid

  if (result.totalCount > config.getBidsBy) {
    yield put(
      actions.updateUi({
        blockHandleScrollFluid: {
          [ui.section]: false,
        },
      })
    );
  }
}

export function* triggerFetchBids() {
  let lastTask;
  while (true) {
    const action = yield take([
      actions.GET_FIRST_PAGE,
      actions.LOAD_MORE,
      actions.UPDATE_FILTERS,
    ]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    if (action.type === actions.LOAD_MORE) {
      lastTask = yield fork(loadMore);
    } else {
      lastTask = yield fork(getFirstPage, action);
    }
  }
}

export function* deleteBid({ bidId }) {
  let session = yield select(sessionSelectors.getSession);

  if (sessionService.isBidTokenExpired(session)) {
    yield put(
      actions.updateUi({
        nextActionObjectOnValidBidToken: actions.deleteBid(bidId),
      })
    );

    return;
  }

  let data = yield select(selectors.getData);
  let ui = yield select(selectors.getUi);

  let currentSectionData = data.bids[ui.section];
  let updatedTotalCountValue = currentSectionData.totalCount - 1;
  let updatedRows = currentSectionData.rows.filter((bid) => bid.id != bidId);

  let updatedSectionData = immutable.updateObjectProps(currentSectionData, {
    totalCount: updatedTotalCountValue,
    rows: updatedRows,
  });

  yield put(
    actions.updateData({
      bids: {
        [ui.section]: updatedSectionData,
      },
    })
  );

  let result = yield call(request.del, {
    path: `Bid/${bidId}/workshop`,
    processName: "deleteBid",
    params: {
      bidToken: session.bidToken,
    },
  });

  if (result instanceof Error) {
    yield put(showErrorMessage(result.message));
    yield put(actions.updateData(data));
    return;
  }

  // load last page if more data can be loaded

  if (updatedTotalCountValue <= updatedRows.length) {
    return;
  }

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: LAST_PAGE });

  // do request

  let path, processName;

  if (ui.section === "active") {
    path = "Bid/workshop/active";
    processName = "loadMore:active";
  } else if (ui.section === "expired") {
    path = "Bid/workshop/expired";
    processName = "loadMore:expired";
  } else if (ui.section === "bought") {
    path = "Bid/workshop/accepted";
    processName = "loadMore:bought";
  }

  result = yield call(request.post, {
    path,
    processName,
    params,
  });

  if (result instanceof Error) {
    return;
  }

  // save results

  result = immutable.updateObjectProps(result, {
    rows: updatedRows.slice(0, -(config.getBidsBy - 1)).concat(result.rows),
  });

  yield put(
    actions.updateData({
      bids: {
        [ui.section]: result,
      },
    })
  );
}

export function* watchUpdateBid() {
  while (true) {
    let action = yield take(actions.UPDATE_BID);

    let session = yield select(sessionSelectors.getSession);

    if (sessionService.isBidTokenExpired(session)) {
      yield put(
        actions.updateUi({
          nextActionObjectOnValidBidToken: actions.updateBid(),
        })
      );

      continue;
    }

    let { amount } = yield select(selectors.getUpdateBidFormValues);
    let ui = yield select(selectors.getUi);

    let id = ui.updateBidId;

    let result = yield call(request.put, {
      path: `Bid/${id}/workshop`,
      processName: "updateBid",
      params: {
        amount,
        bidToken: session.bidToken,
      },
    });

    if (result instanceof Error) continue;

    let data = yield select(selectors.getData);

    yield put(
      actions.updateData({
        bids: {
          [ui.section]: {
            rows: data.bids[ui.section].rows.map((bid) => {
              return bid.id == id
                ? immutable.updateObjectProps(bid, result)
                : bid;
            }),
          },
        },
      })
    );

    yield put(actions.updateUi({ showUpdateBidModal: false }));
  }
}

export function* watchDeleteBid() {
  yield takeEvery(actions.DELETE_BID, deleteBid);
}

export default createRootSaga(triggerFetchBids, watchDeleteBid, watchUpdateBid);
