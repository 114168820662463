import * as config from './../config';

export const data = {
  grid: {}
};

export const ui = {
  currentPage: 0,
  currentPageSize: config.defaultPageSize,
  sorting: [],
  filters: [],
  createEditModal: {
    show: false,
    id: null,
    form: {
      category: {
        value: '', // category id
        payload: null // category object
      },
      visibility: {
        value: '', // visibility value
        payload: null // visibility object
      },
      question: {
        id: 'question',
        label: 'Spørsmål',
        type: 'textarea',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      answer: {
        id: 'answer',
        label: 'Svar',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      }
    }
  },
  answerModal: {
    show: false,
    answer: null
  }
};