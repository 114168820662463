import {IntegratedFiltering} from '@devexpress/dx-react-grid';

export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'id', title: 'Lp. nr.'},
  {name: 'estimateNumber', title: 'DBS-id'},
  {name: 'objectName', title: 'Merke / modell'},
  {name: 'modelYear', title: 'År'},
  {name: 'licenceNumber', title: 'Reg. nr.'},
  {name: 'wreckActionId', title: 'Objektbehandling'},
  {name: 'created', title: 'Mottatt', type: 'datetime'},
  {name: 'bidDeadline', title: 'Budfrist', type: 'datetime'},
  {name: 'acceptedText', title: 'Akseptert'},
  {name: 'companyNumber', title: 'Selskap'},
  {name: 'userName', title: 'DBS-bruker'}
];
/*export const defaultColumnWidths = [
  {columnName: 'id', width: 100},
  {columnName: 'estimateNumber', width: 100},
  {columnName: 'objectName', width: 150},
  {columnName: 'modelYear', width: 100},
  {columnName: 'licenceNumber', width: 100},
  {columnName: 'wreckActionId', width: 150},
  {columnName: 'created', width: 150},
  {columnName: 'bidDeadline', width: 150},
  {columnName: 'companyNumber', width: 70},
  {columnName: 'userName', width: 110}
];*/
export const defaultSorting =  [
  {columnName: "created", direction: "desc"}
];
export const defaultFilters = [];
export const filteringColumnExtensions = [
  {columnName: 'acceptedText', filteringEnabled: false}
];
export const integratedFilteringColumnExtensions = [
  {
    columnName: 'created',
    predicate: (value, filter, row) => {
      if(!filter.value.length) return true;

      if(filter && filter.operation.substring(0,2) === 'd_') {
        let datearr = value.substring(0,10).split('-');

        let year = parseInt(datearr[0]);
        let month = parseInt(datearr[1]);
        let day = parseInt(datearr[2]);

        return month === parseInt(filter.value);
      };

      return IntegratedFiltering.defaultPredicate(value, filter, row);
    }
  }
];
export const sortingColumnExtensions = [
  {columnName: 'acceptedText', sortingEnabled: false}
];

