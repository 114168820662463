export const prefix = 'ADMIN_COMPANIES::';
export const domain = 'AdminCompanies';
export const title = 'Selskaper';
export const componentName = 'AdminCompanies';

// grid config

export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'firstColumn'},
  {name: 'companyNumber', title: 'Selskapsnummer'},
  {name: 'companyName', title: 'Navn'},
  {name: 'homePage', title: 'Hjemmeside'},
  {name: 'tradeCond', title: 'Salgsbetingelser'},
  {name: 'tradeCondTime', title:  'Salgsbetingelser-dato'},
  {name: 'accessDBSM', title:  'Tilgang som standard'},
];
export const filteringStateColumnExtensions = [
  {columnName: 'tradeCondTime', filteringEnabled: false},
];

export const defaultSorting = [
  {columnName: "companyNumber", direction: "asc"}
];