const _prefix = 'ADMIN_WORKSHOPS::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH_WORKSHOPS = _prefix + 'FETCH_WORKSHOPS';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = ({updateOb, triggerFetchWorkshops}) => ({type: UPDATE_UI, updateOb, triggerFetchWorkshops});

export const fetchWorkshops = () => ({type: FETCH_WORKSHOPS});