export const prefix = 'ADMIN_FAQ_QUESTIONS::';
export const domain = 'AdminFaqQuestions';
export const componentName = 'AdminFaqQuestions';

// grid config

export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'firstColumn'},
  {name: 'categoryId', title: 'Kategori'},
  {name: 'question', title: 'Spørsmål'},
  {name: 'answer', title: 'Svar'},
  {name: 'visibility', title: 'Synlighet'},
];
export const columnExtensions = [
  {columnName: 'createdDate', wordWrapEnabled: true},
  {columnName: 'modifiedDate', wordWrapEnabled: true}
];
export const filteringStateColumnExtensions = [
  {columnName: 'answer', filteringEnabled: false},
  {columnName: 'visibility', filteringEnabled: false},
];