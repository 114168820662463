import React from 'react';

const name = 'caret-bottom-icon';

const CaretBottom = () => {
  return (
    <svg className={`${name}-container`} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 8 8">
      <path className={name} d="M0 0l4 4 4-4h-8z" transform="translate(0 2)" />
    </svg>
  );
};

export default CaretBottom;