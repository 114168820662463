import { take, call, select, put } from "redux-saga/effects";

import * as immutable from "../../helpers/immutable";
import * as helpers from "../../helpers";

import * as actions from "./actions";
import { validateValue } from "./validateValue";

export function* validateAndUpdateProp() {
  while (true) {
    let {
      prop, // prop name
      event, // event from element
      value, // sometimes we can provide value manually, (e.g. when we use checkbox & want to use some value instead of true/false)
      payload, // additional payload for element model
      formSelector, // selector for whole form
      updateFormActionCreator, // action creator to update form
      onValid, // callback on valid
    } = yield take(actions.VALIDATE_AND_UPDATE_PROP);

    let form = yield select(formSelector);

    const isCheckbox = event?.target?.type === "checkbox";

    let newValue = isCheckbox
      ? value || event?.target?.checked
      : value || event?.target?.value || "";

    // in case if value represent an array of checkboxes
    if (isCheckbox && helpers.isArray(form[prop].value)) {
      newValue = event?.target?.checked
        ? immutable.pushToArray(form[prop].value, newValue)
        : immutable.removeFromArray(form[prop].value, newValue);
    }

    yield put(
      updateFormActionCreator({
        [prop]: { value: newValue, ...(payload || {}) },
      })
    );

    form = yield select(formSelector); // select updated form

    let isOk = true;
    let rules = form[prop].rules;

    if (rules?.length) {
      const result = yield call(validateValue, {
        prop: prop,
        value: newValue,
        rules: rules,
        form: form,
      });

      yield put(
        updateFormActionCreator({
          [prop]: {
            hasError: !result.isOk,
            errorMessage: result.errorMessage,
            ...(result.payload || {}),
          },
        })
      );

      isOk = result.isOk;
    }

    if (isOk && onValid) {
      yield call(onValid, newValue);
    }
  }
}
