import React from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import ErrorBlock from "./../../common/components/blocks/ErrorBlock";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as formValidatorActions from "./../../common/services/form-validator/actions";

import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

function SetPasswordForm() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: [
          "password",
          "confirmPassword",
          "bidPassword",
          "confirmBidPassword",
        ],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        onValid: () => dispatch(actions.setPassword()),
      })
    );
  };

  const setPasswordProcess = processes.setPassword || {};

  if (setPasswordProcess.isSuccessed) {
    return (
      <div className="form1">
        <p className="text-color1 text-center">
          <h5>
            <b>Bekreftelse på satt passord og budpassord</b>
          </h5>
        </p>
        <p className="text-color5 text-center mt-5">
          <h5>
            <b>
              Passordet og budpassordet ditt har blitt satt. Trykk her for å{" "}
              <NavLink to="/login" className="text-color1">
                logge inn
              </NavLink>
            </b>
          </h5>
        </p>
      </div>
    );
  }

  return (
    <Form className="SetPasswordForm form1" onSubmit={onSubmit}>
      <h1 className="text-color1">
        <b>Sett passord</b>
      </h1>
      <p className="letter-spacing-1 mt-3">Sett passordene dine</p>
      <FormGroup className="mt-5">
        <InputText
          id="password"
          label="Passord"
          type="password"
          value={form.password.value}
          onChange={(e) => validateAndUpdateProp(e, "password")}
          hasError={form.password.hasError}
          errorMessage={form.password.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="confirmPassword"
          label="Gjenta passord"
          type="password"
          value={form.confirmPassword.value}
          onChange={(e) => validateAndUpdateProp(e, "confirmPassword")}
          hasError={form.confirmPassword.hasError}
          errorMessage={form.confirmPassword.errorMessage}
        />
      </FormGroup>
      <FormGroup className="mt-5">
        <InputText
          id="bidPassword"
          label="Budpassord"
          type="password"
          value={form.bidPassword.value}
          onChange={(e) => validateAndUpdateProp(e, "bidPassword")}
          hasError={form.bidPassword.hasError}
          errorMessage={form.bidPassword.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="confirmBidPassword"
          label="Gjenta budpassord"
          type="password"
          value={form.confirmBidPassword.value}
          onChange={(e) => validateAndUpdateProp(e, "confirmBidPassword")}
          hasError={form.confirmBidPassword.hasError}
          errorMessage={form.confirmBidPassword.errorMessage}
        />
      </FormGroup>
      {setPasswordProcess.hasError ? (
        <ErrorBlock error={setPasswordProcess} />
      ) : null}
      <FormGroup className="mt-5">
        <Row>
          <Col lg="6">
            {setPasswordProcess.inProcess || setPasswordProcess.isSuccessed ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color1"
                className="text-uppercase w-100"
              >
                Send inn
              </Button>
            )}
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default SetPasswordForm;
