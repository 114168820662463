import { fork, take, put, call, select, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";

import * as sessionActions from "./../../common/redux/session/actions";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import * as mainActions from "./../../Main/redux/actions";
import request from "./../../common/services/request";
import * as immutable from "./../../common/helpers/immutable";
import * as userService from "./../../common/services/user";
import { createRootSaga } from "../../common/services/redux-saga";
import { showErrorMessage } from "../../Toaster/redux/actions";

import * as config from "./../config";
import * as actions from "./actions";
import * as selectors from "./selectors";

const FIRST_PAGE = "FIRST_PAGE";
const LOAD_MORE = "LOAD_MORE";
const LAST_PAGE = "LAST_PAGE";

function* _prepareParamsForGrid(options) {
  let params = {};

  if (options.type == FIRST_PAGE) {
    params.pageSize = config.loadBy * 2;
  } else {
    params.pageSize = config.loadBy;
  }

  let ui = yield select(selectors.getUi);

  // add page

  if (options.type == FIRST_PAGE) {
    params.currentPage = 0;
  } else if (options.type == LOAD_MORE) {
    params.currentPage = ++ui.currentPage[ui.section];
  } else if (options.type == LAST_PAGE) {
    params.currentPage = ui.currentPage[ui.section];
  }

  return params;
}

export function* loadMore() {
  let ui = yield select(selectors.getUi);
  let data = yield select(selectors.getData);

  // block handle scroll fluid
  yield put(
    actions.updateUi({
      blockHandleScrollFluid: {
        [ui.section]: true,
      },
    })
  );

  // load reserve data if possible

  if (ui.currentPage[ui.section] > ui.showUntil[ui.section]) {
    yield put(
      actions.updateUi({
        showUntil: {
          [ui.section]: ui.showUntil[ui.section] + 1,
        },
      })
    );
  }

  // load more data if possible

  if (data.tc[ui.section].rows.length >= data.tc[ui.section].totalCount) {
    return;
  }

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: LOAD_MORE });

  // do request

  let path, processName;

  if (ui.section == "all") {
    path = "WorkshopAccept/grid";
    processName = "loadMore:alltc";
  } else if (ui.section == "new") {
    path = "WorkshopAccept/updated/grid";
    processName = "loadMore:newtc";
  }

  let result = yield call(request.post, {
    path,
    processName,
    params,
  });

  if (result instanceof Error) {
    return;
  }

  // save current page

  yield put(
    actions.updateUi({
      currentPage: {
        [ui.section]: params.currentPage,
      },
    })
  );

  // save results

  result = immutable.updateObjectProps(result, {
    rows: data.tc[ui.section].rows.concat(result.rows),
  });

  yield put(
    actions.updateData({
      tc: {
        [ui.section]: result,
      },
    })
  );

  // unblock handle scroll fluid if reserve data left or more data can be fetched

  ui = yield select(selectors.getUi);

  if (
    params.currentPage > ui.showUntil[ui.section] ||
    result.rows.length < result.totalCount
  ) {
    yield put(
      actions.updateUi({
        blockHandleScrollFluid: {
          [ui.section]: false,
        },
      })
    );
  }
}

export function* getFirstPage() {
  let ui = yield select(selectors.getUi);

  // block handle scroll fluid

  yield put(
    actions.updateUi({
      blockHandleScrollFluid: {
        [ui.section]: true,
      },
    })
  );

  // wait for some time, don`t do request immediately
  // may be user still interacts with interface

  if (ui.firstRequestAccomplished[ui.section]) yield delay(1000);

  // prepare params

  let params = yield call(_prepareParamsForGrid, { type: FIRST_PAGE });

  // do request

  let path, processName;

  if (ui.section === "all") {
    path = "WorkshopAccept/grid";
    processName = "getFirstPage:alltc";
  } else if (ui.section === "new") {
    path = "WorkshopAccept/updated/grid";
    processName = "getFirstPage:newtc";
  }

  let result = yield call(request.post, {
    path,
    processName,
    params,
  });

  console.log("result");
  console.log(result);

  if (result instanceof Error) {
    return;
  }

  // save `currentPage` & `showUntil` & `firstRequestAccomplished`

  let updatedUi = {
    currentPage: {
      [ui.section]: 1,
    },
    showUntil: {
      [ui.section]: 0,
    },
  };

  if (!ui.firstRequestAccomplished[ui.section]) {
    updatedUi.firstRequestAccomplished = { [ui.section]: true };
  }

  yield put(actions.updateUi(updatedUi));

  // save results

  yield put(
    actions.updateData({
      tc: {
        [ui.section]: result,
      },
    })
  );

  // unblock handle scroll fluid

  if (result.totalCount > config.loadBy) {
    yield put(
      actions.updateUi({
        blockHandleScrollFluid: {
          [ui.section]: false,
        },
      })
    );
  }
}

export function* triggerFetchTradeConditions() {
  let lastTask;
  while (true) {
    const action = yield take([
      actions.GET_FIRST_PAGE,
      actions.LOAD_MORE,
      sessionActions.SUCCESSFUL_LOGIN,
      mainActions.MAIN_COMPONENT_DID_MOUNT,
    ]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    let session = yield select(sessionSelectors.getSession);

    if (action.type == actions.GET_FIRST_PAGE) {
      lastTask = yield fork(getFirstPage);
    } else if (action.type === actions.LOAD_MORE) {
      lastTask = yield fork(loadMore);
    } else if (
      action.type === sessionActions.SUCCESSFUL_LOGIN ||
      (action.type === mainActions.MAIN_COMPONENT_DID_MOUNT &&
        session.isAuthorized)
    ) {
      if (userService.isWorkshopUser(session)) {
        lastTask = yield fork(getFirstPage);
      }
    }
  }
}

export function* changeStatus({ companyNumber, isAccepted }) {
  let data = yield select(selectors.getData);

  // update tc

  yield put(
    actions.updateData({
      tc: {
        all:
          data.tc.all && data.tc.all.rows.length
            ? immutable.updateObjectProps(data.tc.all, {
                rows: data.tc.all.rows.map((tc) => {
                  return tc.companyNumber == companyNumber
                    ? immutable.updateObjectProps(tc, {
                        accepted: isAccepted,
                        updated: false,
                      })
                    : tc;
                }),
              })
            : data.tc.all,
        new:
          data.tc.new && data.tc.new.rows.length
            ? immutable.updateObjectProps(data.tc.new, {
                rows: data.tc.new.rows.filter(
                  (tc) => tc.companyNumber != companyNumber
                ),
              })
            : data.tc.new,
      },
    })
  );

  // do request

  let session = yield select(sessionSelectors.getSession);

  let result = yield call(request.post, {
    path: `WorkshopAccept/${companyNumber}`,
    processName: `changeTCStatus:${companyNumber}`,
    params: {
      accepted: isAccepted,
      bidToken: session.bidToken,
    },
  });

  // on error warn user, return old state and return from func

  if (result instanceof Error) {
    yield put(showErrorMessage(result.message));
    yield put(actions.updateData(data));
  }
}

export function* watchChangeStatus() {
  while (true) {
    let action = yield take(actions.CHANGE_STATUS);

    yield fork(changeStatus, action);
  }
}

export function* watchLoadTCText() {
  while (true) {
    let { companyNumber } = yield take(actions.LOAD_TC_TEXT);

    yield put(
      actions.updateUi({
        showTextModal: true,
      })
    );

    let result = yield call(request.get, {
      processName: "loadTCText",
      path: `WorkshopAccept/${companyNumber}`,
    });

    if (result instanceof Error) return result;

    yield put(
      actions.updateUi({
        companyName: result.companyName,
        tradeCond: result.tradeCond,
      })
    );
  }
}

export default createRootSaga(
  triggerFetchTradeConditions,
  watchChangeStatus,
  watchLoadTCText
);
