import React, { useState } from "react";
import { UncontrolledCollapse, CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/fontawesome-free-solid";

import "./style.css";

import classname from "./../../../../helpers/classname";

const componentName = "FaqBlockItem";
const cn = classname(componentName);

function FaqItem({ ob, ...rest }) {
  const [isOpen, setIsOpen] = useState(false);

  // querySelector method uses CSS3 selectors for querying the DOM and CSS3 doesn't support ID selectors that start with a digit
  // see: https://stackoverflow.com/a/37271406

  let id = `id${ob.id}`;

  return (
    <div className={componentName} {...rest}>
      <div id={id} className={cn("toggler")}>
        <h4>
          <FontAwesomeIcon
            className="mr-3 text-color1"
            icon={isOpen ? faMinus : faPlus}
          />
          <b>{ob.question}</b>
        </h4>
      </div>
      <UncontrolledCollapse
        toggler={`#${id}`}
        onEntering={() => setIsOpen(true)}
        onExiting={() => setIsOpen(false)}
      >
        <Card>
          <CardBody
            className="lead"
            dangerouslySetInnerHTML={{ __html: ob.answer }}
          ></CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
  );
}

export default FaqItem;
