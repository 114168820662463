import { all, call, put, take } from "redux-saga/effects";

import * as actions from "./actions";
import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

function* watchFetchLastVehicles() {
  while (true) {
    yield take(actions.FETCH_LAST_VEHICLES);

    let result = yield call(request.post, {
      path: "Estimate/grid",
      params: {
        pageSize: 3,
        currentPage: 0,
        sorting: [
          {
            columnName: "created",
            direction: "desc",
          },
        ],
      },
      processName: "fetchLastVehicles",
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ vehicles: result }));
  }
}

export default createRootSaga(watchFetchLastVehicles);
