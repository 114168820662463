import { take, put, call, all, select, cancel } from "redux-saga/effects";

import request from "./../../common/services/request";
import * as sessionActions from "./../../common/redux/session/actions";
import * as sessionService from "./../../common/services/session";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

function* watchChangePassword() {
  while (true) {
    yield take(actions.CHANGE_PASSWORD);

    let form = yield select(selectors.getChangePasswordFormValues);
    let ui = yield select(selectors.getUi);

    let result = yield call(request.post, {
      path: `Profile/${
        ui.changePasswordType === "bid" ? "changebidpassword" : "changepassword"
      }`,
      processName: "changePassword",
      params: form,
    });

    if (result instanceof Error) continue;

    // clear bidToken in session

    if (ui.changePasswordType === "bid") {
      yield put(
        sessionActions.updateSession(sessionService.prepareBidTokenUpdateOb())
      );
    }
  }
}

export default createRootSaga(watchChangePassword);
