import React, { useCallback, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import {
  RowDetailState,
  PagingState,
  SortingState,
  FilteringState,
  CustomPaging,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableRowDetail,
  PagingPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "./../../common/components/elements/Spinner";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import DateRangeFilter from "./../common/components/blocks/DateRangeFilter";
import * as gridUtils from "./../../common/helpers/grid-utils";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import { useScrollToTop, useTitle } from "../../common/hooks";

import "./style.css";
import WorkshopSignup from "./WorkshopSignup";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

const DateTypeProvider = gridUtils.DateTypeProvider;

function AdminWorkshops() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  useScrollToTop();
  useTitle("Innmeldinger");

  const updateUi = (updateOb, triggerFetchGrid) => {
    dispatch(actions.updateUi({ updateOb, triggerFetchGrid }));
  };

  const TableCellComponent = useCallback((props) => {
    return (
      <Table.Cell
        {...props}
        className="white-space-normal vertical-align-middle text-center"
      />
    );
  }, []);

  const TableRowDetailContentComponent = useCallback(({ row }) => {
    return (
      <WorkshopSignup
        id={row.id}
        workshopNumber={row.calculatedWorkshopNumber}
      />
    );
  }, []);

  const TableFilterRowCellComponent = useCallback((props) => {
    if (["created", "inProgressDate"].includes(props.column.name)) {
      return (
        <DateRangeFilter
          columnName={props.column.name}
          ui={ui}
          updateUi={updateUi}
        />
      );
    }

    return <TableFilterRow.Cell {...props} />;
  }, []);

  const onExpandedRowIdsChange = (expandedRowIds) => {
    let newExpandedRowIds = [expandedRowIds[expandedRowIds.length - 1]];
    updateUi({ expandedRowIds: newExpandedRowIds });
  };

  useEffect(function fetchGridOnMount() {
    if (!data.grid) {
      dispatch(actions.fetchGrid());
    }
  }, []);

  useEffect(function resetOnUnmount() {
    return () => {
      updateUi({ expandedRowIds: [] });
    };
  }, []);

  let isDataPopulated = data.grid;
  let fetchingProcess = processes.fetchWorkshopSignups || {};

  let columns = config.columns;

  if (ui.section === 1) {
    columns = columns.filter((col) => col.name !== "inProgressDate");
  }

  return (
    <Container fluid className="AdminWorkshopSignups">
      <Row>
        <Col xs="12">
          <AdminHeader title="Innmeldinger" />
        </Col>
        <Col xs="12" className="nav-links">
          <div
            className={["nav-link", ui.section == 1 ? "active" : ""].join(" ")}
            onClick={() => updateUi({ section: 1, expandedRowIds: [] }, true)}
          >
            Nye innmeldinger
          </div>
          <div
            className={["nav-link ml-3", ui.section == 5 ? "active" : ""].join(
              " "
            )}
            onClick={() => updateUi({ section: 5, expandedRowIds: [] }, true)}
          >
            Under behandling
          </div>
        </Col>
      </Row>
      <Row className="mb-5 mt-4">
        <Col className="grid-container">
          {isDataPopulated && !fetchingProcess.hasError && !ui.hideGrid ? (
            <Grid rows={data.grid.rows} columns={columns}>
              <DateTypeProvider
                for={["created", "inProgressDate"]}
                formatterComponent={({ column, value }) => {
                  if (!value) return "";

                  let diff = moment().diff(moment(value), "days");

                  return (
                    <span className={diff > 7 ? "text-color8" : ""}>
                      {diff > 0 ? `${diff} dager siden` : "idag"}
                    </span>
                  );
                }}
              />
              <PagingState
                currentPage={ui.currentPage}
                pageSize={ui.currentPageSize}
                onCurrentPageChange={(currentPage) =>
                  updateUi({ currentPage, expandedRowIds: [] }, true)
                }
                onPageSizeChange={(currentPageSize) =>
                  updateUi({ currentPageSize }, true)
                }
              />
              <RowDetailState
                expandedRowIds={ui.expandedRowIds}
                onExpandedRowIdsChange={onExpandedRowIdsChange}
              />
              <SortingState
                sorting={ui.sorting}
                onSortingChange={(sorting) =>
                  updateUi({ sorting, expandedRowIds: [] }, true)
                }
              />
              <FilteringState
                filters={ui.filters}
                onFiltersChange={(filters) =>
                  updateUi({ filters, expandedRowIds: [] }, true)
                }
                columnExtensions={config.filteringColumnExtensions}
              />
              <CustomPaging totalCount={data.grid.totalCount} />
              <Table
                messages={gridUtils.tableMessages}
                cellComponent={TableCellComponent}
              />
              <TableHeaderRow showSortingControls />
              <TableFilterRow
                messages={gridUtils.filterRowMessages}
                showFilterSelector
                iconComponent={gridUtils.FilterIcon}
                cellComponent={TableFilterRowCellComponent}
              />
              <TableRowDetail
                contentComponent={TableRowDetailContentComponent}
              />
              <PagingPanel
                messages={gridUtils.pagingPanelMessages}
                pageSizes={config.pageSizes}
              />
            </Grid>
          ) : null}
          {fetchingProcess.inProcess && !ui.hideGridSpinner ? (
            <div className="loading-block">
              <Spinner size="medium" />
            </div>
          ) : null}
          {fetchingProcess.hasError ? (
            <div className="text-center">
              <b className="text-danger">Det oppstod en feil</b>
              <br />
              <b>{fetchingProcess.errorMessage}</b>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default AdminWorkshops;
