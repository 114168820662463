import { take, call } from "redux-saga/effects";
import { toast } from "react-toastify";

import { createRootSaga } from "../../common/services/redux-saga";

import { SESSION_HAS_EXPIRED_MESSAGE } from "../../common/services/session";

import * as actions from "./../redux/actions";

export function* watchShowSuccessMessage() {
  while (true) {
    let { text } = yield take(actions.SHOW_SUCCESS_MESSAGE);

    yield call(toast.success, text);
  }
}

export function* watchShowErrorMessage() {
  while (true) {
    let { text } = yield take(actions.SHOW_ERROR_MESSAGE);

    if (text === SESSION_HAS_EXPIRED_MESSAGE /* hack to show success toaster if session has expired */) {
      yield call(toast.success, text);
    } else {
      yield call(toast.error, text);
    }
  }
}

export default createRootSaga(watchShowSuccessMessage, watchShowErrorMessage);
