import React, { useEffect } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";

import RegularModal from "./../RegularModal";
import * as formValidatorActions from "./../../../services/form-validator/actions";
import * as processesSelectors from "./../../../redux/processes/selectors";
import InputText from "./../../elements/InputText";
import Button from "./../../elements/Button";
import Spinner from "./../../elements/Spinner";

function BidPasswordModal({ onCancel, bodyText, onValid }) {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["bidPassword"],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        onValid: () => dispatch(actions.getBidToken(onValid)),
        disableScrolling: true,
      })
    );
  };

  useEffect(function resetFormOnUnmount() {
    return () => {
      dispatch(
        actions.updateForm({
          bidPassword: {
            value: "",
            hasError: false,
            errorMessage: "",
          },
        })
      );
    };
  }, []);

  const getBidTokenProcess = processes[processNames.GET_BID_TOKEN] || {};

  return (
    <RegularModal onCancel={onCancel} className="BidPasswordModal">
      <React.Fragment>
        <h2 className="text-color1">
          <b>Budpassord kreves</b>
        </h2>
        <p className="letter-spacing-1 mt-4">
          {bodyText || "Skriv inn passord for å fortsette"}
        </p>
        <Form className="mt-5" onSubmit={onSubmit}>
          <FormGroup>
            <InputText
              id="bidPassword"
              label="Budpassord"
              type="password"
              value={form.bidPassword.value}
              onChange={(e) => validateAndUpdateProp(e, "bidPassword")}
              hasError={form.bidPassword.hasError}
              errorMessage={form.bidPassword.errorMessage}
            />
          </FormGroup>
          {getBidTokenProcess.hasError ? (
            <FormGroup className="mt-5">
              <div className="text-center">
                <b className="text-danger">Det oppstod en feil</b>
                <br />
                <b>{getBidTokenProcess.errorMessage}</b>
              </div>
            </FormGroup>
          ) : null}
          <FormGroup className="mt-5">
            <Row className="d-flex justify-content-center">
              <Col xs="5">
                <Button
                  type="button"
                  color="color7"
                  className="w-100"
                  onClick={onCancel}
                >
                  Avbryt
                </Button>
              </Col>
              <Col xs="5">
                {getBidTokenProcess.inProcess ? (
                  <Spinner size="medium" />
                ) : (
                  <Button type="submit" color="color2" className="w-100">
                    Send inn
                  </Button>
                )}
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </React.Fragment>
    </RegularModal>
  );
}

export default BidPasswordModal;
