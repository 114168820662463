import roles from "./roles";

class Route {
  constructor({
    name,
    title,
    altTitle = title,
    path,
    isProtected = false,
    role,
  }) {
    this.name = name;
    this.title = title;
    this.altTitle = altTitle;
    this.path = path;
    this.isProtected = isProtected;
    this.role = role;
  }
  buildPath(params = {}) {
    let path = this.path;

    for (let paramName of Object.keys(params)) {
      path = path.replace(new RegExp(`:${paramName}\\??`), params[paramName]);
    }

    path = path.replace(/\/:[a-zA-Z0-9]+\??/g, "");

    return path;
  }
}

export const routes = {
  // public routes

  HOME: new Route({
    name: "HOME",
    title: "Hjem",
    path: "/home",
    isProtected: true,
  }),
  LOGIN: new Route({
    name: "LOGIN",
    title: "Logg inn",
    path: "/login",
  }),
  SINGLE_SIGN_ON_1: new Route({
    name: "SINGLE_SIGN_ON_1",
    title: "Single sign on",
    path: "/account/singlesignon/:userName/:oneTimePassword/:estimateNumber/:estimateVersion/:condemnationVersion",
  }),
  SINGLE_SIGN_ON_2: new Route({
    name: "SINGLE_SIGN_ON_2",
    title: "Single sign on",
    path: "/account/singlesignon/:userName/:oneTimePassword/:estimateId",
  }),
  SINGLE_SIGN_ON_3: new Route({
    name: "SINGLE_SIGN_ON_3",
    title: "Single sign on",
    path: "/account/singlesignon/:userName/:oneTimePassword",
  }),
  FORGOT_PASSWORD: new Route({
    name: "FORGOT_PASSWORD",
    title: "Glemt passord",
    path: "/forgot-password",
  }),
  RESET_PASSWORD: new Route({
    name: "RESET_PASSWORD",
    title: "Tilbakestill passord",
    path: "/reset-password/:userId/:code",
  }),
  SET_PASSWORD: new Route({
    name: "SET_PASSWORD",
    title: "Sett passord",
    path: "/set-password/:userId/:code",
  }),
  REGISTER: new Route({
    name: "REGISTER",
    title: "Registrer",
    path: "/register",
  }),
  CONTACT: new Route({
    name: "CONTACT",
    title: "Kontakt",
    path: "/forms/contact",
  }),
  CHANGE_CONTACT_FORMATION: new Route({
    name: "CHANGE_CONTACT_FORMATION",
    title: "Endre kontaktinformasjon",
    altTitle: "Endre kontakt-informasjon",
    path: "/forms/change-contact-information",
  }),
  COMPANY_FEEDBACK: new Route({
    name: "COMPANY_FEEDBACK",
    title: "Mangler tilbakemelding etter tilslag",
    path: "/forms/company-feedback",
  }),
  TECHNICAL_ERROR: new Route({
    name: "TECHNICAL_ERROR",
    title: "Teknisk feilmelding",
    path: "/forms/technical-error",
  }),
  FORMS: new Route({
    name: "FORMS",
    title: "Skjemaer",
    path: "/forms",
    isProtected: true,
    role: roles.BSK_ADMIN,
  }),

  // protected routes

  ABOUT: new Route({
    name: "ABOUT",
    title: "Spørsmål og svar",
    path: "/faq",
    isProtected: true,
  }),
  VEHICLE_PAGE: new Route({
    name: "VEHICLE_PAGE",
    title: "Tilbudsdetaljer",
    path: "/browse/:id",
    isProtected: true,
  }),
  BROWSE: new Route({
    name: "BROWSE",
    title: "Bla i alle tilbud",
    path: "/browse",
    isProtected: true,
  }),
  MY_BIDS: new Route({
    name: "MY_BIDS",
    title: "Mine bud",
    path: "/mybids/:type",
    isProtected: true,
  }),
  MY_SETTINGS: new Route({
    name: "MY_SETTINGS",
    title: "Brukerinnstillinger",
    path: "/mysettings",
    isProtected: true,
  }),
  MFA_SETUP: new Route({
    name: "MFA_SETUP",
    title: "Sett opp tofaktorautentisering",
    path: "/mfa/setup",
    isProtected: true,
  }),
  MFA_ENABLE: new Route({
    name: "MFA_ENABLE",
    title: "MFA Enable",
    path: "/mfa/enable",
    isProtected: true,
  }),
  MFA_DISABLE: new Route({
    name: "MFA_DISABLE",
    title: "Deaktiver tofaktorautentisering",
    path: "/mfa/disable",
    isProtected: true,
  }),
  MFA_RESET_AUTHENTICATOR_APP: new Route({
    name: "MFA_RESET_AUTHENTICATOR_APP",
    title: "Tilbakestill autentiseringsapp",
    path: "/mfa/reset-authenticator",
    isProtected: true,
  }),
  MFA_RESET_RECOVERY_CODES: new Route({
    name: "MFA_RESET_RECOVERY_CODES",
    title: "Tilbakestill gjenopprettingskoder",
    path: "/mfa/reset-recovery-codes",
    isProtected: true,
  }),
  TRADE_CONDITIONS: new Route({
    name: "TRADE_CONDITIONS",
    title: "Salgsbetingelser",
    path: "/tc/:type",
    isProtected: true,
  }),

  // admin routes

  ADMIN_ACCESS_MATRIX: new Route({
    name: "ADMIN_ACCESS_MATRIX",
    title: "Standard tilgangsmatrise",
    path: "/admin/accessmatrix",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_COMPANIES: new Route({
    name: "ADMIN_COMPANIES",
    title: "Selskaper",
    path: "/admin/companies",
    isProtected: true,
    role: roles.BSK_ADMIN,
  }),
  ADMIN_WORKSHOP_SIGNUPS: new Route({
    name: "ADMIN_WORKSHOP_SIGNUPS",
    title: "Innmeldinger",
    path: "/admin/workshopsignups",
    isProtected: true,
    role: roles.BSK_ADMIN,
  }),
  ADMIN_ESTIMATES: new Route({
    name: "ADMIN_ESTIMATES",
    title: "Tilbud",
    path: "/admin/estimates",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_WORKSHOPS: new Route({
    name: "ADMIN_WORKSHOPS",
    title: "Verksteder",
    path: "/admin/workshops",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_FAQ: new Route({
    name: "ADMIN_FAQ",
    title: "Spørsmål og svar",
    path: "/admin/faq",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_FAQ_CATEGORIES: new Route({
    name: "ADMIN_FAQ_CATEGORIES",
    title: "Kategorier",
    path: "/admin/faq/categories",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_FAQ_QUESTIONS: new Route({
    name: "ADMIN_FAQ_QUESTIONS",
    title: "Spørsmål",
    path: "/admin/faq/questions",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN_STATUSMESSAGES: new Route({
    name: "ADMIN_STATUSMESSAGES",
    title: "Statusmeldinger",
    path: "/admin/statusmessages",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
  ADMIN: new Route({
    name: "ADMIN",
    title: "Administrasjon",
    path: "/admin",
    isProtected: true,
    role: roles.COMPANY_OR_ADMIN,
  }),
};

export default routes;
