import { take, call, select } from "redux-saga/effects";

import request from "./../../../../services/request";
import { createRootSaga } from "../../../../services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export const processNames = {
  GET_BID_TOKEN: `getBidToken`,
};

function* watchGetBidToken() {
  while (true) {
    let { onValid } = yield take(actions.GET_BID_TOKEN);

    let form = yield select(selectors.getFormValues);

    let result = yield call(request.post, {
      path: "Bid/login",
      params: form,
      processName: processNames.GET_BID_TOKEN,
    });

    if (result instanceof Error) continue;

    yield call(onValid, result); // result = {token, expires, expireMinutes}
  }
}

export default createRootSaga(watchGetBidToken);
