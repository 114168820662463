export const pushToArray = (arr, value) => [...(arr || []), value];
export const removeFromArrayByIndex = (arr, index) => {
  if(index != -1){
    return [...(arr.slice(0, index) || []), ...(arr.slice(index+1) || [])];
  }

  return [...arr];
};
export const removeFromArray = (arr, value) => {
  let index = arr.indexOf(value);

  return removeFromArrayByIndex(arr, index);
};

/*
 arguments:

  (<ob>, <updateOb> [, <updateOb>, ...])

 returns:

  Object

 usage:

  <updateOb> contains values for properties that need to be updated/inserted/deleted in <ob> or previous derived object.
  for example: `updateObjectProps({a: 1, b: 2}, {a: 100, c: 3, d: 4}, {b: null, d: 400})` will return `{a: 100, c: 3, d: 400}`.

  To make `updateObjectProps` method return empty object, provide <updateOb> as null or undefined.
  for example: `updateObjectProps({a: 1, b: 2}, null)` will return `{}`.
*/

export const updateObjectProps = (...args) => {
  let ob = args[0];

  for(let i = 1; i < args.length; i++){

    let updateOb = args[i];

    // if updateOb is null or undefined return empty object

    let type = Object.prototype.toString.call(updateOb);

    if(type == '[object Undefined]' || type == '[object Null]'){
      return {};
    };

    ob = Object.assign(

      // first reducer that creates new object inheriting properties of ob
      // and ignoring properties that needs to be deleted

      Object.keys(ob).reduce(
        (wrapperOb, prop) => {

          // in fact we could write just `if(updateOb.hasOwnProperty(prop)){...}`
          // but to keep order of keys in the same way we keep properties that will be overriden

          let type = Object.prototype.toString.call(updateOb[prop]);

          if(updateOb.hasOwnProperty(prop) && (type == '[object Undefined]' || type == '[object Null]')){
            return wrapperOb;
          } else {
            wrapperOb[prop] = ob[prop];
          };

          return wrapperOb;
        },
        {}
      ),

      // second reducer that creates object and sets updated values
      // to properties that needs to be updated

      Object.keys(updateOb).reduce(
        (obWithUpdatedProps, prop) => {

          // if prop`s value is undefined ignore it
          // so this property will be deleted

          let type = Object.prototype.toString.call(updateOb[prop]);

          if(type == '[object Undefined]' || type == '[object Null]')
            return obWithUpdatedProps;

          // handle prop

          obWithUpdatedProps[prop] =
            type == '[object Object]' ?
              updateObjectProps(ob[prop] || {}, updateOb[prop]) :
              updateOb[prop];

          return obWithUpdatedProps;
        },
        {}
      )
    );
  };

  return ob;
};