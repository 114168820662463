import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import styles
import "./../common/styles/base.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datetime/css/react-datetime.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import services
import * as sessionService from "./../common/services/session";
import * as sessionSelectors from "./../common/redux/session/selectors";
import * as sessionActions from "./../common/redux/session/actions";
import * as querySelectors from "./../common/redux/query/selectors";
import * as queryActions from "./../common/redux/query/actions";

// import components
import Landing from "./../Landing";
import Main from "./../Main";
import Footer from "./Footer";
import Toaster from "./../Toaster";

function App({ history }) {
  const dispatch = useDispatch();

  const query = useSelector(querySelectors.getQuery);
  const session = useSelector(sessionSelectors.getSession);

  const clearBidToken = () => {
    dispatch(
      sessionActions.updateSession(sessionService.prepareBidTokenUpdateOb())
    );
  };

  useEffect(
    function updateQueryOnNewPage() {
      if (query.currentPath !== history.location.pathname) {
        dispatch(
          queryActions.updateQuery({
            previousPath: query.currentPath,
            currentPath: history.location.pathname,
          })
        );
      }
    },
    [history.location.pathname]
  );

  useEffect(
    function clearBidTokenOnTime() {
      if (sessionService.isBidTokenExpired(session)) {
        clearBidToken();
        return;
      }

      const timeoutId = setTimeout(
        clearBidToken,
        sessionService.getBidTokenLeftLifeTime(session)
      );

      return () => clearTimeout(timeoutId);
    },
    [sessionService.isBidTokenExpired(session), session.bidTokenExpirationTime]
  );

  return (
    <div className="App d-flex flex-column min-h-100">
      <Toaster />
      <Switch>
        <Route path="/" exact strict component={Landing} />
        <Route component={Main} />
      </Switch>
      <Footer className="flex-shrink-0" />
    </div>
  );
}

export default withRouter(App);
