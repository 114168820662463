const _prefix = "MYSETTINGS::";

export const UPDATE_UI = _prefix + "UPDATE_UI";
export const UPDATE_CHANGE_PASSWORD_FORM =
  _prefix + "UPDATE_CHANGE_PASSWORD_FORM";

export const GET_PROFILE = _prefix + "GET_PROFILE";
export const CHANGE_PASSWORD = _prefix + "CHANGE_PASSWORD";

export const updateUi = (updateOb) => ({ type: UPDATE_UI, updateOb });
export const updateChangePasswordForm = (updateOb) => ({
  type: UPDATE_CHANGE_PASSWORD_FORM,
  updateOb,
});

export const getProfile = () => ({ type: GET_PROFILE });
export const changePassword = () => ({ type: CHANGE_PASSWORD });
