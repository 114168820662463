import React from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import FavoriteButton from "./../../elements/FavoriteButton";

import "./style.css";
import * as vehicleService from "./../../../services/vehicle";
import * as immutable from "./../../../helpers/immutable";

export const types = {
  REGULAR: "regular",
  ACTIVE: "active_bid",
  EXPIRED: "expired_bid",
  WON: "won_bid",
};

const _showOptions = {
  regular: {
    image: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    description: { sizes: { lg: 5, md: 12, sm: 12, xs: 12 } },
    expires_in: { sizes: { lg: 2, md: 4, sm: 4, xs: 4 } },
    icons: { sizes: { lg: 3, md: 8, sm: 8, xs: 8 } },
  },
  active_bid: {
    image: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    description: { sizes: { lg: 5, md: 12, sm: 12, xs: 12 } },
    expires_in: { sizes: { lg: 2, md: 5, sm: 5, xs: 5 } },
    created_amount_and_time: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    menu: { sizes: { md: 1, sm: 1, xs: 1 }, buttons: [] },
  },
  expired_bid: {
    image: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    description: { sizes: { lg: 6, md: 12, sm: 12, xs: 12 } },
    expires_in: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    created_amount_and_time: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
  },
  won_bid: {
    image: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    description: { sizes: { lg: 6, md: 12, sm: 12, xs: 12 } },
    created_amount_and_time: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
    accepted_amount_and_time: { sizes: { lg: 2, md: 6, sm: 6, xs: 6 } },
  },
};

function VehicleCard({ vehicle: v, commonData, type, showOptions, bid }) {
  // normalize values

  if (!v && bid) v = bid.estimate;

  if (type) {
    showOptions = showOptions
      ? immutable.updateObjectProps(_showOptions[type], showOptions)
      : _showOptions[type];
  } else {
    showOptions = showOptions || _showOptions.regular;
  }

  // mock data

  // if(!v) v = {bidDedaline: new Date(), vehicleTypeId:1, modelYear: 2010, makeText: 'MERCEDEC', modelText: 'S900'};

  // derive data

  let deadline = new Date(v.bidDeadline);
  let diff = deadline.getTime() - new Date().getTime();
  let expirationMessage = vehicleService.getExpirationMessage(diff);
  let expirationColorClass = vehicleService.getExpirationColorClass(diff);
  let vehicleIcon = vehicleService.getIcon(v.vehicleTypeId);

  let wreckActions = (commonData && commonData.wreckActions) || [];

  let wreckAction = "";

  if (v.wreckActionId) {
    wreckAction = wreckActions.filter((w) => w.id == v.wreckActionId);
    wreckAction = wreckAction[0] ? wreckAction[0].text : "";
  }

  return (
    <Row className="VehicleCard no-gutters">
      {showOptions.hasOwnProperty("image") ? (
        type === types.EXPIRED ? (
          <Col {...showOptions.image.sizes} className="car-image">
            <img src={v.thumbnailPhotoUrl} alt="" />
          </Col>
        ) : (
          <LinkContainer
            to={`/browse/${v.id}`}
            className="text-decoration-none color-inherit"
          >
            <Col
              {...showOptions.image.sizes}
              className="car-image cursor-pointer"
            >
              <img src={v.thumbnailPhotoUrl} alt="" />
            </Col>
          </LinkContainer>
        )
      ) : null}
      {showOptions.hasOwnProperty("description") ? (
        <Col {...showOptions.description.sizes} className="car-description">
          {type === types.EXPIRED ? (
            <h5>
              <b>{`${v.modelYear} ${v.makeText} ${v.modelText}`}</b>
            </h5>
          ) : (
            <LinkContainer
              to={`/browse/${v.id}`}
              className="text-decoration-none color-inherit"
            >
              <h5 className="car-header">
                <b>{`${v.modelYear} ${v.makeText} ${v.modelText}`}</b>
              </h5>
            </LinkContainer>
          )}
          <div className="text-color5">
            Lp.nr.: <b>{v.id}</b>, {wreckAction}
            <br />
            Motor: <b>{vehicleService.prettyNumber(v.engineVolume)}</b>, Km:{" "}
            <b>{vehicleService.prettyNumber(v.milage)}</b>
          </div>
        </Col>
      ) : null}
      {showOptions.hasOwnProperty("created_amount_and_time") ? (
        <Col
          className="created-amount-and-time"
          {...showOptions.created_amount_and_time.sizes}
        >
          <div className="text-color1 text-center">
            <div>Bydd beløp:</div>
            <div>
              <b>{vehicleService.prettyNumber(bid.amount)} kr</b>
            </div>
            <div>Bydd dato:</div>
            <div>
              <b>{vehicleService.getDate(bid.created)}</b>
            </div>
          </div>
        </Col>
      ) : null}
      {showOptions.hasOwnProperty("accepted_amount_and_time") ? (
        <Col
          className="accepted-amount-and-time"
          {...showOptions.accepted_amount_and_time.sizes}
        >
          <div className="text-color13 text-center">
            <div>Akseptert beløp:</div>
            <div>
              <b>{vehicleService.prettyNumber(bid.acceptedAmount)} kr</b>
            </div>
            <div>Akseptert dato:</div>
            <div>
              <b>{vehicleService.getDate(bid.acceptedTime)}</b>
            </div>
          </div>
        </Col>
      ) : null}
      {showOptions.hasOwnProperty("expires_in") ? (
        <Col {...showOptions.expires_in.sizes} className="car-expires-in">
          <div className="mr-auto">
            {v.countyName || v.workshopPostalCode ? (
              <div>
                <b>
                  {[v.countyName, v.workshopPostalCode]
                    .filter(Boolean)
                    .join(" ")}
                </b>
              </div>
            ) : null}
            <div></div>
            <div className="text-color5">
              {type === types.EXPIRED ? "Ble avsluttet:" : "Avsluttes om:"}
            </div>
            <div className={expirationColorClass}>
              <b>
                {type === types.EXPIRED
                  ? vehicleService.getDate(v.bidDeadline)
                  : expirationMessage}
              </b>
            </div>
          </div>
        </Col>
      ) : null}
      {showOptions.hasOwnProperty("icons") ? (
        <Col
          {...showOptions.icons.sizes}
          className={[
            "car-icons",
            v.hasBids ? "justify-content-between" : "justify-content-flex-end",
          ].join(" ")}
        >
          {v.hasBids ? (
            // <FontAwesomeIcon icon={faDollarSign} className="dollar-icon text-color13"/> :
            <span className="bud-gitt">Bud gitt</span>
          ) : null}
          <div className="d-flex justify-content-flex-end">
            {vehicleIcon ? (
              <img
                src={vehicleIcon}
                className="vehicle-icon"
                alt="vehicle-icon"
              />
            ) : null}
            <FavoriteButton
              estimateId={v.id}
              isFavorite={v.isFavorite}
              className="ml-4"
            />
          </div>
        </Col>
      ) : null}
      {showOptions.hasOwnProperty("menu") && showOptions.menu !== null ? (
        <Col {...showOptions.menu.sizes} className="car-menu">
          <UncontrolledDropdown className="dropdown1">
            <DropdownToggle className="toggle-button py-3 pl-3">
              <div className="menu-button">
                <div className="menu-button-circle"></div>
                <div className="menu-button-circle"></div>
                <div className="menu-button-circle"></div>
              </div>
            </DropdownToggle>
            <DropdownMenu right>
              {showOptions.menu.buttons.map((button, i) => {
                let { className, onClick, name } = button;

                return (
                  <DropdownItem key={i} onClick={onClick}>
                    {React.createElement("div", { className }, name)}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      ) : null}
    </Row>
  );
}

export default VehicleCard;
