import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { NavLink, Redirect } from "react-router-dom";

import Spinner from "../../common/components/elements/Spinner";
import InputText from "./../../common/components/elements/InputText";
import Button from "../../common/components/elements/Button";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";
import { routes } from "../../common/config";
import * as processesSelectors from "../../common/redux/processes/selectors";
import * as formValidatorActions from "../../common/services/form-validator/actions";

import * as models from "../redux/models";
import * as actions from "../redux/actions";
import * as selectors from "../redux/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import SaveRecoveryCodes from "../SaveRecoveryCodes";

function MFAEnable() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) =>
    actions.updateUi({ verificationCodeForm: updateOb });

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getVerificationCodeForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    const props = ["code"];

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props,
        formSelector: selectors.getVerificationCodeForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.verifyAuthenticatorCode()),
      })
    );
  };

  useEffect(function resetFormOnUnmount() {
    return () => {
      dispatch(
        actions.updateUi({
          verificationCodeForm: models.ui.verificationCodeForm,
        })
      );
    };
  }, []);

  useEffect(function resetProcessOnUnmount() {
    return () => {
      dispatch(
        processesActions.resetProcess(actions.VERIFY_AUTHENTICATOR_CODE)
      );
    };
  }, []);

  const { verificationCodeForm } = ui;

  const verifyCodeProcess = processes[actions.VERIFY_AUTHENTICATOR_CODE] || {};

  if (verifyCodeProcess.isSuccessed) {
    if (verifyCodeProcess.status === 204) {
      return <Redirect to={routes.MY_SETTINGS.path} />;
    } else if (data.recoveryCodes) {
      return <SaveRecoveryCodes codes={data.recoveryCodes} />;
    }
  }

  return (
    <React.Fragment>
      <Container className="MySettings">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Aktiver tofaktorautentisering</b>
            </h1>
            <div className="form1 mt-3">
              <p className="mb-4">
                Bekreft at du fortsatt har tilgang til nøkkelen i
                autentiseringsappen ved å skrive inn autentiseringskoden
                nedenfor.
              </p>
              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <InputText
                    id={verificationCodeForm.code.id}
                    type={verificationCodeForm.code.type}
                    label={verificationCodeForm.code.label}
                    value={verificationCodeForm.code.value}
                    hasError={verificationCodeForm.code.hasError}
                    errorMessage={verificationCodeForm.code.errorMessage}
                    onChange={(e) => validateAndUpdateProp(e, "code")}
                  />
                </FormGroup>
                <Row>
                  {verifyCodeProcess.hasError ? (
                    <Col xs="12" className="mb-3">
                      <ErrorBlock error={verifyCodeProcess} />
                    </Col>
                  ) : null}
                  <Col lg="6" className="mx-auto">
                    {verifyCodeProcess.inProcess ? (
                      <Spinner size="medium" />
                    ) : (
                      <Button
                        type="submit"
                        color="color2"
                        className="text-uppercase w-100"
                      >
                        Aktiver 2FA
                      </Button>
                    )}
                  </Col>
                  <Col lg="6">
                    <NavLink to={routes.MY_SETTINGS.path}>
                      <Button color="color1" className="text-uppercase w-100">
                        Gå tilbake
                      </Button>
                    </NavLink>
                  </Col>
                </Row>
              </Form>
              <p className="mt-3">
                Hvis du har mistet enheten med autentiseringsappen eller slettet
                nøkkelen der bør du heller{" "}
                <NavLink
                  to={routes.MFA_RESET_AUTHENTICATOR_APP.path}
                  className="text-color1"
                >
                  tilbakestille autentiseringsappen
                </NavLink>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MFAEnable;
