const _prefix = 'SETPASSWORD::';

export const UPDATE_FORM = _prefix + 'UPDATE_FORM';

export const SET_PASSWORD = _prefix + 'SET_PASSWORD';

export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});

export const setPassword = () => ({type: SET_PASSWORD});

