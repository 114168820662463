import routes from '../../common/config/routes';

export const title = routes.ADMIN.title;

export const menuItems = [
  routes.ADMIN_COMPANIES,
  routes.ADMIN_WORKSHOP_SIGNUPS,
  routes.ADMIN_ESTIMATES,
  routes.ADMIN_WORKSHOPS,
  routes.ADMIN_ACCESS_MATRIX,
  routes.ADMIN_FAQ,
  routes.ADMIN_STATUSMESSAGES,
  routes.FORMS
];

export const dictionary = {
  NEW_ITEM: 'Nytt element',
  EDIT_ITEM: 'Rediger element'
};