import React from "react";
import { Container } from "reactstrap";

import "./style.scss";
import classname from "./../../../helpers/classname";
import { useScrollToTop, useTitle } from "../../../hooks";

let componentName = "Page";
let cn = classname("Page");

function Page({
  title,
  fluid = false,
  className,
  containerProps,
  withPadding = true,
  yPaddingSmall,
  children,
}) {
  useScrollToTop();

  useTitle(title || window.config.mainTitle);

  return (
    <Container
      fluid={fluid}
      className={cn.join(
        componentName,
        className,
        withPadding && cn("with-padding"),
        withPadding && yPaddingSmall && cn("y-padding-small")
      )}
      {...(containerProps || {})}
    >
      {children}
    </Container>
  );
}

export default Page;
