import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../../../helpers/immutable';

let initState = {
  senderFullName: {
    value: '',
    rules: ['required']
  },
  senderEmail: {
    value: '',
    rules: ['required', 'email']
  },
  message: {
    value: '',
    rules: ['required']
  }
};

const form = (state = initState, action) => {
  switch (action.type) {
    case actions.UPDATE_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  form
});

