import * as actions from './../redux/actions';
import {dictionary} from './../../common/config';

export const createEditModalTypes = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
};

export const mapModalTypeToConfig = {
  [createEditModalTypes.CREATE]: {
    title: dictionary.NEW_ITEM,
    onValidActionCreator: actions.createItem
  },
  [createEditModalTypes.EDIT]: {
    title: dictionary.EDIT_ITEM,
    onValidActionCreator: actions.editItem
  }
}