import React, {Component} from 'react';

import * as processesService from './../../../services/processes';

const ErrorBlock = ({className, headerMessage, error, processes}) => {
  if(processes){
    if(processesService.hasError(processes))
      error = processesService.findError(processes);
    else return null;
  }

  let {status, errorMessage, payload} = error;

  className = ['text-center', className || ''].join(' ');
  headerMessage = headerMessage || (status == 1 && !!payload.validationErrors && errorMessage) || 'Det oppstod en feil';

  return (
    <div className={className}>
      <b className="text-danger">
        {headerMessage}
      </b>
      <br/>
      {
        error.status == 1 && !!payload.validationErrors ?
          <div>
            {
              payload.validationErrors.map((ve, i) => {
                return (
                  <div key={i}>
                    <b>{ve.errorMessage}</b>
                  </div>
                )
              })
            }
          </div>
          :
          <span>
            <b>{errorMessage}</b>
          </span>
      }
    </div>
  );
};

export default ErrorBlock;