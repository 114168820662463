const _prefix = 'FORGOTPASSWORD::';

export const UPDATE_FORM = _prefix + 'UPDATE_FORM';

export const FORGOT_PASSWORD = _prefix + 'FORGOT_PASSWORD';

export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});

export const forgotPassword = () => ({type: FORGOT_PASSWORD});

