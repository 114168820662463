import { fork, take, put, call, all, select, cancel } from "redux-saga/effects";
import { delay } from "redux-saga";

import request from "./../../../../../../common/services/request";
import { createRootSaga } from "../../../../../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

function* _prepareParamsForGrid({ getBy, getById }) {
  let params = {
    sorting: [
      {
        columnName: "created",
        direction: "desc",
      },
    ],
  };

  // prepare current instance object

  let ui = yield select(selectors.getUi);
  ui = (ui[getBy] && ui[getBy][getById]) || {};

  // page size

  params.pageSize = ui.currentPageSize;

  // current page

  params.currentPage = ui.currentPage;

  // sorting

  if (ui.sorting && ui.sorting.length) params.sorting = ui.sorting;

  // filters

  params.filters = [{ columnName: getBy, value: getById }];

  if (ui.filters && ui.filters.length)
    params.filters = params.filters.concat(ui.filters);
  if (ui.customFilters && ui.customFilters.length)
    params.filters = params.filters.concat(ui.customFilters);

  return params;
}

export function* fetchBids(action) {
  let { updateOb, getBy, getById } = action;

  if (
    action.type === actions.UPDATE_UI &&
    action.updateOb[getBy][getById].filters
  ) {
    yield delay(1000);
  }

  let params = yield call(_prepareParamsForGrid, { getBy, getById });

  let result = yield call(request.post, {
    path: "Bid/grid",
    processName: `fetchBids:${getBy}:${getById}`,
    params,
  });

  if (result instanceof Error) return;

  // save results

  yield put(
    actions.updateData({
      bids: {
        [getBy]: {
          [getById]: result,
        },
      },
    })
  );
}

export function* triggerFetchBids() {
  let lastTask;

  while (true) {
    const action = yield take([actions.UPDATE_UI, actions.FETCH_BIDS]);

    if (action.type === actions.UPDATE_UI && !action.triggerFetchBids) {
      continue;
    }

    if (lastTask) {
      yield cancel(lastTask);
    }

    lastTask = yield fork(fetchBids, action);
  }
}

export default createRootSaga(triggerFetchBids);
