import {
  fork,
  take,
  put,
  call,
  all,
  select,
  cancel,
  takeLatest,
} from "redux-saga/effects";
import { delay } from "redux-saga";
import { toast } from "react-toastify";

import request from "./../../../common/services/request";
import * as immutable from "./../../../common/helpers/immutable";
import { createRootSaga } from "../../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";
import * as models from "./models";

const gridPath = "Workshop/signup/grid";

const mapStatusToSuccessMessage = {
  2: "Innmelding ble avvist og e-post sendt til verksted",
  3: "Innmelding ble avvist og e-post sendt til deg selv",
  4: "Innmelding ble avvist uten e-post",
  5: "E-post ble sendt for vurdering i selskaper",
  6: "Bruker er opprettet og e-post sendt for aktivering",
};

function* _prepareParamsForGrid() {
  let params = {};

  let ui = yield select(selectors.getUi);

  // page size

  params.pageSize = ui.currentPageSize;

  // current page

  params.currentPage = ui.currentPage;

  // sorting

  if (ui.sorting && ui.sorting.length) params.sorting = ui.sorting;

  // status

  params.status = ui.section;

  // filters

  params.filters = ui.filters || [];
  if (ui.customFilters && ui.customFilters.length) {
    params.filters = params.filters.concat(ui.customFilters);
  }

  return params;
}

export function* fetchGrid(action) {
  if (action.type === actions.UPDATE_UI && action.updateOb.filters) {
    yield delay(1000);
  }

  if (
    action.type === actions.UPDATE_UI &&
    action.updateOb.hasOwnProperty("section")
  ) {
    yield put(
      actions.updateUi({
        updateOb: { hideGrid: true },
      })
    );
  }

  if (action.hideGridSpinner) {
    yield put(
      actions.updateUi({
        updateOb: {
          hideGridSpinner: true,
        },
      })
    );
  }

  let params = yield call(_prepareParamsForGrid);

  let result = yield call(request.post, {
    path: gridPath,
    processName: "fetchWorkshopSignups",
    params,
  });

  if (result instanceof Error) return;

  // update ui if necessary

  let uiUpdateOb = {};
  let updateUi = false;

  if (action.hideGridSpinner) {
    uiUpdateOb.hideGridSpinner = false;
    updateUi = true;
  }

  if (action.resetExpandedRowIds) {
    uiUpdateOb.expandedRowIds = [];
    updateUi = true;
  }

  if (updateUi) {
    yield put(actions.updateUi({ updateOb: uiUpdateOb }));
  }

  // save grid

  result.rows = result.rows.map((row) => {
    row.calculatedWorkshopNumber = row.editedWorkshopNumber
      ? row.editedWorkshopNumber
      : row.workshopNumber;
    return row;
  });

  yield put(actions.updateData({ grid: result }));

  if (
    action.type === actions.UPDATE_UI &&
    action.updateOb.hasOwnProperty("section")
  ) {
    yield put(
      actions.updateUi({
        updateOb: { hideGrid: false },
      })
    );
  }
}

export function* triggerFetchGrid() {
  let lastTask;
  while (true) {
    const action = yield take([actions.UPDATE_UI, actions.FETCH_GRID]);

    if (action.type === actions.UPDATE_UI && !action.triggerFetchGrid) {
      continue;
    }

    if (lastTask) {
      yield cancel(lastTask);
    }

    lastTask = yield fork(fetchGrid, action);
  }
}

export function* fetchSignup({ id }) {
  let result = yield call(request.get, {
    path: `Workshop/signup/${id}`,
    processName: `fetchSignup:${id}`,
  });

  if (result instanceof Error) return;

  yield put(
    actions.updateData({
      signups: {
        [id]: result,
      },
    })
  );

  // verify workshop number & prepare workshop number prop`s object

  let workshopNumber = result.editedWorkshopNumber || result.workshopNumber;
  let workshopNumberPropObject = { value: workshopNumber };

  let verificationResult = yield call(request.get, {
    path: `Workshop/verifyUserNotExists/${workshopNumber}`,
  });

  let isFormValid = true;

  if (verificationResult instanceof Error) {
    isFormValid = false;
    workshopNumberPropObject.hasError = true;
    workshopNumberPropObject.errorMessage = verificationResult.message;
    workshopNumberPropObject.status = verificationResult.status;
  }

  yield put(
    actions.updateForm(
      immutable.updateObjectProps(models.form, {
        isFormValid,
        workshopNumber: workshopNumberPropObject,
        contactTitle: {
          value: result.editedContactTitle || result.contactTitle,
        },
        contactName: {
          value:
            result.editedContactName ||
            `${result.contactFirstName} ${result.contactSurName}`,
        },
        email: {
          value: result.editedEmail || result.email,
        },
      })
    )
  );
}

export function* watchFetchSignup() {
  while (true) {
    let action = yield take(actions.FETCH_SIGNUP);

    yield fork(fetchSignup, action);
  }
}

export function* fetchWorkshopRegister({ id, workshopNumber }) {
  let result = yield call(request.get, {
    path: `Workshop/${workshopNumber}`,
    processName: `fetchWorkshopRegister:${id}:${workshopNumber}`,
  });

  if (result instanceof Error) return;

  yield put(
    actions.updateData({
      workshopRegisters: {
        [workshopNumber]: result,
      },
    })
  );
}

export function* watchFetchWorkshopRegister() {
  while (true) {
    let action = yield take(actions.FETCH_WORKSHOP_REGISTER);

    yield fork(fetchWorkshopRegister, action);
  }
}

export function* updateSignup({ id }) {
  yield put(actions.updateUi({ updateOb: { disableAcceptButton: true } }));

  yield delay(1000);

  let form = yield select(selectors.getFormValues);

  let result = yield call(request.put, {
    path: `Workshop/signup/${id}`,
    processName: `updateSignup:${id}`,
    params: form,
  });

  if (result instanceof Error) return;

  yield put(
    actions.fetchWorkshopRegister({ id, workshopNumber: form.workshopNumber })
  );
  // Is it a more effective way to do this than fetching the whole grid
  // when only one grid-row is updated??? e.g. yield put(actions.fetchSignup(id)) ???
  yield put(actions.fetchGrid());

  yield put(
    actions.updateUi({
      updateOb: { showBlinkingMessage: true, disableAcceptButton: false },
    })
  );

  yield delay(3000);

  yield put(actions.updateUi({ updateOb: { showBlinkingMessage: false } }));
}

export function* watchUpdateSignup() {
  yield takeLatest(actions.UPDATE_SIGNUP, updateSignup);
}

export function* watchUpdateSignupStatus() {
  while (true) {
    let { id, status } = yield take(actions.UPDATE_SIGNUP_STATUS);

    let result = yield call(request.put, {
      path: `Workshop/signup/${id}/status`,
      processName: `updateSignupStatus:${id}`,
      params: {
        status,
      },
    });

    if (result instanceof Error) continue;

    // show success message

    toast.success(mapStatusToSuccessMessage[status], {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });

    // update grid without showing spinner

    yield call(fetchGrid, { hideGridSpinner: true, resetExpandedRowIds: true });
  }
}

export function* setMeAsAssigned({ id }) {
  let result = yield call(request.put, {
    path: `Workshop/signup/${id.id}/touch`,
    processName: `setMeAsAssigned:${id.id}`,
  });

  if (result instanceof Error) return;

  // update grid without showing spinner
  yield call(fetchGrid, { hideGridSpinner: true, resetExpandedRowIds: true });
}

export function* sendMeEmail({ id }) {
  let result = yield call(request.put, {
    path: `Workshop/signup/${id.id}/sendself`,
    processName: `sendMeEmail:${id.id}`,
  });

  if (result instanceof Error) return;

  // update grid without showing spinner
  yield call(fetchGrid, { hideGridSpinner: true, resetExpandedRowIds: true });
}

export function* watchSetMeAsAssigned() {
  yield takeLatest(actions.SET_ME_AS_ASSIGNED, setMeAsAssigned);
}

export function* watchSendMeEmail() {
  yield takeLatest(actions.SEND_ME_EMAIL, sendMeEmail);
}

export default createRootSaga(
  triggerFetchGrid,
  watchFetchSignup,
  watchFetchWorkshopRegister,
  watchUpdateSignup,
  watchUpdateSignupStatus,
  watchSetMeAsAssigned,
  watchSendMeEmail
);
