import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationModal from "./../../../../common/components/blocks/ConfirmationModal";

import * as actions from "./../../redux/actions";
import * as selectors from "./../../redux/selectors";

const mapStatusToConfirmationTextHtml = {
  2: (
    <div className="text-center">
      <h5 className="text-color1 text-uppercase">
        <b>Er du sikker på at du vil avvise innmeldingen?</b>
      </h5>
      <br />
      <span className="text-color8">
        <b>(Det blir sendt e-post til verksted)</b>
      </span>
    </div>
  ),
  3: (
    <div className="text-center">
      <h5 className="text-color1 text-uppercase">
        <b>Er du sikker på at du vil avvise innmeldingen?</b>
      </h5>
      <br />
      <span className="text-color8">
        <b>(Det blir sendt e-post til deg selv)</b>
      </span>
    </div>
  ),
  4: (
    <div className="text-center">
      <h5 className="text-color1 text-uppercase">
        <b>Er du sikker på at du vil avvise innmeldingen?</b>
      </h5>
      <br />
      <span className="text-color8">
        <b>(Det blir IKKE sendt e-post)</b>
      </span>
    </div>
  ),
};

function WorkshopSignupConfirmationModal() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);

  const updateUi = (updateOb) => {
    dispatch(actions.updateUi({ updateOb }));
  };

  let { show, status, id } = ui.confirmationModal;

  if (!show) {
    return null;
  }

  return (
    <ConfirmationModal
      cofirmationTextHtml={mapStatusToConfirmationTextHtml[status]}
      yesButtonText={"Ja"}
      cancelButtonText={"Avbryt"}
      onSubmit={() => {
        updateUi({ confirmationModal: { show: false } });
        dispatch(actions.updateSignupStatus({ id, status }));
      }}
      onCancel={() => updateUi({ confirmationModal: { show: false } })}
    />
  );
}

export default WorkshopSignupConfirmationModal;
