// TODO: move grid-utils to ./common/services

import React from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import { TableFilterRow } from "@devexpress/dx-react-grid-bootstrap4";

// messages

export const tableMessages = {
  noData: "Ingen data",
};

export const filterRowMessages = {
  filterPlaceholder: "Filter...",
  d_today: "I dag",
  d_week: "Uke",
  d_month: "Måned",
  d_year: "År",
  custom: "Tilpasset",
  contains: "Inneholder",
  notContains: "Inneholder ikke",
  startsWith: "Starter med",
  endsWith: "Slutter med",
  equal: "Lik",
  notEqual: "Ikke lik",
  greaterThan: "Større enn",
  greaterThanOrEqual: "Større enn eller lik",
  lessThan: "Mindre enn",
  lessThanOrEqual: "Mindre enn eller lik",
};

export const pagingPanelMessages = {
  showAll: "Alle",
  rowsPerPage: "Rader per side",
  info: "Side {from} av {to} ({count} elementer)",
};

// operation arrays

export const dateFilterOperations = [
  "d_today",
  "d_week",
  "d_month",
  "d_year",
  "custom",
];

export const booleanFilterOperations = [];

export const integerFilterOperations = [
  "equal",
  "notEqual",
  "greaterThan",
  "greaterThanOrEqual",
  "lessThan",
  "lessThanOrEqual",
];

// components

export const FilterIcon = ({ type }) => {
  if (type.substring(0, 2) === "d_") {
    return <span className="d-block oi oi-calendar" />;
  } else if (type == "custom") {
    return <span className="d-block oi oi-resize-width" />;
  }

  return <TableFilterRow.Icon type={type} />;
};

// Data Providers

// Date

const DateFormatter = ({ value }) => (value ? moment(value).format("lll") : "");

export const DateTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={DateFormatter}
    availableFilterOperations={dateFilterOperations}
    {...props}
  />
);

// Boolean

const BooleanEditor = ({ value, onValueChange }) => {
  const toggle = (event) => {
    const { checked: targetChecked } = event.target;
    onValueChange(targetChecked);
  };
  let checked = value;
  return (
    <div style={{ width: "140px", margin: "5px", textAlign: "center" }}>
      {value === undefined ? (
        <span>Filter...</span>
      ) : (
        <span
          className="link-button"
          onClick={() => {
            checked = undefined;
            onValueChange(undefined);
          }}
        >
          Tøm
        </span>
      )}
      <input
        style={{ marginLeft: "10px" }}
        //className="form-control"
        type="checkbox"
        checked={checked}
        onClick={toggle.bind(this)}
      />
    </div>
  );
};

BooleanEditor.propTypes = {
  value: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
};

BooleanEditor.defaultProps = {
  value: undefined,
};

const BooleanFormatter = ({ value }) => (value ? "Ja" : "Nei");

export const BooleanTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={BooleanFormatter}
    availableFilterOperations={booleanFilterOperations}
    editorComponent={BooleanEditor}
    {...props}
  />
);

// Integer

const IntegerEditor = ({ value, onValueChange, disabled }) => {
  let handleChange = (event) => {
    const { value: targetValue } = event.target;
    if (targetValue.trim() === "") {
      onValueChange();
      return;
    }
    onValueChange(parseInt(targetValue, 10));
  };

  return (
    <input
      className="form-control text-right"
      type="number"
      placeholder="Filter..."
      value={value === undefined ? "" : value}
      min={0}
      onChange={handleChange}
    />
  );
};

IntegerEditor.propTypes = {
  value: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

IntegerEditor.defaultProps = {
  value: undefined,
};

const IntegerFormatter = ({ value }) => value;

export const IntegerTypeProvider = (props) => (
  <DataTypeProvider
    formatterComponent={IntegerFormatter}
    availableFilterOperations={integerFilterOperations}
    editorComponent={IntegerEditor}
    {...props}
  />
);
