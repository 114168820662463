import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { NavLink, Redirect } from "react-router-dom";

import Spinner from "../../common/components/elements/Spinner";

import * as processesSelectors from "../../common/redux/processes/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import Button from "../../common/components/elements/Button";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";
import InfoCard from "../../common/components/blocks/InfoCard";
import { routes } from "../../common/config/routes";

import * as actions from "../redux/actions";

function ResetAuthenticatorForm() {
  const dispatch = useDispatch();
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function resetProcessOnUnmount() {
    return () => {
      dispatch(processesActions.resetProcess(actions.RESET_AUTHENTICATOR));
    };
  }, []);

  const resetAuthenticatorProcess =
    processes[actions.RESET_AUTHENTICATOR] || {};

  if (resetAuthenticatorProcess.isSuccessed) {
    return <Redirect to={routes.MY_SETTINGS.path} />;
  }

  return (
    <React.Fragment>
      <Container className="ResetAuthenticatorForm">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Tilbakestill autentiseringsappen</b>
            </h1>
            <div className="form1 mt-3">
              <p>
                Her kan du tilbakestille tofaktor-autentiseringsapplikasjonen.
              </p>
              <p>
                Denne prosessen fjerner tilknytning mellom brukeren din og
                nøkkelen i autentiseringsappen og deaktiverer samtidig
                tofaktor-autentisering slik at du ikke skal bli låst ute.
              </p>
              <p>
                Hvis du startet å sette opp autentiseringsappen uten å fullføre
                kan du{" "}
                <NavLink to={routes.MFA_SETUP.path} className="text-color1">
                  forsette herfra
                </NavLink>{" "}
                uten å tilbakestille nøkkelen.
              </p>
              <InfoCard
                className="mb-3"
                type={InfoCard.types.WARNING}
                title={
                  <span className="font-weight-bold">
                    Tofaktorautentisering blir deaktivert
                  </span>
                }
                message={
                  <React.Fragment>
                    Du vil ikke lenger ha ekstra
                    tofaktorautentisering-sikkerherhet før autentiseringsappen
                    har blitt satt opp på nytt. Eventuelt gammel nøkkel i
                    autentiseringsappen vil ikke lenger være gyldig og må
                    fjernes manuelt.
                  </React.Fragment>
                }
              ></InfoCard>
              <Row>
                {resetAuthenticatorProcess.hasError ? (
                  <Col xs="12" className="mb-3">
                    <ErrorBlock error={resetAuthenticatorProcess} />
                  </Col>
                ) : null}
                <Col lg="6" className="mx-auto">
                  {resetAuthenticatorProcess.inProcess ? (
                    <Spinner size="medium" />
                  ) : (
                    <Button
                      color="color8"
                      className="text-uppercase w-100"
                      onClick={() => dispatch(actions.resetAuthenticator())}
                    >
                      Tilbakestill app
                    </Button>
                  )}
                </Col>
                <Col lg="6">
                  <NavLink to={routes.MY_SETTINGS.path} className="text-color1">
                    <Button color="color1" className="w-100 text-uppercase">
                      Gå tilbake
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default ResetAuthenticatorForm;
