import { useEffect } from "react";

export function useTitle(title) {
  useEffect(() => {
    window.document.title = title || window.config.mainTitle;
  }, []);
}

export function useScrollToTop() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
}
