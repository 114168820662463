// see: https://learn.javascript.ru/metrics
// scrollTop: height of scrolled part
// offsetHeight: visible height of element
// scrollHeight: all scrollable height

const _getSum = (from, to) => {
  let sum = 0;
  for(let v = from; v <= to; v++){
    sum += v;
  }
  return sum;
};

export const getScrollTop = () => document.documentElement.scrollTop || document.body.scrollTop;
export const getClientHeight = () => document.documentElement.clientHeight;
export const getScrollHeight = () => document.documentElement.scrollHeight;

export const timeToLoadMore = () => (getScrollTop() + getClientHeight() + 500) > getScrollHeight();

export const to = (id) => {
  let elem = document.getElementById(id);

  let maxSpeed = 20;
  // minSpeed of 1px can`t be used because of buggy behaviour of window.scrollBy on resizing and zooming out of window
  let minSpeed = 2;
  let step = minSpeed, diff;

  let interval = setInterval(() => {
    let rect = elem.getBoundingClientRect();

    diff = Math.abs(rect.top);

    if(diff < _getSum(minSpeed, maxSpeed)){
      step = step > minSpeed ? --step : minSpeed;
    } else {
      step = step < maxSpeed ? ++step: maxSpeed;
    };

    window.scrollBy(0, rect.top > 0 ? step : -step);

    let scrollBarHasReachedEdge =
      // reached top
      getScrollTop() < minSpeed ||
      // reached bottom
      getScrollHeight() - Math.floor(getClientHeight() + getScrollTop()) < minSpeed;

    if(Math.abs(rect.top) < minSpeed || scrollBarHasReachedEdge) {
      clearInterval(interval);
    };
  }, 1);
};