import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import Wrapper from "./Wrapper";
import StatusMessageCard from "./StatusMessageCard";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import { processNames } from "./redux/sagas";

import * as processesSelectors from "./../../../redux/processes/selectors";
import * as sessionSelectors from "./../../../redux/session/selectors";
import classname from "./../../../helpers/classname";

const componentName = "StatusMessagesBlock";
const cn = classname(componentName);

export { previewModes } from "./config";

function StatusMessagesBlock({ show = true, isPreviewMode, previewMode }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  useEffect(
    function () {
      if (!show) {
        return;
      }

      dispatch(actions.fetchStatusMessages({ isPreviewMode, previewMode }));
    },
    [previewMode]
  );

  if (!show) {
    return null;
  }

  let fetchStatusMessageData =
    processes[processNames.FETCH_STATUSMESSAGES] || {};

  let statusMessageData = isPreviewMode
    ? data.preview[previewMode]
    : data.statusmessages;

  if (fetchStatusMessageData.hasError) {
    return null;
  }

  if (fetchStatusMessageData.inProcess || !statusMessageData) {
    return null;
  }

  return (
    <Wrapper count={statusMessageData.length}>
      <Row>
        <Col>
          {statusMessageData.map((ob) => {
            return <StatusMessageCard key={ob.id} message={ob.message} />;
          })}
        </Col>
      </Row>
    </Wrapper>
  );
}

export default StatusMessagesBlock;
