const _prefix = 'SESSION::';

export const SET_SESSION = _prefix + 'SET_SESSION';
export const UPDATE_SESSION = _prefix + 'UPDATE_SESSION';
export const UNSET_SESSION = _prefix + 'UNSET_SESSION';

export const SUCCESSFUL_LOGIN = _prefix + 'SUCCESSFUL_LOGIN';

export const setSession = (session) => ({type: SET_SESSION, session});
export const updateSession = (updateOb) => ({type: UPDATE_SESSION, updateOb});
export const unsetSession = () => ({type: UNSET_SESSION});

export const successfulLogin = () => ({type: SUCCESSFUL_LOGIN});