import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup } from "reactstrap";

import InputText from "./../../../../common/components/elements/InputText";
import * as processesSelectors from "./../../../../common/redux/processes/selectors";
import * as formValidatorActions from "./../../../../common/services/form-validator/actions";
import Spinner from "./../../../../common/components/elements/Spinner";

import * as actions from "./../../redux/actions";
import * as selectors from "./../../redux/selectors";

function WorkshopSignupForm({ id }) {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateUi = (updateOb) => {
    dispatch(actions.updateUi({ updateOb }));
  };

  const updateAndSubmit = (e, prop) => {
    dispatch(actions.updateForm({ [prop]: { value: e.target.value } }));

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["workshopNumber", "contactTitle", "contactName", "email"],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        disableScrolling: true,
        onValid: () => dispatch(actions.updateSignup(id)),
        onInvalid: () => updateUi({ showBlinkingMessage: false }),
      })
    );
  };

  let updatingSignup = processes[`updateSignup:${id}`] || {};

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <div className="d-flex mb-3">
        <h5 className="text-color1 mb-0">
          <b>Registreres i DBS-M</b>
        </h5>
        {updatingSignup.inProcess ? (
          <div className="ml-4">
            <Spinner size="medium" />
          </div>
        ) : null}
        {ui.showBlinkingMessage && !updatingSignup.inProcess ? (
          <div className="blinking-message ml-4">lagret endringer</div>
        ) : null}
      </div>
      <FormGroup className="mt-5">
        <InputText
          id="workshopNumber"
          label="Verksted nr."
          value={form.workshopNumber.value}
          onChange={(e) => updateAndSubmit(e, "workshopNumber")}
          hasError={form.workshopNumber.hasError}
          errorMessage={form.workshopNumber.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="contactTitle"
          label="Tittel"
          value={form.contactTitle.value}
          onChange={(e) => updateAndSubmit(e, "contactTitle")}
          hasError={form.contactTitle.hasError}
          errorMessage={form.contactTitle.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="contactName"
          label="Navn"
          value={form.contactName.value}
          onChange={(e) => updateAndSubmit(e, "contactName")}
          hasError={form.contactName.hasError}
          errorMessage={form.contactName.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <InputText
          id="email"
          label="E-post DBS-M"
          value={form.email.value}
          onChange={(e) => updateAndSubmit(e, "email")}
          hasError={form.email.hasError}
          errorMessage={form.email.errorMessage}
        />
      </FormGroup>
      {updatingSignup.hasError ? (
        <FormGroup className="mt-5">
          <div className="text-center">
            <b className="text-danger">Det oppstod en feil</b>
            <br />
            <b>{updatingSignup.errorMessage}</b>
          </div>
        </FormGroup>
      ) : null}
    </Form>
  );
}

export default WorkshopSignupForm;
