import { take, call, all, put } from "redux-saga/effects";

import * as mainComponentActions from "./../../../Main/redux/actions";
import request from "./../../services/request";
import { createRootSaga } from "../../services/redux-saga";

import * as actions from "./actions";

export const processNames = {
  FETCH_WRECK_ACTIONS: `COMMON_DATA::FETCH_WRECK_ACTIONS`,
  FETCH_MAKES: `COMMON_DATA::FETCH_MAKES`,
  FETCH_COUNTIES: `COMMON_DATA::FETCH_COUNTIES`,
  FETCH_VEHICLE_TYPES: `COMMON_DATA::FETCH_VEHICLE_TYPES`,
  FETCH_FAQ_CATEGORIES: `COMMON_DATA::FETCH_FAQ_CATEGORIES`,
};

export function* watchWreckActions() {
  while (true) {
    yield take([
      mainComponentActions.MAIN_COMPONENT_DID_MOUNT,
      actions.FETCH_WRECK_ACTIONS,
    ]);

    let result = yield call(request.get, {
      path: "Estimate/lists/wreckActions",
      processName: processNames.FETCH_WRECK_ACTIONS,
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ wreckActions: result }));
  }
}

export function* watchFetchMakes() {
  while (true) {
    yield take(actions.FETCH_MAKES);

    let result = yield call(request.get, {
      path: "Estimate/lists/makes",
      processName: processNames.FETCH_MAKES,
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ makes: result }));
  }
}

export function* watchFetchCounties() {
  while (true) {
    yield take(actions.FETCH_COUNTIES);

    let result = yield call(request.get, {
      path: "Estimate/lists/counties",
      processName: processNames.FETCH_COUNTIES,
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ counties: result }));
  }
}

export function* watchFetchVehicleTypes() {
  while (true) {
    yield take(actions.FETCH_VEHICLE_TYPES);

    let result = yield call(request.get, {
      path: "VehicleType",
      processName: processNames.FETCH_VEHICLE_TYPES,
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ vehicleTypes: result }));
  }
}

export function* watchFetchFaqCategories() {
  while (true) {
    yield take(actions.FETCH_FAQ_CATEGORIES);

    let result = yield call(request.get, {
      path: "faq/categories",
      processName: processNames.FETCH_FAQ_CATEGORIES,
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ faqCategories: result }));
  }
}

export default createRootSaga(
  watchWreckActions,
  watchFetchMakes,
  watchFetchCounties,
  watchFetchVehicleTypes,
  watchFetchFaqCategories
);
