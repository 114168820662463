import * as reduxService from './../../common/services/redux';

import * as actions from './actions';
import * as models from './models';

export default reduxService.createReducer(
  [
    {name: 'ui', state: models.ui, action: actions.UPDATE_UI},
    {name: 'data', state: models.data, action: actions.UPDATE_DATA},
    {name: 'filters', state: models.filters, action: actions.UPDATE_FILTERS}
  ],
  false
);

