import { Col, Container, Row } from "reactstrap";
import React from "react";
import classname from "../../../../helpers/classname";

const componentName = "FaqBlock";
const cn = classname(componentName);

const Wrapper = ({
  className,
  showBackButton,
  currentCategory,
  setCurrentCategory,
  children,
}) => {
  return (
    <Container id={componentName} className={cn.join(componentName, className)}>
      <Row className={cn("header")}>
        {!!currentCategory ? (
          <Col xs={12}>
            <h2 className={cn("header-title")}>{currentCategory.name}</h2>
          </Col>
        ) : null}
        {showBackButton ? (
          <Col xs={12}>
            <h5
              className={cn("header-back-button")}
              onClick={() => setCurrentCategory(null)}
            >
              Tilbake til emneoversikt
            </h5>
          </Col>
        ) : null}
      </Row>
      {children}
    </Container>
  );
};

export default Wrapper;
