import React from "react";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

import Button from "./../../elements/Button";
import InputText from "./../../elements/InputText";
import Spinner from "./../../elements/Spinner";
import * as processesSelectors from "./../../../redux/processes/selectors";
import * as formValidatorActions from "./../../../services/form-validator/actions";
import * as sessionSelectors from "./../../../redux/session/selectors";
import * as cn from "../../../helpers/classname";

function ContactBlock({ className }) {
  const dispatch = useDispatch();

  const session = useSelector(sessionSelectors.getSession);
  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: session.isAuthorized
          ? ["message"]
          : ["senderFullName", "senderEmail", "message"],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        onValid: () => dispatch(actions.sendMessage()),
      })
    );
  };

  const sendMessageProcess = processes["sendMessage"] || {};

  return (
    <div className={cn.join("ContactBlock", className)} id="ContactBlock">
      <Container>
        <Row>
          <Col lg="6" md="8" className="mx-auto">
            <div className="contact-block form1">
              {sendMessageProcess.isSuccessed ? (
                <h5 className="text-center">
                  <b className="text-color1">Din melding ble sendt</b>
                </h5>
              ) : (
                <React.Fragment>
                  <h1 className="text-color1 mb-5">
                    <b>Kontakt oss</b>
                  </h1>
                  <p className="text-color5 mb-5">
                    Spørsmål og evt. henvendelser om funksjoner m.v. kan sendes
                    ved å bruke kontaktskjemaet nedenfor
                  </p>
                  <Form onSubmit={onSubmit}>
                    {!session.isAuthorized ? (
                      <React.Fragment>
                        <FormGroup>
                          <InputText
                            id="senderFullName"
                            label="Ditt navn"
                            value={form.senderFullName.value}
                            onChange={(e) =>
                              validateAndUpdateProp(e, "senderFullName")
                            }
                            hasError={form.senderFullName.hasError}
                            errorMessage={form.senderFullName.errorMessage}
                          />
                        </FormGroup>
                        <FormGroup>
                          <InputText
                            id="senderEmail"
                            label="E-post"
                            value={form.senderEmail.value}
                            onChange={(e) =>
                              validateAndUpdateProp(e, "senderEmail")
                            }
                            hasError={form.senderEmail.hasError}
                            errorMessage={form.senderEmail.errorMessage}
                          />
                        </FormGroup>
                      </React.Fragment>
                    ) : null}
                    <FormGroup className="mb-0">
                      <InputText
                        id="message"
                        type="textarea"
                        label="Melding"
                        value={form.message.value}
                        onChange={(e) => validateAndUpdateProp(e, "message")}
                        hasError={form.message.hasError}
                        errorMessage={form.message.errorMessage}
                      />
                    </FormGroup>
                    <Row className="no-gutters">
                      {sendMessageProcess.hasError ? (
                        <Col xs="12" className="mt-5">
                          <div className="text-center">
                            <b className="text-danger ">
                              Kunne ikke sende melding
                            </b>
                            <br />
                            <b>{sendMessageProcess.errorMessage}</b>
                          </div>
                        </Col>
                      ) : null}
                      <Col lg="6" className="mt-5 mx-auto">
                        {sendMessageProcess.inProcess ? (
                          <Spinner size="medium" />
                        ) : (
                          <Button
                            type="submit"
                            color="color1"
                            className="text-uppercase w-100"
                          >
                            Send
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </React.Fragment>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContactBlock;
