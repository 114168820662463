import React, { useEffect, useState } from "react";

import * as vehicleService from "./../../common/services/vehicle";

function Timer({ bidDeadline }) {
  const [timeLeft, setTimeLeft] = useState(() => {
    return new Date(bidDeadline).getTime() - new Date().getTime();
  });

  useEffect(function setTimer() {
    const timer =
      timeLeft > 0
        ? setInterval(() => setTimeLeft((v) => v - 1000), 1000)
        : null;

    return () => {
      clearInterval(timer);
    };
  }, []);

  const expirationMessage = vehicleService.getFullExpirationMessage(timeLeft);
  const expirationClass = vehicleService.getExpirationColorClass(timeLeft);

  return (
    <React.Fragment>
      <span className="text-uppercase text-color5">Avsluttes om:</span>
      <br />
      <h4 className="d-inline text-color8">
        <b className={expirationClass}>{expirationMessage}</b>
      </h4>
    </React.Fragment>
  );
}

export default Timer;
