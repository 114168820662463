import React from "react";
import { NavLink } from "react-router-dom";

import Spinner from "./../../../../../common/components/elements/Spinner";

import "./style.css";

function AdminHeader({ title, spinner }) {
  return (
    <div className="AdminHeaderBlock">
      <div className="d-flex align-items-center justify-content-center">
        <h1 className="text-color1">{title}</h1>
        {spinner ? (
          <div className="ml-3">
            <Spinner size="medium" />
          </div>
        ) : null}
      </div>
      <div className="text-center">
        <NavLink to="/admin" className="text-color1">
          Tilbake til administrasjonsside
        </NavLink>
      </div>
    </div>
  );
}

export default AdminHeader;
