import { spawn } from "redux-saga/effects";

import Companies from "./Admin/Companies/redux/sagas";
import Register from "./Register/redux/sagas";
import Login from "./Login/redux/sagas";
import ForgotPassword from "./ForgotPassword/redux/sagas";
import ResetPassword from "./ResetPassword/redux/sagas";
import SetPassword from "./SetPassword/redux/sagas";
import Browse from "./Browse/redux/sagas";
import Home from "./Home/redux/sagas";
import Main from "./Main/redux/sagas";
import VehiclePage from "./VehiclePage/redux/sagas";
import MyBids from "./MyBids/redux/sagas";
import TradeConditions from "./TradeConditions/redux/sagas";
import MySettings from "./MySettings/redux/sagas";
import MFASettings from "./MFASettings/redux/sagas";
import Toaster from "./Toaster/redux/sagas";
import AccessMatrix from "./Admin/AccessMatrix/redux/sagas";
import ContactBlock from "./common/components/blocks/Contact/redux/sagas";
import ChangeContactInformation from "./Forms/ChangeContactInformation/redux/sagas";
import CompanyFeedback from "./Forms/CompanyFeedback/redux/sagas";
import TechnicalError from "./Forms/TechnicalError/redux/sagas";
import BidPasswordModal from "./common/components/blocks/BidPasswordModal/redux/sagas";
import formValidator from "./common/services/form-validator/sagas";
import FavoriteButton from "./common/components/elements/FavoriteButton/redux/sagas";
import AdminEstimates from "./Admin/Estimates/redux/sagas";
import AdminWorkshops from "./Admin/Workshops/redux/sagas";
import AdminWorkshopSignups from "./Admin/WorkshopSignups/redux/sagas";
import FaqBlock from "./common/components/blocks/Faq/redux/sagas";
import AdminFaqQuestions from "./Admin/FaqQuestions/redux/sagas";
import AdminFaqCategories from "./Admin/FaqCategories/redux/sagas";
import AdminBidsGrid from "./Admin/common/components/blocks/AdminBidsGrid/redux/sagas";
import StatusMessagesBlock from "./common/components/blocks/StatusMessages/redux/sagas";
import AdminStatusMessages from "./Admin/StatusMessages/redux/sagas";
import SingleSignOn from "./SingleSignOn/redux/sagas";
import session from "./common/redux/session/sagas";
import data from "./common/redux/data/sagas";
import profile from "./common/redux/profile/sagas";

export default function* () {
  yield spawn(Companies);
  yield spawn(Register);
  yield spawn(Login);
  yield spawn(ForgotPassword);
  yield spawn(ResetPassword);
  yield spawn(SetPassword);
  yield spawn(Browse);
  yield spawn(Home);
  yield spawn(Main);
  yield spawn(ContactBlock);
  yield spawn(ChangeContactInformation);
  yield spawn(CompanyFeedback);
  yield spawn(TechnicalError);
  yield spawn(FavoriteButton);
  yield spawn(BidPasswordModal);
  yield spawn(VehiclePage);
  yield spawn(MyBids);
  yield spawn(TradeConditions);
  yield spawn(MySettings);
  yield spawn(MFASettings);
  yield spawn(Toaster);
  yield spawn(AccessMatrix);
  yield spawn(formValidator);
  yield spawn(AdminEstimates);
  yield spawn(AdminWorkshops);
  yield spawn(AdminBidsGrid);
  yield spawn(AdminWorkshopSignups);
  yield spawn(FaqBlock);
  yield spawn(AdminFaqQuestions);
  yield spawn(AdminFaqCategories);
  yield spawn(StatusMessagesBlock);
  yield spawn(AdminStatusMessages);
  yield spawn(SingleSignOn);
  yield spawn(session);
  yield spawn(data);
  yield spawn(profile);
}
