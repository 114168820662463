import React from "react";
import { Form, FormGroup, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import RegularModal from "./../../common/components/blocks/RegularModal";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import * as processesSelectors from "./../../common/redux/processes/selectors";

import "./style.css";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

function TextModal() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const processes = useSelector(processesSelectors.getProcesses);

  const onCancel = () => {
    dispatch(actions.updateUi({ showTextModal: false }));
  };

  if (!ui.showTextModal) return null;

  let loadTCTextProcess = processes.loadTCText || {};

  return (
    <RegularModal onCancel={onCancel} className="TextModal">
      <React.Fragment>
        <h2 className="text-color1">
          <b>Salgsbetingelser for {ui.companyName}</b>
        </h2>
        {loadTCTextProcess.inProcess ? <Spinner /> : null}
        {loadTCTextProcess.isSuccessed ? (
          <div
            className="letter-spacing-1 mt-4"
            dangerouslySetInnerHTML={{ __html: ui.tradeCond }}
          ></div>
        ) : null}
        {loadTCTextProcess.hasError ? (
          <div className="text-center">
            <b className="text-danger">Det oppstod en feil</b>
            <br />
            <b>{loadTCTextProcess.errorMessage}</b>
          </div>
        ) : null}
        <Form className="mt-5" onSubmit={(e) => e.preventDefault()}>
          <FormGroup className="mt-5">
            <Row className="d-flex justify-content-center">
              <Button
                type="button"
                color="color2"
                className="px-5"
                onClick={onCancel}
              >
                OK
              </Button>
            </Row>
          </FormGroup>
        </Form>
      </React.Fragment>
    </RegularModal>
  );
}

export default TextModal;
