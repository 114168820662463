import {combineReducers} from 'redux';
import * as immutable from './../helpers/immutable';

import * as sessionActions from './../redux/session/actions';

export const simpleReducer = ({initState, updateAction}) => {
  return (state = initState, action) => {
    switch (action.type){
      case updateAction:
        return immutable.updateObjectProps(state, action.updateOb);
        break;
      default:
        return state;
    }
  }
};

export const createReducer = (descriptors, resetOnLogOut = false) => {
  let reducer;

  let descriptorsType = Object.prototype.toString.call(descriptors);

  if(descriptorsType === '[object Array]'){

    let wrapper = {};

    for(let d of descriptors)
      wrapper[d.name] = simpleReducer({initState: d.state, updateAction: d.action});

    reducer = combineReducers(wrapper);

  } else if(descriptorsType === '[object Object]'){

    reducer = simpleReducer({initState: descriptors.state, updateAction: descriptors.action});

  } else {

    alert('`descriptors` argument must be type of Object or Array');

  }

  return (state, action) => {
    if(resetOnLogOut && action.type === sessionActions.UNSET_SESSION) {
      state = undefined;
    };

    return reducer(state, action);
  };
};