import React from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu as ReactstrapDropdownMenu,
  DropdownItem as ReactstrapDropdownItem,
} from "reactstrap";

import "./style.css";
import classname from "./../../../helpers/classname";
import CaretBottomIcon from "./../../icons/CaretBottom";

const componentName = "DropdownMenu";
const cn = classname(componentName);

function DropdownMenu({ className, label, toggleButtonText, children }) {
  return (
    <div className={cn.join(componentName, className)}>
      <UncontrolledDropdown>
        <DropdownToggle className={cn("toggle-button")}>
          {label ? <span className={cn("label")}>{label}:</span> : null}
          <span>{toggleButtonText}</span>
          <CaretBottomIcon />
        </DropdownToggle>
        <ReactstrapDropdownMenu right>{children}</ReactstrapDropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export const DropdownItem = ({
  component: C = ReactstrapDropdownItem,
  children,
  ...rest
}) => {
  return <C {...rest}>{children}</C>;
};

export default DropdownMenu;
