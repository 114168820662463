const _prefix = 'TRADECONDITIONS::';

export const UPDATE_UI = _prefix + 'UPDATE_UI';
export const UPDATE_DATA = _prefix + 'UPDATE_DATA';

export const GET_FIRST_PAGE = _prefix + 'GET_FIRST_PAGE';
export const LOAD_MORE = _prefix + 'LOAD_MORE';
export const CHANGE_STATUS = _prefix + 'CHANGE_STATUS';
export const LOAD_TC_TEXT = _prefix + 'LOAD_TC_TEXT';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});

export const getFirstPage = () => ({type: GET_FIRST_PAGE});
export const loadMore = () => ({type: LOAD_MORE});
export const changeStatus = (companyNumber, isAccepted) => ({type: CHANGE_STATUS, companyNumber, isAccepted});
export const loadTCText = (companyNumber) => ({type: LOAD_TC_TEXT, companyNumber});