import React, { useState } from "react";
import { Collapse } from "reactstrap";

import "./style.css";
import ArrowDownIcon from "./../../../icons/ArrowDown";
import ArrowUpIcon from "./../../../icons/ArrowUp";

import * as cn from "../../../../helpers/classname";

function Filter({ header, isCollapsable, isCollapsedByDefault, children }) {
  const [isCollapsed, setIsCollapsed] = useState(
    isCollapsable && isCollapsedByDefault
  );

  return (
    <div className={cn.join("Filter", isCollapsable && "isCollapsable")}>
      <div
        className="filter-header"
        onClick={() => {
          if (isCollapsable) {
            setIsCollapsed((value) => !value);
          }
        }}
      >
        <h5>
          <b>{header}</b>
        </h5>
        {isCollapsable ? (
          <div className="arrow">
            {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
          </div>
        ) : null}
      </div>
      <Collapse className="filter-content-wrapper" isOpen={!isCollapsed}>
        <div className="filter-content">{children}</div>
      </Collapse>
    </div>
  );
}

export default Filter;
