export const defaultPageSize = 10;
export const pageSizes = [defaultPageSize, defaultPageSize*2, defaultPageSize*5];
export const columns = [
  {name: 'workshopNumber', title: 'Verksted nr.'},
  {name: 'name', title: 'Bedrift'},
  {name: 'contactName', title: 'Navn'},
  {name: 'postalAddress', title: 'Postadresse'},
  {name: 'postalCode', title: 'Postnr'},
  {name: 'phone', title: 'Telefon'},
  {name: 'emailMarketPlace', title: 'Epost DBSM'},
  {name: 'approvedDate', title: 'Godkjent', type: 'datetime'},
  {name: 'deletedDate', title: 'Bortmarkert', type: 'datetime'},
  {name: 'accessBlocked', title: 'BSK sperret'}  
];
export const defaultSorting =  [
  {columnName: "workshopNumber", direction: "asc"}
];
export const defaultFilters = [];
export const filteringColumnExtensions = [
  {columnName: 'approvedDate', filteringEnabled: false},
  {columnName: 'deletedDate', filteringEnabled: false},
  {columnName: 'accessBlocked', filteringEnabled: false}
];