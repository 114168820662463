export const data = {};

export const ui = {
  step: 1,
};

export const form = {
  name: {
    value: "",
    rules: ["required"],
  },
  workshopTypes: {
    value: [],
    rules: ["required"],
  },
  orgNo: {
    value: "",
    rules: ["required", { name: "length", type: "lte", size: 15 }],
  },
  contactTitle: {
    value: "",
    rules: ["required"],
  },
  workshopNumber: {
    value: "",
    rules: ["required", { name: "length", type: "lte", size: 7 }],
  },
  approvalNumber: {
    value: "",
    rules: ["required", { name: "length", type: "lte", size: 10 }],
  },
  environmentallyApproved: {
    value: true,
    rules: ["required"],
  },
  contactFirstName: {
    value: "",
    rules: ["required"],
  },
  contactSurName: {
    value: "",
    rules: ["required"],
  },
  visitingAddress: {
    value: "",
    rules: ["required"],
  },
  visitingPostalCode: {
    value: "",
    rules: ["required", "postalCode"],
    postalArea: "",
  },
  postalAddress: {
    value: "",
    rules: ["required"],
  },
  postalCode: {
    value: "",
    rules: ["required", "postalCode"],
    postalArea: "",
  },
  email: {
    value: "",
    rules: ["required", "email"],
  },
  phone: {
    value: "",
    rules: ["required", "phoneNumber"],
  },
  notes: {
    value: "",
  },
};
