import React, { useEffect } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Checkbox from "./../../common/components/elements/Checkbox";
import Spinner from "./../../common/components/elements/Spinner";
import Radio from "./../../common/components/elements/Radio";
import ErrorBlock from "./../../common/components/blocks/ErrorBlock";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as formValidatorActions from "../../common/services/form-validator/actions";

import "./style.css";
import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";

function RegisterForm() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getForm);
  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function fetchWorkshopTypes() {
    if (!!data.workshopTypes) return;

    dispatch(actions.fetchWorkshopTypes());
  }, []);

  const validateAndUpdateProp = (event, prop, value) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        value,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    let form1Props = [
      "name",
      "workshopTypes",
      "orgNo",
      "workshopNumber",
      "approvalNumber",
    ];
    let form2Props = [
      "contactTitle",
      "contactFirstName",
      "contactSurName",
      "visitingAddress",
      "visitingPostalCode",
      "postalAddress",
      "postalCode",
      "email",
      "phone",
    ];

    if (ui.step == 1) {
      dispatch(
        formValidatorActions.submitForm({
          event: e,
          props: form1Props,
          formSelector: selectors.getForm,
          updateFormActionCreator: actions.updateForm,
          onValid: () => dispatch(actions.updateUi({ step: 2 })),
        })
      );
    } else {
      dispatch(
        formValidatorActions.submitForm({
          event: e,
          props: form2Props,
          formSelector: selectors.getForm,
          updateFormActionCreator: actions.updateForm,
          onValid: () => dispatch(actions.register()),
        })
      );
    }
  };

  const fetchWorkshopTypesProcess = processes.fetchWorkshopTypes || {};
  const registerProcess = processes.register || {};

  return (
    <Form className="RegisterForm form1" onSubmit={onSubmit}>
      {fetchWorkshopTypesProcess.inProcess ? <Spinner /> : null}
      {fetchWorkshopTypesProcess.hasError ? (
        <div className="text-center">
          <b className="text-danger ">
            Kunne ikke klargjøre innmeldingsskjemaet
          </b>
          <br />
          <b>{fetchWorkshopTypesProcess.errorMessage}</b>
        </div>
      ) : null}
      {registerProcess.isSuccessed ? (
        <h5 className="text-center">
          <b className="text-color1 ">
            Verkstedinnmelding er sendt inn og vil bli behandlet i løpet av noen
            få dager.
          </b>
        </h5>
      ) : null}
      {fetchWorkshopTypesProcess.isSuccessed && !registerProcess.isSuccessed ? (
        <React.Fragment>
          {ui.step === 1 ? (
            <React.Fragment>
              <h4 className="text-color1">
                <b>Steg 1: Virksomhetsdetaljer</b>
              </h4>
              <FormGroup className="mt-5">
                <InputText
                  id="name"
                  label="Bedriftens navn"
                  value={form.name.value}
                  onChange={(e) => validateAndUpdateProp(e, "name")}
                  hasError={form.name.hasError}
                  errorMessage={form.name.errorMessage}
                />
              </FormGroup>
              <h4
                id="workshopTypes"
                className={[
                  "mt-4",
                  form.workshopTypes.hasError ? "text-color8" : "",
                ].join(" ")}
              >
                <b>Type virksomhet</b>
                {form.workshopTypes.hasError ? (
                  <span className="font-smaller text-color5">
                    {" "}
                    * {form.workshopTypes.errorMessage}
                  </span>
                ) : null}
              </h4>
              <h6>Mulige verkstedtyper (velg alle som passer):</h6>
              <Row className="form-group">
                {data.workshopTypes &&
                  data.workshopTypes.map((t) => {
                    return (
                      <Col md="6" key={t.id}>
                        <FormGroup check>
                          {
                            <Checkbox
                              label={t.text}
                              checked={
                                form.workshopTypes.value.indexOf(t.id) != -1
                              }
                              onChange={(e) =>
                                validateAndUpdateProp(e, "workshopTypes", t.id)
                              }
                            />
                          }
                        </FormGroup>
                      </Col>
                    );
                  })}
              </Row>
              <FormGroup>
                <InputText
                  id="orgNo"
                  label="Organisasjonsnr."
                  value={form.orgNo.value}
                  onChange={(e) => validateAndUpdateProp(e, "orgNo")}
                  hasError={form.orgNo.hasError}
                  errorMessage={form.orgNo.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="workshopNumber"
                  label="DBS verkstednr."
                  value={form.workshopNumber.value}
                  onChange={(e) => validateAndUpdateProp(e, "workshopNumber")}
                  hasError={form.workshopNumber.hasError}
                  errorMessage={form.workshopNumber.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="approvalNumber"
                  label="Offentl. godkj.nr."
                  value={form.approvalNumber.value}
                  onChange={(e) => validateAndUpdateProp(e, "approvalNumber")}
                  hasError={form.approvalNumber.hasError}
                  errorMessage={form.approvalNumber.errorMessage}
                />
              </FormGroup>
              <h4 className="mt-4">
                <b>Er bedriftens virksomhet godkjent av myndightene?</b>
              </h4>
              <h6>(gjelder bildemontering)</h6>
              <Row>
                <Col md="3">
                  <FormGroup check>
                    <Radio
                      label="Ja"
                      name="Godkjent av myndighetene"
                      checked={form.environmentallyApproved.value}
                      onChange={(e) => {
                        if (e.target.checked)
                          dispatch(
                            actions.updateForm({
                              environmentallyApproved: { value: true },
                            })
                          );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup check>
                    <Radio
                      label="Nei"
                      name="Godkjent av myndighetene"
                      checked={!form.environmentallyApproved.value}
                      onChange={(e) => {
                        if (e.target.checked)
                          dispatch(
                            actions.updateForm({
                              environmentallyApproved: { value: false },
                            })
                          );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          ) : null}
          {ui.step === 2 ? (
            <React.Fragment>
              <h4 className="text-color1">
                <b>Steg 2: Kontaktdetaljer</b>
              </h4>
              <FormGroup className="mt-5">
                <InputText
                  id="contactTitle"
                  label="Kontaktperson, tittel"
                  value={form.contactTitle.value}
                  onChange={(e) => validateAndUpdateProp(e, "contactTitle")}
                  hasError={form.contactTitle.hasError}
                  errorMessage={form.contactTitle.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="contactFirstName"
                  label="Kontaktperson, fornavn"
                  value={form.contactFirstName.value}
                  onChange={(e) => validateAndUpdateProp(e, "contactFirstName")}
                  hasError={form.contactFirstName.hasError}
                  errorMessage={form.contactFirstName.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="contactSurName"
                  label="Kontaktperson, etternavn"
                  value={form.contactSurName.value}
                  onChange={(e) => validateAndUpdateProp(e, "contactSurName")}
                  hasError={form.contactSurName.hasError}
                  errorMessage={form.contactSurName.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="visitingAddress"
                  label="Besøksadresse"
                  value={form.visitingAddress.value}
                  onChange={(e) => validateAndUpdateProp(e, "visitingAddress")}
                  hasError={form.visitingAddress.hasError}
                  errorMessage={form.visitingAddress.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="6">
                    <InputText
                      id="visitingPostalCode"
                      label="Besøkspostnr."
                      value={form.visitingPostalCode.value}
                      onChange={(e) =>
                        validateAndUpdateProp(e, "visitingPostalCode")
                      }
                      hasError={form.visitingPostalCode.hasError}
                      errorMessage={form.visitingPostalCode.errorMessage}
                    />
                  </Col>
                  <Col
                    xs="6"
                    className="d-flex align-items-center justify-content-center"
                  >
                    {form.visitingPostalCode.hasError ||
                    !form.visitingPostalCode.value ? (
                      <div className="text-center text-uppercase">
                        <b>Besøkspostnr.</b>
                      </div>
                    ) : (
                      <div className="text-center text-uppercase text-color1">
                        <b>{form.visitingPostalCode.postalArea}</b>
                      </div>
                    )}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <InputText
                  id="postalAddress"
                  label="Postadresse"
                  value={form.postalAddress.value}
                  onChange={(e) => validateAndUpdateProp(e, "postalAddress")}
                  hasError={form.postalAddress.hasError}
                  errorMessage={form.postalAddress.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col xs="6">
                    <InputText
                      id="postalCode"
                      label="Postnr."
                      value={form.postalCode.value}
                      onChange={(e) => validateAndUpdateProp(e, "postalCode")}
                      hasError={form.postalCode.hasError}
                      errorMessage={form.postalCode.errorMessage}
                    />
                  </Col>
                  <Col
                    xs="6"
                    className="d-flex align-items-center justify-content-center"
                  >
                    {form.postalCode.hasError || !form.postalCode.value ? (
                      <div className="text-center text-uppercase">
                        <b>Postnr.</b>
                      </div>
                    ) : (
                      <div className="text-center text-uppercase text-color1">
                        <b>{form.postalCode.postalArea}</b>
                      </div>
                    )}
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <InputText
                  id="email"
                  label="E-post"
                  value={form.email.value}
                  onChange={(e) => validateAndUpdateProp(e, "email")}
                  hasError={form.email.hasError}
                  errorMessage={form.email.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="phone"
                  label="Telefon"
                  value={form.phone.value}
                  onChange={(e) => validateAndUpdateProp(e, "phone")}
                  hasError={form.phone.hasError}
                  errorMessage={form.phone.errorMessage}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id="notes"
                  type="textarea"
                  label="Evt. bemerkninger"
                  value={form.notes.value}
                  onChange={(e) => validateAndUpdateProp(e, "notes")}
                  hasError={form.notes.hasError}
                  errorMessage={form.notes.errorMessage}
                />
              </FormGroup>
            </React.Fragment>
          ) : null}
          {registerProcess.hasError ? (
            <FormGroup className="mt-6">
              <ErrorBlock
                headerMessage="Kunne ikke foreta innmelding"
                error={registerProcess}
              />
            </FormGroup>
          ) : null}
          <FormGroup className="mt-6">
            <Row>
              <Col>
                {ui.step === 2 ? (
                  <Button
                    color="color7"
                    className="text-uppercase w-100"
                    onClick={() => dispatch(actions.updateUi({ step: 1 }))}
                  >
                    {"Forrige"}
                  </Button>
                ) : null}
              </Col>
              <Col className="text-center d-flex align-items-center flex-column justify-content-around">
                <div className="step-indicators">
                  <div
                    className={[
                      "step-indicator",
                      ui.step === 1 ? "active" : "",
                    ].join(" ")}
                  ></div>
                  <div
                    className={[
                      "step-indicator",
                      ui.step === 2 ? "active" : "",
                    ].join(" ")}
                  ></div>
                </div>
                <div className="step">{ui.step} av 2</div>
              </Col>
              <Col>
                {ui.step === 1 ? (
                  <Button
                    type="submit"
                    color="color1"
                    className="text-uppercase w-100"
                  >
                    {"Neste"}
                  </Button>
                ) : null}
                {ui.step === 2 ? (
                  registerProcess.inProcess ? (
                    <Spinner size="medium" />
                  ) : (
                    <Button
                      type="submit"
                      color="color1"
                      className="text-uppercase w-100"
                    >
                      {"Send inn"}
                    </Button>
                  )
                ) : null}
              </Col>
            </Row>
          </FormGroup>
        </React.Fragment>
      ) : null}
    </Form>
  );
}

export default RegisterForm;
