import * as filtersModels from './../../common/components/blocks/Filters/redux/models';

export const ui = {
  section: 'active',
  searchTerm: {
    active: '',
    expired: '',
    bought: ''
  },
  blockHandleScrollFluid: {
    active: false,
    expired: false,
    bought: false
  },
  firstRequestAccomplished: {
    active: false,
    expired: false,
    bought: false
  },
  currentPage: {
    active: 0,
    expired: 0,
    bought: 0
  },
  showUntil: {
    active: 0,
    expired: 0,
    bought: 0
  },
  isCancelled: false,
  showUpdateBidModal: false,
  updateBidId: null,
  nextActionObjectOnValidBidToken: null
};

export const data = {
  bids: {
    active: null,
    expired: null,
    bought: null
  }
};

export const filters = {
  active: filtersModels.filters,
  expired: filtersModels.filters,
  bought: filtersModels.filters
}

export const updateBidForm = {
  amount: {
    value: '',
    hasError: false,
    errorMessage: '',
    rules: ['required', 'number']
  }
};
