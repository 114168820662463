import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

let initFormState = {
  password: {
    value: '',
    title: 'Nytt passord',
    rules: ['required', {name: 'length', size: 10}]
  },
  confirmPassword: {
    value: '',
    title: 'Gjenta passord',
    rules: ['required', {name: 'length', size: 10}, {name: 'equal', with: 'password'}]
  }
};

const form = (state = initFormState, action) => {
  switch (action.type) {
    case actions.UPDATE_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

let initDataState = {
  minPasswordLength: 10
};

const data = (state = initDataState, action) => {
  switch (action.type) {
    case actions.UPDATE_DATA:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  form,
  data
});

