import * as actions from "./actions";
import * as immutable from "./../../helpers/immutable";

const processes = (state = {}, action) => {
  switch (action.type) {
    case actions.UPDATE:
      return immutable.updateObjectProps(state, action.updateOb);
    case actions.ADD_PROCESS:
      return {
        ...state,
        [action.processName]: {
          inProcess: true,
          isSuccessed: false,
          hasError: false,
        },
      };
    case actions.RESET_PROCESS:
      return {
        ...state,
        [action.processName]: {
          inProcess: false,
          isSuccessed: false,
          hasError: false,
        },
      };
    case actions.SET_SUCCESS:
      return {
        ...state,
        [action.processName]: {
          inProcess: false,
          isSuccessed: true,
          hasError: false,
          status: action.status,
        },
      };
    case actions.SET_ERROR:
      return {
        ...state,
        [action.processName]: {
          inProcess: false,
          isSuccessed: false,
          hasError: true,
          status: action.status,
          errorMessage: action.errorMessage,
          payload: action.payload,
        },
      };
    default:
      return state;
  }
};

export default processes;
