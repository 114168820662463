import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../../../helpers/immutable';

const initState = {
  bidPassword: {
    value: '',
    rules: ['required']
  }
};

const form = (state = initState, action) => {
  switch (action.type) {
    case actions.UPDATE_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  }
};

export default combineReducers({
  form
});

