export const ui = {
  form: {
    // workshop
    workshopNumber: {
      id: 'workshopNumber',
      label: 'Verkstednummer',
      value: '',
      hasError: false,
      errorMessage: '',
      rules: ['required']
    },
    workshopName: {
      id: 'workshopName',
      label: 'Verkstednavn',
      value: '',
      hasError: false,
      errorMessage: '',
      rules: ['required']
    },
    // sender
    senderFullName: {
      id: 'senderFullName',
      label: 'Navn',
      value: '',
      hasError: false,
      errorMessage: '',
      rules: ['required']
    },
    senderEmail: {
      id: 'senderEmail',
      label: 'E-postadresse',
      value: '',
      hasError: false,
      errorMessage: '',
      rules: ['required', 'email']
    },
    // description
    description: {
      id: 'description',
      label: 'Beskrivelse',
      type: 'textarea',
      value: '',
      hasError: false,
      errorMessage: '',
      rules: ['required']
    }
  }
};