export const visibilities = {
  HIDDEN: 'HIDDEN',
  FOR_AUTHORIZED: 'FOR_AUTHORIZED',
  FOR_UNAUTHORIZED: 'FOR_UNAUTHORIZED',
  FOR_ALL: 'FOR_ALL',
  INHERIT: 'INHERIT'
};

export const visibilitiesConfig = {
  [visibilities.HIDDEN]: {
    value: 0,
    title: 'Skjult'
  },
  [visibilities.FOR_AUTHORIZED]: {
    value: 1,
    title: 'Synlig når innlogget'
  },
  [visibilities.FOR_UNAUTHORIZED]: {
    value: 2,
    title: 'Synlig når ikke innlogget'
  },
  [visibilities.FOR_ALL]: {
    value: 3,
    title: 'Synlig både innlogget/ikke innlogget'
  },
  [visibilities.INHERIT]: {
    value: 4,
    title: 'Arve synlighet fra kategori'
  },
};

export const mapVisibilityValueToConfig = {};

