const _prefix = 'VEHICLE_PAGE::';

export const UPDATE_UI = _prefix + 'UPDATE_UI';
export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_FORM = _prefix + 'UPDATE_FORM';

export const FETCH_VEHICLE = _prefix + 'FETCH_VEHICLE';
export const PLACE_BID = _prefix + 'PLACE_BID';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});

export const fetchVehicle = (id) => ({type: FETCH_VEHICLE, id});
export const placeBid = () => ({type: PLACE_BID});