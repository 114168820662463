import React, { useEffect } from "react";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import * as processesSelectors from "../../common/redux/processes/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import * as sessionSelectors from "../../common/redux/session/selectors";
import * as formValidatorActions from "../../common/services/form-validator/actions";
import { routes } from "../../common/config/routes";
import classname from "../../common/helpers/classname";
import Page from "../../common/components/blocks/Page";
import SectionHeader from "../../common/components/blocks/SectionHeader";
import InputText from "../../common/components/elements/InputText";
import Button from "../../common/components/elements/Button";
import Spinner from "../../common/components/elements/Spinner";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";

import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import * as models from "./redux/models";
import headerBg from "../../common/imgs/road-arrows.jpg";

const componentName = "TechnicalError";
const cn = classname(componentName);

const Wrapper = ({ children }) => {
  return (
    <Page
      className={componentName}
      title={routes.TECHNICAL_ERROR.title}
      fluid={true}
      withPadding={false}
    >
      <SectionHeader
        className="mb-5"
        header={routes.TECHNICAL_ERROR.title}
        text="Vi opplever teknisk feil på forsikringsbiler.no"
        bg={headerBg}
      />
      <Container className="mb-5">
        <Row>
          <Col lg="6" md="8" className="mx-auto">
            <div className="form1">{children}</div>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

function ChangeContactInformation() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const session = useSelector(sessionSelectors.getSession);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) =>
    actions.updateUi({ form: updateOb });

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    let props = ["workshopNumber", "workshopName"];

    if (!session.isAuthorized)
      props = [...props, "senderFullName", "senderEmail"];

    props.push("description");

    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props,
        formSelector: selectors.getForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.send()),
      })
    );
  };

  useEffect(function resetOnUnmount() {
    return () => {
      dispatch(actions.updateUi(models.ui));
      dispatch(processesActions.update({ [config.processNames.SEND]: null }));
    };
  }, []);

  const { form } = ui;

  let sendProcess = processes[config.processNames.SEND] || {};

  if (sendProcess.isSuccessed) {
    return (
      <Wrapper>
        <h5 className="text-center">
          <b className="text-color1">Din melding ble sendt</b>
        </h5>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Form onSubmit={onSubmit}>
          <h1 className="text-color1 mb-5">
            <b>{routes.TECHNICAL_ERROR.title}</b>
          </h1>
          <p className="text-color5 mb-5">
            Her kan dere rapportere inn tekniske feil dersom dere opplever feil
            på forsikringsbiler.no
          </p>
          <FormGroup>
            <h6>Verksted:</h6>
          </FormGroup>
          <FormGroup>
            <InputText
              id={form.workshopNumber.id}
              label={form.workshopNumber.label}
              value={form.workshopNumber.value}
              hasError={form.workshopNumber.hasError}
              errorMessage={form.workshopNumber.errorMessage}
              onChange={(e) => validateAndUpdateProp(e, "workshopNumber")}
            />
          </FormGroup>
          <FormGroup>
            <InputText
              id={form.workshopName.id}
              label={form.workshopName.label}
              value={form.workshopName.value}
              hasError={form.workshopName.hasError}
              errorMessage={form.workshopName.errorMessage}
              onChange={(e) => validateAndUpdateProp(e, "workshopName")}
            />
          </FormGroup>
          {!session.isAuthorized ? (
            <React.Fragment>
              <FormGroup>
                <h6>Avsender:</h6>
              </FormGroup>
              <FormGroup>
                <InputText
                  id={form.senderFullName.id}
                  label={form.senderFullName.label}
                  value={form.senderFullName.value}
                  hasError={form.senderFullName.hasError}
                  errorMessage={form.senderFullName.errorMessage}
                  onChange={(e) => validateAndUpdateProp(e, "senderFullName")}
                />
              </FormGroup>
              <FormGroup>
                <InputText
                  id={form.senderEmail.id}
                  label={form.senderEmail.label}
                  value={form.senderEmail.value}
                  hasError={form.senderEmail.hasError}
                  errorMessage={form.senderEmail.errorMessage}
                  onChange={(e) => validateAndUpdateProp(e, "senderEmail")}
                />
              </FormGroup>
            </React.Fragment>
          ) : null}
          <FormGroup>
            <h6>Feil og feilsituasjon:</h6>
          </FormGroup>
          <FormGroup className="mb-0">
            <InputText
              id={form.description.id}
              label={form.description.label}
              type={form.description.type}
              value={form.description.value}
              hasError={form.description.hasError}
              errorMessage={form.description.errorMessage}
              onChange={(e) => validateAndUpdateProp(e, "description")}
            />
          </FormGroup>
          <Row className="no-gutters">
            {sendProcess.hasError ? (
              <Col xs="12" className="mt-5">
                <ErrorBlock error={sendProcess} />
              </Col>
            ) : null}
            <Col lg="6" className="mt-5 mx-auto">
              {sendProcess.inProcess ? (
                <Spinner size="medium" />
              ) : (
                <Button
                  type="submit"
                  color="color1"
                  className="text-uppercase w-100"
                >
                  Send
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </Wrapper>
    );
  }
}

export default ChangeContactInformation;
