import * as config from './../config';
import {previewModes} from './../../../common/components/blocks/Faq';

export const data = {
  grid: null
};

export const ui = {
  currentPage: 0,
  currentPageSize: config.defaultPageSize,
  sorting: [],
  filters: [],
  previewModal: {
    show: false,
    mode: previewModes.FOR_AUTHORIZED
  },
  createEditModal: {
    show: false,
    id: null,
    form: {
      id: {
        id: 'id',
        label: 'Id',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: []
      },
      name: {
        id: 'name',
        label: 'Navn',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      description: {
        id: 'description',
        label: 'Beskrivelse',
        type: 'textarea',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: []
      },
      visibility: {
        value: '', // visibility value
        payload: null // visibility object
      }
    }
  }
};