import { all, spawn, call, put } from "redux-saga/effects";

import * as helpers from "./../helpers";
import * as toasterActions from "./../../Toaster/redux/actions";

// see: https://redux-saga.js.org/docs/advanced/RootSaga.html
export const createRootSaga = (...sagas) => {
  // root saga function
  return function* () {
    yield all(
      sagas.map((saga) => {
        return spawn(function* () {
          while (true) {
            try {
              yield call(saga);
              break;
            } catch (e) {
              yield put(toasterActions.showErrorMessage(e.message));
              if (helpers.isDev) {
                console.log(e);
                break;
              }
            }
          }
        });
      })
    );
  };
};
