export const getProcesses = (state, processNames) => {
  if(!processNames) return state.processes;

  let processes = {};

  for(let processName of processNames){
    processes[processName] = state.processes[processName] || {};
  };

  return processes;
};