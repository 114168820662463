import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";

import { useScrollToTop, useTitle } from "../common/hooks";

import Form from "./Form";
import * as actions from "./redux/actions";

function ResetPassword({ match }) {
  const dispatch = useDispatch();

  useScrollToTop();

  useTitle("Tilbakestill passord");

  useEffect(function updateFormOnMount() {
    dispatch(
      actions.updateForm({
        userId: { value: match.params.userId },
        code: { value: match.params.code },
      })
    );
  }, []);

  useEffect(function fetchMinPasswordLengthOnMount() {
    dispatch(
      actions.fetchMinPasswordLength({
        userId: match.params.userId,
      })
    );
  }, []);

  return (
    <div className="ResetPassword">
      <Container>
        <Row className="justify-content-center my-5">
          <Col xs="12" sm="10" md="8" lg="5" className="mt-3">
            <Form />
          </Col>
          <div className="w-100"></div>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
