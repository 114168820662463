import { all, call, put, take, select } from "redux-saga/effects";

import * as sessionActions from "./../../common/redux/session/actions";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import * as userService from "./../../common/services/user";
import request from "./../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";

function* watchFetchLogout() {
  while (true) {
    yield take(actions.FETCH_LOGOUT);

    let session = yield select(sessionSelectors.getSession);

    let params = {
      openIdAuthorizationId: yield call(
        userService.getOpenIdAuthorizationId,
        session
      ),
    };

    let result = yield call(request.post, {
      path: "Account/logout",
      processName: "fetchLogout",
      params,
    });

    yield put(sessionActions.unsetSession());

    if (result instanceof Error) continue;
  }
}

export default createRootSaga(watchFetchLogout);
