import React from 'react';

const name = 'arrow-up-icon';

const ArrowDown = () => {
  return (
    <svg className={`${name}-container`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 8" width="100%" height="100%">
      <g className={name} transform="translate(-535.000000, -109.000000)">
        <g transform="translate(455.000000, 101.000000)">
          <g transform="translate(86.500000, 12.000000) rotate(-90.000000) translate(-86.500000, -12.000000) translate(83.000000, 6.000000)">
            <path d="M6.7,6.6l-5.3,5.2c-0.3,0.3-0.9,0.3-1.2,0c-0.3-0.3-0.3-0.9,0-1.2L4.9,6L0.2,1.4c-0.3-0.3-0.3-0.9,0-1.2
              s0.9-0.3,1.2,0l5.3,5.2C6.9,5.6,7,5.8,7,6S6.9,6.4,6.7,6.6z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;