const roles = {

  // base roles

  WORKSHOP_USER: 'WorkshopUser',
  COMPANY_USER: 'CompanyUser',
  BSK_ADMIN: 'BskAdmin',
  COMPANY_ADMIN: 'CompanyAdmin',

  // derived roles

  ADMIN: 'Admin',
  COMPANY: 'Company',
  COMPANY_OR_ADMIN: 'CompanyOrAdmin',
};

export default roles;