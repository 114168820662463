import React, { useEffect } from "react";
import { Form, FormGroup, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import "./style.css";
import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import InfoCard from "./../../common/components/blocks/InfoCard";
import * as selectors from "./../redux/selectors";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as actions from "./../redux/actions";
import * as formValidatorActions from "./../../common/services/form-validator/actions";
import * as models from "../redux/models";

function RecoveryCodeForm() {
  const dispatch = useDispatch();

  const form = useSelector(selectors.getRecoveryCodeForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const updateFormActionCreator = (updateOb) =>
    actions.updateUi({ recoveryCodeForm: updateOb });

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getRecoveryCodeForm,
        updateFormActionCreator: updateFormActionCreator,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["recoveryCode"],
        formSelector: selectors.getRecoveryCodeForm,
        updateFormActionCreator: updateFormActionCreator,
        onValid: () => dispatch(actions.login()),
      })
    );
  };

  useEffect(function resetFormOnUnmount() {
    return () => {
      dispatch(
        actions.updateUi({
          recoveryCodeForm: models.ui.recoveryCodeForm,
        })
      );
    };
  }, []);

  return (
    <Form className="RecoveryCodeForm form1" onSubmit={onSubmit}>
      <h1 className="text-color1 mb-4">
        <b>Bekreftelse av gjenopprettings- kode</b>
      </h1>
      <p>Du har bedt om å logge på med en gjenopprettingskode.</p>
      <p className="mb-4">
        Etter innlogging bør du gå inn på brukerinnstillinger og tilbakestille
        autentiseringsappen og deretter sette opp appen med ny nøkkel eller
        deaktivere tofaktorautentisering.
      </p>
      <InfoCard
        type={InfoCard.types.WARNING}
        className="mb-4"
        message="Ved bruk av gjenopprettingskode vil det sendes varsel til brukeren."
      ></InfoCard>
      <FormGroup>
        <InputText
          id="recoveryCode"
          label="Gjenopprettingskode"
          value={form.recoveryCode.value}
          onChange={(e) => validateAndUpdateProp(e, "recoveryCode")}
          hasError={form.recoveryCode.hasError}
          errorMessage={form.recoveryCode.errorMessage}
        />
      </FormGroup>
      {processes.login?.hasError ? (
        <FormGroup>
          <div className="text-center">
            <b className="text-danger ">Kunne ikke logge inn</b>
            <br />
            <b>{processes.login?.errorMessage}</b>
          </div>
        </FormGroup>
      ) : null}
      <FormGroup>
        <Row>
          <Col lg="6">
            {processes.login?.inProcess || processes.login?.isSuccessed ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color1"
                className="text-uppercase w-100"
              >
                Logg inn
              </Button>
            )}
          </Col>
          <Col lg="6">
            <button
              className="link-button text-uppercase w-100 h-100"
              onClick={() => dispatch(actions.setStep(2))}
            >
              Gå tilbake
            </button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default RecoveryCodeForm;
