import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

const initUiState = {
  showChangePasswordModal: false,
  changePasswordType: '',
  minOldPasswordLength: 5,
  minNewPasswordLength: 5
};

const ui = (state = initUiState, action) => {
  switch (action.type) {
    case actions.UPDATE_UI:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  }
};

const initChangePasswordForm = {
  oldPassword: {
    value: '',
    title: 'Gammelt passord',
    rules: ['required', {name: 'length', size: 5}] // Hack: size: 5 will be overwritten in selectors.js
  },
  newPassword: {
    value: '',
    title: 'Nytt passord',
    rules: ['required', {name: 'length', size: 5}] // Hack: size: 5 will be overwritten in selectors.js
  },
  confirmPassword: {
    value: '',
    title: 'Gjenta passord',
    rules: ['required', {name: 'length', size: 5}, {name: 'equal', with: 'newPassword'}] // Hack: size: 5 will be overwritten in selectors.js
  },
  agreement: {
    value: false,
    rules: ['required']
  }
};

const changePasswordForm = (state = initChangePasswordForm, action) => {
  switch (action.type) {
    case actions.UPDATE_CHANGE_PASSWORD_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
      break;
    default:
      return state;
  }
};

export default combineReducers({
  ui,
  changePasswordForm
});

