import { take, call, put } from "redux-saga/effects";

import request from "../../services/request";
import { createRootSaga } from "../../services/redux-saga";

import * as actions from "./actions";

function* watchGetProfile() {
  while (true) {
    yield take(actions.GET_PROFILE);

    const result = yield call(request.get, {
      path: "Profile",
      processName: actions.GET_PROFILE,
    });

    if (result instanceof Error) {
      continue;
    }

    yield put(
      actions.updateProfile({
        ...result,
      })
    );
  }
}

export default createRootSaga(watchGetProfile);
