import roles from '../config/roles';

export * from '../config/roles';

export const getUsername = (session) => {
  return session.decodedIdToken.name;
};
export const getFullName = (session) => {
  return session.decodedIdToken.fullName;
};
export const getHasChangedPassword = (session) => {
  return session.decodedIdToken.hasChangedPassword == "true";
};
export const getHasChangedBidPassword = (session) => {
  return session.decodedIdToken.hasChangedBidPassword == "true";
};
export const getEmail = (session) => {
  return session.decodedIdToken.name;
};
export const getOpenIdAuthorizationId = (session) => {
  return session.decodedIdToken.oi_au_id;
};

export const getRole = (session) => {
  return session && session.decodedIdToken && session.decodedIdToken.role;
};
export const isWorkshopUser = (session) => {
  let role = getRole(session);

  return role && role.includes(roles.WORKSHOP_USER);
};
export const isCompanyUser = (session) => {
  let role = getRole(session);

  return role && role.includes(roles.COMPANY_USER);
};
export const isBskAdmin = (session) => {
  let role = getRole(session);

  return role && role.includes(roles.BSK_ADMIN);
};
export const isCompanyAdmin = (session) => {
  let role = getRole(session);

  return role && role.includes(roles.COMPANY_ADMIN);
};
export const isAdmin = (session) => {
  return isBskAdmin(session) || isCompanyAdmin(session);
};
export const isCompany = (session) => {
  return isCompanyUser(session) || isCompanyAdmin(session);
};
export const isCompanyOrAdmin = (session) => {
  return isCompanyUser(session) || isCompanyAdmin(session) || isBskAdmin(session)
};

export const mapRolesToMethods = {
  [roles.WORKSHOP_USER]: isWorkshopUser,
  [roles.COMPANY_USER]: isCompanyUser,
  [roles.BSK_ADMIN]: isBskAdmin,
  [roles.COMPANY_ADMIN]: isCompanyAdmin,
  [roles.ADMIN]: isAdmin,
  [roles.COMPANY]: isCompany,
  [roles.COMPANY_OR_ADMIN]: isCompanyOrAdmin,
};