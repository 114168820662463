import React from "react";

import "./style.css";

import RegularModal from "./../RegularModal";
import Button from "./../../elements/Button";
import * as cn from "../../../helpers/classname";

function ConfirmationModal({
  className,
  cofirmationText,
  cofirmationTextHtml,
  yesButtonText,
  cancelButtonText,
  onSubmit,
  onCancel,
}) {
  return (
    <RegularModal
      onCancel={onCancel}
      className={cn.join("ConfirmationModal", className)}
    >
      <div>
        {cofirmationText ? (
          <div className="text-center">
            <h5 className="text-color1 text-uppercase">
              <b>{cofirmationText}</b>
            </h5>
          </div>
        ) : (
          cofirmationTextHtml
        )}
      </div>
      <div className="mt-5 d-flex justify-content-around">
        <Button color="color8" onClick={onSubmit}>
          {yesButtonText}
        </Button>
        <Button color="color1" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </div>
    </RegularModal>
  );
}

export default ConfirmationModal;
