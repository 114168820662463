import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, FormGroup } from "reactstrap";

import InputText from "./../../common/components/elements/InputText";
import Button from "./../../common/components/elements/Button";
import Spinner from "./../../common/components/elements/Spinner";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as processesActions from "./../../common/redux/processes/actions";
import * as formValidatorActions from "./../../common/services/form-validator/actions";

import * as actions from "./../redux/actions";
import * as selectors from "./../redux/selectors";
import * as models from "./../redux/models";

function BidForm() {
  const dispatch = useDispatch();

  const data = useSelector(selectors.getData);
  const form = useSelector(selectors.getForm);
  const processes = useSelector(processesSelectors.getProcesses);

  const validateAndUpdateProp = (event, prop) => {
    dispatch(
      formValidatorActions.validateAndUpdateProp({
        event,
        prop,
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
      })
    );
  };

  const onSubmit = (e) => {
    dispatch(
      formValidatorActions.submitForm({
        event: e,
        props: ["amount"],
        formSelector: selectors.getForm,
        updateFormActionCreator: actions.updateForm,
        onValid: () => dispatch(actions.placeBid()),
      })
    );
  };

  const placeAnotherBid = () => {
    dispatch(actions.updateForm(models.form));
    dispatch(processesActions.update({ placeBid: null }));
  };

  return processes.placeBid?.isSuccessed ? (
    <div className="text-center">
      <h5 className="text-center my-5">
        <b className="text-color1">Budet er lagt inn</b>
      </h5>
      <Button
        type="submit"
        color="color2"
        className="text-uppercase px-5"
        onClick={() => placeAnotherBid()}
      >
        Endre bud
      </Button>
    </div>
  ) : (
    <Form onSubmit={onSubmit}>
      <FormGroup className="mb-0">
        <InputText
          id="amount"
          label="Bud (hele kr)"
          value={form.amount.value}
          onChange={(e) => validateAndUpdateProp(e, "amount")}
          hasError={form.amount.hasError}
          errorMessage={form.amount.errorMessage}
        />
      </FormGroup>
      <FormGroup>
        <Row>
          {processes.placeBid?.hasError ? (
            <Col lg="8" className="mt-5">
              <div className="text-center">
                <b className="text-danger">Det oppstod en feil</b>
                <br />
                <b>{processes.placeBid?.errorMessage}</b>
              </div>
            </Col>
          ) : null}
          <Col lg="8" className="mt-5">
            {processes.placeBid?.inProcess ? (
              <Spinner size="medium" />
            ) : (
              <Button
                type="submit"
                color="color2"
                className="text-uppercase w-100"
              >
                {data.vehicle && data.vehicle.hasBids
                  ? "Endre bud"
                  : "Legg inn bud"}
              </Button>
            )}
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
}

export default BidForm;
