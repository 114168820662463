import { take, call, all, select, put } from "redux-saga/effects";

import request from "./../../common/services/request";
import * as processesActions from "./../../common/redux/processes/actions";
import { requestProcesses } from "../../common/services/request";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

export function* watchSetStep() {
  while (true) {
    const { nextStep } = yield take(actions.SET_STEP);

    yield put(
      processesActions.update({
        [requestProcesses.LOGIN]: {
          hasError: false,
          errorMessage: "",
          status: null,
        },
      })
    );

    yield put(actions.updateUi({ step: nextStep }));
  }
}

export function* watchLogin() {
  while (true) {
    yield take(actions.LOGIN);

    let ui = yield select(selectors.getUi);

    let { username, password, rememberMe } = yield select(
      selectors.geLoginPasswordFormValues
    );

    let { mfaCode } = yield select(selectors.getAuthenticatorCodeFormValues);
    let { recoveryCode } = yield select(selectors.getRecoveryCodeFormValues);

    const result = yield call(request.login, {
      username,
      password,
      mfaCode,
      mfaRecoveryCode: recoveryCode,
      rememberMe,
    });

    if (result instanceof Error) {
      if (
        result.message.includes("mfaCode or mfaRecoveryCode is required") &&
        ui.step === 1
      ) {
        yield put(actions.setStep(2));
      }
    }
  }
}

export default createRootSaga(watchLogin, watchSetStep);
