import React from 'react';

const name = 'arrow-right-icon';

const ArrowDown = () => {
  return (
    <svg className={`${name}-container`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 12.1"
    >
      <g className={name} transform="translate(-535.000000, -109.000000)">
        <g transform="translate(455.000000, 101.000000)">
          <g transform="translate(86.500000, 12.000000) rotate(-90.000000) translate(-86.500000, -12.000000) translate(83.000000, 6.000000)">
            <path d="M0.9,6.2l-5.2-5.3c-0.3-0.3-0.3-0.9,0-1.2c0.3-0.3,0.9-0.3,1.2,0l4.6,4.7l4.6-4.7c0.3-0.3,0.9-0.3,1.2,0
					s0.3,0.9,0,1.2L2.1,6.2C1.9,6.4,1.7,6.5,1.5,6.5S1.1,6.4,0.9,6.2z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowDown;