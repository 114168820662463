const _prefix = 'SINGLE_SIGN_ON::';

export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const HANDLE_SINGLE_SIGNON = _prefix + 'HANDLE_SINGLE_SIGNON';

export const updateUi = (updateOb) => ({type: UPDATE_UI, updateOb});

export const handleSingleSignon = ({userName, oneTimePassword, estimateNumber, estimateVersion, condemnationVersion}) => ({
  type: HANDLE_SINGLE_SIGNON,
  userName,
  oneTimePassword,
  estimateNumber,
  estimateVersion,
  condemnationVersion
});