import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";

import * as scroll from "./../../common/helpers/scroll";
import * as sessionSelectors from "./../../common/redux/session/selectors";

import "./style.css";

function Top() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((value) => !value);

  return (
    <div className="LandingTop">
      <div className="color-layer"></div>
      <Container>
        <Navbar color="faded" dark expand="lg">
          <NavbarBrand className="text-color3 mr-5">
            <h4>Forsikringsbiler</h4>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar className="justify-content-between">
            <Nav navbar>
              {[
                {
                  title: "Spørsmål og svar",
                  show: true,
                  scrollToId: "AboutBlock",
                },
              ].map((menuItem) => {
                if (!menuItem.show) return null;

                return (
                  <NavItem
                    key={menuItem.title}
                    className="mr-5"
                    onClick={() => scroll.to(menuItem.scrollToId)}
                  >
                    <NavLink className="text-color3 text-uppercase">
                      {menuItem.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <Nav navbar>
              {[
                {
                  title: "Logg inn",
                  show: true,
                  to: "/login",
                },
              ].map((menuItem) => {
                if (!menuItem.show) return null;

                return (
                  <LinkContainer key={menuItem.title} to={menuItem.to}>
                    <NavItem className="mr-5">
                      <NavLink className="text-color3 text-uppercase">
                        {menuItem.title}
                      </NavLink>
                    </NavItem>
                  </LinkContainer>
                );
              })}
            </Nav>
          </Collapse>
        </Navbar>
        <Row className="justify-content-center mt-8">
          <Col className="col-md-7 text-center">
            <h1>
              <b>Velkommen til Forsikringsbiler</b>
            </h1>
          </Col>
          <div className="w-100"></div>
          <Col lg="9">
            <p className="lead">
              Markedsplass for objekter innløst av norske forsikringsselskaper
              &mdash; kun for profesjonelle aktører
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Top;
