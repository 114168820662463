export const join = (...args) => args.filter(Boolean).join(' ');

export const concat = (...args) => args.filter(Boolean).join('-');

export const create = (prefix) => {
  let fn = (...args) => concat(prefix, ...args);

  fn.join = join;

  return fn;
};

export default create;