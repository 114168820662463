import { fork, take, put, call, all, select, cancel } from "redux-saga/effects";

import * as commonConfig from "../../../common/config";
import * as immutable from "./../../../common/helpers/immutable";
import * as commonDataSelectors from "./../../../common/redux/data/selectors";
import * as visibilityService from "./../../../common/services/visibility";
import * as toasterActions from "./../../../Toaster/redux/actions";
import { toasts } from "../../../Toaster/config";
import * as processesActions from "./../../../common/redux/processes/actions";
import request from "./../../../common/services/request";
import { createEditModalTypes } from "../CreateEditModal/config";
import { createRootSaga } from "../../../common/services/redux-saga";

import * as config from "./../config";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as models from "./models";

export const processNames = {
  FETCH_GRID: `${config.prefix}FETCH_GRID`,
  CREATE_EDIT_MODAL_PROCESS: `${config.prefix}CREATE_EDIT_MODAL_PROCESS`,
};

export function* prepareParamsForGrid() {
  let params = {};

  let ui = yield select(selectors.getUi);

  // current page + page size

  params.currentPage = ui.currentPage;
  params.pageSize = ui.currentPageSize;

  // sorting

  if (ui.sorting && ui.sorting.length) params.sorting = ui.sorting;

  // filters

  let filters = ui.filters && ui.filters.length ? ui.filters : [];

  filters.push({
    columnName: "categoryId",
    operation: "=",
    value: ui.currentCategory.id,
  });

  params.filters = filters;

  return params;
}

export function* fetchGrid({ updateOb } = {}) {
  let params = yield call(prepareParamsForGrid);

  let data = yield select(selectors.getData);

  let result = yield call(request.post, {
    path: "faq/grid",
    processName: processNames.FETCH_GRID,
    params,
  });

  let ui = yield select(selectors.getUi);

  if (!ui.isInitialFetchPerformed) {
    yield put(
      actions.updateUi({
        isInitialFetchPerformed: true,
      })
    );
  }

  if (result instanceof Error) {
    if (ui.isInitialFetchPerformed)
      yield put(toasterActions.showErrorMessage(result.message));

    return;
  }

  result.offset = ui.currentPage * ui.currentPageSize;

  yield put(
    actions.updateData({
      grid: result,
    })
  );
}

export function* triggerFetchGrid() {
  let lastTask;

  while (true) {
    const action = yield take([actions.UPDATE_UI, actions.FETCH_GRID]);

    if (action.type === actions.UPDATE_UI && !action.triggerFetchGrid) continue;

    if (lastTask) {
      yield cancel(lastTask);
    }

    lastTask = yield fork(fetchGrid, action);
  }
}

export function* watchLaunchCreateEditModal() {
  while (true) {
    let { id } = yield take(actions.LAUNCH_CREATE_EDIT_MODAL);

    let { currentCategory } = yield select(selectors.getUi);
    let { grid } = yield select(selectors.getData);

    let formUpdateOb = immutable.updateObjectProps(
      {},
      models.ui.createEditModal.form
    );

    let commonData = yield select(commonDataSelectors.getData);

    if (id) {
      let currentItem = grid.rows.filter((row) => row.id === id)[0];

      formUpdateOb = immutable.updateObjectProps(formUpdateOb, {
        category: {
          value: currentItem.categoryId,
          payload: commonData.faqCategories.filter(
            (category) => category.id === currentItem.categoryId
          )[0],
        },
        visibility: {
          value: currentItem.visibility,
          payload: visibilityService.getVisibilityConfigByValue(
            currentItem.visibility
          ),
        },
        question: {
          value: currentItem.question,
        },
        answer: {
          value: currentItem.answer,
        },
      });
    } else {
      formUpdateOb = immutable.updateObjectProps(formUpdateOb, {
        category: {
          value: currentCategory.id,
          payload: currentCategory,
        },
        visibility: {
          value:
            commonConfig.visibilitiesConfig[commonConfig.DEFAULT_VISIBILITY]
              .value,
          payload:
            commonConfig.visibilitiesConfig[commonConfig.DEFAULT_VISIBILITY],
        },
      });
    }

    yield put(
      processesActions.update({
        [processNames.CREATE_EDIT_MODAL_PROCESS]: null,
      })
    );

    yield put(
      actions.updateUi({
        createEditModal: {
          id,
          show: true,
          type: id ? createEditModalTypes.EDIT : createEditModalTypes.CREATE,
          form: formUpdateOb,
        },
      })
    );
  }
}

export function* watchCreateItem() {
  while (true) {
    let action = yield take(actions.CREATE_ITEM);

    let {
      createEditModal: { form },
    } = yield select(selectors.getUi);

    let params = {
      categoryId: form.category.value,
      visibility: form.visibility.value,
      question: form.question.value,
      answer: form.answer.value,
    };

    let result = yield call(request.post, {
      path: "faq",
      processName: processNames.CREATE_EDIT_MODAL_PROCESS,
      params,
    });

    if (result instanceof Error) continue;

    yield call(fetchGrid);

    yield put(
      actions.updateUi({
        createEditModal: { show: false },
      })
    );

    yield put(toasterActions.showSuccessMessage(toasts.ITEM_IS_CREATED));
  }
}

export function* watchEditItem() {
  while (true) {
    let action = yield take(actions.EDIT_ITEM);

    let {
      createEditModal: { id, form },
    } = yield select(selectors.getUi);

    let params = {
      visibility: form.visibility.value,
      question: form.question.value,
      answer: form.answer.value,
    };

    let result = yield call(request.put, {
      path: `faq/${id}`,
      processName: processNames.CREATE_EDIT_MODAL_PROCESS,
      params,
    });

    if (result instanceof Error) continue;

    // update item

    let { grid } = yield select(selectors.getData);

    yield put(
      actions.updateData({
        grid: {
          rows: grid.rows.map((row) => (row.id === id ? result : row)),
        },
      })
    );

    yield put(
      actions.updateUi({
        createEditModal: { show: false },
      })
    );

    yield put(toasterActions.showSuccessMessage(toasts.ITEM_IS_EDITED));
  }
}

export function* watchDeleteItem() {
  while (true) {
    let { id } = yield take(actions.DELETE_ITEM);

    let result = yield call(request.del, {
      path: `faq/${id}`,
    });

    if (result instanceof Error) {
      yield put(toasterActions.showErrorMessage(result.message));
      continue;
    }

    yield call(fetchGrid);

    yield put(toasterActions.showSuccessMessage(toasts.ITEM_IS_DELETED));
  }
}

export function* watchMoveUp() {
  while (true) {
    let { id } = yield take(actions.MOVE_UP);

    let result = yield call(request.get, {
      path: `faq/${id}/moveup`,
    });

    if (result instanceof Error) {
      yield put(toasterActions.showErrorMessage(result.message));
      continue;
    }

    yield call(fetchGrid);
  }
}

export function* watchMoveDown() {
  while (true) {
    let { id } = yield take(actions.MOVE_DOWN);

    let result = yield call(request.get, {
      path: `faq/${id}/movedown`,
    });

    if (result instanceof Error) {
      yield put(toasterActions.showErrorMessage(result.message));
      continue;
    }

    yield call(fetchGrid);
  }
}

export default createRootSaga(
  triggerFetchGrid,
  watchLaunchCreateEditModal,
  watchCreateItem,
  watchEditItem,
  watchDeleteItem,
  watchMoveUp,
  watchMoveDown
);
