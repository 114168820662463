import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { NavLink, Redirect } from "react-router-dom";

import Spinner from "../../common/components/elements/Spinner";

import * as processesSelectors from "../../common/redux/processes/selectors";
import * as processesActions from "../../common/redux/processes/actions";
import Button from "../../common/components/elements/Button";
import ErrorBlock from "../../common/components/blocks/ErrorBlock";
import InfoCard from "../../common/components/blocks/InfoCard";
import { routes } from "../../common/config/routes";

import * as actions from "../redux/actions";

function DisableMFAForm() {
  const dispatch = useDispatch();
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function resetProcessOnUnmount() {
    return () => {
      dispatch(processesActions.resetProcess(actions.DISABLE_MFA));
    };
  }, []);

  const disableMFAProcess = processes[actions.DISABLE_MFA] || {};

  if (disableMFAProcess.isSuccessed) {
    return <Redirect to={routes.MY_SETTINGS.path} />;
  }

  return (
    <React.Fragment>
      <Container className="DisableMFAForm">
        <Row className="justify-content-center my-5">
          <Col md="7">
            <h1 className="text-color1">
              <b>Deaktiver tofaktorautentisering</b>
            </h1>
            <div className="form1 mt-3">
              <p>Her kan du deaktivere tofaktorautentisering.</p>
              <p>
                Hvis du heller ønsker å endre nøkkelen som brukes i
                autentiseringsappen, bør du{" "}
                <NavLink
                  to={routes.MFA_RESET_AUTHENTICATOR_APP.path}
                  className="text-color1"
                >
                  tilbakestille autentiseringsappen
                </NavLink>{" "}
                istedenfor.
              </p>
              <InfoCard
                type={InfoCard.types.WARNING}
                className="mb-3"
                title={
                  <span className="font-weight-bold">
                    Denne handlingen kun deaktiverer tofaktorautentisering.
                  </span>
                }
                message={
                  <React.Fragment>
                    Deaktivering av tofaktorautentisering endrer ikke nøkkelen
                    som brukes i autentiseringsappen. Etter deaktivering vil du
                    ikke lenger ha ekstra tofaktorautentisering-sikkerherhet.
                  </React.Fragment>
                }
              ></InfoCard>
              <Row>
                {disableMFAProcess.hasError ? (
                  <Col xs="12" className="mb-3">
                    <ErrorBlock error={disableMFAProcess} />
                  </Col>
                ) : null}
                <Col lg="6" className="mx-auto">
                  {disableMFAProcess.inProcess ? (
                    <Spinner size="medium" />
                  ) : (
                    <Button
                      color="color8"
                      className="text-uppercase w-100"
                      onClick={() => dispatch(actions.disableMFA())}
                    >
                      Deaktiver 2FA
                    </Button>
                  )}
                </Col>
                <Col lg="6">
                  <NavLink to={routes.MY_SETTINGS.path} className="text-color1">
                    <Button color="color1" className="w-100 text-uppercase">
                      Gå tilbake
                    </Button>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default DisableMFAForm;
