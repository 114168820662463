import React from "react";
import "./style.css";

import * as cn from "../../../helpers/classname";

function CustomCheckbox({ label, className, onChange, checked }) {
  return (
    <div className={cn.join("CustomCheckbox", className)}>
      <label>
        <input
          type="checkbox"
          checked={!!checked}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
        />
        <div className="custom-checkbox"></div>
        <div className="label-text">{label}</div>
      </label>
    </div>
  );
}

export default CustomCheckbox;
