import * as actions from "./actions";
import * as models from "./models";
import * as immutable from "./../../helpers/immutable";

let initState;

try {
  initState = JSON.parse(localStorage.getItem("session"));
} catch (e) {
  console.log(e);
  alert(e.message);
}

const session = (state = initState || models.session, action) => {
  switch (action.type) {
    case actions.SET_SESSION:
      return action.session;
    case actions.UPDATE_SESSION:
      return immutable.updateObjectProps(state, action.updateOb);
    case actions.UNSET_SESSION:
      return models.session;
    default:
      return state;
  }
};

export default session;
