import { take, put, call, select } from "redux-saga/effects";

import request from "./../../common/services/request";
import * as sessionService from "./../../common/services/session";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import { createRootSaga } from "../../common/services/redux-saga";

import * as actions from "./actions";
import * as selectors from "./selectors";

function* watchFetchVehiclePage() {
  while (true) {
    let action = yield take(actions.FETCH_VEHICLE);

    let result = yield call(request.get, {
      path: `Estimate/${action.id}`,
      processName: "fetchVehicle",
    });

    if (result instanceof Error) continue;

    yield put(actions.updateData({ vehicle: result }));
  }
}

function* watchPlaceBid() {
  while (true) {
    let action = yield take(actions.PLACE_BID);

    let session = yield select(sessionSelectors.getSession);

    if (sessionService.isBidTokenExpired(session)) {
      yield put(
        actions.updateUi({
          updateBidToken: true,
          nextActionObjectOnValidBidToken: actions.placeBid(),
        })
      );

      continue;
    }

    let { amount } = yield select(selectors.getFormValues);
    let data = yield select(selectors.getData);

    let result = yield call(request.post, {
      path: `Bid/workshop`,
      params: {
        amount,
        bidToken: session.bidToken,
        estimateId: data.vehicle.id,
      },
      processName: "placeBid",
    });
  }
}

export default createRootSaga(watchFetchVehiclePage, watchPlaceBid);
