import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Filters from "./../common/components/blocks/Filters";
import VehicleCard from "./../common/components/blocks/VehicleCard";
import SearchInput from "./../common/components/elements/SearchInput";
import Spinner from "./../common/components/elements/Spinner";
import BidPasswordModal from "./../common/components/blocks/BidPasswordModal";
import * as processesSelectors from "./../common/redux/processes/selectors";
import * as scroll from "./../common/helpers/scroll";
import * as sessionService from "./../common/services/session";
import * as sessionSelectors from "./../common/redux/session/selectors";
import * as sessionActions from "./../common/redux/session/actions";
import * as querySelectors from "./../common/redux/query/selectors";

import "./style.css";
import UpdateBidModal from "./UpdateBidModal";
import * as selectors from "./redux/selectors";
import * as actions from "./redux/actions";
import * as models from "./redux/models";
import * as config from "./config";
import { useScrollToTop, useTitle } from "../common/hooks";
import { useDispatch, useSelector } from "react-redux";

function MyBids({ history, match }) {
  const [previousPath, setPreviousPath] = useState("");

  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const filters = useSelector(selectors.getFilters);
  const query = useSelector(querySelectors.getQuery);
  const session = useSelector(sessionSelectors.getSession);
  const processes = useSelector(processesSelectors.getProcesses);

  useScrollToTop();

  useTitle("Mine Bud");

  const getShowOptions = (bid) => {
    if (new Date(bid.estimate.bidDeadline) <= new Date()) {
      return {
        menu: null,
      };
    }

    return {
      menu: {
        buttons: [
          {
            className: "text-center",
            name: "Endre bud",
            onClick: () => {
              dispatch(
                actions.updateUi({
                  showUpdateBidModal: true,
                  updateBidId: bid.id,
                })
              );
            },
          },
          {
            className: "text-color8 text-center",
            name: "Kanseller bud",
            onClick: () => {
              dispatch(actions.deleteBid(bid.id));
            },
          },
        ],
      },
    };
  };

  useEffect(function setPreviousPathOnMount() {
    setPreviousPath(query.currentPath);
  }, []);

  useEffect(
    function setSectionAndGetPage() {
      let type = match.params.type;

      if (ui.section !== type) {
        dispatch(actions.updateUi({ section: type }));
      }

      if (!ui.firstRequestAccomplished[type]) {
        dispatch(actions.getFirstPage());
      }
    },
    [match.params.type]
  );

  useEffect(
    function setScrollHandler() {
      const handleScroll = () => {
        if (
          ui.blockHandleScrollFluid[ui.section] ||
          ui.showUpdateBidModal ||
          sessionService.isBidTokenExpired(session)
        )
          return;

        if (scroll.timeToLoadMore()) dispatch(actions.loadMore());
      };

      document.addEventListener("scroll", handleScroll);

      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    },
    [ui.blockHandleScrollFluid, ui.section, ui.showUpdateBidModal, session]
  );

  useEffect(function resetOnUnmount() {
    return () => {
      dispatch(actions.updateUi(models.ui));
      dispatch(actions.updateData(models.data));
    };
  }, []);

  // if wrong section provided or bid password modal is cancelled redirect user to another page

  if (ui.isCancelled) return <Redirect to={previousPath || "/"} />;

  if (!["active", "expired", "bought"].includes(ui.section))
    return <Redirect to="/" />;

  // ask user for bid password if necessary

  if (sessionService.isBidTokenExpired(session)) {
    return (
      <BidPasswordModal
        bodyText="For å kunne se bud så må du sende inn budpassord"
        onValid={({ token, expires, expireMinutes }) => {
          // save bid token
          dispatch(
            sessionActions.updateSession(
              sessionService.prepareBidTokenUpdateOb(
                token,
                expires,
                expireMinutes
              )
            )
          );
          // dispatch next action object
          if (ui.nextActionObjectOnValidBidToken)
            dispatch(ui.nextActionObjectOnValidBidToken);
          // remove next action object
          dispatch(actions.updateUi({ nextActionObjectOnValidBidToken: null }));
        }}
        onCancel={() =>
          dispatch(
            actions.updateUi({
              isCancelled: true,
              nextActionObjectOnValidBidToken: null,
            })
          )
        }
      />
    );
  }

  // prepare status vars

  let getFirstPageProcess = processes[`getFirstPage:${ui.section}`] || {};
  let loadMoreProcess = processes[`loadMore:${ui.section}`] || {};

  let nothingFound =
    !getFirstPageProcess.inProcess &&
    !getFirstPageProcess.hasError &&
    (!data.bids[ui.section] || !data.bids[ui.section].rows.length);

  // count how many rows to show

  let show = config.getBidsBy * (ui.showUntil[ui.section] + 1);

  return (
    <React.Fragment>
      {ui.showUpdateBidModal ? <UpdateBidModal /> : null}
      <Container className="MyBids my-4">
        <Row>
          <Col lg="3" md="12">
            <Filters
              showGeneralFilters={false}
              filters={filters[ui.section]}
              update={(updateOb) =>
                dispatch(actions.updateFilters({ [ui.section]: updateOb }))
              }
            />
          </Col>
          <Col lg="9" md="12" className="right-block">
            <Row>
              <Col lg="8" md="12" className="nav-links">
                <LinkContainer to="/mybids/active">
                  <div className="nav-link">Mine aktive bud</div>
                </LinkContainer>
                <LinkContainer to="/mybids/expired">
                  <div className="nav-link ml-3">Mine utløpte bud</div>
                </LinkContainer>
                <LinkContainer to="/mybids/bought">
                  <div className="nav-link ml-3">Mine kjøp</div>
                </LinkContainer>
              </Col>
              <Col lg="4" md="12" className="search-input">
                <SearchInput
                  value={filters[ui.section].filterValue}
                  onChange={(e) =>
                    dispatch(
                      actions.updateFilters({
                        [ui.section]: { filterValue: e.target.value },
                      })
                    )
                  }
                />
              </Col>
            </Row>
            <Row className="mt-4">
              {getFirstPageProcess.inProcess ? (
                <Col xs="12">
                  <Spinner />
                </Col>
              ) : null}
              {getFirstPageProcess.hasError ? (
                <Col xs="12" className="text-center">
                  <b className="text-danger">Det oppstod en feil</b>
                  <br />
                  <b>{getFirstPageProcess.errorMessage}</b>
                </Col>
              ) : null}
              {nothingFound ? (
                <Col xs="12" className="text-center">
                  <h4 className="text-color1 text-uppercase">
                    <b>Ingen treff</b>
                  </h4>
                </Col>
              ) : null}
              {!getFirstPageProcess.inProcess &&
              !getFirstPageProcess.hasError &&
              !nothingFound
                ? data.bids[ui.section].rows.slice(0, show).map((bid) => {
                    switch (ui.section) {
                      case "active":
                        return (
                          <Col xs="12" key={bid.id}>
                            <VehicleCard
                              type={`active_bid`}
                              bid={bid}
                              showOptions={getShowOptions(bid)}
                            />
                          </Col>
                        );
                        break;
                      case "expired":
                        return (
                          <Col xs="12" key={bid.id}>
                            <VehicleCard
                              type={`expired_bid`}
                              bid={bid}
                              showOptions={getShowOptions(bid)}
                            />
                          </Col>
                        );
                        break;
                      case "bought":
                        return (
                          <Col xs="12" key={bid.id}>
                            <VehicleCard type={`won_bid`} bid={bid} />
                          </Col>
                        );
                        break;
                    }
                  })
                : null}
              <Col xs="12" className="text-center mt-4">
                {loadMoreProcess.inProcess ? <Spinner size="medium" /> : null}
                {loadMoreProcess.hasError ? (
                  <div className="text-center">
                    <b className="text-danger">Det oppstod en feil</b>
                    <br />
                    <b>{loadMoreProcess.errorMessage}</b>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MyBids;
