import React from "react";
import { Container, Row, Col } from "reactstrap";

import * as cn from "../../../helpers/classname";

function AboutBlock({ className, onLandingPage }) {
  return (
    <div className={cn.join("AboutBlock", className)} id="AboutBlock">
      <Container>
        <Row>
          <Col xs="12">
            {onLandingPage ? (
              <h2 className="text-color1">
                <b>Spørsmål og svar</b>
              </h2>
            ) : null}
            <div className="lead text-color5">
              <p className="mb-0">
                Nedenfor finnes vanlige spørsmål og svar gruppert etter emne.
                Velg et emne for å se spørsmål og svar for det valgte emnet.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutBlock;
