import React, { useState } from "react";

import "./style.css";
import classname from "./../../../../helpers/classname";

const componentName = "StatusMessageCard";
const cn = classname(componentName);

const StatusMessageCard = ({ message }) => {
  return <div className={componentName}>{message}</div>;
};

export default StatusMessageCard;
