import {createStore, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga'
import {createLogger} from 'redux-logger';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const isDev = process.env.NODE_ENV === 'development';

let middlewares = [];

// create saga middleWare

const sagaMiddleWare = createSagaMiddleware();

middlewares.push(sagaMiddleWare);

// create logger middleware

if(isDev) {
  const logger = createLogger({
    collapsed: true,
    duration: true
  });

  middlewares.push(logger);
}

// create store with root reducer and middlewares

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares)
);

// run all sagas

sagaMiddleWare.run(rootSaga);

export default store;
