import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import * as selectors from "./redux/selectors";
import * as sessionSelectors from "./../common/redux/session/selectors";
import * as tradeConditionsSelectors from "./../TradeConditions/redux/selectors";
import * as userService from "./../common/services/user";
import { useScrollToTop, useTitle } from "../common/hooks";

import LoginPasswordForm from "./LoginPasswordForm";
import AuthenticatorCodeForm from "./AuthenticatorCodeForm";
import RecoveryCodeForm from "./RecoveryCodeForm";

export default function Login() {
  const ui = useSelector(selectors.getUi);
  const tradeConditionsData = useSelector(tradeConditionsSelectors.getData);
  const session = useSelector(sessionSelectors.getSession);

  useScrollToTop();
  useTitle("Logg inn");

  if (session.isAuthorized) {
    let isWorkshopUser = userService.isWorkshopUser(session);

    if (isWorkshopUser) {
      if (tradeConditionsData.tc.new) {
        return tradeConditionsData.tc.new.rows.length ? (
          <Redirect to="/tc/new" />
        ) : (
          <Redirect to="/home" />
        );
      }
    } else {
      return <Redirect to="/home" />;
    }

    /*if(!isWorkshopUser || (tradeConditionsData.tc.new && !tradeConditionsData.tc.new.rows.length)) {
      return <Redirect to="/home"/>;
    } else {
      return <Redirect to="/tc/new"/>;
    }*/
  }

  const { step } = ui;

  return (
    <div className="Login">
      <Container>
        <Row className="justify-content-center mt-5">
          <Col xs="12" sm="10" md="8" lg="6" className="mt-3">
            {step === 1 && <LoginPasswordForm />}
            {step === 2 && <AuthenticatorCodeForm />}
            {step === 3 && <RecoveryCodeForm />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
