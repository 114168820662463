import {combineReducers} from 'redux';

import * as actions from './actions';
import * as immutable from './../../common/helpers/immutable';

let initState = {
  password: {
    value: '',
    title: 'Passord',
    rules: ['required', {name: 'length', size: 10}]
  },
  confirmPassword: {
    value: '',
    title: 'Gjenta passord',
    rules: ['required', {name: 'length', size: 10}, {name: 'equal', with: 'password'}]
  },
  bidPassword: {
    value: '',
    title: 'Budpassord',
    rules: ['required', {name: 'length', size: 5}, {name: 'notequal', with: 'password'}]
  },
  confirmBidPassword: {
    value: '',
    title: 'Gjenta budpassord',
    rules: ['required', {name: 'length', size: 5}, {name: 'equal', with: 'bidPassword'}]
  }  
};

const form = (state = initState, action) => {
  switch (action.type) {
    case actions.UPDATE_FORM:
      return immutable.updateObjectProps(state, action.updateOb);
    default:
      return state;
  }
};

export default combineReducers({
  form
});

