import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";

import "./style.css";

import * as sessionSelectors from "./../common/redux/session/selectors";
import * as processesSelectors from "./../common/redux/processes/selectors";
import * as profileSelectors from "./../common/redux/profile/selectors";
import * as profileActions from "../common/redux/profile/actions";
import * as userService from "./../common/services/user";
import { routes } from "../common/config/routes";

import ChangePasswordModal from "./ChangePasswordModal";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";
import Spinner from "../common/components/elements/Spinner";
import ErrorBlock from "../common/components/blocks/ErrorBlock";

function MySettings() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const session = useSelector(sessionSelectors.getSession);
  const profile = useSelector(profileSelectors.getProfile);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function getProfileOnMount() {
    dispatch(profileActions.getProfile());
  }, []);

  const calculateMinOldPasswordLength = (session) => {
    let minOldPasswordLength = 5;
    const hasChangedPassword = userService.getHasChangedPassword(session);
    if (hasChangedPassword) {
      if (userService.isAdmin(session)) {
        minOldPasswordLength = 16;
      } else {
        minOldPasswordLength = 10;
      }
    }

    return minOldPasswordLength;
  };

  const calculateMinOldBidPasswordLength = (session) => {
    let minOldPasswordLength = 5;
    const hasChangedPassword = userService.getHasChangedBidPassword(session);
    if (hasChangedPassword) {
      minOldPasswordLength = 10;
    }

    return minOldPasswordLength;
  };

  const showMFASettings = window.config.showMFA;
  const isMFAEnabled = profile?.mfaEnabled;
  const isMFAAppInitialized = profile?.mfaAppInitialized;
  const validRecoveryCodesLeftCount = profile?.mfaValidRecoveryCodes;

  const getProfileProcess = processes[profileActions.GET_PROFILE] || {};

  return (
    <React.Fragment>
      {ui.showChangePasswordModal && <ChangePasswordModal />}
      <Container className="MySettings">
        <Row className="justify-content-center my-5">
          <Col md="8">
            <h1 className="text-color1">
              <b>Brukerinnstillinger</b>
            </h1>
            {getProfileProcess?.inProcess && (
              <div className="form1 mt-3">
                <Spinner />
              </div>
            )}
            {getProfileProcess?.hasError && (
              <div className="form1 mt-3">
                <ErrorBlock error={getProfileProcess} />
              </div>
            )}
            {getProfileProcess?.isSuccessed && (
              <div className="form1 mt-3">
                <div className="d-flex justify-content-between">
                  <h5 className="text-color10 cursor-pointer">
                    <b>Brukernavn</b>
                  </h5>
                  <span className="d-flex brukernavn">
                    {userService.getUsername(session)}
                  </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <h5 className="text-color10 cursor-pointer">
                    <b>Passord</b>
                  </h5>
                  <span
                    className="link-button"
                    onClick={() =>
                      dispatch(
                        actions.updateUi({
                          showChangePasswordModal: true,
                          changePasswordType: "password",
                          changePasswordAction: "change",
                          minOldPasswordLength:
                            calculateMinOldPasswordLength(session),
                          minNewPasswordLength: userService.isAdmin(session)
                            ? 16
                            : 10,
                        })
                      )
                    }
                  >
                    Endre
                  </span>
                </div>
                {userService.isWorkshopUser(session) ? (
                  <React.Fragment>
                    <hr />
                    <div className="d-flex justify-content-between mt-3">
                      <h5 className="text-color10 cursor-pointer">
                        <b>Budpassord</b>
                      </h5>
                      <div>
                        <span
                          className="link-button"
                          onClick={() =>
                            dispatch(
                              actions.updateUi({
                                showChangePasswordModal: true,
                                changePasswordType: "bid",
                                changePasswordAction: "reset",
                                minOldPasswordLength:
                                  calculateMinOldBidPasswordLength(session),
                                minNewPasswordLength: 10,
                              })
                            )
                          }
                        >
                          Tilbakestill
                        </span>
                        <span
                          className="link-button ml-3"
                          onClick={() =>
                            dispatch(
                              actions.updateUi({
                                showChangePasswordModal: true,
                                changePasswordType: "bid",
                                changePasswordAction: "change",
                                minOldPasswordLength:
                                  calculateMinOldBidPasswordLength(session),
                                minNewPasswordLength: 10,
                              })
                            )
                          }
                        >
                          Endre
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                ) : null}
                {((showMFASettings && userService.isCompanyOrAdmin(session)) ||
                  isMFAAppInitialized ||
                  isMFAEnabled ||
                  validRecoveryCodesLeftCount > 0) && (
                  <React.Fragment>
                    <hr />
                    <div className="d-flex justify-content-between mt-3">
                      <h5 className="text-color10 cursor-pointer">
                        <b>Tofaktorautentisering</b>
                      </h5>
                      <div className="d-flex flex-column align-items-end gap-10px">
                        {!isMFAAppInitialized && (
                          <LinkContainer to={routes.MFA_SETUP.path}>
                            <span className="link-button ml-3">
                              Sett opp autentiseringsappen
                            </span>
                          </LinkContainer>
                        )}
                        {isMFAAppInitialized && (
                          <LinkContainer
                            to={routes.MFA_RESET_AUTHENTICATOR_APP.path}
                          >
                            <span className="link-button ml-3">
                              Tilbakestill autentiseringsappen
                            </span>
                          </LinkContainer>
                        )}
                        {(isMFAEnabled || validRecoveryCodesLeftCount > 0) && (
                          <React.Fragment>
                            <span className="ml-3">
                              <LinkContainer
                                to={routes.MFA_RESET_RECOVERY_CODES.path}
                              >
                                <span className="link-button">
                                  Tilbakestill gjenopprettingskoder
                                </span>
                              </LinkContainer>
                              <div className="ml-1">
                                ({validRecoveryCodesLeftCount} ubrukte koder)
                              </div>
                            </span>
                          </React.Fragment>
                        )}
                        {isMFAEnabled && (
                          <LinkContainer to={routes.MFA_DISABLE.path}>
                            <span className="link-button ml-3">Deaktiver</span>
                          </LinkContainer>
                        )}
                        {!isMFAEnabled && isMFAAppInitialized && (
                          <LinkContainer to={routes.MFA_ENABLE.path}>
                            <span className="link-button ml-3">Aktiver</span>
                          </LinkContainer>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default MySettings;
