import * as config from './../config';

export const data = {
  faq: null,
  preview: {
    [config.previewModes.FOR_AUTHORIZED]: null,
    [config.previewModes.FOR_NOT_AUTHORIZED]: null,
  }
};

export const ui = {
  currentCategory: null
};