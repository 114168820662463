import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Button } from "reactstrap"; // see: https://reactstrap.github.io/components/buttons/

import "./style.css";

import * as cn from "../../../helpers/classname";

function ExtendedBootstrapButton({
  color,
  disabled,
  tag,
  active,
  size,
  onClick,
  outline,
  type,
  children,
  className,
}) {
  const buttonRef = useRef(null);

  const animate = () => {
    const elem = ReactDOM.findDOMNode(buttonRef.current);

    elem.className = elem.className.replace(/\s*animate/, "");

    // see: https://css-tricks.com/restart-css-animation/
    setTimeout(() => (elem.className += " animate"), 0);
  };

  return (
    <Button
      ref={buttonRef}
      className={cn.join(
        "Button",
        className,
        ["color2", "color7"].includes(color) && !outline && "apply-white",
        ["color2", "color7"].includes(color) &&
          outline &&
          "apply-white-on-hover",
        color === "color3" && outline && "apply-color1-background-on-hover"
      )}
      color={color}
      disabled={disabled}
      tag={tag}
      active={active}
      size={size}
      onClick={onClick}
      outline={outline}
      type={type}
      onMouseDown={() => animate()}
    >
      {children}
    </Button>
  );
}

export default ExtendedBootstrapButton;
