import React from 'react';

import classname from './../../../helpers/classname';

import './style.css';

const componentName = 'NavButtons';
const cn = classname(componentName);

const NavButtons = ({className, children}) => {
  return (
    <div className={cn.join(componentName, className)}>
      {children}
    </div>
  )
};

export const NavButton = ({className, component, children, ...rest}) => {
  let ButtonComponent = component || 'button';

  return (
    <ButtonComponent className={cn.join(cn('button'), className)} {...rest}>
      {children}
    </ButtonComponent>
  )
};

export default NavButtons;