import React from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';

import './style.css';

const currentYear = new Date().getFullYear();

const Footer = ({className}) => (
  <div className={['Footer', className || ''].join(' ')}>
    <Container>
      <Row>
        <Col className="text">
          Forsikringsbiler © {currentYear}
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;