import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "reactstrap";

import "./style.css";
import Filter from "./Filter";

import Checkbox from "./../../elements/Checkbox";
import InputText from "./../../elements/InputText";
import * as processesSelectors from "./../../../redux/processes/selectors";
import * as immutable from "./../../../helpers/immutable";
import * as userService from "./../../../services/user";
import * as sessionSelectors from "./../../../redux/session/selectors";
import * as commonDataActions from "./../../../redux/data/actions";
import * as commonDataSelectors from "./../../../redux/data/selectors";
import { processNames as commonDataProcessNames } from "./../../../redux/data/sagas";

function Filters({ filters, update, showGeneralFilters = true }) {
  const dispatch = useDispatch();

  const commonData = useSelector(commonDataSelectors.getData);
  const session = useSelector(sessionSelectors.getSession);
  const processes = useSelector(processesSelectors.getProcesses);

  useEffect(function getFilterOptionsDataOnMount() {
    if (!commonData.vehicleTypes) {
      dispatch(commonDataActions.fetchVehicleTypes());
    }
    if (!commonData.makes) {
      dispatch(commonDataActions.fetchMakes());
    }
    if (!commonData.counties) {
      dispatch(commonDataActions.fetchCounties());
    }
    if (!commonData.wreckActions) {
      dispatch(commonDataActions.fetchWreckActions());
    }
  }, []);

  const clearAll = () => {
    update({
      vehicleTypes: [],
      makes: [],
      counties: [],
      wreckActions: [],
      yearFrom: "",
      yearTo: "",
      filterValue: "",
      favorites: false,
      bids: false,
    });
  };

  const handleFilterCheckbox = (e, filterProperty, id) => {
    update({
      [filterProperty]: e.target.checked
        ? immutable.pushToArray(filters[filterProperty], id)
        : immutable.removeFromArray(filters[filterProperty], id),
    });
  };

  let fetchingInProcess = false;
  let fetchingHasError = false;
  let fetchingErrorMessage;

  for (let processName of [
    commonDataProcessNames.FETCH_VEHICLE_TYPES,
    commonDataProcessNames.FETCH_MAKES,
    commonDataProcessNames.FETCH_COUNTIES,
    commonDataProcessNames.FETCH_WRECK_ACTIONS,
  ]) {
    let p = processes[processName] || {};

    if (p.hasError) {
      fetchingHasError = true;
      fetchingErrorMessage = p.errorMessage;
      break;
    } else if (p.inProcess) {
      fetchingInProcess = true;
    }
  }

  return (
    <div className="Filters">
      <div className="header">
        <h6 className="text-uppercase text-color10">Filtre</h6>
        <span className="clear-all" onClick={clearAll}>
          Tøm alle
        </span>
      </div>
      {fetchingHasError ? (
        <div className="text-center py-4">
          <b className="text-danger ">Kunne ikke laste filter</b>
          <br />
          <b>{fetchingErrorMessage}</b>
        </div>
      ) : fetchingInProcess ? (
        /*<Spinner className="py-4"/>*/
        <div className="py-4 d-flex justify-content-center text-color6">
          Laster filter
        </div>
      ) : (
        <React.Fragment>
          {showGeneralFilters ? (
            <Filter header="Generelt">
              <Checkbox
                label="Favoritter"
                checked={filters.favorites}
                onChange={(e) => update({ favorites: e.target.checked })}
              />
              {userService.isWorkshopUser(session) ? (
                <Checkbox
                  label="Gitt bud"
                  checked={filters.bids}
                  onChange={(e) => update({ bids: e.target.checked })}
                />
              ) : null}
            </Filter>
          ) : null}
          <Filter header="Kjøretøytype">
            {commonData.vehicleTypes &&
              commonData.vehicleTypes.map((t) => {
                return (
                  <Checkbox
                    key={t.id}
                    label={t.text}
                    checked={filters.vehicleTypes.indexOf(t.id) !== -1}
                    onChange={(e) =>
                      handleFilterCheckbox(e, "vehicleTypes", t.id)
                    }
                  />
                );
              })}
          </Filter>
          <Filter header="Objektbehandling">
            {commonData.wreckActions &&
              commonData.wreckActions.map((t) => {
                return (
                  <Checkbox
                    key={t.id}
                    label={t.text}
                    checked={filters.wreckActions.indexOf(t.id) !== -1}
                    onChange={(e) =>
                      handleFilterCheckbox(e, "wreckActions", t.id)
                    }
                  />
                );
              })}
          </Filter>
          <Filter
            header="Merke"
            isCollapsable={true}
            isCollapsedByDefault={true}
          >
            {commonData.makes &&
              commonData.makes.map((t) => {
                return (
                  <Checkbox
                    key={t.id}
                    label={t.text}
                    checked={filters.makes.indexOf(t.id) !== -1}
                    onChange={(e) => handleFilterCheckbox(e, "makes", t.id)}
                  />
                );
              })}
          </Filter>
          <Filter
            header="Fylke"
            isCollapsable={true}
            isCollapsedByDefault={true}
          >
            {commonData.counties &&
              commonData.counties.map((t) => {
                return (
                  <Checkbox
                    key={t.id}
                    label={t.name}
                    checked={filters.counties.indexOf(t.id) !== -1}
                    onChange={(e) => handleFilterCheckbox(e, "counties", t.id)}
                  />
                );
              })}
          </Filter>
          <Filter header="Årsmodell" isCollapsable={true}>
            <Row>
              <Col xs="5">
                <InputText
                  label="Fra"
                  value={filters.yearFrom || ""}
                  onChange={(e) => update({ yearFrom: e.target.value })}
                />
              </Col>
              <Col xs="2">-</Col>
              <Col xs="5">
                <InputText
                  label="Til"
                  value={filters.yearTo || ""}
                  onChange={(e) => update({ yearTo: e.target.value })}
                />
              </Col>
            </Row>
          </Filter>
        </React.Fragment>
      )}
    </div>
  );
}

export default Filters;
