const _prefix = 'RESETPASSWORD::';

export const UPDATE_FORM = _prefix + 'UPDATE_FORM';
export const RESET_PASSWORD = _prefix + 'RESET_PASSWORD';
export const FETCH_MINPASSWORDLENGTH = _prefix + 'FETCH_MINPASSWORDLENGTH';
export const UPDATE_DATA = _prefix + 'UPDATE_DATA';

export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});
export const resetPassword = () => ({type: RESET_PASSWORD});
export const fetchMinPasswordLength = ({userId}) => ({type: FETCH_MINPASSWORDLENGTH, userId});
export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});