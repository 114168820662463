const _prefix = 'WORKSHOP_SIGNUPS::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';
export const UPDATE_FORM = _prefix + 'UPDATE_FORM';

export const FETCH_GRID = _prefix + 'FETCH_GRID';
export const FETCH_SIGNUP = _prefix + 'FETCH_SIGNUP';
export const FETCH_WORKSHOP_REGISTER = _prefix + 'FETCH_WORKSHOP_REGISTER';
export const UPDATE_SIGNUP = _prefix + 'UPDATE_SIGNUP';
export const UPDATE_SIGNUP_STATUS = _prefix + 'UPDATE_SIGNUP_STATUS';
export const SET_ME_AS_ASSIGNED = _prefix + 'SET_ME_AS_ASSIGNED';
export const SEND_ME_EMAIL = _prefix + 'SEND_ME_EMAIL';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = ({updateOb, triggerFetchGrid}) => ({type: UPDATE_UI, updateOb, triggerFetchGrid});
export const updateForm = (updateOb) => ({type: UPDATE_FORM, updateOb});

export const fetchGrid = () => ({type: FETCH_GRID});
export const fetchSignup = (id) => ({type: FETCH_SIGNUP, id});
export const fetchWorkshopRegister = ({id, workshopNumber}) => ({type: FETCH_WORKSHOP_REGISTER, id, workshopNumber});
export const updateSignup = (id) => ({type: UPDATE_SIGNUP, id});
export const updateSignupStatus = ({id, status}) => ({type: UPDATE_SIGNUP_STATUS, id, status});
export const setMeAsAssigned = (id) => ({type: SET_ME_AS_ASSIGNED, id});
export const sendMeEmail = (id) => ({type: SEND_ME_EMAIL, id});
