import React, { useRef, useState } from "react";
import ReactSlickSlider from "react-slick";
import { useDispatch, useSelector } from "react-redux";

import ArrowRightIcon from "./../../common/components/icons/ArrowRight";
import * as GalleryActions from "./../../Gallery/redux/actions";

import "./style.css";
import * as selectors from "./../redux/selectors";

const settings = {
  className: "ReactSlickSlider",
  slidesToShow: 4,
  slidesToScroll: 4,
  dots: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  autoplaySpeed: 5000,
  speed: 1000,
  responsive: [
    {
      breakpoint: 960, // lg
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

function Slider({ images }) {
  const dispatch = useDispatch();
  const sliderRef = useRef();

  const [mouseState, setMouseState] = useState("");
  const [touchState, setTouchState] = useState("");

  const data = useSelector(selectors.getData);

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const loadGallery = (current) => {
    let header = data.vehicle
      ? `${data.vehicle.makeText} ${data.vehicle.modelText || ""}`
      : "";

    dispatch(GalleryActions.update({ isOpen: true, images, current, header }));
  };

  return (
    <div className="Slider">
      <div className="control left-control" onClick={previous}>
        <ArrowRightIcon />
      </div>
      <div className="control right-control" onClick={next}>
        <ArrowRightIcon />
      </div>
      <ReactSlickSlider {...settings} ref={sliderRef}>
        {images.map((img, i) => {
          return (
            <div key={img}>
              <div
                className="slider-image"
                style={{ backgroundImage: `url(${img})` }}
                onMouseDown={() => {
                  setMouseState("down");
                }}
                onMouseMove={() => {
                  if (mouseState !== "move") setMouseState("move");
                }}
                onMouseUp={() => {
                  if (mouseState === "down") loadGallery(i);
                }}
                onTouchStart={() => {
                  setTouchState("start");
                }}
                onTouchMove={() => {
                  if (touchState !== "move") {
                    setTouchState("move");
                  }
                }}
                onTouchCancel={() => {
                  setTouchState("");
                }}
                onTouchEnd={() => {
                  if (touchState === "start") {
                    loadGallery(i);
                  }
                }}
              ></div>
            </div>
          );
        })}
      </ReactSlickSlider>
    </div>
  );
}

export default Slider;
