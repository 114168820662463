const _prefix = 'COMMONDATA::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';

export const FETCH_WRECK_ACTIONS = _prefix + 'FETCH_WRECK_ACTIONS';
export const FETCH_MAKES = _prefix + 'FETCH_MAKES';
export const FETCH_COUNTIES = _prefix + 'FETCH_COUNTIES';
export const FETCH_VEHICLE_TYPES = _prefix + 'FETCH_VEHICLE_TYPES';
export const FETCH_FAQ_CATEGORIES = _prefix + 'FETCH_FAQ_CATEGORIES';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});

export const fetchWreckActions = () => ({type: FETCH_WRECK_ACTIONS});
export const fetchMakes = () => ({type: FETCH_MAKES});
export const fetchCounties = () => ({type: FETCH_COUNTIES});
export const fetchVehicleTypes = () => ({type: FETCH_VEHICLE_TYPES});
export const fetchFaqCategories = () => ({type: FETCH_FAQ_CATEGORIES});