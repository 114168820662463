import React, { useCallback, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  RowDetailState,
  PagingState,
  SortingState,
  FilteringState,
  CustomPaging,
  DataTypeProvider,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableRowDetail,
  PagingPanel,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import { Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFilter } from "@fortawesome/fontawesome-free-solid";

import DateRangeFilter from "./../common/components/blocks/DateRangeFilter";
import * as processesSelectors from "./../../common/redux/processes/selectors";
import * as gridUtils from "./../../common/helpers/grid-utils";
import Spinner from "./../../common/components/elements/Spinner";
import Checkbox from "./../../common/components/elements/Checkbox";
import Radio from "./../../common/components/elements/Radio";
import AdminBidsGrid from "./../common/components/blocks/AdminBidsGrid";
import AdminHeader from "./../common/components/blocks/AdminHeader";
import * as immutable from "./../../common/helpers/immutable";
import * as commonDataSelectors from "./../../common/redux/data/selectors";
import * as commonDataActions from "./../../common/redux/data/actions";
import * as userService from "./../../common/services/user";
import * as sessionSelectors from "./../../common/redux/session/selectors";
import { useScrollToTop, useTitle } from "../../common/hooks";

import "./style.css";
import * as config from "./config";
import * as actions from "./redux/actions";
import * as selectors from "./redux/selectors";

const DateTypeProvider = gridUtils.DateTypeProvider;
const IntegerTypeProvider = gridUtils.IntegerTypeProvider;

function AdminEstimates() {
  const dispatch = useDispatch();

  const ui = useSelector(selectors.getUi);
  const data = useSelector(selectors.getData);
  const commonData = useSelector(commonDataSelectors.getData);
  const processes = useSelector(processesSelectors.getProcesses);
  const session = useSelector(sessionSelectors.getSession);

  useScrollToTop();
  useTitle("Tilbud");

  const updateUi = (updateOb, triggerFetchEstimates) => {
    dispatch(actions.updateUi({ updateOb, triggerFetchEstimates }));
  };

  const handleFilterCheckbox = (e, filterProperty, id) => {
    updateUi(
      {
        [filterProperty]: e.target.checked
          ? immutable.pushToArray(ui[filterProperty], id)
          : immutable.removeFromArray(ui[filterProperty], id),
      },
      true
    );
  };

  const TableRowDetailContentComponent = useCallback(({ row }) => {
    return <AdminBidsGrid estimateId={row.id} getBy="estimateId" />;
  }, []);

  const TableFilterRowCellComponent = useCallback((props) => {
    if (props.column.name === "acceptedText") {
      let isActive = !!ui.acceptedTextFilter;

      return (
        <th style={{ fontWeight: "normal" }}>
          <UncontrolledDropdown className="dropdown-filter dropdown1">
            <DropdownToggle
              className="filter-toggle-button"
              caret
              color="primary"
            >
              <FontAwesomeIcon
                icon={faCircle}
                className={[
                  "filter-indicator",
                  isActive ? "is-active" : "",
                ].join(" ")}
              />
              <span className="ml-2 mr-3">Filter</span>
            </DropdownToggle>
            <DropdownMenu right>
              <div className="text-center dropdown-item">
                <span
                  className="link-button"
                  onClick={() => updateUi({ acceptedTextFilter: "" }, true)}
                >
                  Tøm
                </span>
              </div>
              <div className="dropdown-item">
                <Radio
                  label="Akseptert etter frist"
                  name="acceptedTextFilter"
                  checked={ui.acceptedTextFilter == "acceptedAfterDeadline"}
                  onChange={(e) =>
                    updateUi(
                      { acceptedTextFilter: "acceptedAfterDeadline" },
                      true
                    )
                  }
                />
              </div>
              <div className="dropdown-item">
                <Radio
                  label="Ikke akseptert"
                  name="acceptedTextFilter"
                  checked={ui.acceptedTextFilter == "notAcceptedAfterDeadline"}
                  onChange={(e) =>
                    updateUi(
                      { acceptedTextFilter: "notAcceptedAfterDeadline" },
                      true
                    )
                  }
                />
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </th>
      );
    } else if (props.column.name === "wreckActionId") {
      return (
        <th style={{ fontWeight: "normal" }}>
          <UncontrolledDropdown className="dropdown-filter dropdown1">
            <DropdownToggle
              className="filter-toggle-button"
              caret
              color="primary"
            >
              <FontAwesomeIcon
                icon={faCircle}
                className={[
                  "filter-indicator",
                  ui.wreckActionsFilters.length ? "is-active" : "",
                ].join(" ")}
              />
              <span className="ml-2 mr-3">Filter</span>
            </DropdownToggle>
            <DropdownMenu right>
              <div className="text-center dropdown-item">
                <span
                  className="link-button"
                  onClick={() => updateUi({ wreckActionsFilters: [] }, true)}
                >
                  Tøm
                </span>
              </div>
              {commonData.wreckActions &&
                commonData.wreckActions.map((t) => {
                  return (
                    <div key={t.id} className="dropdown-item">
                      <Checkbox
                        label={t.text}
                        checked={ui.wreckActionsFilters.indexOf(t.id) !== -1}
                        onChange={(e) =>
                          handleFilterCheckbox(e, "wreckActionsFilters", t.id)
                        }
                      />
                    </div>
                  );
                })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </th>
      );
    } else if (["created", "bidDeadline"].includes(props.column.name)) {
      return (
        <DateRangeFilter
          columnName={props.column.name}
          ui={ui}
          updateUi={updateUi}
        />
      );
    }

    return <TableFilterRow.Cell {...props} />;
  }, []);

  const TableCellComponent = useCallback((props) => {
    let style =
      props.column.name === "acceptedText" &&
      props.row["hasBids"] &&
      props.row["afterAcceptDeadline"]
        ? { backgroundColor: "#ffcccc" }
        : null;

    return (
      <Table.Cell
        {...props}
        className="white-space-normal vertical-align-middle text-center"
        style={style}
      />
    );
  }, []);

  const TableRowComponent = useCallback(({ row, ...props }) => {
    const style =
      row.status === 7 || row.status === 8
        ? { backgroundColor: "#ffcccc" }
        : null;
    return (
      <Table.Row
        {...props}
        style={{
          ...style,
        }}
      />
    );
  }, []);

  useEffect(function fetchWreckActionsOnMount() {
    if (!commonData.wreckActions) {
      dispatch(commonDataActions.fetchWreckActions());
    }
  }, []);

  const getColumns = () => {
    let isBskAdmin = userService.isBskAdmin(session);
    let isAdmin = userService.isAdmin(session);
    let columns = config.columns;

    if (!isBskAdmin)
      columns = columns.filter((col) => col.name !== "companyNumber");
    if (!isAdmin) columns = columns.filter((col) => col.name !== "userName");

    return columns;
  };

  useEffect(function fetchEstimatesOnMount() {
    if (!data.estimates) {
      dispatch(actions.fetchEstimates());
    }
  }, []);

  useEffect(function resetOnUnmount() {
    return () => {
      updateUi({ expandedRowIds: [] });
    };
  }, []);

  let isDataPopulated = data.estimates;
  let fetchingProcess = processes.fetchEstimates || {};
  let isAdmin = userService.isAdmin(session);
  let columns = getColumns();

  if (ui.section === 1) {
    columns = columns.filter((col) => col.name !== "acceptedText");
  }

  return (
    <Container fluid className="AdminEstimates">
      <Row>
        <Col>
          <AdminHeader title="Tilbud" />
        </Col>
        <Col xs="12" className="nav-links">
          <div
            className={["nav-link", ui.section === 1 ? "active" : ""].join(" ")}
            onClick={() =>
              updateUi({ statuses: [1], section: 1, expandedRowIds: [] }, true)
            }
          >
            Aktive tilbud
          </div>
          <div
            className={["nav-link ml-3", ui.section === 2 ? "active" : ""].join(
              " "
            )}
            onClick={() =>
              updateUi(
                {
                  statuses: [2, 3, 4, 5, 6, 7, 8, 9],
                  section: 2,
                  expandedRowIds: [],
                },
                true
              )
            }
          >
            Avsluttede tilbud
          </div>
        </Col>
      </Row>
      <Row className="mb-5 mt-4">
        <Col className="grid-container">
          {isDataPopulated && !fetchingProcess.hasError && !ui.hideGrid ? (
            <Grid rows={data.estimates.rows} columns={columns}>
              <DataTypeProvider
                for={["id"]}
                formatterComponent={({ value }) => (
                  <Link to={`/browse/${value}`}>{value}</Link>
                )}
              />
              <IntegerTypeProvider for={["modelYear"]} />
              <DateTypeProvider
                for={["created", "bidDeadline", "acceptedTime"]}
              />
              <DataTypeProvider
                for={["wreckActionId"]}
                formatterComponent={({ value }) => {
                  if (!commonData.wreckActions) return null;
                  let mappedValue = commonData.wreckActions.filter((w) =>
                    w.id == value ? w : null
                  )[0];
                  return mappedValue ? mappedValue.text : "";
                }}
              />
              <PagingState
                currentPage={ui.currentPage}
                pageSize={ui.currentPageSize}
                onCurrentPageChange={(currentPage) =>
                  updateUi({ currentPage, expandedRowIds: [] }, true)
                }
                onPageSizeChange={(currentPageSize) =>
                  updateUi({ currentPageSize }, true)
                }
              />
              <RowDetailState
                expandedRowIds={ui.expandedRowIds}
                onExpandedRowIdsChange={(expandedRowIds) =>
                  updateUi({ expandedRowIds })
                }
              />
              <SortingState
                sorting={ui.sorting}
                columnExtensions={config.sortingColumnExtensions}
                onSortingChange={(sorting) =>
                  updateUi({ sorting, expandedRowIds: [] }, true)
                }
              />
              <FilteringState
                filters={ui.filters}
                onFiltersChange={(filters) =>
                  updateUi(
                    { filters, currentPage: 0, expandedRowIds: [] },
                    true
                  )
                }
                columnExtensions={config.filteringColumnExtensions}
              />
              <IntegratedFiltering
                columnExtensions={config.integratedFilteringColumnExtensions}
              />
              <CustomPaging totalCount={data.estimates.totalCount} />
              <Table
                messages={gridUtils.tableMessages}
                rowComponent={TableRowComponent}
                cellComponent={TableCellComponent}
              />
              {/*<TableColumnResizing
                    defaultColumnWidths={config.defaultColumnWidths}
                  />*/}
              <TableHeaderRow showSortingControls />
              <TableFilterRow
                messages={gridUtils.filterRowMessages}
                showFilterSelector
                iconComponent={gridUtils.FilterIcon}
                cellComponent={TableFilterRowCellComponent}
              />
              {isAdmin ? (
                <TableRowDetail
                  contentComponent={TableRowDetailContentComponent}
                />
              ) : null}
              <PagingPanel
                messages={gridUtils.pagingPanelMessages}
                pageSizes={config.pageSizes}
              />
            </Grid>
          ) : null}
          {fetchingProcess.inProcess ? (
            <div className="loading-block">
              <Spinner size="medium" />
            </div>
          ) : null}
          {fetchingProcess.hasError ? (
            <div className="text-center mt-5">
              <b className="text-danger">Det oppstod en feil</b>
              <br />
              <b>{fetchingProcess.errorMessage}</b>
            </div>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
}

export default AdminEstimates;
