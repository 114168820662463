export const getUi = (state) => state.Login.ui;
export const getData = (state) => state.Login.data;

export const getLoginPasswordForm = (state) => getUi(state).loginPasswordForm;
export const geLoginPasswordFormValues = (state) => {
  return Object.keys(getLoginPasswordForm(state)).reduce((valuesOb, key) => {
    valuesOb[key] = getLoginPasswordForm(state)[key].value;
    return valuesOb;
  }, {});
};

export const getAuthenticatorCodeForm = (state) =>
  getUi(state).authenticatorCodeForm;
export const getAuthenticatorCodeFormValues = (state) => {
  return Object.keys(getAuthenticatorCodeForm(state)).reduce(
    (valuesOb, key) => {
      valuesOb[key] = getAuthenticatorCodeForm(state)[key].value;
      return valuesOb;
    },
    {}
  );
};

export const getRecoveryCodeForm = (state) => getUi(state).recoveryCodeForm;
export const getRecoveryCodeFormValues = (state) => {
  return Object.keys(getRecoveryCodeForm(state)).reduce((valuesOb, key) => {
    valuesOb[key] = getRecoveryCodeForm(state)[key].value;
    return valuesOb;
  }, {});
};
