import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/fontawesome-free-solid";
import { useDispatch } from "react-redux";

import * as cn from "../../../helpers/classname";

import "./style.css";
import * as actions from "./redux/actions";

function FavoriteButton({ estimateId, isFavorite, className }) {
  const dispatch = useDispatch();

  return (
    <span
      className={cn.join(
        "FavoriteButton",
        className,
        isFavorite && "is-favorite"
      )}
    >
      <UncontrolledTooltip
        placement="top"
        target={`favorite-button${estimateId}`}
      >
        {isFavorite ? "Fjern fra favoritter" : "Legg til favoritter"}
      </UncontrolledTooltip>
      <FontAwesomeIcon
        icon={faHeart}
        id={`favorite-button${estimateId}`}
        onClick={() =>
          dispatch(
            actions.updateFavoriteFlag({ estimateId, newValue: !isFavorite })
          )
        }
      />
    </span>
  );
}

export default FavoriteButton;
