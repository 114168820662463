const _prefix = 'ADMIN_ESTIMATES::';

export const UPDATE_DATA = _prefix + 'UPDATE_DATA';
export const UPDATE_UI = _prefix + 'UPDATE_UI';

export const FETCH_ESTIMATES = _prefix + 'FETCH_ESTIMATES';

export const updateData = (updateOb) => ({type: UPDATE_DATA, updateOb});
export const updateUi = ({updateOb, triggerFetchEstimates}) => ({type: UPDATE_UI, updateOb, triggerFetchEstimates});

export const fetchEstimates = () => ({type: FETCH_ESTIMATES});