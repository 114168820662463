import React from 'react';

import FaqBlock, {previewModes} from '../../../common/components/blocks/Faq';
import BasicModal, {modalSizes} from '../../../common/components/blocks/BasicModal';

const mapModeToConfig = {
  [previewModes.FOR_AUTHORIZED]: {
    subHeader: '(for innloggede brukere)',
    switchButtonTitle: 'Bytt til forhåndsvisning for ikke-innloggede brukere',
    nextMode: previewModes.FOR_NOT_AUTHORIZED
  },
  [previewModes.FOR_NOT_AUTHORIZED]: {
    subHeader: '(for ikke-innloggede brukere)',
    switchButtonTitle: 'Bytt til forhåndsvisning for innloggede brukere',
    nextMode: previewModes.FOR_AUTHORIZED
  }
};

export const FaqPreviewModal = ({show, mode, updateUi}) => {
  return (
    <BasicModal
      show={show}
      size={modalSizes.XLARGE}
      onClose={() => updateUi({previewModal: {show: false}}, false)}
    >
      <header className="text-color1 mb-3 text-center">
        <h1>
          <b>Forhåndsvisning</b>
        </h1>
        <h5>{mapModeToConfig[mode].subHeader}</h5>
      </header>
      <div className="text-center mb-3" onClick={() => updateUi({previewModal: {mode: mapModeToConfig[mode].nextMode}}, false)}>
        <button className="link-button">{mapModeToConfig[mode].switchButtonTitle}</button>
      </div>
      <FaqBlock
        className="px-0"
        isPreviewMode={true}
        previewMode={mode}
      />
    </BasicModal>
  )
};

export default FaqPreviewModal;