export const isDev = process.env.NODE_ENV === "development";

export const isArray = (value) => {
  const valueType = Object.prototype.toString.call(value);

  return valueType === "[object Array]";
};

export const isBoolean = (value) => {
  const valueType = Object.prototype.toString.call(value);

  return valueType === "[object Boolean]";
};

export const getScrollbarWidth = () => {
  // taken from: https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};
