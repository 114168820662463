const _prefix = "PROCESSES::";

export const ADD_PROCESS = _prefix + "ADD_PROCESS";
export const SET_SUCCESS = _prefix + "SET_SUCCESS";
export const SET_ERROR = _prefix + "SET_ERROR";
export const UPDATE = _prefix + "UPDATE";
export const RESET_PROCESS = _prefix + "RESET_PROCESS";

export const addProcess = (processName) => ({ type: ADD_PROCESS, processName });
export const setSuccess = (processName, status) => ({
  type: SET_SUCCESS,
  processName,
  status,
});
export const setError = (processName, status, errorMessage, payload) => ({
  type: SET_ERROR,
  processName,
  status,
  errorMessage,
  payload,
});
export const update = (updateOb) => ({ type: UPDATE, updateOb });
export const resetProcess = (processName) => ({
  type: RESET_PROCESS,
  processName,
});
