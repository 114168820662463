import React, { useState } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";

import * as sessionSelectors from "./../../common/redux/session/selectors";

import "./style.css";
import UserDropdown from "./UserDropdown";
import { useSelector } from "react-redux";

function MainNavigation({ logout }) {
  const [isOpen, setIsOpen] = useState(false);

  const session = useSelector(sessionSelectors.getSession);

  const toggle = () => {
    setIsOpen((value) => !value);
  };

  return (
    <div className="MainNavigation">
      <Container>
        <Navbar color="faded" light expand="lg">
          <LinkContainer to={session.isAuthorized ? "/home" : "/"}>
            <NavbarBrand className="mr-5">
              <h4 className="text-color1">Forsikringsbiler</h4>
            </NavbarBrand>
          </LinkContainer>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar className="justify-content-between">
            <Nav navbar>
              {session.isAuthorized ? (
                <React.Fragment>
                  <LinkContainer to="/home" exact strict>
                    <NavItem className="mr-5">
                      <NavLink className="text-uppercase">Hjem</NavLink>
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/browse">
                    <NavItem className="mr-5">
                      <NavLink className="text-uppercase">Tilbud</NavLink>
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/faq">
                    <NavItem className="mr-5">
                      <NavLink className="text-uppercase">
                        Spørsmål og svar
                      </NavLink>
                    </NavItem>
                  </LinkContainer>
                </React.Fragment>
              ) : null}
            </Nav>
            <Nav navbar>
              {session.isAuthorized ? (
                <UserDropdown session={session} logout={logout} />
              ) : (
                <LinkContainer to="/login">
                  <NavItem>
                    <NavLink className="text-uppercase">Logg inn</NavLink>
                  </NavItem>
                </LinkContainer>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

// without withRouter rerendering of component wont work as expected
// see: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(MainNavigation);
