import * as config from './../config';
import dictionary from './../../../common/config/dictionary';

export const data = {
  grid: {}
};

export const ui = {
  currentPage: 0,
  currentPageSize: config.defaultPageSize,
  sorting: config.defaultSorting,
  filters: [],
  createEditModal: {
    show: false,
    id: null,
    form: {
      companyNumber: {
        id: 'companyNumber',
        label: 'Selskapsnummer',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      companyName: {
        id: 'companyName',
        label: 'Navn',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      homePage: {
        id: 'homePage',
        label: 'Hjemmeside',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      tradeCond: {
        id: 'tradeCond',
        label: 'Salgsbetingelser',
        value: '',
        hasError: false,
        errorMessage: '',
        rules: ['required']
      },
      accessDBSM: {
        label: 'Tilgang som standard',
        value: true,
        payload: dictionary.YES
      }
    }
  },
  tradeCondModal: {
    show: false,
    html: ''
  }
};