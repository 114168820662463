import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as sessionSelectors from "./../../../redux/session/selectors";
import * as sessionActions from "./../../../redux/session/actions";
import * as userService from "./../../../services/user";
import * as toasterActions from "./../../../../Toaster/redux/actions";
import { 
  sessionExpirationSafetyMarginInSeconds, 
  SESSION_HAS_EXPIRED_MESSAGE 
} from "../../../services/session";

function ProtectedRoute({ component, role, path }) {
  const dispatch = useDispatch();

  const session = useSelector(sessionSelectors.getSession);

  if (!session.isAuthorized) {
    return <Redirect to="/login" />;
  }

  const currentTime = new Date().getTime();
  const sessionExpirationTime =
    session.expirationTime - sessionExpirationSafetyMarginInSeconds * 1000; // Subtract x seconds to be on the safe side
  const isSessionExpired = sessionExpirationTime < currentTime;

  if (isSessionExpired && !session.refresh_token) {
    dispatch(sessionActions.unsetSession());

    dispatch(
      toasterActions.showSuccessMessage(SESSION_HAS_EXPIRED_MESSAGE)
    );

    return <Redirect to="/login" />;
  }

  if (role && !userService.mapRolesToMethods[role](session)) {
    return <Redirect to="/" />;
  }

  return <Route path={path} component={component} />;
}

// without withRouter re-rendering of component wont work as expected
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(ProtectedRoute);
